const {
  SET_USER,
  IS_COMPONENT_BUSY,
  SET_NOTIFICATION,
  RESET_NOTIFICATION_COUNTER,
  SET_NOTIFICATION_LIST,
} = require("../constants");

let initialState = {
  token: "",
  userData: {},
  isComponentBusy: false,
  notificationCounts: 0,
  notifications: [],
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_NOTIFICATION_COUNTER:
      let notifications = [];
      if (action.payload.isRead) {
        state.notifications.map((notificaiton) => {
          notificaiton.read = true;
          notifications.push(notificaiton);
        });
      }

      return {
        ...state,
        notifications: action.payload.isRead
          ? notifications
          : state.notifications,
        notificationCounts: action.payload.count > 0 ? action.payload.count : 0,
      };
    case SET_NOTIFICATION_LIST:
      return { ...state, notifications: [...action.payload] };
    case SET_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case SET_USER:
      return {
        ...state,
        token: action.payload.key,
        userData: action.payload.user,
      };
    case IS_COMPONENT_BUSY:
      return { ...state, isComponentBusy: action.payload };
    default:
      return state;
  }
};

export default UserReducer;
