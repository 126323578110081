import {
  Box,
  Grid,
  Avatar,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Card,
  Breadcrumbs,
  Paper,
  TextField,
  Button,
  FormHelperText,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonalInfo from "../assets/icons/PersonalInfo.svg";
import { Logo } from "../assets/assets";
import location from "../assets/icons/location.svg";
import IDCards from "../assets/icons/IDCards.svg";
import Slider from "../assets/images/Slider1.png";
import React, { useEffect, useState } from "react";
import PersonalDetail from "./ProfileDetails/PersonalDetail";
import ContactDetail from "./ProfileDetails/ContactDetail";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useGeneralProvider } from "../providers/GeneralProvider";
import { responsiveStyles } from "../assets/css/generalStyling";
import IDCard from "./ProfileDetails/IDCard";
import BankAcDetails from "./ProfileDetails/BankDetails";
import NomineeDetails from "./ProfileDetails/NomineeDetails";
import { useHistory } from "react-router";
import cameraIcon from "../assets/icons/camera.svg";
import FilePicker from "../utils/FilePicker";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { getProfileData, updatePersonalDetails } from "../store/actions/apis";
import { useAuth } from "../providers/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../store/actions/accountActions";
import { useFormik } from "formik";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSnackbar } from "notistack";

let queryString = require("query-string");

function ProfileEdit(deviceType, onBack, formMode, id) {
  const history = useHistory();
  const { userId } = useParams();

  const notification = useSnackbar();
  let search = queryString.parse(history.location.search);
  console.log("SEARCH: ", search.page);

  const renderForm = () => {
    switch (search.page) {
      case "personal":
        return 1;

      case "contact_info":
        return 2;

      case "id_cards":
        return 3;

      case "bank_details":
        return 4;

      case "nominee_details":
        return 5;
    }
  };

  const [selectedCard, setSelectedCard] = React.useState(renderForm());
  const general = useGeneralProvider();
  const responsive = responsiveStyles();
  const auth = useAuth();
  const [image, setImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [profileImageFile, setProfileImageFile] = useState("");
  const dispatch = useDispatch();
  const {
    PersonalData,
    BrikData,
    ContactData,
    IDCardData,
    BankAccountData,
    NomineeData,
  } = useSelector((state) => {
    return {
      PersonalData: state.account.personal_info,
      BrikData: state.myOwnerShip.myOwnerShips,
      ContactData: state.account.contact_info,
      IDCardData: state.account.id_cards,
      BankAccountData: state.account.bank_account_details,
      NomineeData: state.account.nominee_details,
    };
  });

  useEffect(() => {
    getProfileData(auth.getUser().id).then((result) => {
      setProfileImage(result.data.personal_info.profile_pic);
      dispatch(setProfileData(result.data));
    });

    general.setHeaderComponent(() => {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ textDecoration: "none" }} color="inherit">
            Home
          </Link>
          <Link style={{ textDecoration: "none" }} color="inherit">
            My Profile
          </Link>
          <Link color="inherit" style={{ textDecoration: "none" }}>
            Edit Profile
          </Link>
        </Breadcrumbs>
      );
    });
  }, []);

  const renderCards = () => {
    switch (selectedCard) {
      case 2:
        return (
          <ContactDetail
            onBack={() => {
              setProfile(false);
            }}
          />
        );
      case 3:
        return (
          <IDCard
            onBack={() => {
              setProfile(false);
            }}
          />
        );
      case 4:
        return (
          <BankAcDetails
            onBack={() => {
              setProfile(false);
            }}
          />
        );
      case 5:
        return (
          <NomineeDetails
            onBack={() => {
              setProfile(false);
            }}
          />
        );
      default:
        return (
          <PersonalDetail
            profilePic={profileImageFile}
            coverPic={imageFile}
            onBack={() => {
              setProfile(false);
            }}
          />
        );
    }
    // return selectedCard
    // return <PersonalDetail/>
  };
  const [Profile, setProfile] = useState(false);

  // const {
  //   values,
  //   touched,
  //   errors,
  //   handleSubmit,
  //   handleChange,
  //   handleBlur,
  //   setValues,
  //   setFieldValue,
  //   submitForm,
  // } = useFormik({
  //   initialValues: {
  //     name: "",
  //     email: "",
  //     number: "",
  //     address: "",
  //   },
  //   onSubmit: () => {
  //     if (userId) {
  //         updatePersonalDetails(userId, {
  //             name: values.name,
  //             email: values.email,
  //             number: values.number,
  //             address: values.address,
  //           }).then((result) => {
  //           if (deviceType == "website") {
  //             onBack();
  //           } else history.goBack();
  //           notification.enqueueSnackbar("Profile Updated Successfully", {
  //             variant: "success",
  //           });
  //         });
  //       }
  //       if (formMode == "edit") {
  //         updatePersonalDetails(id, {
  //           name: values.name,
  //             email: values.email,
  //             number: values.number,
  //             address: values.address,
  //           }).then((result) => {
  //           if (deviceType == "website") {
  //             onBack();
  //           } else history.goBack();
  //           notification.enqueueSnackbar("Profile Updated Successfully", {
  //             variant: "success",
  //           });
  //         });
  //       }
  //   },
  //   // validationSchema: updatePersonalDetailsValidation,
  // });

  return (
    <>
      <Box className={responsive.web}>
        <Grid container>
          <Grid style={{ padding: "30px" }} item xs={12} sm={6}>
            <Card
              style={{ position: "relative", width: "85%", padding: "30px" }}
            >
              <img
                style={{
                  position: "absolute",
                  width: "100%",
                  left: "0px",
                  objectFit: "cover",
                  height: "150px",
                  top: "0px",
                }}
                width="100%"
                src={Slider}
              />

              <Box
                style={{ position: "relative", bottom: "23px", left: "324px" }}
              >
                <FilePicker
                  type="component"
                  fileHandler={(file, alt) => {
                    // console.log("FILE PICKED: ", URL.createObjectURL(file))
                    setImageFile(file);
                    setImage(URL.createObjectURL(file));
                  }}
                >
                  <IconButton size="small" style={{ height: "30px" }}>
                    {/* <img src={cameraIcon}
                    style={{ width: "26px", height: "15px" }} /> */}
                  </IconButton>
                </FilePicker>
              </Box>

              {/* <Box>
              <Grid item xs="12">
              <TextField
                id="name"
                name="name"
                label="Name"
                fullWidth
                placeholder="Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
                helperText={touched.name && errors.name}
              />
            </Grid>
              </Box> */}

              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  position: "relative",
                  top: "65px",
                }}
              >
                <Box
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Avatar
                    src={profileImage}
                    style={{ height: "70px", width: "70px" }}
                  />
                  <FilePicker
                    type="component"
                    fileHandler={(file, alt) => {
                      // console.log("FILE PICKED: ", URL.createObjectURL(file))
                      setProfileImageFile(file);
                      setProfileImage(URL.createObjectURL(file));
                    }}
                  >
                    <IconButton
                      size="small"
                      style={{
                        height: "30px",
                        position: "absolute",
                        right: "0px",
                        top: "50px",
                      }}
                    >
                      <img
                        src={cameraIcon}
                        style={{ width: "26px", height: "15px" }}
                      />
                    </IconButton>
                  </FilePicker>
                </Box>
                <Box style={{ paddingLeft: "12px" }}>
                  <Box fontWeight={600}>
                    {PersonalData.first_name} {PersonalData.last_name}
                  </Box>
                  {/* <Box>
                    Delhi, India
                </Box> */}
                </Box>
              </Box>
              <Box style={{ marginTop: "100px" }}>
                <Paper
                  onClick={() => {
                    setSelectedCard(1);
                  }}
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                  elevation={1}
                >
                  <Box marginTop="5px">
                    <img src={PersonalInfo} />
                  </Box>
                  <Box fontWeight="600" marginLeft="10px">
                    Personal Info
                  </Box>
                  <Box flex={1} textAlign="right">
                    <ChevronRightIcon />
                  </Box>
                </Paper>
              </Box>

              <Box>
                <Paper
                  onClick={() => {
                    setSelectedCard(2);
                  }}
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                  elevation={1}
                >
                  <Box marginTop="5px">
                    {ContactData.house_number ? (
                      <img src={PersonalInfo} />
                    ) : (
                      <img src={IDCards} />
                    )}
                  </Box>
                  <Box fontWeight="600" marginLeft="10px">
                    Contact Info
                  </Box>
                  <Box flex={1} textAlign="right">
                    <ChevronRightIcon />
                  </Box>
                </Paper>
              </Box>

              <Box padding="10px 0">
                <Paper
                  onClick={() => {
                    setSelectedCard(3);
                  }}
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                  elevation={1}
                >
                  <Box marginTop="5px">
                    {IDCardData.length ? (
                      <img src={PersonalInfo} />
                    ) : (
                      <img src={IDCards} />
                    )}
                  </Box>
                  <Box fontWeight="600" marginLeft="10px">
                    ID Cards
                  </Box>
                  <Box flex={1} textAlign="right">
                    <ChevronRightIcon />
                  </Box>
                </Paper>
              </Box>

              <Box>
                <Paper
                  onClick={() => {
                    setSelectedCard(4);
                  }}
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                  elevation={1}
                >
                  <Box marginTop="5px">
                    {BankAccountData?.bank_name ? (
                      <img src={PersonalInfo} />
                    ) : (
                      <img src={IDCards} />
                    )}
                  </Box>
                  <Box fontWeight="600" marginLeft="10px">
                    Bank Account Details
                  </Box>
                  <Box flex={1} textAlign="right">
                    <ChevronRightIcon />
                  </Box>
                </Paper>
              </Box>

              <Box padding="10px 0">
                <Paper
                  onClick={() => {
                    setSelectedCard(5);
                  }}
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                  elevation={1}
                >
                  <Box marginTop="5px">
                    {NomineeData?.full_name ? (
                      <img src={PersonalInfo} />
                    ) : (
                      <img src={IDCards} />
                    )}
                  </Box>
                  <Box fontWeight="600" marginLeft="10px">
                    Nominee Details
                  </Box>
                  <Box flex={1} textAlign="right">
                    <ChevronRightIcon />
                  </Box>
                </Paper>
              </Box>
            </Card>
          </Grid>

          <Grid style={{ padding: "30px" }} item xs={12} sm={6}>
            <Card style={{ padding: "30px" }}>{renderCards()}</Card>
          </Grid>
        </Grid>
      </Box>

      <Box className={responsive.app}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            {Profile == false && (
              <>
                <Card style={{ position: "relative" }}>
                  <img
                    style={{
                      position: "absolute",
                      width: "100%",
                      left: "0px",
                      height: "150px",
                      top: "0px",
                    }}
                    width="100%"
                    src={Slider}
                  />

                  {/* <Box style={{ position: "absolute", top: "6px", right: "10px" }}>
                  <IconButton size="small" style={{ height: "30px" }}>
                    <img src={cameraIcon}
                      style={{ width: "26px", height: "15px" }} />
                  </IconButton>
                </Box> */}

                  <Box
                    display="flex"
                    style={{
                      width: "100%",
                      position: "absolute",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    <Box
                      display="flex"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <Box>
                        <ChevronLeftIcon />
                      </Box>
                      <Box>Back</Box>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      position: "relative",
                      top: "95px",
                      marginTop: "18px",
                      padding: "10px",
                    }}
                  >
                    <Avatar
                      src={profileImage}
                      style={{ height: "70px", width: "70px" }}
                    />

                    <Box
                      style={{
                        position: "absolute",
                        top: "60px",
                        left: "46px",
                      }}
                    >
                      <FilePicker
                        type="component"
                        fileHandler={(file, alt) => {
                          setProfileImageFile(file);
                          setProfileImage(URL.createObjectURL(file));
                        }}
                      >
                        <IconButton size="small" style={{ height: "30px" }}>
                          <img
                            src={cameraIcon}
                            style={{ width: "26px", height: "15px" }}
                          />
                        </IconButton>
                      </FilePicker>
                    </Box>

                    <Box style={{ paddingLeft: "12px" }}>
                      <Box fontWeight={600}>
                        {PersonalData.first_name} {PersonalData.last_name}
                      </Box>
                      {/* <Box>
                        Delhi, India
                    </Box> */}
                    </Box>
                  </Box>

                  <Box
                    style={{ marginTop: "100px", padding: "0px 12px" }}
                    padding="10px 0"
                  >
                    <Paper
                      onClick={() => {
                        setProfile(true);
                        setSelectedCard(1);
                      }}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      elevation={1}
                    >
                      <Box marginTop="5px">
                        <img src={PersonalInfo} />
                      </Box>
                      <Box fontWeight="600" marginLeft="10px">
                        Personal Info
                      </Box>
                      <Box flex={1} textAlign="right">
                        <ChevronRightIcon />
                      </Box>
                    </Paper>
                  </Box>

                  <Box style={{ padding: "0px 12px" }}>
                    <Paper
                      onClick={() => {
                        setProfile(true);
                        setSelectedCard(2);
                      }}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      elevation={1}
                    >
                      <Box marginTop="5px">
                        {ContactData.house_number ? (
                          <img src={PersonalInfo} />
                        ) : (
                          <img src={IDCards} />
                        )}
                      </Box>
                      <Box fontWeight="600" marginLeft="10px">
                        Contact Info
                      </Box>
                      <Box flex={1} textAlign="right">
                        <ChevronRightIcon />
                      </Box>
                    </Paper>
                  </Box>

                  <Box style={{ padding: "0px 12px" }}>
                    <Paper
                      onClick={() => {
                        setProfile(true);
                        setSelectedCard(3);
                      }}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      elevation={1}
                    >
                      <Box marginTop="5px">
                        {IDCardData.length ? (
                          <img src={PersonalInfo} />
                        ) : (
                          <img src={IDCards} />
                        )}
                      </Box>
                      <Box fontWeight="600" marginLeft="10px">
                        ID Cards
                      </Box>
                      <Box flex={1} textAlign="right">
                        <ChevronRightIcon />
                      </Box>
                    </Paper>
                  </Box>

                  <Box style={{ padding: "0px 12px" }}>
                    <Paper
                      onClick={() => {
                        setProfile(true);
                        setSelectedCard(4);
                      }}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      elevation={1}
                    >
                      <Box marginTop="5px">
                        {BankAccountData?.bank_name ? (
                          <img src={PersonalInfo} />
                        ) : (
                          <img src={IDCards} />
                        )}
                      </Box>
                      <Box fontWeight="600" marginLeft="10px">
                        Bank Account Details
                      </Box>
                      <Box flex={1} textAlign="right">
                        <ChevronRightIcon />
                      </Box>
                    </Paper>
                  </Box>

                  <Box style={{ padding: "0px 12px" }}>
                    <Paper
                      onClick={() => {
                        setProfile(true);
                        setSelectedCard(5);
                      }}
                      style={{
                        padding: "10px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      elevation={1}
                    >
                      <Box marginTop="5px">
                        {NomineeData?.full_name ? (
                          <img src={PersonalInfo} />
                        ) : (
                          <img src={IDCards} />
                        )}
                      </Box>
                      <Box fontWeight="600" marginLeft="10px">
                        Nominee Details
                      </Box>
                      <Box flex={1} textAlign="right">
                        <ChevronRightIcon />
                      </Box>
                    </Paper>
                  </Box>
                </Card>
              </>
            )}
          </Grid>

          {Profile == true && (
            <Grid item xs={12} sm={6}>
              <Card style={{ padding: "20px" }}>{renderCards()}</Card>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default ProfileEdit;
