export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const IS_COMPONENT_BUSY = "IS_COMPONENT_BUSY";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
export const RESET_NOTIFICATION_COUNTER = "RESET_NOTIFICATION_COUNTER";
export const GET_MY_OWNERSHIPS = "GET_MY_OWNERSHIPS";
export const IS_COMPONENT_BUSY_OWNERSHIP = "IS_COMPONENT_BUSY_OWNERSHIP";
export const SET_COOWNERS = "SET_COOWNERS";
export const SET_TRANSACTION = "SET_TRANSACTION";

export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";

export const SET_META_DATA = "SET_META_DATA";
export const SET_DASHBOARD_DATA = "SET_META_DATASET_DASHBOARD_DATA";
export const GET_ALL_LEADS = "GET_ALL_LEADS";
export const SET_ALL_LEADS = "SET_ALL_LEADS";
export const SET_SEARCH_LEADS = "SET_SEARCH_LEADS";

export const SIZE = {
  XS: 599,
  SM: 899,
  MD: 1199,
  LG: 1538,
  XL: 1539, //and above
};
