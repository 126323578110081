import React from "react";
import {
  Box,
  Avatar,
  IconButton,
  makeStyles,
  Button,
  Card,
  Select,
  MenuItem,
  Grid
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Faq from "../assets/icons/graph/faq.png";
import { responsiveStyles } from "../assets/css/generalStyling";

function FAQ() {

  const responsive = responsiveStyles();

  return (
    <>
      <Box className={responsive.web}>
      <Box style={{ fontSize: "22px", padding: "12px" }}>FAQ</Box>
      <Grid container>
      <Grid item md={8}>
      <Box padding="8px 0">
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                HOW DOES BRIKITT COMPENSATE REAL ESTATE AGENTS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                {" "}
                BRIKitt works in collaboration with agents and pays a minimum of 6 % buyers agent commission.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I HAVE A BUYER INTERESTED IN A BRIK. WHAT'S THE NEXT STEP?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                Your buyer can contact us directly, but you're welcome to schedule an in-person or virtual tour on your buyer's behalf. Visit www.brikitt.com , and click on projects on the listing page for the home they're interested in and you can book their BRIK on their behalf from the web itself by filling up a simple form and KYC.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I’M UNFAMILIAR WITH THE BRIKITT PROCESS. HOW DOES IT COMPARE TO A WHOLE HOME SALE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                The paperwork and process are similar to a whole home sale. The main difference is you get an easy commission because BRIKitt handles everything!
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                HOW DO YOU KEEP ME IN THE LOOP ON THE TRANSACTION?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                You can be as involved as you want. Or, it can be a simple handoff. Just let us know what you prefer. You can track yours clint entire sales journey through the BRIKitt CRM once you register you Clint in it.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                IS COMMISSION PAID ON THE WHOLE HOME PRICE OR THE BRIK PRICE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                Commision is paid on the BRIK price. If you bring multiple buyers, you'll receive a commission for each BRIK sold.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                DO I HAVE TO WAIT UNTIL ALL BRIKS OF THE HOME ARE SOLD TO GET PAID?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                No! You collect payment with in 30 days from the day you sell the BRIK and Payment gets credited to BRIKitt.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I HAVE A PROJECT /PROPERTY THAT WOULD BE A GREAT BRIKITT . HOW DO I SHARE IT WITH YOU?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                mail your listing at info@brikitt.com and forward details of your listing. If it’s a fit, we’ll follow up.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I WANT TO REPRESENT BRIKITT AS A LISTING AGENT. ARE YOU LOOKING FOR NEW AGENT PARTNERS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                We engage buy-side agents who have sold 100+ BRIKs with the opportunity to represent us as Ambassador Agents.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I WANT TO PROMOTE BRIKITT TO MY NETWORK. WHAT DO YOU RECOMMEND?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                We have a robust toolkit of ready-to-use marketing materials for agents. They include agent education videos , buyer education videos and a suite of Collatrals that can be co-branded. Find it all at our website , Google and on our YouTube channel.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                WHAT IF I HAVE A CLIENT INTERESTED IN SELLING PART OF THEIR HOME TO BRIKITT?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                We’d love to learn more about it. mail the details at info@brikitt.com  to submit the home for consideration. If it’s a fit, we’ll follow up. 
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      </Grid>
      <Grid item ms={3}>
          <img src={Faq}/>
      </Grid>
      </Grid>
      </Box>
      <Box className={responsive.app}>
      <Box style={{ fontSize: "22px", padding: "12px" }}>FAQ</Box>
      <Box padding="8px 0">
        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                HOW DOES BRIKITT COMPENSATE REAL ESTATE AGENTS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                {" "}
                BRIKitt works in collaboration with agents and pays a minimum of 6 % buyers agent commission.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I HAVE A BUYER INTERESTED IN A BRIK. WHAT'S THE NEXT STEP?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                Your buyer can contact us directly, but you're welcome to schedule an in-person or virtual tour on your buyer's behalf. Visit www.brikitt.com , and click on projects on the listing page for the home they're interested in and you can book their BRIK on their behalf from the web itself by filling up a simple form and KYC.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I’M UNFAMILIAR WITH THE BRIKITT PROCESS. HOW DOES IT COMPARE TO A WHOLE HOME SALE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                The paperwork and process are similar to a whole home sale. The main difference is you get an easy commission because BRIKitt handles everything!
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                HOW DO YOU KEEP ME IN THE LOOP ON THE TRANSACTION?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                You can be as involved as you want. Or, it can be a simple handoff. Just let us know what you prefer. You can track yours clint entire sales journey through the BRIKitt CRM once you register you Clint in it.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                IS COMMISSION PAID ON THE WHOLE HOME PRICE OR THE BRIK PRICE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                Commision is paid on the BRIK price. If you bring multiple buyers, you'll receive a commission for each BRIK sold.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                DO I HAVE TO WAIT UNTIL ALL BRIKS OF THE HOME ARE SOLD TO GET PAID?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                No! You collect payment with in 30 days from the day you sell the BRIK and Payment gets credited to BRIKitt.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I HAVE A PROJECT /PROPERTY THAT WOULD BE A GREAT BRIKITT . HOW DO I SHARE IT WITH YOU?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                mail your listing at info@brikitt.com and forward details of your listing. If it’s a fit, we’ll follow up.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I WANT TO REPRESENT BRIKITT AS A LISTING AGENT. ARE YOU LOOKING FOR NEW AGENT PARTNERS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                We engage buy-side agents who have sold 100+ BRIKs with the opportunity to represent us as Ambassador Agents.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                I WANT TO PROMOTE BRIKITT TO MY NETWORK. WHAT DO YOU RECOMMEND?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                We have a robust toolkit of ready-to-use marketing materials for agents. They include agent education videos , buyer education videos and a suite of Collatrals that can be co-branded. Find it all at our website , Google and on our YouTube channel.
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box padding="8px 0">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  paddingLeft: "8px",
                  fontWeight: "600",
                  color: "#565656",
                }}
              >
                WHAT IF I HAVE A CLIENT INTERESTED IN SELLING PART OF THEIR HOME TO BRIKITT?
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                style={{
                  fontSize: "11px",
                  padding: "0 10px",
                  color: "#8A9EBC",
                }}
              >
                We’d love to learn more about it. mail the details at info@brikitt.com  to submit the home for consideration. If it’s a fit, we’ll follow up. 
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      </Box>
    </>
  );
}

export default FAQ;
