import { makeStyles } from '@material-ui/core';
import { Box, Grid, Paper, Tab, Tabs, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import images from '../assets/images/Slider1.png';
import { getPropertyDetails } from '../store/actions/apis';
import COwners from './PropertyDetails/COwners';
import Docs from './PropertyDetails/Docs';
import Growth from './PropertyDetails/Growth';
import Overview from './PropertyDetails/Overview';
import Review from './PropertyDetails/Review';
import Schedule from './PropertyDetails/Schedule';
import Transaction from './PropertyDetails/Transaction';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import OWLSlider from '../component/Slider'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles(theme => ({
    TabScroller: {
        overflow: "auto !important"
    },
    web: {
        visibility: "block"
    },
    app: {
        display: "none"
    },
    [theme.breakpoints.down('sm')]: {
        web: {
            display: "none"
        },
        app: {
            display: "block"
        },
    }
}))

function DetailView() {
    const [selectedTab, setselectedTab] = React.useState(0);
    const history = useHistory()
    const [propertyDetails, setPropertyDetails] = React.useState({ amenities: [{}], property_detail: [{}], brik_image: [{}], spv_info: [{}] });
    const classes = useStyles()
    const handleChange = (event, newValue) => {
        setselectedTab(newValue);
    };

    const { id } = useParams()

    const img = {
        display: 'Block',
        margin: 'auto',
        maxWidth: '100%',
        maxHeight: '100%'
    }

    // useEffect(() => {
    //     console.log("propertyDetails: ",propertyDetails)
    // })
    useEffect(() => {
        // console.log("propertyDetails: ",propertyDetails)
        getPropertyDetails(id).then(result => {
            const { data } = result
            setPropertyDetails(data)
        })
    }, [id])

    const renderScreen = () => {
        switch (selectedTab) {
            case 1:
                return <COwners propertyDetails={propertyDetails} />
            case 2:
                return <Growth propertyDetails={propertyDetails} />
            case 3:
                return <Transaction propertyDetails={propertyDetails} />
            case 4:
                return <Docs propertyDetails={propertyDetails} />
            case 5:
                return <Schedule propertyDetails={propertyDetails} />
            case 6:
                return <Review propertyDetails={propertyDetails} />
            default:
                return <Overview propertyDetails={propertyDetails} />
        }
    }

    return (
        <>
            <Box className={classes.web} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Tabs
                            // TabIndicatorProps={{
                            //     style: {
                            //         display: "none",
                            //     },
                            // }} 
                            value={selectedTab} classes={{ scroller: classes.TabScroller }} onChange={handleChange}>
                            <Tab label='OVERVIEW'></Tab>
                            <Tab label='CO OWNERS'></Tab>
                            <Tab label='GROWTH'></Tab>
                            <Tab label='PAYMENTS'></Tab>
                            <Tab label='DOCS'></Tab>
                            <Tab label='SCHEDULE'></Tab>
                            <Tab label='REVIEW'></Tab>
                        </Tabs>
                        <hr style={{ border: "1px solid #2b2a2a0d" }} />
                    </Grid>
                </Grid>
                {/* <Overview display={selectedTab == 0 ? "visible" : "hidden"} />
                    <COwners display={selectedTab == 1 ? "visible" : "hidden"} />
                    <Growth display={selectedTab == 2 ? "visible" : "hidden"} /> */}
                <Box style={{ padding: "10px 30px" }}>
                    {renderScreen()}
                </Box>
            </Box>


            <Box className={classes.app}  style={{padding:"10px"}} >
                <Grid container spacing={2}>
                    <Grid style={{ position: "relative" }} item xs={12}>
                        <Box>
                            {/* <OWLSlider items={1} data={propertyDetails.brik_image} render={(slider, index) => {
                                return (
                                    <img width="100%" style={img} src={slider.image} alt='image' />
                                )
                            }} /> */}
                            <Carousel showThumbs={false} showStatus={false}>
                                {propertyDetails.brik_image.map(items => {
                                    return <div>
                                        <img style={{width:"100%",objectFit:"cover",height:"250px"}} src={items.image} />
                                    </div>
                                })}
                            </Carousel>
                            {/* <img width="100%" style={img} src={propertyDetails.brik_cover_image} alt='image' /> */}
                        </Box>
                        <Box style={{ width: "90%", position: "absolute", top: "20px", display: "flex", alignItems: "center" }}>
                            <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                        <IconButton onClick={() => {
                                            history.push('/my-ownership')
                                        }}>
                                            <ArrowBackIosIcon style={{ color: "white" }} />
                                        </IconButton>
                                    </Box>
                                    <Box style={{ color: "white" }} fontSize="24px" fontWeight="600">{propertyDetails.property_name}</Box>
                                </Box>

                                <Box border='1px solid black' borderRadius='10px' padding='0 8px' color='#FF9E12' fontWeight='600' style={{ backgroundColor: '#FFF9E7', maxHeight: "24px" }}>{propertyDetails.property_type}</Box>
                            </Box>
                        </Box>
                        <Box>
                            <Tabs value={selectedTab} classes={{ scroller: classes.TabScroller }} onChange={handleChange}>
                                <Tab label='OVERVIEW'></Tab>
                                <Tab label='CO OWNERS'></Tab>
                                <Tab label='GROWTH'></Tab>
                                <Tab label='PAYMENTS'></Tab>
                                <Tab label='DOCS'></Tab>
                                <Tab label='SCHEDULE'></Tab>
                                <Tab label='REVIEW'></Tab>
                            </Tabs>
                        </Box>
                    </Grid>
                </Grid>
                <Box >
                    {renderScreen()}
                </Box>
            </Box>
        </>
    )
}

export default DetailView
