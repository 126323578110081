import axios from "axios";
import { identity, result } from "lodash";
import http from "../../http";

export const fetchUserData = async ({ id }) => {
  return http("GET", "posts", { isAuthenticated: false });
};

export const documentByID = async (document_id) => {
  return http(
    "GET",
    `workflows/docs/${document_id}/`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const deleteBulkLead = async (apiBody) => {
  return http(
    "DELETE",
    "leads/bulklead/",
    {
      ...apiBody,
    },
    {
      isAuthenticated: true,
      // headers: {
      //   contentType:
      //     "'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   responseType: "arraybuffer",
      // },
    }
  )
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateBulkLead = async (apiBody) => {
  return http(
    "PATCH",
    "leads/bulklead/",
    {
      ...apiBody,
    },
    {
      isAuthenticated: true,
      // headers: {
      //   contentType:
      //     "'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   responseType: "arraybuffer",
      // },
    }
  )
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const exportTableData = async (queryString) => {
  let endpoint = "";

  if (queryString == "") endpoint = `leads/download/`;
  else endpoint = `leads/download/?${queryString}`;

  return http(
    "GET",
    endpoint,
    {},
    {
      isAuthenticated: true,
      responseType: "blob",
      // headers: {
      //   contentType:
      //     "'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //   responseType: "arraybuffer",
      // },
    }
  )
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const leads = async (queryString) => {
  console.log("queryString: ", queryString);
  // return {
  //   count: 9,
  //   next: "http://localhost:8000/api/leads/new/?limit=5&offset=5&search=new",
  //   previous: null,
  //   results: [
  //     {
  //       id: 11,
  //       name: "Mukul Jain",
  //       description: null,
  //       status: "new",
  //       gig: null,
  //       start_date: null,
  //       end_date: null,
  //       priority: "Hot",
  //       source: "google",
  //       files: null,
  //       moved_on: null,
  //       last_moved_type: null,
  //       lost_reason: null,
  //       client: {
  //         id: 13,
  //         name: "Kamal B",
  //         email: "test@test.com",
  //         number: "9999999999",
  //         address: "Mumbai",
  //         country: null,
  //         state: null,
  //         city: null,
  //         pincode: null,
  //         marital_status: "UnMarried",
  //         occupation: "Service",
  //         vehicle_owned: "RJ 27 BS 6345",
  //         category: "Category 1",
  //       },
  //       budget: null,
  //       creator_name: "shardul",
  //       parent_lead_creator: null,
  //       referred_to: null,
  //       workflow: [],
  //       last_call_status: "Status 2",
  //       follow_up_date: "2022-03-22",
  //       interested_in: [
  //         {
  //           id: 1,
  //           property_name: "Rio de goa test",
  //           description: "teshabjah",
  //           location: "Goa",
  //           property_type: "Gold leisure",
  //           property_area: "1000",
  //           bedroom: 2,
  //           bathroom: 2,
  //           accomadation: "4 Adults, 2 Kids",
  //           project_manager: "Shardul Singh",
  //           brik_value: 700000,
  //           capital_gain: "6",
  //           irr: "7",
  //           pe_ratio: "3",
  //           brik_manager: "Shardul Singh",
  //           brik_manager_image: null,
  //           brik_manager_contact: "8058435576",
  //           brik_cover_image: null,
  //           lat: "0.00000002",
  //           lng: "0.00000002",
  //           amenities: [1],
  //         },
  //       ],
  //     },
  //     {
  //       id: 10,
  //       name: "Sana Eram",
  //       description: null,
  //       status: "new",
  //       gig: null,
  //       start_date: null,
  //       end_date: null,
  //       priority: "Hot",
  //       source: "google",
  //       files: null,
  //       moved_on: null,
  //       last_moved_type: null,
  //       lost_reason: null,
  //       client: {
  //         id: 12,
  //         name: "Kamal B",
  //         email: "test@test.com",
  //         number: "9999999999",
  //         address: "Mumbai",
  //         country: null,
  //         state: null,
  //         city: null,
  //         pincode: null,
  //         marital_status: "UnMarried",
  //         occupation: "Service",
  //         vehicle_owned: "RJ 27 BS 6345",
  //         category: "Category 1",
  //       },
  //       budget: null,
  //       creator_name: "shardul",
  //       parent_lead_creator: null,
  //       referred_to: null,
  //       workflow: [],
  //       last_call_status: "Status 2",
  //       follow_up_date: "2022-03-22",
  //       interested_in: null,
  //     },
  //     {
  //       id: 9,
  //       name: "Kamal B",
  //       description: null,
  //       status: "new",
  //       gig: null,
  //       start_date: null,
  //       end_date: null,
  //       priority: "Hot",
  //       source: "google",
  //       files: null,
  //       moved_on: null,
  //       last_moved_type: null,
  //       lost_reason: null,
  //       client: {
  //         id: 11,
  //         name: "Kamal B",
  //         email: "test@test.com",
  //         number: "9999999999",
  //         address: "Mumbai",
  //         country: null,
  //         state: null,
  //         city: null,
  //         pincode: null,
  //         marital_status: "UnMarried",
  //         occupation: "Service",
  //         vehicle_owned: "rj 27 bs 6345",
  //         category: "Category 1",
  //       },
  //       budget: null,
  //       creator_name: "shardul",
  //       parent_lead_creator: null,
  //       referred_to: null,
  //       workflow: [],
  //       last_call_status: "Status 2",
  //       follow_up_date: "2022-03-22",
  //       interested_in: null,
  //     },
  //     {
  //       id: 8,
  //       name: "Kamal B",
  //       description: null,
  //       status: "new",
  //       gig: null,
  //       start_date: null,
  //       end_date: null,
  //       priority: "Hot",
  //       source: "google",
  //       files: null,
  //       moved_on: null,
  //       last_moved_type: null,
  //       lost_reason: null,
  //       client: {
  //         id: 10,
  //         name: "Kamal B",
  //         email: "test@test.com",
  //         number: "9999999999",
  //         address: "Mumbai",
  //         country: null,
  //         state: null,
  //         city: null,
  //         pincode: null,
  //         marital_status: "UnMarried",
  //         occupation: "Service",
  //         vehicle_owned: "rj 27 bs 6345",
  //         category: "Category 1",
  //       },
  //       budget: null,
  //       creator_name: "shardul",
  //       parent_lead_creator: null,
  //       referred_to: null,
  //       workflow: [],
  //       last_call_status: "Status 2",
  //       follow_up_date: "2022-03-22",
  //       interested_in: null,
  //     },
  //     {
  //       id: 7,
  //       name: "Kamal B",
  //       description: null,
  //       status: "new",
  //       gig: null,
  //       start_date: null,
  //       end_date: null,
  //       priority: "Hot",
  //       source: "google",
  //       files: null,
  //       moved_on: null,
  //       last_moved_type: null,
  //       lost_reason: null,
  //       client: {
  //         id: 9,
  //         name: "Kamal B",
  //         email: "test@test.com",
  //         number: "9999999999",
  //         address: "Mumbai",
  //         country: null,
  //         state: null,
  //         city: null,
  //         pincode: null,
  //         marital_status: "UnMarried",
  //         occupation: "Service",
  //         vehicle_owned: "rj 27 bs 6345",
  //         category: "Category 1",
  //       },
  //       budget: null,
  //       creator_name: "shardul",
  //       parent_lead_creator: null,
  //       referred_to: null,
  //       workflow: [],
  //       last_call_status: "Status 2",
  //       follow_up_date: "2022-03-22",
  //       interested_in: null,
  //     },
  //   ],
  //   notify: 9,
  // };
  // console.log("url",url)
  // if(url!==''){
  //   return http("GET", `${url}`, {}, { isAuthenticated: true })
  //   .then((result) => {
  //     return Promise.resolve(result.data);
  //   })
  //   .catch((er) => {
  //     return Promise.reject(er);
  //   });
  // }
  // else{
  return http("GET", `leads/new/?${queryString}`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const createTask = async (data) => {
  return http("POST", "workflows/task/", data, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const createLead = async (data) => {
  return http("POST", "leads/new/", data, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const editLead = async (id, data) => {
  return http("PUT", `leads/${id}/`, data, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const deleteLead = async (id) => {
  return http("DELETE", `leads/delete/${id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const dashboardStats = async () => {
  // return {
  //   statistics: [
  //     {
  //       type: "New Leads",
  //       count: "2",
  //       last_lead: {
  //         name: "Mukul A",
  //       },
  //     },
  //     {
  //       type: "Sales Done",
  //       count: "4",
  //       last_lead: {
  //         name: "Kamal A",
  //       },
  //     },
  //     {
  //       type: "Commision Earned",
  //       count: "3",
  //       last_lead: {
  //         name: "Kamal A",
  //       },
  //     },
  //     {
  //       type: "Total Sales",
  //       count: "1",
  //       last_lead: {
  //         name: "Kamal A",
  //       },
  //     },
  //   ],
  //   graphData: {
  //     new: {
  //       count: 6,
  //       last_lead: {
  //         name: "Kamal B",
  //         id: 8,
  //         property_name: null,
  //       },
  //       percentage: 85.71428571428571,
  //     },
  //     "Sales Done": {
  //       count: 1,
  //       last_lead: {
  //         name: "Kamal A",
  //         id: 11,
  //         property_name: "Rio de goa test",
  //       },
  //       percentage: 14.285714285714285,
  //     },
  //     Lost: {
  //       count: 1,
  //       last_lead: {
  //         name: "Kamal A",
  //         id: 11,
  //         property_name: "Rio de goa test",
  //       },
  //       percentage: 14.285714285714285,
  //     },
  //     Converted: {
  //       count: 1,
  //       last_lead: {
  //         name: "Kamal A",
  //         id: 11,
  //         property_name: "Rio de goa test",
  //       },
  //       percentage: 14.285714285714285,
  //     },
  //     "Presentation Done": {
  //       count: 1,
  //       last_lead: {
  //         name: "Kamal A",
  //         id: 11,
  //         property_name: "Rio de goa test",
  //       },
  //       percentage: 14.285714285714285,
  //     },
  //   },
  // };
  return http("GET", "leads/dashboard-stats/", {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateEvent = async () => {
  alert("Event Updated");
};

export const addEvent = async (values) => {
  return http("POST", "workflows/task/", values, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const metaData = async (filter) => {
  let { org } = filter;
  let url = "leads/meta-data/";
  if (org > 0) {
    url = url + "?org=" + org;
  }
  console.log("Organisaction: ", org);

  return http("GET", url, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const loggedInUser = async () => {
  let id = localStorage.getItem("userId");

  return http("GET", `auth/profile/${id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      console.log("auth/profile/ GETY API DATA", result);
      let { data } = result;

      // return {
      //   id: 1,
      //   name: "shardul",
      //   email: "shardul@esmagico.in",
      //   number: null,
      //   organization: {
      //     id: 1,
      //     connections_count: 0,
      //     lead_received_count: 0,
      //     lead_shared_count: 0,
      //     public_link: "/in/brikkit/",
      //     category_name: "Real Estate",
      //     created_on: "2022-03-20T16:38:40.747655+05:30",
      //     updated_on: "2022-03-20T16:38:40.747694+05:30",
      //     name: "Brikkit",
      //     email: "tech@brikitt.com",
      //     number: "",
      //     address_line_1: "Lucknow",
      //     address_line_2: "Lucknow",
      //     city: "Lucknow",
      //     country: "India",
      //     pin_code: "226010",
      //     company_size: 4,
      //     cin: "",
      //     ac_no: "",
      //     bank: "",
      //     gst: "",
      //     igst: "",
      //     branch: "",
      //     upi: "",
      //     website_url: "https://www.brikitt.com/",
      //     org_description: "Fractional Real Estate Ownership",
      //     background_img_url: "",
      //     company_logo_url: "",
      //     use_linkBiz_for: [],
      //     is_guided_tour_completed: false,
      //     guided_tour_current_step: 1,
      //     is_workflow_tour_completed: false,
      //     categories: [1],
      //   },
      //   category: 1,
      //   is_active: true,
      //   is_staff: true,
      //   is_superuser: true,
      //   verified: false,
      //   gender: "MALE",
      //   dob: null,
      //   address: "",
      //   gmail_username: "null",
      //   gmail_connected: false,
      // }
      return Promise.resolve(data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getAuthToken = async (data) => {
  // return {
  //   key: "95a85184fa7739153f7b284c245324c73997d845",
  //   user: {
  //     id: 1,
  //     name: "shardul",
  //     email: "shardul@esmagico.in",
  //     number: null,
  //     organization: {
  //       id: 1,
  //       connections_count: 0,
  //       lead_received_count: 0,
  //       lead_shared_count: 0,
  //       public_link: "/in/brikkit/",
  //       category_name: "Real Estate",
  //       created_on: "2022-03-20T16:38:40.747655+05:30",
  //       updated_on: "2022-03-20T16:38:40.747694+05:30",
  //       name: "Brikkit",
  //       email: "tech@brikitt.com",
  //       number: "",
  //       address_line_1: "Lucknow",
  //       address_line_2: "Lucknow",
  //       city: "Lucknow",
  //       country: "India",
  //       pin_code: "226010",
  //       company_size: 4,
  //       cin: "",
  //       ac_no: "",
  //       bank: "",
  //       gst: "",
  //       igst: "",
  //       branch: "",
  //       upi: "",
  //       website_url: "https://www.brikitt.com/",
  //       org_description: "Fractional Real Estate Ownership",
  //       background_img_url: "",
  //       company_logo_url: "",
  //       use_linkBiz_for: [],
  //       is_guided_tour_completed: false,
  //       guided_tour_current_step: 1,
  //       is_workflow_tour_completed: false,
  //       categories: [1],
  //     },
  //     category: 1,
  //     is_active: true,
  //     is_staff: true,
  //     is_superuser: true,
  //     verified: false,
  //     gender: "MALE",
  //     dob: null,
  //     address: "",
  //     gmail_username: "null",
  //     gmail_connected: false,
  //   },
  // };
  return http(
    "POST",
    "auth/user-login/",
    {
      email: data.username,
      password: data.password,
    },
    { isAuthenticated: false }
  )
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getLeadDetails = async (id) => {
  // return {
  //   id: 11,
  //   name: "Kamal A",
  //   description: null,
  //   status: "Sales Done",
  //   gig: null,
  //   start_date: null,
  //   end_date: null,
  //   priority: "Cool",
  //   source: "online promotion",
  //   files: null,
  //   moved_on: null,
  //   last_moved_type: null,
  //   lost_reason: null,
  //   client: {
  //     id: 13,
  //     name: "Kamal B",
  //     email: "test@test.com",
  //     number: "+91 9587308126",
  //     address: "Mumbai",
  //     country: null,
  //     state: null,
  //     city: null,
  //     pincode: null,
  //     marital_status: "Married",
  //     occupation: "Business",
  //     remark: "Works in US MNC IN Hyderabad, belong to Lucknow, Eldeco City",
  //     vehicle_owned: "RJ 27 bs 6345",
  //     category: "Category 2",
  //   },
  //   budget: null,
  //   creator_name: "shardul",
  //   parent_lead_creator: null,
  //   created_at: "2023-12-11",
  //   last_updated_at: "2023-11-11",
  //   days_to_expire: 13,
  //   referred_to: null,
  //   workflow: [],
  //   last_call_status: "Status 3",
  //   follow_up_date: "2022-03-28",
  //   interested_in: [
  //     {
  //       id: 1,
  //       property_name: "Rio de goa test",
  //       description: "teshabjah",
  //       location: "Goa",
  //       property_type: "Gold leisure",
  //       property_area: "1000",
  //       bedroom: 2,
  //       bathroom: 2,
  //       accomadation: "4 Adults, 2 Kids",
  //       project_manager: "Shardul Singh",
  //       brik_value: 700000,
  //       capital_gain: "6",
  //       irr: "7",
  //       pe_ratio: "3",
  //       brik_manager: "Shardul Singh",
  //       brik_manager_image: null,
  //       brik_manager_contact: "8058435576",
  //       brik_cover_image: null,
  //       lat: "0.00000002",
  //       lng: "0.00000002",
  //       amenities: [1],
  //     },
  //   ],
  // };
  return http("GET", `leads/${id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getOwnerShipData = async (data) => {
  return http("GET", "brik/brik_list/", data, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getPropertyDetails = async (id) => {
  return http("GET", `brik/brik_detail/${id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getPropertyCowners = async (id) => {
  return http("GET", `brik/brik_owners/${id}/`, {}, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getPaymentReceipt = async (id) => {
  return http(
    "GET",
    `brik/payment_receipt/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getSPVInfoData = async (id) => {
  return http(
    "GET",
    `brik/spv_info/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getSpvTransactionPlan = async (id) => {
  return http(
    "GET",
    `brik/get_virtual_account/?payment_plan_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const registerToken = async (apiBody) => {
  return http("POST", `fcm/devices/`, apiBody, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const readToken = async (registration_id) => {
  return http(
    "GET",
    `fcm/devices/${registration_id}/`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getDocumentList = async (id) => {
  return http(
    "GET",
    `brik/brik_documents_list/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getTransactionData = async (id) => {
  return http(
    "GET",
    `brik/new_maintenance/?brik_id=${id}`,
    {},
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const forgotPassword = async (apiBody) => {
  return http("POST", `auth/request-reset-email`, apiBody, {
    isAuthenticated: false,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const resetPassword = async (apiBody) => {
  return http("PATCH", `auth/password-reset-complete/`, apiBody, {
    isAuthenticated: false,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updatePersonalDetails = async (id, data) => {
  if (id === undefined) {
    id = localStorage.getItem("userId");
  }
  console.log("Called updateProfileData");
  console.log("Data:", data);
  return http("PUT", `auth/profile/${id}/`, data, {
    isAuthenticated: true,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addPersonalDetails = async ({ apiBody, headers }) => {
  return http("POST", `personal_info/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateContactDetails = async ({ user_id, apiBody }) => {
  return http("PUT", `contact_info/${user_id}/`, apiBody, {
    isAuthenticated: true,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addContactDetails = async ({ apiBody }) => {
  return http("POST", `contact_info/`, apiBody, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateIdCard = async ({ card_id, apiBody, headers }) => {
  return http("PUT", `id_cards/${card_id}/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addIdCard = async ({ apiBody, headers }) => {
  return http("POST", `id_cards/`, apiBody, { isAuthenticated: true, headers })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addBankDetails = async ({ apiBody, headers }) => {
  return http("POST", `bank_info/`, apiBody, { isAuthenticated: true, headers })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateBankDetails = async ({ user_id, apiBody, headers }) => {
  return http("PUT", `bank_info/${user_id}/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateNomineeDetails = async ({ user_id, apiBody, headers }) => {
  return http("PUT", `nominee_info/${user_id}/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const addNomineeDetails = async ({ apiBody, headers }) => {
  return http("POST", `nominee_info/`, apiBody, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const readNotifications = async (apiBody) => {
  return http("PUT", `workflows/notifications/`, {}, { isAuthenticated: true })
    .then((result) => {
      console.log(Promise.resolve(result.data));
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const updateDocumentData = async (apiBody) => {
  return http("POST", `/terms_conditions/`, apiBody, {
    isAuthenticated: true,
    headers: {
      "content-type":
        "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    },
  })
    .then((result) => {
      console.log(Promise.resolve(result.data));
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getDocumentById = async (id) => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_AADHAR_API_URL}/v2/client/document/${id}`,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_AADHARESIGN_TOKEN}`,
      // 'Authorization':'Basic '+window.btoa('AIXBIB9I5NKCUS6HV5AGQQ8FWCH9IOHY:CHPB16MQ3AX79J2SUHXS23X7RHS5REI')
    },
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.resolve(er);
    });
};

export const getNotificationsList = async (apiBody) => {
  return http("GET", `workflows/notifications/`, {}, { isAuthenticated: true })
    .then((result) => {
      console.log(Promise.resolve(result.data));
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });

  // return [
  //     {
  //         id:"21",
  //         read:false,
  //         brik_name:"Rio De Goa",
  //         title:"This is title",
  //         body:"hello this is test notificaiton",
  //         notificaiton_date:"2021-12-21T09:37:14.886009Z"
  //     },
  //     {
  //         id:"21",
  //         read:false,
  //         brik_name:"Rio De Goa",
  //         title:"This is title",
  //         body:"hello this is test notificaiton",
  //         notificaiton_date:"2021-12-21T09:37:14.886009Z"
  //     },
  //     {
  //         id:"21",
  //         read:true,
  //         brik_name:"Rio De Goa",
  //         title:"This is title",
  //         body:"hello this is test notificaiton",
  //         notificaiton_date:"2021-12-21T09:37:14.886009Z"
  //     }
  // ]
};

export const updateProfilePicture = async (id, data) => {
  return http("PUT", `auth/update_profile_pic/${id}/`, data, {
    isAuthenticated: true,
    headers: {
      "content-type":
        "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    },
  })
    .then((result) => {
      console.log(result);
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getProfileData = async (id) => {
  console.log("User id:" + id);
  if (id === undefined) {
    id = localStorage.getItem("userId");
  }
  // return {
  //     personal_details: {
  //         name: "Mukul",
  //         lastname: "jain"
  //     },
  //     contact_info: {

  //     },
  //     id_cards: {

  //     },
  //     back_account_details: {

  //     },
  //     nominee_details: {

  //     }
  // }
  return http(
    "GET",
    `auth/profile/${id}/`,
    {
      // hes
    },
    { isAuthenticated: true }
  )
    .then((result) => {
      console.log(result);
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const starDocument = async ({ document_id, status, userId }) => {
  return http(
    "PUT",
    `workflows/docs/${document_id}/`,
    {
      uploaded_by: userId,
      is_starred: status,
    },
    { isAuthenticated: true }
  )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getDocumentDetails = async (filters) => {
  let { starred, document_name, brik_id } = filters;
  console.log("brik_id", brik_id);
  if (brik_id && brik_id.length) filters.brik_id = filters.brik_id.join(",");

  if (document_name == "") {
    delete filters.document_name;
  }

  var queryString = Object.keys(filters)
    .map(function (key) {
      return key + "=" + filters[key];
    })
    .join("&");

  if (starred === 1) {
    return http(
      "GET",
      `workflows/docs/?${queryString}`,
      {},
      { isAuthenticated: true }
    )
      .then((result) => {
        console.log(result);
        return Promise.resolve(result);
      })
      .catch((er) => {
        return Promise.reject(er);
      });
  } else {
    return http(
      "GET",
      `workflows/docs/?${queryString}`,
      {},
      { isAuthenticated: true }
    )
      .then((result) => {
        console.log(result);
        return Promise.resolve(result);
      })
      .catch((er) => {
        return Promise.reject(er);
      });
  }
};

var dt = JSON.stringify({
  name: "Brikitt Sales Template",
  workflow_type: "sales",
  stages: [
    {
      name: "PreSales",
      order: 0,
      tasks: [
        {
          name: "Scheduled a Meeting",
          order: 0,
          task_type: "todo",
        },
        {
          name: "Sent Documents",
          order: 0,
          task_type: "todo",
        },
        {
          name: "Made a call",
          order: 0,
          task_type: "todo",
        },
      ],
    },
  ],
});

export const getCalenderEvents = async (currentYear, month) => {
  return http(
    "GET",
    `leads/getCalenderEvents/?year=${currentYear}&month=${month}`,
    {
      year: 2022,
      month: 8,
    },
    {
      isAuthenticated: true,
    }
  )
    .then((result) => {
      console.log(result);
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const getTaskDetails = async (date) => {
  if (date) {
    return http("GET", `workflows/task/?date=${date}`, dt, {
      isAuthenticated: true,
    })
      .then((result) => {
        console.log(result);
        return Promise.resolve(result);
      })
      .catch((er) => {
        return Promise.reject(er);
      });
  } else {
    return http("GET", `workflows/task/`, dt, { isAuthenticated: true })
      .then((result) => {
        console.log(result);
        return Promise.resolve(result);
      })
      .catch((er) => {
        return Promise.reject(er);
      });
  }
};

export const createDocument = async (data) => {
  return http("POST", "workflows/docs/", data, { isAuthenticated: true })
    .then((result) => {
      return Promise.resolve(result.data);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};

export const uploadBulkLeads = async ({ apiBody, headers }) => {
  console.log("UPLOAD BULK LEAD: ", apiBody, headers);

  let fd = new FormData();

  for (var pair of Object.entries(apiBody)) {
    console.log(pair[0] + ", " + pair[1]);
    fd.append(pair[0], pair[1]);
  }

  return http("POST", `leads/leadupload/`, fd, {
    isAuthenticated: true,
    headers,
  })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((er) => {
      return Promise.reject(er);
    });
};
