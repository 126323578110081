import { AppBar, IconButton, Typography, makeStyles, Box, TextField, Avatar, Menu, MenuItem, Card, List, ListItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { ListGroup, ListGroupItem } from "reactstrap"
// import MenuIcon from '@material-ui/icons/Menu';
import MenuIcon from '../assets/icons/menu.png';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { DRAWER_WIDTH } from './MainDrawer';
import { BrikCoin, Logo } from '../assets/assets';
import BellIcon from '../assets/BellIcon.png'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useAuth } from '../providers/AuthProvider';
import { useGeneralProvider } from '../providers/GeneralProvider';
// import MessageIcon from '../../assets/icons/message_icon.svg';
import Popover from '@mui/material/Popover';
import BrikittIcon from '../assets/images/Brikitt.png'
import Badge from '@mui/material/Badge';
import { onMessageListener } from '../firebase/firebaseInit';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification, resetNotificationCounter } from '../store/actions/userActions';
import { useHistory } from 'react-router';
import moment from 'moment'
import { readNotifications } from '../store/actions/apis';

function Footer() {

    return (
        (
            <>
                <div style={{position:"relative",left:"-5%",bottom:"0",backgroundColor:"white",color:"black",zIndex:"1300"}}>
                    {/* <ul style={{display:"inline",alignItems:"left",color:"#6B778C"}}>
                        <li>Support</li>
                        <li>Help Center</li>
                        <li>Privacy</li>
                        <li>Terms of use</li>
                    </ul> 
                    <ul style={{display:"inline",alignItems:"right",color:"#6B778C"}}>
                        <li>All rights are Reserved</li>
                    </ul>  */}
                    <ListGroup style={{display:"flex",float:"left",listStyle:"none",color:"#6B778C"}}>
                        <ListGroupItem>Support&nbsp;&nbsp;&nbsp;</ListGroupItem>
                        <ListGroupItem>Help Center&nbsp;&nbsp;&nbsp;</ListGroupItem>
                        <ListGroupItem>Privacy&nbsp;&nbsp;&nbsp;</ListGroupItem>
                        <ListGroupItem>Terms of use</ListGroupItem>
                    </ListGroup>
                    <ListGroup style={{display:"flex",float:"right",listStyle:"none",color:"#6B778C"}}>
                        <ListGroupItem>All rights are Reserved</ListGroupItem>
                    </ListGroup>
                </div>
            </>
        ) 
    )
}

export default Footer
