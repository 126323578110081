import { Box, Card, Grid } from '@material-ui/core';
import React, { useState } from 'react'
import { responsiveStyles } from '../../assets/css/generalStyling'
import Chart from "react-apexcharts";
import DetailCard from './DetailCard';

function Growth({ propertyDetails, display }) {
    const responsive = responsiveStyles();
    const [donutData, setDonutData] = useState({
        options: {
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center'
            },
            dataLabels: {
                enabled: false
            },
            chart: {
                type: "pie",
            },
            labels: ["Total Days", "Remaining Days"],
            colors: ['#CDFDE9','#1ACB7F'],
        },
        series: [0, 30]
    });

    const [data, setData] = useState({
        options: {
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left'
            },
            stroke: {
                colors: ["transparent"],
                width: 10
              },
            chart: {
                type: 'bar',
            },
            xaxis: {
                categories: [2021]
            },
            colors: ['#C4C599','#BA8C6A','#1A4F5D'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '60%',
                },    
            }, 
        },
        series: [
            {
                name: "Brik Value",
                data: [699]
            },
            {
                name: "Total Paid",
                data: [68.1]
            },
            {
                name: "Payment Due",
                data: [630]
            }
        ]
    });

    return (
        <>
            <Grid container className={responsive.web} xs={12} sm={12}>
                <Box className={responsive.web}>
                    <Grid spacing={3} container item xs={12} sm={12}>
                        <Grid item xs={12}>
                            <Box fontSize="22px" fontWeight="bold">{propertyDetails.property_name}</Box>
                        </Grid>
                        <Grid item xs={12}>
                            <DetailCard propertyDetails={propertyDetails} />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid container>
                <Grid xs={12} sm={5}>
                    <Box style={{ padding: "10px 0px", fontSize: "20px" }}>Usage</Box>
                    <Card style={{ padding: "10px", width: "100%",minHeight:"355px" }}>
                        <Box textAlign="center">Show data: 2020 - 2021</Box>
                        <Chart
                            series={donutData.series}
                            options={donutData.options}
                            type="donut"
                            width="100%"
                            height="250"
                        />
                        <Box textAlign="center">Usage Distribution Data of Nights</Box>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
                <Grid item xs={12} sm={5}>
                    <Box style={{ padding: "10px 0px", fontSize: "20px" }}>Accumulative Growth</Box>
                    <Card style={{ padding: "10px" }}>
                        <Chart
                            options={data.options}
                            series={data.series}
                            type="bar"
                            width="100%"
                            height="320"
                        />
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Growth
