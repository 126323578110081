import AccountReducer from "./reducers/AccountReducer";
import DashboardReducer from "./reducers/DashboardReducer";
import MyOwnershipReducer from "./reducers/MyOwnershipReducer";
import UserReducer from "./reducers/UserReducer";

const { combineReducers } = require("redux");

export const reducers = combineReducers({
  userReducer: UserReducer,
  myOwnerShip: MyOwnershipReducer,
  dashboard: DashboardReducer,
});
