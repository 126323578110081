import Logo from "../assets/Logo.svg";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountIcon from "../assets/icons/account icon.svg";
import MyOwnershipIcon from "../assets/icons/My ownership.svg";
import FAQIcon from "../assets/icons/FAQ.svg";
import Wifi from "../assets/aminities/wifi.svg";
import BrikCoin from "../assets/BrikCoin.svg";
import TV from "../assets/aminities/TV.svg";

import AccountWhite from "../assets/icons/account white.svg";
import profileBlackIcon from "../assets/icons/profile 2.svg";
import exploreBlackIcon from "../assets/icons/explore 2.svg";
import MyOwnershipWhite from "../assets/icons/My ownership white.svg";
import MyOwnershipBlack from "../assets/icons/my ownership1.svg";
import FAQWhite from "../assets/icons/FAQ white.svg";

import ColdIcon from "../assets/icons/leadDetail/Cold.svg";
import HotIcon from "../assets/icons/leadDetail/Hot.svg";
import WarmIcon from "../assets/icons/leadDetail/Warm.svg";
import CoolIcon from "../assets/icons/leadDetail/Cool.svg";
import RedBullet from "../assets/icons/graph/red.png";
import YellowBullet from "../assets/icons/graph/yellow.png";
import BlueBullet from "../assets/icons/graph/blue.png";
import LightBullet from "../assets/icons/graph/lightblue.png";
import GreenBullet from "../assets/icons/graph/green.png";
import DashboardPeopleIcon from "../assets/icons/graph/peoples.png";
import DashboardSaleIcon from "../assets/icons/graph/sale.png";
import DashboardCommissionIcon from "../assets/icons/graph/commission.png";
import DashboardPropertyIcon from "../assets/icons/graph/property.png";

export const icons = {
  explore: DashboardIcon,
  exploreWhite: DashboardIcon,
  myOwnership: MyOwnershipIcon,
  myOwnershipWhite: MyOwnershipWhite,
  profileBlack: profileBlackIcon,
  ownershipBlack: MyOwnershipBlack,
  exploreBlackIcon: exploreBlackIcon,
  account: AccountIcon,
  accountWhite: AccountWhite,
  faq: FAQIcon,
  faqWhite: FAQWhite,
  ColdIcon: ColdIcon,
  HotIcon: HotIcon,
  WarmIcon: WarmIcon,
  CoolIcon: CoolIcon,
};

export const amenities = {
  "Wi-FI": Wifi,
  TV: TV,
};

export const graphBulltes = [
  RedBullet,
  YellowBullet,
  BlueBullet,
  LightBullet,
  GreenBullet,
];

export const statisticsProperties = [
  { icon: DashboardPeopleIcon, bgcolor: "#00C0EE" },
  { icon: DashboardSaleIcon, bgcolor: "#00BF86" },
  { icon: DashboardCommissionIcon, bgcolor: "#FE9F00" },
  { icon: DashboardPropertyIcon, bgcolor: "#0DB0C0" },
];

const renderPriority = (priority) => {
  switch (priority) {
    case "Hot":
      return icons.HotIcon;
    case "Warm":
      return icons.WarmIcon;
    case "Cool":
      return icons.CoolIcon;
    default:
      return icons.ColdIcon;
  }
};

export { Logo, BrikCoin, renderPriority };
