import { Box, Grid, Button, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { responsiveStyles } from "../assets/css/generalStyling";
import { useAuth } from "../providers/AuthProvider";
import HomeBackground from "../assets/images/Homepage-backgroud.png";

const useStyles = makeStyles((theme) => ({
  //   loginContainer: { height: "400px" },
  loginFormSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    // loginContainer: { height: "600px" },
    loginFormSection: { justifyContent: "flex-end" },
  },
}));

function HomePage() {
  const auth = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const repsonsive = responsiveStyles();

  useEffect(() => {
    if (localStorage.getItem("token")) history.push("/my-ownership");
  }, []);

  return (
    <>
      <Box className={repsonsive.web}>Web</Box>
      <Box
        className={repsonsive.app}
        style={{
          padding: 10,
          marginTop: "60px",
          backgroundImage: `url(${HomeBackground})`,
        }}
      >
        <Grid className={classes.loginContainer} container spacing={2}>
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box style={{ color: "blue", padding: "12px 0" }}>Welcome!</Box>

            <Box style={{ padding: "15px 0", fontWeight: "bold" }}>
              Enjoy Broker’s Experience in simple way
            </Box>

            <Box style={{ lineHeight: 1.5, fontWeight: 300 }}>
              BRIK itt is India’s Leading Proptech Company unlocking Fractional
              Ownership in Private & Residential Real Estate. It brings to the
              people the opportunity to own Exclusive luxury residential &
              vacational assets.
            </Box>
          </Grid>

          <Grid className={classes.loginFormSection} item xs={12} sm={4}>
            <Button
              onClick={() => {
                history.push("/login");
              }}
              variant="contained"
              fullWidth
              color="primary"
            >
              LOGIN
            </Button>
          </Grid>
        </Grid>

        {/* <button onClick={()=>auth.login(1)}>Login</button> */}
      </Box>
    </>
  );
}

export default HomePage;
