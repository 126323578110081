import React, { useEffect, useState } from "react";
import { Box, Avatar, IconButton, Button, makeStyles } from "@material-ui/core";
import pdfIcon from "../assets/icons/pdf.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { documentByID } from "../store/actions/apis";
import { getLeads } from "../store/actions/dashboardActions";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  selectedDocument: {
    backgroundColor: "#a1a1a1",
    padding: "5px",
    borderRadius: "5px",
  },
}));

function DocumentPdf() {
  const classes = useStyles();
  const history = useHistory();
  const { leads } = useSelector((state) => state.dashboard);
  const [documentDetails, setDocumentDetails] = useState({});
  const [selectedDocument, setSelectedDocument] = useState({});
  const { document_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    documentByID(document_id).then((result) => {
      let { data } = result;
      console.log("RESULT: ", data);
      setDocumentDetails(data);
    });

    dispatch(getLeads({ search: "", status: "", priority: "" }));
  }, []);

  return (
    <>
      <Box
        className={clsx({ "app-dashboard": true })}
        style={{ backgroundColor: "white", padding: "12px" }}
      >
        <Box marginBottom="10px" display="flex" alignItems="center">
          <Box>
            <IconButton
              size="small"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Box>
          <Box fontSize="22px" fontWeight={600}>
            {documentDetails.document_name}
          </Box>
        </Box>
        <Box
          display="flex"
          padding="13px 10px"
          style={{ borderBottom: "1px solid #C4C4C4" }}
        >
          <Box>
            <img src={pdfIcon} height="55px" />
          </Box>
          <Box marginLeft="8px">
            <Box fontWeight="bold" color="#565656">
              {documentDetails.document_name}
            </Box>
            <Box style={{ color: "#8A9EBC", fontSize: "12px" }}>8.25MB</Box>
          </Box>
        </Box>

        <Box padding="15px 0" style={{ borderBottom: "1px solid #C4C4C4" }}>
          <Box fontWeight="600">Description</Box>
          <Box style={{ color: "#8A9EBC" }}>{documentDetails.description}</Box>
        </Box>

        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box fontWeight="600" fontSize="16px" padding="10px 0px">
              Document shared with
            </Box>
            <Box style={{ color: "#8A9EBC", fontSize: "12px" }}>View all</Box>
          </Box>
          {leads.results.map((lead) => {
            return (
              <Box
                onClick={() => {
                  setSelectedDocument(lead);
                }}
                className={clsx({
                  [classes.selectedDocument]: lead.id == selectedDocument.id,
                })}
                margin="10px"
                display="flex"
                alignItems="center"
              >
                <Avatar />
                <Box marginLeft="8px">{lead.name}</Box>
              </Box>
            );
          })}
        </Box>

        <Box
          display="flex"
          justifyContent="space-around"
          padding="30px 0"
          marginTop="40px"
        >
          <Button
            onClick={() => {
              let text = `Hi%20${
                selectedDocument.client.name
              }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                documentDetails.document
              )}`;

              window.open(
                `mailto:${selectedDocument.client.email}?subject=Sharing Brik Document&body=${text}`
              );
            }}
            variant="outlined"
            color="primary"
          >
            <Box display="flex" alignItems="center">
              {/* <img src={delet} /> */}
              <Box style={{ width: "100px" }}>Email</Box>
            </Box>
          </Button>
          <Button
            style={{ width: "150px" }}
            variant="contained"
            onClick={() => {
              console.log("Lead Data: ", selectedDocument.client.number);
              let text = `Hi%20${
                selectedDocument.client.name
              }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                documentDetails.document
              )}`;
              window.open(
                `https://api.whatsapp.com/send/?phone=+91${selectedDocument.client.number}&text=${text}&app_absent=0`
              );
            }}
            color="primary"
          >
            Whatsapp
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default DocumentPdf;
