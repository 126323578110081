import { useSnackbar } from "notistack";
import React, { createContext, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAuthToken } from "../store/actions/apis";
import {
  getUserData,
  getToken,
  setComponentBusyState,
  setUserData,
} from "../store/actions/userActions";

const AuthContext = createContext({
  login: () => {},
  getUser: () => {},
  getUserToken: () => {},
});

function AuthProvider(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const notification = useSnackbar();
  const { userData } = useSelector((state) => state.userReducer);

  useEffect(() => {
    // if(localStorage.getItem('token'))
    //     dispatch(getUserData({id:1}))
  }, []);

  const getUser = () => {
    return userData;
  };

  const getUserId = () => {
    return localStorage.getItem("userId");
    // userData.id;
  };

  const getUserToken = () => {
    return localStorage.getItem("token");
  };

  const setUser = () => {
    localStorage.clear();
  };

  const login = (data) => {
    dispatch(setComponentBusyState(true));

    return getAuthToken(data)
      .then((result) => {
        console.log(result.key);
        localStorage.setItem("token", result.key);
        localStorage.setItem("userId", result.user["id"]);
        dispatch(setUserData(result));
        dispatch(setComponentBusyState(false));
        notification.enqueueSnackbar("Logged In Successfully", {
          variant: "success",
        });
      })
      .catch((er) => {
        notification.enqueueSnackbar("Please Check Credentials", {
          variant: "error",
        });

        dispatch(setComponentBusyState(false));
      });
    // dispatch(getToken(data))

    // dispatch(getUserData({id:id}))
    // window.location.href="/home"
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider
      value={{ login, logout, getUser, getUserToken, getUserId, setUser }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  // if(context){
  //     return "useAuth must be used inside AuthProvider "
  // }else
  return context;
};

export default AuthProvider;
