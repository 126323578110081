import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Box } from '@material-ui/core';
import _ from 'lodash'

function Slider({ data, render, title, ...props }) {
    return (
        <>
            {/* {!_.isUndefined(title) && (
                <Box style={{fontSize:"18px",padding:"10px 0px"}}>{title}</Box>
            )} */}
            <OwlCarousel className='owl-theme' loop {...props}>
                {data.map((item, index) => {
                    return <Box style={{padding:"10px 0px"}} key={index} className="item">
                        {render(item)}
                    </Box>
                })}
            </OwlCarousel>
        </>
    )
}

export default Slider
