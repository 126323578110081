import { Box, Grid, Button, Avatar, Typography, IconButton, TextField, Breadcrumbs, Card, makeStyles } from '@material-ui/core'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { responsiveStyles } from '../../assets/css/generalStyling';
import CallIcon from '../../assets/icons/CallIcon.svg';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import BathtubOutlinedIcon from '@material-ui/icons/BathtubOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import WifiOutlinedIcon from '@material-ui/icons/WifiOutlined';
import Modal from '../../component/Modal';
import { Close } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Link from '@material-ui/core/Link';
import { useGeneralProvider } from '../../providers/GeneralProvider';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import Slider from '../../assets/images/Slider1.png'
import BuildIcon from '../../assets/icons/build_icon.svg'
import BSIcon from '../../assets/icons/bs_icon.svg'
import PNLICON from '../../assets/icons/profilt_loss_icon.svg'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getSPVInfoData } from '../../store/actions/apis';
import OWLSlider from '../../component/Slider'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import house from '../../assets/images/House.svg'

const useStyles = makeStyles((theme) => ({
  carousel: { width: "100%", objectFit: "cover", height: "300px" },
  [theme.breakpoints.down('sm')]: {
    carousel: { width: "100%", objectFit: "cover", height: "200px" }
  }
}))

function Overview({ propertyDetails, display, ...props }) {
  const responsive = responsiveStyles()
  const [modal, setModal] = useState(false)
  const classes = useStyles();
  const [enargeView, setEnargeView] = useState(false)
  const [enargeViewImg, setEnargeViewImg] = useState("")
  const [spvInfo, setSPVInfo] = useState({
    id: 1,
    spv_info_spv_name: "",
    account_no: "",
    spv_info_bank_name: "",
    bank_ifsc_code: "",
    pan_card: "",
    coi_number: "",
    account_statement: null,
    balance_sheet: null,
    profit_loss: null
  })

  // const [propertyDetails,setPropertyDetails]=useState({property_detail:[{}]})
  const { id } = useParams();
  const general = useGeneralProvider();
  const [sliders, setSlider] = useState([
    'https://cdn.pixabay.com/photo/2020/07/06/01/33/sky-5375005_960_720.jpg',
    'https://cdn.pixabay.com/photo/2012/08/25/22/22/saturn-54999_960_720.jpg',
    'https://cdn.pixabay.com/photo/2014/05/10/18/10/saturn-341379_960_720.jpg',
    'https://cdn.pixabay.com/photo/2012/08/27/14/19/mountains-55067_960_720.png'
  ]);

  // general.getHeader=()=>{
  //     return  "Overview"
  // }

  const img = {
    display: 'Block',
    margin: 'auto',
    maxWidth: '100%',
    height: '400px'
  }
  const imag = {
    height: 80,
    width: "100%",
    borderRadius: "10px",
  }
  const flfo = {
    flex: 1,
    fontWeight: 600
  }

  useEffect(() => {
    general.setHeaderComponent(() => {
      return (
        <Breadcrumbs style={{ fontSize: "14px" }} aria-label="breadcrumb">
          <Link style={{ textDecoration: "none", cursor: "pointer" }} href="/home" color="inherit">
            Home
          </Link>
          <Link style={{ textDecoration: "none", cursor: "pointer" }} href="/my-ownership" color="inherit">
            My Ownership
          </Link>
          <Link color="inherit" style={{ textDecoration: "none" }}>
            {propertyDetails.property_name}
          </Link>
        </Breadcrumbs>
      )
    });
  }, [propertyDetails])

  const ModalHeader2 = () => {
    return (
      <Box style={{ zIndex: 1, color: "black" }} padding="0px 23px" paddingTop="14px" display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
        <Box style={{ fontWeight: "bold" }}>
          Preview
        </Box>
        <Box>
          <IconButton onClick={() => setEnargeView(false)}><Close /></IconButton>
        </Box>
      </Box>
    )
  }

  const ModalHeader = () => {
    return (
      <Box style={{ zIndex: 1 }} padding="14px 14px" display="flex" flexDirection="row" alignItems="center">
        <Box></Box>
        <Box>
          <IconButton onClick={() => {
            setModal(false)
          }} size="small"><ArrowBackIosIcon /></IconButton>
        </Box>
        <Box fontSize="20px" fontWeight="600">SPV Info.</Box>
      </Box>
    )
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAhemXdADjtIK_N8-lBQ3VjhmIWxLA38vk'
    // googleMapsApiKey: 'AIzaSyCmC6nAMmA9ZIXRZLpYLH593C-joJmLMeo'
  });
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  if (loadError) return "Error";
  if (!isLoaded) return "loading...";

  const containerStyle = {
    width: '100%',
    height: '100px'
  };


  const center = {
    lat: parseFloat(propertyDetails.lat),
    lng: parseFloat(propertyDetails.lng),
  }

  const getSPVInfo = () => {
    // getSPVInfoData(id).then(result => {
    //   setSPVInfo(result.data)
    // })

    setSPVInfo(propertyDetails.spv_info)
    // getSPVInfoData()
    setModal(true)
  }

  return (
    <>
      <Modal width="100%" headerComponent={ModalHeader2} status={enargeView}>
        <Box>
          <Carousel showThumbs={false} showStatus={false}>
            {propertyDetails.brik_image.map(items => {
              return <div>
                <img className={classes.carousel} src={items.image} />
              </div>
            })}
          </Carousel>
          {/* <img style={{ width: "100%" }} src={enargeViewImg} /> */}
        </Box>
      </Modal>
      <Modal width="450px" height="500px" headerComponent={ModalHeader} status={modal}>
        <img style={{
          position: "absolute",
          width: "100%",
          left: "0px",
          top: "0px",
          height: "175px",
          objectFit: "cover",
        }} width="100%" src={house} />
        {/* <Box position="relative" top="55px">SPV Info</Box> */}
        <Box style={{ lineHeight: 1.6, display: 'flex', flexDirection: 'column', position: "absolute", top: '171px', width: "100%", left: "0px", padding: "32px 9px" }} className={responsive.SPVmodal}>

          <Box style={{ display: "flex" }}>
            <Box flex={1} fontWeight="600">SPV Name</Box>
            <Box flex={1}>:</Box>
            <Box flex={1}>{spvInfo.spv_name}</Box>
          </Box>
          <Box display='flex'>
            <Box flex={1} fontWeight="600">Account No.</Box>
            <Box flex={1}>:</Box>
            <Box flex={1}>{spvInfo.bank_account_number}</Box>
          </Box>
          <Box display='flex'>
            <Box flex={1} fontWeight="600">Bank Name</Box>
            <Box flex={1}>:</Box>
            <Box flex={1}>{spvInfo.bank_name}</Box>
          </Box>
          <Box display='flex'>
            <Box flex={1} fontWeight="600">IFSC Code</Box>
            <Box flex={1}>:</Box>
            <Box flex={1}>{spvInfo.ifsc_code}</Box>
          </Box>
          <Box display='flex'>
            <Box flex={1} fontWeight="600">PAN Card No.</Box>
            <Box flex={1}>:</Box>
            <Box flex={1}>{spvInfo.pan_card}</Box>
          </Box>
          <Box display='flex'>
            <Box flex={1} fontWeight="600">CIN No.</Box>
            <Box flex={1}>:</Box>
            <Box flex={1}>{spvInfo.coi_number}</Box>
          </Box>
          <Box display='flex'>
            <Box flex={1} fontWeight="600">Unit Number</Box>
            <Box flex={1}>:</Box>
            <Box flex={1}>{spvInfo.unit_number}</Box>
          </Box>

          <Box style={{ lineHeight: 1.6, display: 'flex', flexDirection: 'row', width: "100%", padding: "31px 20px", justifyContent: "space-between", left: "0px" }}>
            <Box textAlign='center'>
              <IconButton onClick={() =>
                window.open(spvInfo.account_statement)
              }>
                <img src={BuildIcon} />
              </IconButton>
              <Box fontSize="12px">Account Statment</Box>
            </Box>

            <Box textAlign='center'>
              <IconButton onClick={() =>
                window.open(spvInfo.balance_sheet)
              }>
                <img src={BSIcon} />
              </IconButton>
              <Box fontSize="12px">BalanceSheet</Box>
            </Box>

            <Box textAlign='center'>
              <IconButton onClick={() =>
                window.open(spvInfo.profit_loss)
              }>
                <img src={PNLICON} />
              </IconButton>
              <Box fontSize="12px">Profit & Loss</Box>
            </Box>

          </Box>
        </Box>

        {/* <Box
          style={{ lineHeight: 1.6, display: 'flex', flexDirection: 'row', position: "absolute", top: '371px', width: "100%", padding: "31px 20px", justifyContent: "space-between", left: "0px" }}
        >

          <Box textAlign='center'>
            <IconButton onClick={() =>
              window.open(spvInfo.account_statement)
            }>
              <img src={BuildIcon} />
            </IconButton>
            <Box fontSize="12px">Account Statment</Box>
          </Box>

          <Box textAlign='center'>
            <IconButton onClick={() =>
              window.open(spvInfo.balance_sheet)
            }>
              <img src={BSIcon} />
            </IconButton>
            <Box fontSize="12px">BalanceSheet</Box>
          </Box>

          <Box textAlign='center'>
            <IconButton onClick={() =>
              window.open(spvInfo.profit_loss)
            }>
              <img src={PNLICON} />
            </IconButton>
            <Box fontSize="12px">Profit & Loss</Box>
          </Box>

        </Box> */}

      </Modal>


      <Grid container spacing={2}>
        <Grid style={{ display: display }} className={responsive.web} item xs={12} sm={8}>
          <Box fontSize="22px" paddingBottom="22px" fontWeight="bold">
            {propertyDetails.property_name}
          </Box>
          <Box>
            {/* <OWLSlider items={1} data={propertyDetails.brik_image} render={(slider, index) => {
              return (
                <img width="100%" style={img} src={slider.image} alt='image' />
              )
            }} /> */}
            <Carousel showThumbs={false} showStatus={false}>
              {propertyDetails.brik_image.map(items => {
                return <div>
                  <img style={{ width: "100%", objectFit: "cover", height: "350px", borderRadius:"10px" }} src={items.image} />
                </div>
              })}
            </Carousel>
            {/* <img width="100%" style={img} src={propertyDetails.brik_cover_image} alt='image' /> */}
          </Box>
          <Box fontSize="18px" padding="14px 0px">Description</Box>
          <Box>{propertyDetails.description}</Box>

          <Box padding='10px 0'>
            <Box padding='10px 0' fontSize='18px'>Amenities</Box>
            <Grid container>
              {propertyDetails.amenities.map((amenitie, key) => {
                return (
                  <Grid key={key} item xs={6}>
                    <Box display='flex' padding='5px 0'>
                      <Box padding='0 10px'>
                        {amenitie.aminety_icon != null ? <img style={{ height: "30px" }} src={amenitie.aminety_icon} /> : <WifiOutlinedIcon />}
                      </Box>
                      <Box><Typography>{amenitie.amenity_feature}</Typography></Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>

          <Box padding='10px 0'>
            <Box fontSize='18px' padding='10px 0'>Property Info</Box>
            <Box display='flex' flexDirection='column' lineHeight='1.8'>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Project Name</Box>
                <Box flex={1}>{propertyDetails.property_name}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Location</Box>
                <Box flex={1}>{propertyDetails.location}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>SPV Name</Box>
                <Box flex={1}>{propertyDetails?.spv_info?.spv_name}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Property Type</Box>
                <Box flex={1}>{propertyDetails.property_type}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Accomadation</Box>
                <Box flex={1}>{propertyDetails.accomadation}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Project Manager</Box>
                <Box flex={1}>{propertyDetails.project_manager}</Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid style={{ display: display }} className={responsive.web} item xs={4} sm={4} order={{ xs: 4, sm: 4 }}>
          <Box style={{ marginTop: "50px", backgroundColor: "#F5F5F5", padding: "12px", borderRadius:"10px" }}>
            <Box style={{ backgroundColor: "white", borderRadius:"10px" }}>
              <Box padding="20px" borderBottom="1px solid #EBEBEB" justifyContent="space-between" display="flex">
                <Box flex={1}>
                  <KingBedOutlinedIcon />
                </Box>
                <Box flex={1}>{propertyDetails.bedroom}</Box>
                <Box flex={1}>BEDROOMS</Box>
              </Box>
              <Box padding="20px" borderBottom="1px solid #EBEBEB" justifyContent="space-between" display="flex">
                <Box flex={1}>
                  <BathtubOutlinedIcon />
                </Box>
                <Box flex={1}>{propertyDetails.bathroom}</Box>
                <Box flex={1}>BATHROOMS</Box>
              </Box>
              <Box padding="20px" justifyContent="space-between" display="flex">
                <Box flex={1}>
                  <HomeOutlinedIcon />
                </Box>
                <Box flex={1}>{propertyDetails.property_area}</Box>
                <Box flex={1}>SF<sup>2</sup></Box>
              </Box>
            </Box>

            <Box padding='15px 10px' display='flex' flexDirection='column' style={{ backgroundColor: "#2B4041", color: "white", borderRadius: "10px", marginTop:  "5%" }}>
              <Box style={{ width: "100%" }} display='flex' flexDirection='row' alignItems='center'>
                <Box padding='0 10px'>
                  <Avatar src={propertyDetails.brik_manager_image} />
                </Box>
                <Box>
                  <Box fontWeight='600' padding='5px'>{propertyDetails.brik_manager}</Box>
                  <Box>Brik Manager</Box>
                </Box>
              </Box>
              <Box width='100%' padding='10px 0'>
                <Button onClick={() => {
                  window.open(`tel:${propertyDetails.brik_manager_contact}`)
                }} variant='outlined' fullWidth style={{ backgroundColor: 'white' }}>

                  <Box style={{ color: "#4A8EAB" }} display="flex" alignItems="center">
                    <img src={CallIcon} />
                    <Box style={{ paddingLeft: "10px" }}>Brik Manager</Box>
                  </Box>
                </Button>
              </Box>
            </Box>

            <Box padding='10px 0'>
              <Box fontWeight='600' padding='10px 0' fontSize='18px'>Gallery</Box>
              <Box>
                <Grid spacing={1} container>
                  {propertyDetails.brik_image.map(image => {
                    return (<Grid item xs={4}>
                      <img onClick={() => {
                        setEnargeView(true)
                        setEnargeViewImg(image.image)
                      }} style={imag} src={image.image} alt='image' />
                    </Grid>)
                  })}
                </Grid>
              </Box>
            </Box>

            <Box style={{ position: "relative" }} padding='10px 0'>
              <Box padding='5px 0' fontWeight='600' fontSize='18px'>Location</Box>
              <Box>{propertyDetails.location}</Box>

              {console.log("GOOGLE: ", { lat: parseFloat(propertyDetails.lat), lng: parseFloat(propertyDetails.lng) })}
              {/* {JSON.stringify({ lat: parseFloat(propertyDetails.lat), lng: parseFloat(propertyDetails.lng) })} */}
              <Map
                google={props.google}
                zoom={8}
                style={{
                  height: "100px",
                  width: "100%",
                  borderRadius: "10px",
                }}
                initialCenter={{ lat: parseFloat(propertyDetails.lat), lng: parseFloat(propertyDetails.lng) }}
              >
                <Marker
                  onClick={() => {
                    window.open("https://maps.google.com?q=" + parseFloat(propertyDetails.lat) + "," + parseFloat(propertyDetails.lng))
                  }}
                  position={{ lat: parseFloat(propertyDetails.lat), lng: parseFloat(propertyDetails.lng) }}
                />
              </Map>
              {/* <GoogleMap
                                mapContainerStyle={containerStyle}
                                // center={center}
                                zoom={10}
                                onLoad={onMapLoad}
                            >
                                <Marker
                                    // key={marker.time.toISOString()}
                                    position={{ lat: 24.543736, lng: 73.695119 }}
                                >
                                </Marker>
                            </GoogleMap> */}

            </Box>
            <Button style={{ marginTop: "100px" }}
              onClick={() => {
                getSPVInfo()
                // setModal(true)
              }}
              variant='contained' color='secondary' fullWidth>VIEW SPV INFO</Button>

            <Box padding='10px 0'>
              <Box fontWeight='600' padding='10px 0' fontSize='18px'>Price Insight</Box>
              <Box display='flex' flexDirection='column' lineHeight='1.8'>
                <Box style={{ display: 'flex' }}>
                  <Box style={flfo}>Ownership</Box>
                  <Box flex={1} style={{ color: "#4A8EAB" }}>You Own {propertyDetails.property_detail[0].ownership_integer} BRIK(s)</Box>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Box style={flfo}>Brik Value</Box>
                  <Box flex={1}>₹ {propertyDetails.brik_value}</Box>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Box style={flfo}>Capital Gain</Box>
                  <Box flex={1}>{propertyDetails.capital_gain}</Box>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Box style={flfo}>Invested Amount</Box>
                  <Box flex={1}>₹ {propertyDetails.property_detail[0].invested_amount}</Box>
                </Box>

                <Box style={{ display: 'flex' }}>
                  <Box style={flfo}>IRR</Box>
                  <Box flex={1}>{propertyDetails.irr}</Box>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Box style={flfo}>PE Ratio</Box>
                  <Box flex={1}>{propertyDetails.pe_ratio}</Box>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Box style={flfo}>Unit Number</Box>
                  <Box flex={1}>{propertyDetails?.spv_info?.unit_number}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid style={{ display: display }} className={responsive.app} item xs={12} sm={12}>
          <Box display='flex' justifyContent='space-between' alignItems="center" padding='13px'>
            <Box style={{ textAlign: "center" }}>
              <Box display="flex" alignItems="center">
                <KingBedOutlinedIcon />
                <Box style={{ padding: "0px 8px" }}>{propertyDetails.bedroom}</Box>
              </Box>
              <Typography style={{ fontSize: "12px" }}>BEDROOMS</Typography>
            </Box>

            <Box textAlign='center'>
              <Box display="flex" alignItems="center">
                <BathtubOutlinedIcon />
                <Box style={{ padding: "0px 8px" }}>{propertyDetails.bathroom}</Box>
              </Box>
              <Typography style={{ fontSize: "12px" }}>BATHROOMS</Typography>
            </Box>

            <Box textAlign='center'>
              <Box display="flex" alignItems="center">
                <HomeOutlinedIcon />
                <Box style={{ padding: "0px 8px" }}>{propertyDetails.property_area}</Box>
              </Box>
              <Typography style={{ fontSize: "12px" }}>SF<sup>2</sup></Typography>
            </Box>
          </Box>

          <Box fontWeight='600' padding='10px 0' fontSize="17px">Description</Box>
          <Box>{propertyDetails.description}</Box>

          <Box padding='10px 0'>
            <Box padding='10px 0' fontWeight='600' fontSize="17px">Amenities</Box>
            <Box>
              <Grid container
                spacing={2}
                style={{ textAlign: "center" }}
              >
                {propertyDetails.amenities.map((amenitie, key) => {
                  return (
                    <Grid item xs={4}>
                      <Box>
                        <Box>
                          {amenitie.aminety_icon != undefined ? <img style={{ height: "30px" }} src={amenitie.aminety_icon} /> : <WifiOutlinedIcon />}
                        </Box>
                        <Box><Typography>{amenitie.amenity_feature}</Typography></Box>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>

          <Box padding='10px 0'>
            <Box fontWeight='600' padding='10px 0' fontSize="17px">Property Info</Box>
            <Box display='flex' flexDirection='column' lineHeight='1.8'>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Project Name</Box>
                <Box flex={1}>{propertyDetails.property_name}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Location</Box>
                <Box flex={1}>{propertyDetails.location}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>SPV Name</Box>
                <Box flex={1}>{propertyDetails?.spv_info[0]?.spv_name}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Property Type</Box>
                <Box flex={1}>{propertyDetails.property_type}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Accomadation</Box>
                <Box flex={1}>{propertyDetails.accomadation}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Project Manager</Box>
                <Box flex={1}>{propertyDetails.project_manager}</Box>
              </Box>
            </Box>
          </Box>


          <Box padding='10px 0'>
            <Box fontWeight='600' padding='10px 0' fontSize="17px">Price Insight</Box>
            <Box display='flex' flexDirection='column' lineHeight='1.8'>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Ownership</Box>
                <Box flex={1} style={{ color: "#4A8EAB" }}>You Own {propertyDetails.property_detail[0].ownership_integer} BRIK(s)</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Brik Value</Box>
                <Box flex={1}>{propertyDetails.brik_value}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Capital Gain</Box>
                <Box flex={1}>{propertyDetails.capital_gain}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>Invested Amount</Box>
                <Box flex={1}>{propertyDetails.property_detail[0].invested_amount}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>IRR</Box>
                <Box flex={1}>{propertyDetails.irr}</Box>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Box style={flfo}>PR Ratio</Box>
                <Box flex={1}>{propertyDetails.pr_ratio}</Box>
              </Box>
            </Box>
          </Box>

          <Box padding='10px 0' style={{ position: "relative" }}>
            <Box padding='10px 0' fontWeight='600' fontSize="17px">Location</Box>
            <Box>{propertyDetails.location}</Box>
            <Map
              google={props.google}
              zoom={8}
              style={{
                height: "100px",
                width: "100%"
              }}
              initialCenter={{ lat: parseFloat(propertyDetails.lat), lng: parseFloat(propertyDetails.lng) }}
            >
              <Marker
                onClick={() => {
                  window.open("https://maps.google.com?q=" + parseFloat(propertyDetails.lat) + "," + parseFloat(propertyDetails.lng))
                }}

                position={{ lat: parseFloat(propertyDetails.lat), lng: parseFloat(propertyDetails.lng) }}
              />
            </Map>

            {/* <GoogleMap
              mapContainerStyle={{ maxWidth: '100%', height: '100px', padding: '5px 0' }}
              center={center}
              zoom={10}
              onLoad={onMapLoad}
            >
            </GoogleMap> */}

            {/* <img style={{ maxWidth: '100%', maxHeight: '50%', padding: '5px 0' }} src={images} alt='img' /> */}
          </Box>


          <Box padding='10px 0' style={{ marginTop: "87px" }}>
            <Box fontWeight='600' padding='10px 0' fontSize="17px">Gallery</Box>
            <Box>
              <Grid spacing={1} container>
                {propertyDetails.brik_image.map(image => {
                  return (<Grid item xs={3}>
                    <img onClick={() => {
                      setEnargeView(true)
                      setEnargeViewImg(image.image)
                    }} style={imag} src={image.image} alt='image' />
                  </Grid>)
                })}
              </Grid>
            </Box>
          </Box>

          <Box style={{ color: "white", backgroundColor: '#2B4041', borderRadius: "15px 15px 0 0" }} padding='18px 5px 31px 5px' display='flex' flexDirection='row' alignItems='center'>
            <Box padding='0 10px'>
              <Avatar src={propertyDetails.brik_manager_image} />
            </Box>
            <Box alignItems="center">
              <Box fontWeight='600' fontSize="17px">{propertyDetails.brik_manager}</Box>
              <Box>Brik Manager</Box>
            </Box>
          </Box>

          {/* <Box style={{ color: "white", backgroundColor: '#2B4041' }} padding='20px 5px' display='flex'
                    alignItems='center' borderRadius='10px'>
                    <Avatar />
                    <Box padding='0 15px'>
                        <Box fontWeight='600' padding='5px'>Hritik</Box>
                        <Box>Brik Manager</Box>
                    </Box>
                </Box> */}

          <Card style={{ position: "relative", bottom: "20px", borderRadius: "21px 21px 0 0", margin: "3px 0" }}>
            <Box display='flex' justifyContent='space-around' padding='30px 0'>
              <Button onClick={() => {
                window.open(`tel:${propertyDetails.brik_manager_contact}`)
              }} variant='outlined' color="secondary">
                <Box display="flex" alignItems="center">
                  <img src={CallIcon} />
                  <Box style={{ paddingLeft: "10px" }}>Brik Manager</Box>
                </Box>
              </Button>
              <Button variant='contained' onClick={() => {
                getSPVInfo()
                // setModal(true)
              }} color="secondary">VIEW SPV INFO</Button>
            </Box>
          </Card>

        </Grid>
      </Grid>
    </>
  )
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyBtIVzIwt7vp3XYdFAchnxuLO7Gos8P0Hc'
})(React.memo(Overview));
