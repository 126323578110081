import {
  GET_MY_OWNERSHIPS,
  IS_COMPONENT_BUSY_OWNERSHIP,
  SET_COOWNERS,
  SET_TRANSACTION,
} from "../constants";

let initialState = {
  myOwnerShips: [],
  coowners: [],
  payments: {
    due_remainders: {},
    installments: [],
    maintenance_annual: [],
    payment_details: {},
  },
  documents: [{}],
  isComponentBusy: false,
};

const MyOwnershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_OWNERSHIPS:
      return {
        ...state,
        myOwnerShips: action.payload,
      };
    case SET_COOWNERS:
      return {
        ...state,
        coowners: action.payload,
      };
    case SET_TRANSACTION:
      return {
        ...state,
        payments: action.payload,
      };
    case IS_COMPONENT_BUSY_OWNERSHIP:
      return { ...state, isComponentBusy: action.payload };
    default:
      return state;
  }
};

export default MyOwnershipReducer;
