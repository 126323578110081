import React from "react";
import policy from "../assets/documents/CHANNEL_PARTNER_AGREEMENT.pdf";
import { Document, Page } from "react-pdf";
import { Box } from "@material-ui/core";

function Agreement() {
  return (
    <Box
      style={{
        padding: "10px",
        // position: "absolute",
        // margin: 0,
        // left: 20,
        // right: 0,
        // top: 0,
        // bottom: 0,
      }}
    >
      <iframe height="1000px" width="100%" src={policy} />
    </Box>
  );
}

export default Agreement;
