import { filter } from "lodash";
import {
  SET_ALL_LEADS,
  SET_DASHBOARD_DATA,
  SET_META_DATA,
  SET_SEARCH_LEADS,
} from "../constants";
import {
  dashboardStats,
  documentByID,
  exportTableData,
  leads,
  metaData,
} from "./apis";
import { setComponentBusyState } from "./userActions";
const queryString = require("query-string");

export const setMetaData = (data) => {
  return {
    type: SET_META_DATA,
    payload: data,
  };
};

export const getMetaData = (filter = {}) => {
  return (dispatch) => {
    dispatch(setComponentBusyState(true));
    return metaData(filter)
      .then((result) => {
        console.log("Result getMetaData: ", result);
        dispatch(setMetaData(result));
        dispatch(setComponentBusyState(false));
      })
      .catch((er) => {
        dispatch(setComponentBusyState(false));
      });
  };
};

export const getDashboardSatistics = () => {
  return (dispatch) => {
    dispatch(setComponentBusyState(true));
    return dashboardStats()
      .then((result) => {
        dispatch(setDashboardData(result));
        dispatch(setComponentBusyState(false));
      })
      .catch((er) => {
        dispatch(setComponentBusyState(false));
      });
  };
};

// export const getDocumentByID = (document_id) => {
//   return (dispatch) => {
//     dispatch(setComponentBusyState(true));
//     return documentByID(document_id)
//       .then((result) => {
//         dispatch(setLeadsData(result));
//         dispatch(setComponentBusyState(false));
//       })
//       .catch((er) => {
//         dispatch(setComponentBusyState(false));
//       });
//   };
// };

export const exportResults = (filters) => {
  // if (filters.priority) filters.priority = filters.priority.join(",");

  // if (filters.url)
  // newurl=filters.url

  if (!(filters.search == "" || filters.search == undefined)) {
    delete filters.status;
  }

  if (Object.keys(filters).length) {
    var queryString = Object.keys(filters)
      .map(function (key) {
        return key + "=" + filters[key];
      })
      .join("&");
  } else var queryString = "";

  console.log("EXPORT RESULTS: ", queryString);
  // return (dispatch) => {
  //   dispatch(setComponentBusyState(true));
  return exportTableData(queryString).then((result) => {
    // var uInt8Array = new Uint8Array(result); //first convert the returned binary array into a js binary array
    //Actual Code for downloading Binary File
    // let blob = new Blob([result]); //Then create a blob object and set the file type to the type of
    // let blobURL = window.URL.createObjectURL(blob); //Then create an accessible URL
    // let tempLink = document.createElement("a"); //Create a tag to download
    // tempLink.style.display = "none";
    // tempLink.href = blobURL;
    // tempLink.setAttribute("download", Date.now() + ".xlsx");
    // if (typeof tempLink.download === "undefined") {
    //   tempLink.setAttribute("target", "_blank");
    // }
    // document.body.appendChild(tempLink);
    // tempLink.click();
    // document.body.removeChild(tempLink);
    // window.URL.revokeObjectURL(blobURL);
    //Actual Code Endsfor downloading Binary File
    // const url = window.URL.createObjectURL(new Blob([result]));
    // console.log("URL FOR BLOB: ", url);
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", `${Date.now()}.xlsx`);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
    // console.log("REUSLT EXPORT RESULTS: ", result);
  });

  // };
};

export const getLeads = (filters) => {
  // if (filters.priority) filters.priority = filters.priority.join(",");

  // if (filters.url)
  // newurl=filters.url

  if (!(filters.search == "" || filters.search == undefined)) {
    delete filters.status;
  }

  var queryString = Object.keys(filters)
    .map(function (key) {
      return key + "=" + filters[key];
    })
    .join("&");

  return (dispatch) => {
    dispatch(setComponentBusyState(true));
    return leads(queryString)
      .then((result) => {
        dispatch(setLeadsData(result));
        dispatch(setComponentBusyState(false));
      })
      .catch((er) => {
        dispatch(setComponentBusyState(false));
      });
  };
};

export const getLeadsSearching = (filters) => {
  if (filters.priority) filters.priority = filters.priority.join(",");
  // if (filters.url)
  // newurl=filters.url
  // console.log("url",newurl)

  var queryString = Object.keys(filters)
    .map(function (key) {
      return key + "=" + filters[key];
    })
    .join("&");

  return (dispatch) => {
    dispatch(setComponentBusyState(true));
    return leads(queryString)
      .then((result) => {
        dispatch(setSearchLeadsData(result));
        dispatch(setComponentBusyState(false));
      })
      .catch((er) => {
        dispatch(setComponentBusyState(false));
      });
  };
};

const setDashboardData = (data) => {
  return {
    type: SET_DASHBOARD_DATA,
    payload: data,
  };
};

const setLeadsData = (data) => {
  if (data.next) {
    let qs = data.next.split("?");
    let parsedQueryString = queryString.parse(qs[1]);

    data.limit = parsedQueryString.limit;
    data.offset = parsedQueryString.offset;
    data.override = parsedQueryString.override;
    console.log("SET LEAD DATA: ", data);
  } else if (data.previous) {
    let qs = data.previous.split("?");
    let parsedQueryString = queryString.parse(qs[1]);

    data.limit = parsedQueryString.limit;
    data.offset = parsedQueryString.offset;
    data.override = parsedQueryString.override;
    console.log("SET LEAD DATA: ", data);
  }

  return {
    type: SET_ALL_LEADS,
    payload: data,
  };
};

const setSearchLeadsData = (data) => {
  if (data.next) {
    let qs = data.next.split("?");
    let parsedQueryString = queryString.parse(qs[1]);

    data.limit = parsedQueryString.limit;
    data.offset = parsedQueryString.offset;
    data.override = parsedQueryString.override;
  } else if (data.previous) {
    let qs = data.previous.split("?");
    let parsedQueryString = queryString.parse(qs[1]);

    data.limit = parsedQueryString.limit;
    data.offset = parsedQueryString.offset;
    data.override = parsedQueryString.override;
  }

  return {
    type: SET_SEARCH_LEADS,
    payload: data,
  };
};
