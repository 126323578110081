import React, {
  useDebugValue,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import "antd/dist/antd.css";
import { DatePicker, Space } from "antd";
import { icons } from "../../assets/assets";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Box, Grid, MenuItem } from "@mui/material";
import Edit from "../../assets/icons/graph/edit.png";
import Update from "../../assets/icons/graph/update.svg";
import Delete from "../../assets/icons/graph/delete.png";
import Arr from "../../assets/icons/graph/arr.png";
import Arr1 from "../../assets/icons/graph/arr1.png";
import ArrowForwardIosIcon from "../../assets/icons/graph/arrow.png";
import {
  TableBody,
  TableFooter,
  TablePagination,
  CircularProgress,
  Menu,
  Badge,
} from "@material-ui/core";
import {
  AppBar,
  Tab,
  Switch,
  TextField,
  OutlinedInput,
  Select,
  Button,
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
// import { Form, Button, Row, InputGroup, FormControl } from "react-bootstrap";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { KeyboardArrowRight, Search } from "@material-ui/icons";
import SortIcon from "@material-ui/icons/Sort";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from "@material-ui/icons/LastPage";
import { colDef } from "./colDefination.json";
import Modal from "../../component/Modal";
import clsx from "clsx";
import { exportResults, getLeads } from "../../store/actions/dashboardActions";
import HotSqIcon from "../../assets/icons/leadDetail/HotSqIcon.png";
import WarmSqIcon from "../../assets/icons/leadDetail/WarmSqIcon.png";
import CoolSqIcon from "../../assets/icons/leadDetail/CoolSqIcon.png";
import ColdSqIcon from "../../assets/icons/leadDetail/ColdSqIcon.png";
import Hot from "../../assets/icons/leadDetail/Hot.svg";
import Warm from "../../assets/icons/leadDetail/Warm.svg";
import Cool from "../../assets/icons/leadDetail/Cool.svg";
import Cold from "../../assets/icons/leadDetail/Cold.svg";
import Last from "../../assets/icons/graph/last.png";
import { Close } from "@material-ui/icons";
import { useFormik } from "formik";
import { responsiveStyles } from "../../assets/css/generalStyling";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
// import { share } from "../../images";
import "./index.css";
import { deleteLead } from "../../store/actions/apis";
import { useSnackbar } from "notistack";
import CreateLead from "../../screens/CreateLead";
import moment from "moment";
import { lte } from "lodash";
import Tooltip from "@mui/material/Tooltip";
import { object } from "yup";
const { RangePicker } = DatePicker;

// import { BASE_URL } from "../../../../constants";

// import { MainGuidedTourContext } from "../../containers/MainGuidedTourProvider";

// import sortIcon from "../../../../assets/images/icons/sortIcon.svg"; //"../../../../assets/images/icons/sortIcon.svg"

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles = makeStyles((theme) => ({
  badge: {
    marginTop: "8px",
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    cursor: "pointer",
    fontFamily: "Red Hat Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#172B4D",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "rgba(1,89,169,0.1)",
    },
  },
  tabelCell: {
    fontFamily: "Red Hat Display",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#6B778C !important",
  },
  muiIcon: {
    width: "17%",
    height: "15px",
    margin: "auto",
    // color: "#000000",
  },
  svgIcon: {
    marginLeft: "6px",
  },
  input: {
    padding: "10px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "1px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "60px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const TabTable = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    downloadResult,
  }));

  const {
    tableName,
    setClear = () => {},
    clear = clear === false ? false : true,
  } = props;
  // const {
  //   data = null,
  //   tableName,
  //   isBusyFetching = false,
  //   history,
  //   token,
  //   getData = () => {},
  //   searchString = "",
  //   setShare = () => {},
  // } = props;
  const classes = useStyles();
  const refs = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [nm, setNm] = React.useState(false);
  const [nm1, setNm1] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [update, setUpdate] = React.useState(false);
  const [lse, setLse] = React.useState("");
  const [gte, setGte] = React.useState("");
  const [nxtUrl, setNxtUrl] = React.useState("");
  const [link, setLink] = React.useState("");
  const [preUrl, setPreUrl] = React.useState("");
  const [serPriority, setSerPriorty] = React.useState("");
  const [serDate, setSerDate] = React.useState("");
  const [sortString, setSortString] = React.useState("ordering=");
  const [searchStr, setSearchStr] = React.useState("");
  const [modified, setModified] = React.useState(false);
  const [duplicate, setDuplicate] = React.useState("none");
  const [dupl, setDup] = useState(false);
  const [srch, setSrch] = React.useState("");
  const [source, setSource] = React.useState("");
  const [src, setSrc] = React.useState("none");
  const [assign, setAssign] = React.useState("");
  const [assg, setAssg] = React.useState("none");
  const [ids, setId] = useState("");
  const [org, setOrg] = React.useState("");
  const [orgn, setOrgn] = React.useState("none");
  const [client, setClient] = React.useState("");
  const [clt, setClt] = React.useState("none");
  const [priority, setPriority] = React.useState("");
  const [offset, setOffset] = React.useState("");
  const [prty, setPrty] = React.useState("none");
  const [lst, setLst] = React.useState("none");
  const [last, setLast] = React.useState("");
  const [searchTxt, setSearchTxt] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [leadId, setLeadId] = useState("");
  const [modal, setModal] = useState(false);
  const notification = useSnackbar();
  const [sts, setSts] = useState("");
  const [status, setStatus] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { leads, meta_data } = useSelector((state) => state.dashboard);
  const [leadResult, setLeadResult] = useState(leads);
  const { values, touched, errors, handleSubmit, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        lead_status: 0,
      },
      onSubmit: () => {},
    });

  const budgetType = ["Single", "Range"];
  // const priority = ["Low", "Medium", "High"];
  const priorityColor = ["#6B778C", "#FCA016", "#FF5353"];

  useEffect(() => {
    //console.log("date filter",updated)
    // if(clear===true){
    //   setSrch("")
    //   setSource("")
    //   setClient("")
    //   setPriority([])
    //   setRowsPerPage(5)
    //   setLink("")
    //   setLse("")
    //   setGte("")
    //   dispatch(
    //     getLeads({
    //       search: srch,
    //       source: source,
    //       category: client,
    //       priority: [priority],
    //       limit:rowsPerPage,
    //       url:link,
    //       status:props.filter,
    //       date__lte:lse,
    //       date__gte:gte,
    //     })
    //   );
    // }
    // else
    // {
    if (clear === false) {
      if (props.filter === "new") {
        dispatch(
          getLeads({
            search: srch,
            source: source,
            category: client,
            priority: [priority],
            limit: rowsPerPage,
            status: "new",
            url: link,
            date__lte: lse,
            date__gte: gte,
            last_call_status: last,
            org: org !== "" ? parseInt(org) : "",
            assigned: assign !== "" ? parseInt(assign) : "",
          })
        );
      } else {
        dispatch(
          getLeads({
            search: srch,
            source: source,
            category: client,
            priority: [priority],
            limit: rowsPerPage,
            url: link,
            status: props.filter,
            date__lte: lse,
            date__gte: gte,
            last_call_status: last,
            org: org !== "" ? parseInt(org) : "",
            assigned: assign !== "" ? parseInt(assign) : "",
          })
        );
      }
    }
  }, [
    srch,
    source,
    client,
    priority,
    rowsPerPage,
    link,
    props.filter,
    lse,
    gte,
    last,
    clear,
    org,
    assign,
  ]);

  useEffect(() => {
    if (clear === true) {
      dispatch(
        getLeads({
          search: "",
          source: "",
          category: "",
          priority: [],
          limit: 5,
          url: "",
          status: props.filter,
          date__lte: "",
          date__gte: "",
          last_call_status: "",
          org: "",
          assigned: "",
        })
      );
      setAssign("");
      setSource("");
      setLast("");
      setClient("");
      setPriority("");
    }
  }, [clear]);

  const closeDrpdwn = () => {
    setSrc("none");
    setClt("none");
    setNm(false);
    setNm1(false);
    setUpdate(false);
    setLst("none");
    setPrty("none");
    setOrgn("none");
    setAssg("none");
    setId("");
  };

  // useEffect(() => {
  //   const checkIfClickedOutside = e => {
  //     // If the menu is open and the clicked target is not within the menu,
  //     // then close the menu
  //     if (src==="block" && ref.current && !ref.current.contains(e.target)) {
  //       setSrc("none")
  //     }
  //     if (prty && ref.current && !ref.current.contains(e.target)) {
  //       var id1 = "myDropdown4";
  //                       var ie1 =
  //                         document.all && !window.opera ? document.all : 0;
  //                       var frmObj1 = ie1
  //                         ? ie1[id1]
  //                         : document.getElementById(id1);
  //                       frmObj1.style.display = "none";
  //                       setPrty(false);
  //     }
  //     if (clt && ref.current && !ref.current.contains(e.target)) {
  //       var id1 = "myDropdown3";
  //                       var ie1 =
  //                         document.all && !window.opera ? document.all : 0;
  //                       var frmObj1 = ie1
  //                         ? ie1[id1]
  //                         : document.getElementById(id1);
  //                       frmObj1.style.display = "none";
  //                       setClt(false);
  //     }
  //   }

  //   document.addEventListener("mousedown", checkIfClickedOutside)

  //   return () => {
  //     // Cleanup the event listener
  //     document.removeEventListener("mousedown", checkIfClickedOutside)
  //   }
  // }, [src,prty,clt,source])

  useEffect(() => {
    console.log("HELLO UPDATED LEAD DATA iNTO LEADRESULT");
    setLeadResult({ ...leads });
  }, [leads]);

  const getIcon = (name, classes, field) => {
    switch (name) {
      case "last":
        if (field === "last_call") {
          return (
            <>
              <span className="dropbtn3">
                <Tooltip title="Last Call Status" arrow>
                  <img
                    src={Last}
                    alt="Last Call Status"
                    style={{
                      cursor: "pointer",
                      marginBottom: "-6%",
                      width: "14px",
                      height: "14px",
                    }}
                    className={classes.muiIcon}
                    onClick={(e) => {
                      if (lst === "none") {
                        closeDrpdwn();
                        setLst("block");
                      } else closeDrpdwn();
                    }}
                  />
                </Tooltip>
              </span>
              <span
                id="myDropdown5"
                className="dropdown-download1"
                style={{ display: lst }}
              >
                {meta_data.call_statuses.map((status, index) => (
                  <React.Fragment key={index}>
                    <a
                      href="#"
                      id="ac5"
                      onClick={() => {
                        if (lst === "none") {
                          closeDrpdwn();
                          setLst("block");
                        } else closeDrpdwn();
                        setLast(status);
                        setClear(false);
                      }}
                    >
                      {status}
                    </a>
                  </React.Fragment>
                ))}
                <a
                  href="#"
                  id="ac5"
                  onClick={() => {
                    if (lst === "none") {
                      closeDrpdwn();
                      setLst("block");
                    } else closeDrpdwn();
                    setLast("");
                    setClear(false);
                  }}
                >
                  None
                </a>
              </span>
            </>
          );
        }
        break;
      case "search":
        if (field === "name") {
          return (
            <>
              <Search
                onClick={() => {
                  if (!nm) {
                    var id = "itm1";
                    var ie = document.all && !window.opera ? document.all : 0;
                    var frmObj = ie ? ie[id] : document.getElementById(id);
                    var toObj = ie
                      ? ie[id + "b"]
                      : document.getElementById(id + "b");
                    //toObj.style.width = frmObj.offsetWidth + 7 + "px";
                    frmObj.style.display = "none";
                    toObj.style.display = "inline";
                    toObj.style.width = "auto";
                    toObj.value = frmObj.innerHTML;
                    closeDrpdwn();
                    setNm(!nm);
                  } else {
                    var id = "itm1";
                    var ie = document.all && !window.opera ? document.all : 0;
                    var frmObj = ie ? ie[id] : document.getElementById(id);
                    var toObj = ie
                      ? ie[id + "b"]
                      : document.getElementById(id + "b");
                    frmObj.style.display = "inline";
                    toObj.style.display = "none";
                    closeDrpdwn();
                    setNm(!nm);
                  }
                }}
                className={classes.muiIcon}
                style={{
                  cursor: "pointer",
                  width: "14px",
                  height: "14px",
                  marginBottom: "-3%",
                }}
              />
            </>
          );
        }
        if (field === "mobile") {
          return (
            <Search
              onClick={() => {
                if (!nm1) {
                  var id1 = "itm2";
                  var ie1 = document.all && !window.opera ? document.all : 0;
                  var frmObj1 = ie1 ? ie1[id1] : document.getElementById(id1);
                  var toObj1 = ie1
                    ? ie1[id1 + "b"]
                    : document.getElementById(id1 + "b");
                  toObj1.style.width = frmObj1.offsetWidth + 7 + "px";
                  frmObj1.style.display = "none";
                  toObj1.style.display = "inline";
                  toObj1.value = frmObj1.innerHTML;
                  closeDrpdwn();
                  setNm1(!nm1);
                } else {
                  var id1 = "itm2";
                  var ie1 = document.all && !window.opera ? document.all : 0;
                  var frmObj1 = ie1 ? ie1[id1] : document.getElementById(id1);
                  var toObj1 = ie1
                    ? ie1[id1 + "b"]
                    : document.getElementById(id1 + "b");
                  frmObj1.style.display = "inline";
                  toObj1.style.display = "none";
                  closeDrpdwn();
                  setNm1(!nm1);
                }
              }}
              className={classes.muiIcon}
              style={{
                cursor: "pointer",
                width: "14px",
                height: "14px",
                marginBottom: "-4%",
              }}
            />
          );
        }
        break;
      case "sortIcon":
        // {
        //   console.log("sort icon....");
        // }
        if (field === "assigned") {
          return (
            <>
              <span className="dropbtn">
                <SortIcon
                  style={{
                    cursor: "pointer",
                    marginBottom: "-6%",
                    width: "14px",
                    height: "14px",
                  }}
                  className={classes.muiIcon}
                  onClick={() => {
                    console.log("click", org);
                    if (orgn === "none") {
                      //closeDrpdwn();
                      setOrgn("block");
                    } else closeDrpdwn();
                  }}
                />
              </span>
              <span
                id="myDropdown2"
                className="dropdown-download"
                style={{ display: orgn }}
              >
                {meta_data.orgs.map((org, index) => (
                  <React.Fragment key={index}>
                    <Box
                      display="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href="#"
                        id="ac9"
                        onClick={() => {
                          //dispatch(getLeads({ source: source }));
                          if (orgn === "none") {
                            //closeDrpdwn();
                            setId(org.id);
                            setAssg("block");
                          } else closeDrpdwn();
                          setOrg(org.id);
                          setClear(false);
                        }}
                      >
                        {org.name}
                      </a>
                      <span className="dropbtn">
                        <SortIcon
                          style={{
                            cursor: "pointer",
                            marginBottom: "-6%",
                            width: "14px",
                            height: "14px",
                            float: "right",
                          }}
                          className={classes.muiIcon}
                          onClick={() => {
                            console.log("click", assign);
                            if (assg === "none") {
                              //closeDrpdwn();
                              setId(org.id);
                              setAssg("block");
                            } else closeDrpdwn();
                          }}
                        />
                      </span>
                      <span
                        id="myDropdown2"
                        className="dropdown-download"
                        style={{ display: assg }}
                      >
                        {meta_data.users.map((user, index) => (
                          <>
                            {org.id === user.org_id &&
                            user.org_id === parseInt(ids) ? (
                              <React.Fragment key={index}>
                                <a
                                  href="#"
                                  id="ac2"
                                  onClick={() => {
                                    //dispatch(getLeads({ source: source }));
                                    if (assg === "none") {
                                      //closeDrpdwn();
                                      setId("");
                                      setAssg("block");
                                    } else closeDrpdwn();
                                    setAssign(user.id);
                                    setClear(false);
                                  }}
                                >
                                  {user.name}
                                </a>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </span>
                    </Box>
                  </React.Fragment>
                ))}
                <a
                  href="#"
                  id="ac2"
                  onClick={() => {
                    //dispatch(getLeads({ source: source }));
                    if (orgn === "none") {
                      //closeDrpdwn();
                      setOrgn("block");
                    } else closeDrpdwn();
                    setOrg("");
                    setClear(false);
                  }}
                >
                  None
                </a>
              </span>
            </>
          );
        }
        if (field === "source") {
          return (
            <>
              <span className="dropbtn">
                <SortIcon
                  style={{
                    cursor: "pointer",
                    marginBottom: "-6%",
                    width: "14px",
                    height: "14px",
                  }}
                  className={classes.muiIcon}
                  // onClick={field === "start_date" ? configureDate : configurePriority}
                  onClick={() => {
                    console.log("click", src);
                    if (src === "none") {
                      closeDrpdwn();
                      setSrc("block");
                    } else closeDrpdwn();
                    // if (!src) {
                    //   let btns = document.querySelectorAll(".dropbtn");
                    //   function showDrpDown(e) {
                    //     e.target.parentNode.nextElementSibling.classList.toggle(
                    //       "show"
                    //     );
                    //   }
                    //   btns.forEach((btn) => {
                    //     btn.addEventListener("click", showDrpDown);
                    //   });
                    //   var id1 = "myDropdown2";
                    //   var ie1 =
                    //     document.all && !window.opera ? document.all : 0;
                    //   var frmObj1 = ie1
                    //     ? ie1[id1]
                    //     : document.getElementById(id1);
                    //   frmObj1.style.display = "block";
                    //   setSrc(!src);
                    // } else {
                    //   let btns = document.querySelectorAll(".dropbtn");
                    //   function showDrpDown(e) {
                    //     e.target.parentNode.nextElementSibling.classList.toggle(
                    //       "show"
                    //     );
                    //   }
                    //   btns.forEach((btn) => {
                    //     btn.addEventListener("click", showDrpDown);
                    //   });
                    //   var id1 = "myDropdown2";
                    //   var ie1 =
                    //     document.all && !window.opera ? document.all : 0;
                    //   var frmObj1 = ie1
                    //     ? ie1[id1]
                    //     : document.getElementById(id1);
                    //   frmObj1.style.display = "none";
                    //   setSrc(!src);
                    // }
                  }}
                />
              </span>
              <span
                id="myDropdown2"
                className="dropdown-download"
                style={{ display: src }}
              >
                {meta_data.sources.map((source, index) => (
                  <React.Fragment key={index}>
                    <a
                      href="#"
                      id="ac2"
                      onClick={() => {
                        //dispatch(getLeads({ source: source }));
                        if (src === "none") {
                          closeDrpdwn();
                          setSrc("block");
                        } else closeDrpdwn();
                        setSource(source);
                        setClear(false);
                      }}
                    >
                      {source}
                    </a>
                  </React.Fragment>
                ))}
                <a
                  href="#"
                  id="ac2"
                  onClick={() => {
                    //dispatch(getLeads({ source: source }));
                    if (src === "none") {
                      closeDrpdwn();
                      setSrc("block");
                    } else closeDrpdwn();
                    setSource("");
                    setClear(false);
                  }}
                >
                  None
                </a>
                {/* <a href="#">Link 1 - drpdown2</a>
            <a href="#">Link 2 - drpdown2</a>
            <a href="#">Link 3 - drpdown2</a> */}
              </span>
            </>
          );
        }
        if (field === "client") {
          return (
            <>
              <span className="dropbtn1">
                <SortIcon
                  style={{
                    cursor: "pointer",
                    marginBottom: "-4%",
                    width: "14px",
                    height: "14px",
                  }}
                  className={classes.muiIcon}
                  // onClick={field === "start_date" ? configureDate : configurePriority}
                  onClick={() => {
                    if (clt === "none") {
                      closeDrpdwn();
                      setClt("block");
                    } else closeDrpdwn();
                    // if (!clt) {
                    //   let btns1 = document.querySelectorAll(".dropbtn1");
                    //   function showDrpDown1(e) {
                    //     e.target.parentNode.nextElementSibling.classList.toggle(
                    //       "show1"
                    //     );
                    //   }
                    //   btns1.forEach((btn) => {
                    //     btn.addEventListener("click", showDrpDown1);
                    //   });
                    //   var id1 = "myDropdown3";
                    //   var ie1 =
                    //     document.all && !window.opera ? document.all : 0;
                    //   var frmObj1 = ie1
                    //     ? ie1[id1]
                    //     : document.getElementById(id1);
                    //   frmObj1.style.display = "block";
                    //   setClt(!clt);
                    // } else {
                    //   let btns1 = document.querySelectorAll(".dropbtn1");
                    //   function showDrpDown1(e) {
                    //     e.target.parentNode.nextElementSibling.classList.toggle(
                    //       "show1"
                    //     );
                    //   }
                    //   btns1.forEach((btn) => {
                    //     btn.addEventListener("click", showDrpDown1);
                    //   });
                    //   var id1 = "myDropdown3";
                    //   var ie1 =
                    //     document.all && !window.opera ? document.all : 0;
                    //   var frmObj1 = ie1
                    //     ? ie1[id1]
                    //     : document.getElementById(id1);
                    //   frmObj1.style.display = "none";
                    //   setClt(!clt);
                    // }
                  }}
                />
              </span>
              <span
                id="myDropdown3"
                className="dropdown-download1"
                style={{ display: clt }}
              >
                {meta_data.client_categories.map((client, index) => (
                  <React.Fragment key={index}>
                    <a
                      href="#"
                      id="ac3"
                      onClick={() => {
                        if (clt === "none") {
                          closeDrpdwn();
                          setClt("block");
                        } else closeDrpdwn();
                        setClient(client);
                        setClear(false);
                        // setClient(client);
                        // //dispatch(getLeads({ category: client }));
                        // var id1 = "myDropdown3";
                        // var ie1 =
                        //   document.all && !window.opera ? document.all : 0;
                        // var frmObj1 = ie1
                        //   ? ie1[id1]
                        //   : document.getElementById(id1);
                        // frmObj1.style.display = "none";
                        // setClt(!clt);
                      }}
                    >
                      {client}
                    </a>
                  </React.Fragment>
                ))}
                <a
                  href="#"
                  id="ac3"
                  onClick={() => {
                    if (clt === "none") {
                      closeDrpdwn();
                      setClt("block");
                    } else closeDrpdwn();
                    setClient("");
                    setClear(false);
                    // setClient("");
                    // //dispatch(getLeads({ category: client }));
                    // var id1 = "myDropdown3";
                    // var ie1 =
                    //   document.all && !window.opera ? document.all : 0;
                    // var frmObj1 = ie1
                    //   ? ie1[id1]
                    //   : document.getElementById(id1);
                    // frmObj1.style.display = "none";
                    // setClt(!clt);
                  }}
                >
                  None
                </a>
                {/* <a href="#">Link 1 - drpdown2</a>
                <a href="#">Link 2 - drpdown2</a>
                <a href="#">Link 3 - drpdown2</a> */}
              </span>
            </>
          );
        }
        if (field === "lead_status") {
          return (
            <>
              <span className="dropbtn2">
                <SortIcon
                  style={{
                    cursor: "pointer",
                    marginBottom: "-6%",
                    width: "14px",
                    height: "14px",
                  }}
                  className={classes.muiIcon}
                  // onClick={field === "start_date" ? configureDate : configurePriority}
                  onClick={(e) => {
                    // if (!prty) {
                    //   let btns1 = document.querySelectorAll(".dropbtn2");
                    //   function showDrpDown2(e) {
                    //     e.target.parentNode.nextElementSibling.classList.toggle(
                    //       "show2"
                    //     );
                    //   }
                    //   btns1.forEach((btn) => {
                    //     btn.addEventListener("click", showDrpDown2);
                    //   });
                    //   var id1 = "myDropdown4";
                    //   var ie1 =
                    //     document.all && !window.opera ? document.all : 0;
                    //   var frmObj1 = ie1
                    //     ? ie1[id1]
                    //     : document.getElementById(id1);
                    //   frmObj1.style.display = "block";
                    //   setPrty(!prty);
                    // } else {
                    //   let btns1 = document.querySelectorAll(".dropbtn2");
                    //   function showDrpDown2(e) {
                    //     e.target.parentNode.nextElementSibling.classList.toggle(
                    //       "show2"
                    //     );
                    //   }
                    //   btns1.forEach((btn) => {
                    //     btn.addEventListener("click", showDrpDown2);
                    //   });
                    //   var id1 = "myDropdown4";
                    //   var ie1 =
                    //     document.all && !window.opera ? document.all : 0;
                    //   var frmObj1 = ie1
                    //     ? ie1[id1]
                    //     : document.getElementById(id1);
                    //   frmObj1.style.display = "none";
                    //   setPrty(!prty);
                    // }
                    if (prty === "none") {
                      closeDrpdwn();
                      setPrty("block");
                    } else closeDrpdwn();
                  }}
                />
              </span>
              <span
                id="myDropdown4"
                className="dropdown-download1"
                style={{ display: prty }}
              >
                {meta_data.priorities.map((priority, index) => (
                  <React.Fragment key={index}>
                    <a
                      href="#"
                      id="ac4"
                      onClick={() => {
                        if (prty === "none") {
                          closeDrpdwn();
                          setPrty("block");
                        } else closeDrpdwn();
                        setPriority(priority);
                        setClear(false);
                        // setPriority(priority);
                        // //dispatch(getLeads({ priority: [priority] }));
                        // var id1 = "myDropdown4";
                        // var ie1 =
                        //   document.all && !window.opera ? document.all : 0;
                        // var frmObj1 = ie1
                        //   ? ie1[id1]
                        //   : document.getElementById(id1);
                        // frmObj1.style.display = "none";
                        // setPrty(!prty);
                      }}
                    >
                      {priority}
                    </a>
                  </React.Fragment>
                ))}
                <a
                  href="#"
                  id="ac4"
                  onClick={() => {
                    if (prty === "none") {
                      closeDrpdwn();
                      setPrty("block");
                    } else closeDrpdwn();
                    setPriority("");
                    setClear(false);
                    // setPriority("");
                    // //dispatch(getLeads({ priority: [priority] }));
                    // var id1 = "myDropdown4";
                    // var ie1 =
                    //   document.all && !window.opera ? document.all : 0;
                    // var frmObj1 = ie1
                    //   ? ie1[id1]
                    //   : document.getElementById(id1);
                    // frmObj1.style.display = "none";
                    // setPrty(!prty);
                  }}
                >
                  None
                </a>
                {/* <a href="#">Link 1 - drpdown2</a>
                <a href="#">Link 2 - drpdown2</a>
                <a href="#">Link 3 - drpdown2</a> */}
              </span>
            </>
          );
        }
        break;
      case "updated_on":
        return (
          <>
            <CalendarTodayIcon
              style={{
                cursor: "pointer",
                marginBottom: "-4%",
                width: "14px",
                height: "14px",
              }}
              className={classes.muiIcon}
              // onClick={field === "start_date" ? configureDate : configurePriority}
              onClick={(e) => {
                // if(update==="none")
                // setUpdate("block")
                // else
                // setUpdate("none")
                if (!update) {
                  var id = "updt";
                  var ie = document.all && !window.opera ? document.all : 0;
                  var frmObj = ie ? ie[id] : document.getElementById(id);
                  var toObj = ie
                    ? ie[id + "b"]
                    : document.getElementById(id + "b");
                  toObj.style.width = frmObj.offsetWidth + 7 + "px";
                  frmObj.style.display = "none";
                  toObj.style.display = "inline";
                  toObj.style.width = "auto";
                  toObj.value = frmObj.innerHTML;
                  closeDrpdwn();
                  setUpdate(!update);
                } else {
                  var id = "updt";
                  var ie = document.all && !window.opera ? document.all : 0;
                  var frmObj = ie ? ie[id] : document.getElementById(id);
                  var toObj = ie
                    ? ie[id + "b"]
                    : document.getElementById(id + "b");
                  frmObj.style.display = "inline";
                  toObj.style.display = "none";
                  closeDrpdwn();
                  setUpdate(!update);
                }
              }}
            />
          </>
        );
        break;
      default:
        return;
    }
  };
  const colDefination = (
    colName
    // classes,
    // configurePriority = () => {},
    // configureDate = () => {}
  ) => {
    return colDef[colName].map((item, index) => {
      return colName === "body" ? (
        <TableCell key={index} style={{ cursor: "auto", fontSize: "11px" }}>
          {item.field === "action" ? (
            <>
              <PopupState variant="popover" popupId="demoMenu">
                {(popupState) => (
                  <React.Fragment>
                    <IconButton
                      size="small"
                      variant="contained"
                      {...bindTrigger(popupState)}
                      onClick={(event) => {
                        event.stopPropagation();
                        bindTrigger(popupState).onClick(event);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>

                    <Menu
                      {...bindMenu(popupState)}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      style={{ top: "5px", left: "1px", fontSize: "13px" }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          popupState.close();
                          setStatus(true);
                        }}
                      >
                        <img src={Update} />
                        &nbsp;Update Sales Flow
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          setLeadId(item.id);
                          event.stopPropagation();
                          popupState.close();
                          setModal(true);
                        }}
                      >
                        <img src={Edit} />
                        &nbsp;Edit Lead
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          popupState.close();
                        }}
                      >
                        <img src={Delete} />
                        &nbsp;Delete Lead
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </>
          ) : item.field === "status" ? (
            <img src={icons.ColdIcon} />
          ) : item.field === "share" ? (
            <div
              onClick={() => {
                history.push("/lead/234567");
              }}
            >
              {item.title}
            </div>
          ) : (
            item.title
          )}
          {/* &nbsp; */}
        </TableCell>
      ) : (
        <TableCell
          key={index}
          style={{ cursor: "auto", fontSize: "12px", fontWeight: "bold" }}
          ref={refs}
        >
          <span style={{ whiteSpace: "nowrap", display: "inline-block" }}>
            {item.field === "name" ? (
              <>
                <span id="itm1" style={{ margin: "auto" }}>
                  {item.title}
                </span>
                <input
                  id="itm1b"
                  type="text"
                  value={srch}
                  style={{
                    display: "none",
                    margin: "auto",
                    border: "none",
                    width: "80px !important",
                  }}
                  onChange={(e) => {
                    setSrch(e.target.value);
                    setClear(false);
                    //dispatch(getLeads({ search: e.target.value }));
                    // var id="itm1"
                    // var ie=document.all&&!window.opera? document.all : 0
                    // var frmObj=ie? ie[id] : document.getElementById(id)
                    // var toObj=ie? ie[id+'b'] : document.getElementById(id+'b')
                    // frmObj.style.display='inline';
                    // toObj.style.display='none';
                    // setNm(!nm);
                  }}
                />
              </>
            ) : item.field === "mobile" ? (
              <>
                <span id="itm2" style={{ margin: "auto" }}>
                  {item.title}
                </span>
                <input
                  id="itm2b"
                  type="text"
                  value={srch}
                  style={{
                    display: "none",
                    margin: "auto",
                    width: "20%",
                    border: "none",
                  }}
                  onChange={(e) => {
                    setSrch(e.target.value);
                    setClear(false);
                    //dispatch(getLeads({ search: e.target.value }));
                    // var id1="itm2"
                    // var ie1=document.all&&!window.opera? document.all : 0
                    // var frmObj1=ie1? ie1[id1] : document.getElementById(id1)
                    // var toObj1=ie1? ie1[id1+'b'] : document.getElementById(id1+'b')
                    // frmObj1.style.display='inline';
                    // toObj1.style.display='none';
                    // setNm1(!nm1);
                  }}
                />
              </>
            ) : item.field === "updated_on" ? (
              <>
                <span id="updt" style={{ margin: "auto" }}>
                  {item.title}
                </span>
                <span style={{ display: "none" }} id="updtb">
                  <RangePicker
                    className="ant-design-custom"
                    placeholder={["Start Date", "End Date"]}
                    value={[]}
                    onChange={(e, value) => {
                      console.log("RANGE DATE: ", value);
                      let [date__gte, date__lte] = value;
                      // setValues({
                      //   ...values,
                      //   created__lte: created__lte ?moment(created__lte):'',
                      //   created__gte: created__gte ?moment(created__gte):'',
                      // });
                      // let filter={
                      //   "date__lte":date__lte||undefined,
                      //   "date__gte":date__gte||undefined,
                      //   }
                      // setUpdated(filter)
                      //console.log(filter)
                      setGte(date__gte);
                      setLse(date__lte);
                      setClear(false);
                      var id = "updt";
                      var ie = document.all && !window.opera ? document.all : 0;
                      var frmObj = ie ? ie[id] : document.getElementById(id);
                      var toObj = ie
                        ? ie[id + "b"]
                        : document.getElementById(id + "b");
                      frmObj.style.display = "inline";
                      toObj.style.display = "none";
                      setUpdate(!update);
                    }}
                    format={"YYYY-MM-DD"}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </span>
              </>
            ) : (
              <span style={{ margin: "auto" }}>{item.title}</span>
            )}
            <span>
              &nbsp;{item.icon ? getIcon(item.icon, classes, item.field) : null}
            </span>
          </span>
        </TableCell>
      );
    });
  };

  const handleChangePage = () => {};
  const incrementPage = (num) => {
    setPage(page + num);
  };
  const decrementPage = (num) => {
    setPage(page - num);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setClear(false);
    // dispatch(getLeads({ limit: `${event.target.value}` }));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const {
      history,
      decrementPage = () => {},
      rows,
      incrementPage = () => {},
    } = props;
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    // const handleFirstPageButtonClick = (event) => {
    //   onChangePage(event, 0);
    // };

    const handleBackButtonClick = (url) => {
      console.log("prev url", url);
      if (url !== null || url !== undefined) {
        setLink(url);
        setClear(false);
        //dispatch(getLeads({ url: `${url}` }));
        decrementPage(1);
      }
    };

    const handleNextButtonClick = (url) => {
      console.log("next url", url);
      if (url !== null || url !== undefined) {
        setLink(url);
        setClear(false);
        //dispatch(getLeads({ url: `${url}` }));
        incrementPage(1);
      }
    };

    // const handleLastPageButtonClick = (event) => {
    //   onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    // };

    return (
      <div className={classes.root}>
        {/* <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton> */}
        <IconButton
          onClick={() => handleBackButtonClick(props.previous)}
          disabled={props.previous === null ? true : false}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={() => handleNextButtonClick(props.next)}
          disabled={props.next === null ? true : false}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        {/* <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton> */}
      </div>
    );
  }

  const ModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "#282827" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "20px" }}>Edit Lead</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ModalHeader1 = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="7px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>Update Sales & Specific Details</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setStatus(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const downloadResult = () => {
    dispatch(
      exportResults({
        search: srch,
        source: source,
        category: client,
        priority: [priority],
        limit: rowsPerPage,
        status: props.filter,
        url: link,
        date__lte: lse,
        date__gte: gte,
        last_call_status: last,
        org: org !== "" ? parseInt(org) : "",
        assigned: assign !== "" ? parseInt(assign) : "",
      })
    );
  };

  return (
    <React.Fragment>
      <Modal
        width="450px"
        height="150px"
        title="Are you sure want to delete?"
        status={confirmationModal}
        closeBtnText="No"
        saveBtnText="Yes"
        closeHandler={() => {
          setConfirmationModal(false);
        }}
        saveHandler={() => {
          deleteLead(leadId).then((result) => {
            dispatch(getLeads({ status: props.filter }));
            setConfirmationModal(false);
            notification.enqueueSnackbar("Lead Deleted Successfully", {
              variant: "success",
            });
          });
        }}
      >
        <Box>Are you sure want to delete this lead?</Box>
      </Modal>

      <Modal
        width="450px"
        height="100%"
        headerComponent={ModalHeader1}
        status={status}
      >
        <Box>Lead Status</Box>

        <Box display="flex">
          <Box
            onClick={() => {
              setFieldValue("lead_status", 0);
            }}
            flex={1}
          >
            <img
              className={clsx({ [classes.selected]: values.lead_status == 0 })}
              src={HotSqIcon}
            />
            <Box textAlign="left">Hot</Box>
          </Box>

          <Box onClick={() => setFieldValue("lead_status", 1)} flex={1}>
            <img
              className={clsx({ [classes.selected]: values.lead_status == 1 })}
              src={WarmSqIcon}
            />
            <Box textAlign="left">Warm</Box>
          </Box>

          <Box onClick={() => setFieldValue("lead_status", 2)} flex={1}>
            <img
              className={clsx({ [classes.selected]: values.lead_status == 2 })}
              src={CoolSqIcon}
            />
            <Box textAlign="left">Cool</Box>
          </Box>

          <Box onClick={() => setFieldValue("lead_status", 3)} flex={1}>
            <img
              className={clsx({ [classes.selected]: values.lead_status == 3 })}
              src={ColdSqIcon}
            />
            <Box textAlign="left">Cold</Box>
          </Box>
        </Box>

        <Box>
          <Box marginBottom="10px" color="#FD4243">
            Hot:{" "}
            <Box color="black" fontSize="13px" component="span">
              Lorem ipsum donor festhsiojd hjdiheygdjrfhge
            </Box>
          </Box>
          <Box marginBottom="10px" color="#FE8F02">
            Warm:{" "}
            <Box color="black" fontSize="13px" component="span">
              Lorem ipsum donor festhsiojd hjdiheygdjrfhge
            </Box>
          </Box>
          <Box marginBottom="10px" color="#02A6E9">
            Cool:{" "}
            <Box color="black" fontSize="13px" component="span">
              Lorem ipsum donor festhsiojd hjdiheygdjrfhge
            </Box>
          </Box>
          <Box marginBottom="10px" color="#0B79A5">
            Cold:{" "}
            <Box color="black" fontSize="13px" component="span">
              Lorem ipsum donor festhsiojd hjdiheygdjrfhge
            </Box>
          </Box>
        </Box>
        <Box marginBottom="10px">
          <Select
            input={<OutlinedInput classes={{ input: classes.input }} />}
            fullWidth
            // onChange={handleChange}
            // error={touched.document_type && errors.document_type ? true : false}
            // helperText={touched.document_type && errors.document_type}
            labelId="demo-simple-select-outlined-label"
          >
            <MenuItem value="none">Intrested Properties</MenuItem>
          </Select>
        </Box>
        <Box marginBottom="10px">
          <Select
            input={<OutlinedInput classes={{ input: classes.input }} />}
            fullWidth
            // onChange={handleChange}
            // error={touched.document_type && errors.document_type ? true : false}
            // helperText={touched.document_type && errors.document_type}
            labelId="demo-simple-select-outlined-label"
          >
            <MenuItem value="none">Next Follow up</MenuItem>
          </Select>
        </Box>

        <Box marginBottom="10px">
          <Select
            input={<OutlinedInput classes={{ input: classes.input }} />}
            fullWidth
            // onChange={handleChange}
            // error={touched.document_type && errors.document_type ? true : false}
            // helperText={touched.document_type && errors.document_type}
            labelId="demo-simple-select-outlined-label"
          >
            <MenuItem value="none">Last Call Status</MenuItem>
          </Select>
        </Box>

        <Box marginBottom="10px">
          <TextField
            fullWidth
            variant="outlined"
            multiline
            placeholder="Remark"
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-around" padding="30px 0">
            <Button
              onClick={() => {
                setStatus(false);
                // window.open(`tel:${propertyDetails.brik_manager_contact}`)
              }}
              variant="outlined"
              style={{ width: "123px" }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              style={{ width: "150px" }}
              variant="contained"
              onClick={() => {
                setStatus(false);
              }}
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        width="90%"
        height="500"
        headerComponent={ModalHeader}
        status={modal}
      >
        {(() => {
          return (
            <CreateLead
              formMode="edit"
              id={leadId}
              deviceType="website"
              onBack={() => {
                // setSts(props.filter)
                dispatch(getLeads({ status: props.filter }));
                setModal(false);
              }}
            />
          );
        })()}
      </Modal>

      <TableContainer component={Paper}>
        <Table size="medium" aria-label="simple table">
          <TableHead
            style={{
              backgroundColor: "#EAD2AB",
              fontFamily: "Proxima Nova",
              fontWeight: "bold",
            }}
          >
            <TableRow>{colDefination(tableName)}</TableRow>
          </TableHead>
          <TableBody>
            {leadResult.results.map((lead, index) => {
              return (
                <>
                  <TableRow
                    style={{
                      backgroundColor: index % 2 == 0 ? "#FFFFF" : "#FFF8EC",
                    }}
                  >
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      {lead.duplicate_count !== null ? (
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            display: "inline-block",
                          }}
                          // onClick={() => {
                          //   // if (duplicate === "none") {
                          //   // setDuplicate("table-row");
                          //   //lead.duplicate = !lead.duplicate;
                          //   //setLeadResult({ ...leadResult });
                          //   // } else {
                          //   // setDuplicate("none");
                          //   // lead.duplicate = false;
                          //   // setLeadResult({ ...leadResult });
                          //   // }
                          //   if (duplicate === "none") {
                          //     setDuplicate("table-row");
                          //   } else {
                          //     setDuplicate("none");
                          //   }
                          // }}
                          // >
                          onClick={() => {
                            lead.duplicate = !lead.duplicate;
                            setLeadResult({ ...leadResult });
                          }}
                        >
                          {lead.duplicate ? (
                            <img src={Arr1} />
                          ) : (
                            <img src={Arr} />
                          )}
                          &nbsp;
                          {index + 1}
                        </span>
                      ) : (
                        <>&nbsp;&nbsp;{index + 1}</>
                      )}
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(`/lead/${lead.id}`, { leadId: lead.id });
                        }}
                      >
                        {lead.name}
                      </span>
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      {lead.source}
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      {/* {parseInt(lead?.client?.number)} */}
                      {lead?.duplicate_count !== null ? (
                        <>
                          <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={lead.duplicate_count}
                            color="primary"
                          >
                            {lead?.client?.number
                              ? parseInt(lead?.client?.number)
                              : ""}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </Badge>
                        </>
                      ) : lead?.client?.number ? (
                        parseInt(lead?.client?.number)
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(`/lead/${lead.id}`, { leadId: lead.id });
                        }}
                      >
                        <div class="tooltip">
                          <div class="lead_remark">{lead.remarks}</div>
                          <span class="tooltiptext"> {lead.remarks}</span>
                        </div>
                      </span>
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(`/lead/${lead.id}`, { leadId: lead.id });
                        }}
                      >
                        {lead.assigned_name}
                      </span>
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      {lead?.client?.category}
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      {lead.priority === "Hot" || lead.priority === "hot" ? (
                        <img src={Hot} />
                      ) : lead.priority === "Cold" ||
                        lead.priority === "cold" ? (
                        <img src={Cold} />
                      ) : lead.priority === "Cool" ||
                        lead.priority === "cool" ? (
                        <img src={Cool} />
                      ) : (
                        <img src={Warm} />
                      )}
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      {moment(lead.updated_on).format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      <>
                        <PopupState variant="popover" popupId="demoMenu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton
                                size="small"
                                variant="contained"
                                {...bindTrigger(popupState)}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  bindTrigger(popupState).onClick(event);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>

                              <Menu
                                {...bindMenu(popupState)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                style={{
                                  top: "5px",
                                  left: "1px",
                                  fontSize: "13px",
                                }}
                              >
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    popupState.close();
                                    props.updateSalesFlowModal(lead);
                                  }}
                                >
                                  <img src={Update} />
                                  &nbsp;Update Sales Flow
                                </MenuItem>
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    popupState.close();
                                    setModal(true);
                                    setLeadId(lead.id);
                                  }}
                                >
                                  <img src={Edit} />
                                  &nbsp;Edit Lead
                                </MenuItem>
                                <MenuItem
                                  onClick={(event) => {
                                    setConfirmationModal(true);
                                    setLeadId(lead.id);
                                    // event.stopPropagation();
                                    popupState.close();
                                  }}
                                >
                                  <img src={Delete} />
                                  &nbsp;Delete Lead
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </>
                    </TableCell>
                    {/* <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(`/lead/${lead.id}`, { leadId: lead.id });
                        }}
                      >
                        <img src={ArrowForwardIosIcon} />
                      </div>
                    </TableCell> */}
                  </TableRow>

                  {lead.duplicates &&
                    lead.duplicates.map((dup, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor: "#EEEEEE",
                          display:
                            lead.duplicate == true ? "table-row" : "none",
                        }}
                      >
                        {console.log("duplicates", lead.duplicates)}
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/lead/${dup.id}`, {
                                leadId: dup.id,
                              });
                            }}
                          >
                            {dup.name}
                          </span>
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          {dup.source}
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          {lead?.client?.number
                            ? parseInt(lead?.client?.number)
                            : ""}
                          &nbsp;
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/lead/${dup.id}`, {
                                leadId: dup.id,
                              });
                            }}
                          >
                            <div class="tooltip">
                              <div class="lead_remark">{dup?.remarks}</div>
                              <span class="tooltiptext"> {dup?.remarks}</span>
                            </div>
                          </span>
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/lead/${dup.id}`, {
                                leadId: dup.id,
                              });
                            }}
                          >
                            {dup?.assigned_name}
                          </span>
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          {dup?.client?.category}
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          {dup.priority === "Hot" || dup.priority === "hot" ? (
                            <img src={Hot} />
                          ) : dup.priority === "Cold" ||
                            dup.priority === "cold" ? (
                            <img src={Cold} />
                          ) : dup.priority === "Cool" ||
                            dup.priority === "cool" ? (
                            <img src={Cool} />
                          ) : (
                            <img src={Warm} />
                          )}
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          {moment(dup.updated_on).format("DD-MMM-YYYY")}
                        </TableCell>
                        <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          <>
                            <PopupState variant="popover" popupId="demoMenu">
                              {(popupState) => (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      bindTrigger(popupState).onClick(event);
                                    }}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>

                                  <Menu
                                    {...bindMenu(popupState)}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    style={{
                                      top: "5px",
                                      left: "1px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={(event) => {
                                        setConfirmationModal(true);
                                        setLeadId(dup.id);
                                        event.stopPropagation();
                                        // deleteLead(dup.id).then((result) => {
                                        //   notification.enqueueSnackbar(
                                        //     "Lead Deleted Successfully",
                                        //     {
                                        //       variant: "success",
                                        //     }
                                        //   );
                                        // });
                                        // popupState.close();
                                      }}
                                    >
                                      <img src={Delete} />
                                      &nbsp;Delete Lead
                                    </MenuItem>
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          </>
                        </TableCell>
                        {/* <TableCell style={{ cursor: "auto", fontSize: "11px" }}>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/lead/${dup.id}`, {
                                leadId: dup.id,
                              });
                            }}
                          >
                            <img src={ArrowForwardIosIcon} />
                          </div>
                        </TableCell> */}
                      </TableRow>
                    ))}
                </>
              );
            })}
            {/* <Pagination
              className="my-3"
              count={10}
              page={1}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              // onChange={this.handlePageChange}
            /> */}
          </TableBody>
          <TableFooter>
            <TableRow>
              {leadResult && leadResult.results && leadResult.results.length ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={12}
                  count={leadResult && leadResult.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={() => (
                    <TablePaginationActions
                      next={leadResult.next}
                      previous={leadResult.previous}
                      incrementPage={incrementPage}
                      decrementPage={decrementPage}
                      rows={rowsPerPage}
                    />
                  )}
                />
              ) : (
                <TableCell
                  colSpan={12}
                  style={{ fontSize: "16px", textTransform: "capitalize" }}
                  className={`pl-2 pr-2 pt-3 pb-3`}
                >
                  <center>{`Sorry, no leads found based on your search`}</center>
                </TableCell>
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
});

export default TabTable;
