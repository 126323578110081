import {
  Card,
  Box,
  Avatar,
  Button,
  IconButton,
  Grid,
  FormControlLabel,
  Checkbox,
  makeStyles,
  InputAdornment,
  TextField,
  MenuItem,
  OutlinedInput,
  Select,
  FormHelperText,
} from "@material-ui/core";
import clsx from "clsx";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { useAuth } from "../providers/AuthProvider";
import HotSqIcon from "../assets/icons/leadDetail/HotSqIcon.png";
import WarmSqIcon from "../assets/icons/leadDetail/WarmSqIcon.png";
import CoolSqIcon from "../assets/icons/leadDetail/CoolSqIcon.png";
import ColdSqIcon from "../assets/icons/leadDetail/ColdSqIcon.png";
import React, { useEffect, useState } from "react";
import Edit from "../assets/icons/graph/edit.png";
import Pdf from "../assets/icons/graph/pdf.png";
import SearchIcon from "@material-ui/icons/Search";
import Interested from "../assets/icons/graph/interested.png";
import Followup from "../assets/icons/graph/followup.png";
import Status from "../assets/icons/graph/status.png";
import Remark from "../assets/icons/graph/remark.png";
import Email from "../assets/icons/graph/email.png";
import Phone from "../assets/icons/graph/phone.png";
import { getDocumentDetails, getTaskDetails } from "../store/actions/apis";
import Client from "../assets/icons/graph/client.png";
import Action from "../assets/icons/graph/action.png";
import Cancel from "../assets/icons/graph/cancel.png";
import EditLead from "../assets/icons/graph/editlead.png";
import CreateLead from "../screens/CreateLead";
import AddEvent1 from "../assets/icons/graph/addevent.png";
import Emails from "../assets/icons/graph/emails.png";
import ShareDocument from "../assets/icons/graph/sharedocument.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link, useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavIcon from "../assets/icons/leadDetail/Fav.png";
import FollowUp from "../assets/icons/leadDetail/Followup.png";
import LastCallIcon from "../assets/icons/leadDetail/LastCall.png";
import RemarkIcon from "../assets/icons/leadDetail/Remark.png";
import Address from "../assets/icons/leadDetail/address.png";
import EditLeadIcon from "../assets/icons/actions/EditLead.png";
import EditFilled from "../assets/icons/editfilled.png";
import SMSIcon from "../assets/icons/actions/SMSIcon.png";
import AddEventIcon from "../assets/icons/actions/AddEvent.png";
import EmailIcon from "../assets/icons/actions/EmailIcon.png";
import CallIcon from "../assets/icons/actions/Call.png";
import ShareDocIcon from "../assets/icons/actions/ShareDoc.png";
import { icons, renderPriority } from "../assets/assets";
import Modal from "../component/Modal";
import { Close } from "@material-ui/icons";
import { responsiveStyles } from "../assets/css/generalStyling";
import Update from "../assets/icons/graph/update-icon.svg";
import Delete from "../assets/icons/graph/delete.png";
import delet from "../assets/icons/delete.png";
import edt from "../assets/icons/edt.png";
import { useParams } from "react-router-dom";
import { getLeadDetails } from "../store/actions/apis";
import CallLeadIcon from "../assets/icons/leadDetail/CallIconLead.png";
// import LocationIcon from "../assets/icons/leadDetail/LocationIcon.png";
import EmailLeadIcon from "../assets/icons/leadDetail/EmailIcon.png";
import PersonalDetailIcon from "../assets/icons/leadDetail/PersonalDetail.png";
import ClientCategoryIcon from "../assets/icons/leadDetail/ClientCategory.png";
import moment from "moment";
import { FormGroup } from "@mui/material";
import AddEvent from "./AddEvent";
import { useFormik } from "formik";
// import { useSnackbar } from "notistack";
import { deleteLead, editLead } from "../store/actions/apis";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getLeads } from "../store/actions/dashboardActions";

// const notification = useSnackbar();

const useStyles = makeStyles((theme) => ({
  generalDocumentCss: {
    border: "1px solid #ACACAC",
    boxShadow: "0px 4px 14px #E7E7E7",
    borderRadius: "10px",
    boxSizing: "border-box",
    marginBottom: "10px",
  },
  selectedDocument: {
    border: "2px solid #8B7862",
  },
  FormControlLabel: {
    fontSize: "12px",
  },
  indicatorConnector: {
    position: "absolute",
    background: "#ffeaed",
    height: "40px",
    width: "2px",
    top: "40px",
  },
  accordianLabel: {
    color: "#9B9B9B",
    fontWeight: "bold",
    lineHeight: 1.5,
  },
  indicator: {
    backgroundColor: "#FFE9C7",
    position: "relative",
    color: "#FF5674",
    borderRadius: "50%",
    margin: "12px",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notchedOutlineInput: {
    borderRadius: "20px",
  },
  selectedTab: {
    backgroundColor: "#FF5674",
    color: "#FFFFFF",
    // padding: "8px",
  },
  input: {
    padding: "10px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "2px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "70px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function LeadDetail(props) {
  const [modal, setModal] = useState(false);
  const [share, setShare] = useState(false);
  const [edit, setEdit] = useState(false);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const auth = useAuth();
  const classes = useStyles();
  const [selectedDoc, setSelectedDoc] = useState({});
  const [viewAction, setViewAction] = useState(false);
  const responsive = responsiveStyles();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const { id } = useParams();
  const [docs, setDocs] = useState([{}, {}]);
  const [task, setTask] = useState([]);
  const [status, setStatus] = useState(false);
  const [leadDetails, setLeadDetails] = useState({
    client: {},
    interested_in: [],
    workflow: [{ stages: [{ tasks: [] }] }],
  });
  const dispatch = useDispatch();
  const {
    meta_data: { statuses, briks, call_statuses },
  } = useSelector((state) => state.dashboard);
  const [filter, setFilter] = useState("new");
  const [priority, setPriority] = useState([]);
  const notification = useSnackbar();
  const { leadId } = props;
  // const { values, touched, errors, handleSubmit, handleBlur, setFieldValue } =
  //   useFormik({
  //     initialValues: {
  //       lead_status: 0,
  //     },
  //     onSubmit: () => {},
  //   });

  useEffect(() => {
    // if (Object.keys(myplanPayment).length == 0)
    getDocumentDetails({ starred: "", document_name: search })
      .then((result) => {
        console.log("result", result.data);
        setDocs(result.data);
        //setSelectedDoc(result.data[0]);
        // console.log("data.due_remainders: ", _.isEmpty(data.due_remainders))
        // setDueRemainders(data.due_remainders)
      })
      .catch((er) => {
        console.log("error: ", er);
      });
  }, [search]);

  const date = new Date().toISOString().split("T")[0];

  useEffect(() => {
    // tomm.setDate(tomm.getDate() + 1);
    // console.log("tomm", tomm.toISOString().split("T")[0]);
    // getTaskDetails(tomm.toISOString().split("T")[0])
    //   .then((result) => {
    //     setTask1(result.data);
    //   })
    //   .catch((er) => {
    //     console.log("error: ", er);
    //   });
    getTaskDetails(date)
      .then((result) => {
        const { data } = result;
        console.log("TASSKKKK", data);
        setTask(data);
      })
      .catch((er) => {
        console.log("error: ", er);
      });
  }, []);

  const handleChang = (event) => {
    if (event.target.checked) {
      priority.push(event.target.value);
      setPriority([...priority]);
    } else {
      let index = priority.indexOf(event.target.value);
      priority.splice(index, 1);
      setPriority([...priority]);
    }
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    submitForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      status: "none",
      priority: "Hot",
      last_call_status: "none",
      follow_up_date: moment().format("YYYY-MM-DD"),
      interested_in: "none",
      remarks: "",
    },
    onSubmit: () => {
      delete values.budget;
      editLead(values.id, {
        ...values,
        interested_in: values.interested_in,
      }).then((result) => {
        getLeadDetails(id).then((result) => {
          setLeadDetails(result);
        });
        setStatus(false);
      });
    },
  });

  const ModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>Actions</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const EditHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "#282827" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "20px" }}>Edit Lead</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setEdit(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const UpdateHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="7px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        fontSize={"24px"}
        fontWeight="bold"
      >
        <Box>Update Sales & Specific Details</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setStatus(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const AddEventHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box fontSize="22px" fontWeight={600}>
          Add Event
        </Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setEventModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ModalHeader1 = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>Share Document</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setShare(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    getLeadDetails(id).then((result) => {
      setLeadDetails(result);
    });
  }, []);

  const renderPriority = (priority) => {
    switch (priority) {
      case "Hot":
        return icons.HotIcon;
      case "Warm":
        return icons.WarmIcon;
      case "Cool":
        return icons.CoolIcon;
      default:
        return icons.ColdIcon;
    }
  };

  const updateSalesAction = (lead) => {
    console.log("VALUE: ", lead);
    setValues({
      ...lead,
      last_call_status: lead.last_call_status ? lead.last_call_status : "none",
      follow_up_date: moment().format("YYYY-MM-DD"),
      remarks: lead.remarks,
      status: lead.status ? lead.status : "none",
      interested_in:
        lead.interested_in.length > 0
          ? lead.interested_in.map((item) => item.id)
          : "none",
    });
    setStatus(true);
  };

  return (
    <>
      <Modal
        width="450px"
        height="680px"
        headerComponent={UpdateHeader}
        status={status}
      >
        <Box color="#979797">Lead Type</Box>

        <Box display="flex" padding="9px 0">
          <Box
            onClick={() => {
              setFieldValue("priority", "Hot");
            }}
            flex={1}
          >
            <img
              className={clsx({
                [classes.selected]: values.priority == "Hot",
              })}
              src={HotSqIcon}
            />
            <Box margin="auto">Hot</Box>
          </Box>

          <Box onClick={() => setFieldValue("priority", "Warm")} flex={1}>
            <img
              className={clsx({
                [classes.selected]: values.priority == "Warm",
              })}
              src={WarmSqIcon}
            />
            <Box margin="auto">Warm</Box>
          </Box>

          <Box onClick={() => setFieldValue("priority", "Cool")} flex={1}>
            <img
              className={clsx({
                [classes.selected]: values.priority == "Cool",
              })}
              src={CoolSqIcon}
            />
            <Box margin="auto">Cool</Box>
          </Box>

          <Box onClick={() => setFieldValue("priority", "Cold")} flex={1}>
            <img
              className={clsx({
                [classes.selected]: values.priority == "Cold",
              })}
              src={ColdSqIcon}
            />
            <Box margin="auto">Cold</Box>
          </Box>
        </Box>

        <Box>
          <Box marginBottom="10px" color="#FD4243">
            Hot:{" "}
            <Box color="black" fontSize="13px" component="span">
              Highly Interested
            </Box>
          </Box>
          <Box marginBottom="10px" color="#FE8F02">
            Warm:{" "}
            <Box color="black" fontSize="13px" component="span">
              Interested
            </Box>
          </Box>
          <Box marginBottom="10px" color="#02A6E9">
            Cool:{" "}
            <Box color="black" fontSize="13px" component="span">
              May be Interested
            </Box>
          </Box>
          <Box marginBottom="10px" color="#0B79A5">
            Cold:{" "}
            <Box color="black" fontSize="13px" component="span">
              Not Responding
            </Box>
          </Box>
        </Box>
        <Box marginBottom="10px">
          <Select
            input={<OutlinedInput classes={{ input: classes.input }} />}
            fullWidth
            value={values.interested_in}
            onChange={(event) => {
              setFieldValue("interested_in", event.target.value);
            }}
            error={touched.interested_in && errors.interested_in}
            labelId="demo-simple-select-outlined-label"
          >
            <MenuItem disabled value="none">
              Interested In{" "}
            </MenuItem>
            {briks.map((brik) => {
              return <MenuItem value={brik.id}>{brik.name}</MenuItem>;
            })}
          </Select>
          <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
            {touched.interested_in && errors.interested_in}
          </FormHelperText>
        </Box>
        <Box marginBottom="10px">
          <Select
            fullWidth
            input={<OutlinedInput classes={{ input: classes.input }} />}
            labelId="demo-simple-select-outlined-label"
            placeholder="Lead Status"
            name="status"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.status}
            error={touched.status && errors.status}
          >
            <MenuItem value="none" disabled>
              Lead Status
            </MenuItem>
            {statuses.map((status) => {
              return <MenuItem value={status}>{_.capitalize(status)}</MenuItem>;
            })}
          </Select>
          <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
            {touched.status && errors.status}
          </FormHelperText>
        </Box>
        {values.status === "Sales Done" ? (
          <>
            <Box marginBottom="10px" item xs="12">
              <TextField
                fullWidth
                multiline
                id="outlined-password-input"
                name="sale_value"
                label="Sales Value"
                placeholder="Sales Value"
                value={values.sale_value}
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.sale_value && errors.sale_value}
                helperText={touched.sale_value && errors.sale_value}
              />
            </Box>
          </>
        ) : (
          ""
        )}
        <Box marginBottom="10px">
          <Select
            input={<OutlinedInput classes={{ input: classes.input }} />}
            fullWidth
            name="last_call_status"
            value={values.last_call_status}
            onChange={handleChange}
            // error={touched.document_type && errors.document_type ? true : false}
            // helperText={touched.document_type && errors.document_type}
            labelId="demo-simple-select-outlined-label"
          >
            <MenuItem disabled value="none">
              Call Status{" "}
            </MenuItem>
            {call_statuses.map((status) => {
              return <MenuItem value={status}>{status}</MenuItem>;
            })}
          </Select>
        </Box>
        <Box marginBottom="10px">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              fullWidth
              margin="normal"
              label="Follow Up"
              id="date-picker-dialog"
              // label="Date picker dialog"
              format="DD-MM-YYYY"
              value={values.follow_up_date}
              onChange={(date) => {
                setFieldValue(
                  "follow_up_date",
                  moment(date).format("YYYY-MM-DD")
                );
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                id: `datePicker1`,
              }}
            />
          </MuiPickersUtilsProvider>
          <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
            {touched.follow_up_date && errors.follow_up_date}
          </FormHelperText>
        </Box>
        <Box marginBottom="10px">
          <TextField
            fullWidth
            multiline
            label="Remarks"
            id="outlined-password-input"
            placeholder="Remarks"
            type="text"
            variant="outlined"
            name="remarks"
            value={values.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.remarks && errors.remarks}
            helperText={touched.remarks && errors.remarks}
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-around" padding="30px 0">
            <Button
              onClick={() => {
                setStatus(false);
              }}
              variant="outlined"
              style={{ width: "123px" }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              style={{ width: "150px" }}
              variant="contained"
              onClick={() => {
                submitForm();
              }}
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        width="90%"
        height="500"
        headerComponent={EditHeader}
        status={edit}
      >
        <CreateLead
          formMode="edit"
          id={leadDetails.id}
          deviceType="website"
          onBack={() => {
            setEdit(false);
            getLeadDetails(id).then((result) => {
              setLeadDetails(result);
            });
          }}
        />
      </Modal>
      <Modal
        width="450px"
        height="510px"
        headerComponent={AddEventHeader}
        status={eventModal}
      >
        <AddEvent
          from="leadDetail"
          leadData={leadDetails}
          name={leadDetails.name}
          deviceType="website"
          onBack={() => setEventModal(false)}
        />
      </Modal>
      <Modal
        width="450px"
        height="510px"
        headerComponent={ModalHeader1}
        status={share}
      >
        <Box width="100%">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="#787878" />
                </InputAdornment>
              ),
            }}
            placeholder="Search Documents"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          {/* <Box display="flex" alignItems="center">
            <Box flex={1}>
              <FormControlLabel
                classes={{ label: classes.FormControlLabel }}
                control={<Checkbox color="primary" />}
                label="SELECT ALL"
              />
            </Box>
            <Box flex={1} textAlign="right">
              <FormGroup style={{ fontSize: "12px" }}>CLEAR ALL</FormGroup>
            </Box>
          </Box> */}
          <Box
            marginTop="5px"
            style={{
              marginTop: "5px",
              height: "255px",
              overflow: "scroll",
              padding: "10px",
            }}
          >
            <Grid container spacing={1}>
              {docs.map((document) => {
                return (
                  <Grid
                    item
                    xs={12}
                    className={clsx({
                      [classes.generalDocumentCss]: true,
                      [classes.selectedDocument]:
                        document.document_name == selectedDoc.document_name,
                    })}
                  >
                    <Box
                      onClick={() => {
                        setSelectedDoc(document);
                      }}
                      display="flex"
                    >
                      {/* <Box>
                        <Checkbox
                          color="primary"
                          onClick={() => {  
                            setSelectedDoc(document);
                          }}
                        />
                      </Box> */}
                      <Box>
                        <img width="40px" height="40px" src={Pdf} />
                      </Box>
                      <Box>
                        <span style={{ fontSize: "12px" }}>
                          {document.document_name}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px" }}>8.5 MB</span>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Card
          elevation={4}
          className="share-via"
          style={{
            borderRadius: "20px 20px 0px 0px",
            padding: "10px",
            marginBottom: "0%",
          }}
        >
          <Box
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "#8A9EBC",
            }}
          >
            SHARE VIA
          </Box>
          <Box>
            <Box style={{ float: "left", width: "50%" }}>
              <Button
                style={{
                  width: "90%",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#8B7862",
                  borderRadius: "7px",
                  color: "white",
                }}
                onClick={() => {
                  console.log("Selecyed Lead WEBSS: ", selectedDoc);
                  let text = `Hi%20${
                    leadDetails.client.name
                  }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                    selectedDoc.document
                  )}`;
                  window.open(
                    `https://api.whatsapp.com/send/?phone=+91${leadDetails?.client?.number}&text=${text}&app_absent=0`
                  );
                }}
              >
                WHATSAPP
              </Button>
            </Box>
            <Box style={{ float: "right", width: "50%" }}>
              <Button
                variant="outlined"
                style={{
                  width: "90%",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  border: "2px solid #8B7862",
                  borderRadius: "7px",
                  color: "#8B7862",
                }}
                onClick={() => {
                  let text = `Hi%20${
                    leadDetails.client.name
                  }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                    selectedDoc.document
                  )}`;

                  window.open(
                    `mailto:${leadDetails?.client?.email}?subject=Sharing Brik Document&body=${text}`
                  );
                }}
              >
                EMAIL
              </Button>
            </Box>
          </Box>
        </Card>
      </Modal>
      <Modal
        width="450px"
        height="150px"
        title="Are you sure want to delete?"
        status={confirmationModal}
        closeBtnText="No"
        saveBtnText="Yes"
        closeHandler={() => {
          setConfirmationModal(false);
        }}
        saveHandler={() => {
          deleteLead(id).then((result) => {
            dispatch(getLeads({ status: props.filter }));
            setConfirmationModal(false);
            history.goBack();
            notification.enqueueSnackbar("Lead Deleted Successfully", {
              variant: "success",
            });
          });
        }}
      >
        <Box>Are you sure want to delete this lead?</Box>
      </Modal>
      <Modal
        width="450px"
        height="350px"
        headerComponent={ModalHeader}
        status={modal}
      >
        <Box width="100%" display="flex">
          <Box
            onClick={() => {
              console.log("leadDetails: ", leadDetails);
              let text = `Hi%20${encodeURI(
                auth.getUser().name
              )}%2C%0Awe%20are%20sharing%20lead%20details%20with%20you%20here%0A%0AName%3A%20${encodeURI(
                leadDetails.client.name
              )}%0AContact%20No%3A%20${encodeURI(leadDetails?.client?.number)}`;
              window.open(
                `https://api.whatsapp.com/send?phone=+91${leadDetails?.client?.number}&text=${text}`
              );
            }}
            flex={1}
            textAlign="center"
          >
            <img src={EditLeadIcon} />
            <Box>Whatsapp</Box>
          </Box>
          <Box
            flex={1}
            onClick={() => {
              window.open(`tel:${leadDetails?.client?.number}`);
            }}
            textAlign="center"
          >
            <img src={CallIcon} />
            <Box>Call</Box>
          </Box>
          <Box
            onClick={() => {
              let text = `Hi%20${encodeURI(
                auth.getUser().name
              )}%2C%0Awe%20are%20sharing%20lead%20details%20with%20you%20here%0A%0AName%3A%20${encodeURI(
                leadDetails.client.name
              )}%0AContact%20No%3A%20${encodeURI(leadDetails?.client?.number)}`;
              window.open(
                `sms://${leadDetails?.client?.number}/?body=${text}`,
                "_blank"
              );
            }}
            flex={1}
            textAlign="center"
          >
            <img src={SMSIcon} />
            <Box>SMS</Box>
          </Box>
        </Box>
        <Box marginTop="20px" width="100%" display="flex">
          <Box
            onClick={() => {
              setEventModal(true);
              setModal(false);
            }}
            flex={1}
            textAlign="center"
          >
            <img src={AddEventIcon} />
            <Box>Add an Event</Box>
          </Box>
          {/* <Box flex={1} textAlign="center">
            <img src={EmailIcon} />
            <Box>Email</Box>
          </Box> */}
          <Box
            onClick={() => {
              setShare(true);
            }}
            flex={1}
            textAlign="center"
          >
            <img src={ShareDocIcon} />
            <Box>Share Document</Box>
          </Box>
        </Box>
      </Modal>
      <Box className={responsive.web}>
        <Box
          style={{
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              color: "#8B7862",
              fontSize: "14px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              history.goBack();
            }}
          >
            &#60;&nbsp;
          </span>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            Dashboard{" "}
          </span>
          / View Lead
          {/* <span style={{ float: "right", color: "#FF5674" }}>
            &nbsp;&nbsp;&nbsp;Next Lead&nbsp;&#62;
          </span>
          <span style={{ float: "right", color: "#FF5674" }}>
            &#60;&nbsp;Previous Lead&nbsp;&nbsp;&nbsp;
          </span> */}
        </Box>
        <Card
          style={{
            padding: "10px",
            borderRadius: "10px 10px 0px 0px",
            boxShadow: "0px 5px 13px -3px rgba(0, 0, 0, 0.15)",
          }}
        >
          {/* <Box display="flex" alignItems="center">
                    <Box><ArrowBackIosIcon /></Box>
                    <Box fontSize="18px" fontWeight={600}>Rithesh Upadhyay</Box>
                </Box> */}
          {/* <Box fontSize="12px" display="flex">
                    <Box flex={1}>#01/50</Box>
                    <Box display="flex" fontSize="12px">
                        <Box color="#9B9B9B">Created</Box> <Box>&nbsp;&nbsp;10-Jan-2022</Box>
                    </Box>
                </Box> */}
          {/* <Box fontSize="12px" display="flex">
                    <Box color="#9B9B9B" flex={1}>About to expire <Box component="span" color="#00BF86">13 days</Box></Box>
                    <Box fontSize="12px" display="flex">
                        <Box color="#9B9B9B">Last updated</Box> <Box>&nbsp;&nbsp;17-Jan-2022</Box>
                    </Box>
                </Box> */}

          <Box style={{ margin: "10px" }}>
            <Box alignItems="center" display="flex">
              <Avatar style={{ height: "60px", width: "60px" }} />
              <Box paddingLeft="10px">
                <Box
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  {leadDetails.name ? leadDetails.name : ""}
                </Box>
                <Box
                  color="#9B9B9B"
                  fontSize="12px"
                  display="flex"
                  alignItems="center"
                >
                  <Box>
                    {leadDetails.priority ? (
                      <img src={renderPriority(leadDetails.priority)} />
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box
                    marginLeft="10%"
                    fontWeight="semi-bold"
                    color="#9B9B9B"
                    style={{ display: "inline-block", whiteSpace: "nowrap" }}
                  >
                    <span
                      style={{
                        margin: "auto",
                        color: "#282827",
                        height: "8px",
                        width: "8px",
                      }}
                    >
                      &bull;
                    </span>
                    &nbsp;&nbsp;
                    <span style={{ fontSize: "14px" }}>
                      {leadDetails?.client?.category}
                    </span>
                  </Box>
                </Box>
              </Box>
              <Box flex={1} textAlign="left" marginLeft="10%">
                <div
                  style={{
                    color: "#958570",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // setModal1(!modal1)
                    setEdit(true);
                  }}
                >
                  <img src={Edit} />
                  &nbsp;Edit Lead
                </div>
                <div
                  style={{ color: "#958570", cursor: "pointer" }}
                  onClick={() => {
                    setConfirmationModal(true);

                    // deleteLead(id).then((result) => {
                    //   // notification.enqueueSnackbar(
                    //   //   "Lead Deleted Successfully",
                    //   //   {
                    //   //     variant: "success",
                    //   //   }
                    //   // );
                    //   // alert("Lead deleted successfully!!!")
                    // });
                  }}
                >
                  <img src={Delete} />
                  &nbsp;Delete Lead
                </div>
              </Box>
              <Box flex={1} textAlign="right">
                {/* <Box>#01/50</Box> */}
                <Box color="#9B9B9B">
                  {/* About to expire{" "}
                  <Box component="span" color="#00BF86">
                    {leadDetails.days_to_expire} days
                  </Box> */}
                </Box>
              </Box>
              <Box flex={1} textAlign="right">
                <span color="#9B9B9B" style={{ marginBottom: "10px" }}>
                  Created&nbsp;&nbsp;
                  {leadDetails.created_on
                    ? moment(leadDetails.created_on).format("DD-MMM-yyyy")
                    : ""}
                </span>
                <br />
                <span color="#9B9B9B">
                  Last Updated&nbsp;&nbsp;
                  {leadDetails.updated_on
                    ? moment(leadDetails.updated_on).format("DD-MMM-yyyy")
                    : ""}
                </span>
              </Box>
            </Box>
          </Box>
        </Card>
        <Grid container spacing={2} style={{ marginTop: "1%" }}>
          <Grid item md={4}>
            <Card
              style={{
                height: "auto",
                minHeight: "300px",
                padding: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 5px 13px -3px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Box
                style={{
                  color: "#565656",
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Sales & Specific Details
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={FavIcon} />
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Interested Properties
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {/* {JSON.stringify(leadDetails.interested_in)} */}
                    {leadDetails.interested_in
                      ? leadDetails.interested_in.map((i) => (
                          <>{i.property_name} </>
                        ))
                      : ""}
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={FollowUp} />
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Next Follow Up
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails.follow_up_date
                      ? moment(leadDetails.follow_up_date).format("DD-MMM-yyyy hh:mm a")
                      : ""}
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={LastCallIcon} />
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Last Call Status
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails.last_call_status
                      ? leadDetails.last_call_status
                      : ""}
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={RemarkIcon} />
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Remarks
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails.remarks ? leadDetails.remarks : "No remarks"}
                  </Box>
                </Box>
              </Box>
              <Box>
                <button
                  onClick={() => {
                    updateSalesAction(leadDetails);
                  }}
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontSize: "12px",
                    backgroundColor: "#8B7862",
                    borderRadius: "5px",
                    width: "100%",
                    border: "1px solid #8B7862",
                    padding: "10px 25px",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img backgroundColor="white" src={Update} />
                  &nbsp;Update Sales Flow
                </button>
              </Box>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card
              style={{
                height: "auto",
                minHeight: "300px",
                padding: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 5px 13px -3px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Box
                style={{
                  color: "#565656",
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Follow up Details
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={EmailLeadIcon} />
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Email
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails?.client?.email}
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={CallLeadIcon} />
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Phone
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails?.client?.number}
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Avatar src={Address} />
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Address
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails?.client?.address}
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Avatar src={Address} />
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Assigned
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails?.assigned_name}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card
              style={{
                height: "auto",
                minHeight: "300px",
                padding: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 5px 13px -3px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Box
                style={{
                  color: "#565656",
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                General Info
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={PersonalDetailIcon} />
                </Box>
                <Box paddingLeft="10px">
                  <Box
                    style={{ color: "#9B9B9B", fontSize: "14px" }}
                    padding={"2%"}
                  >
                    Personal Details
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box flex={1} textAlign="left" padding={"2%"}>
                      <span
                        style={{
                          color: "#565656",
                          fontWeight: "600",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Maritial Status
                      </span>
                    </Box>
                    <Box flex={1} textAlign="left" marginLeft="10%">
                      <span>{leadDetails?.client?.marital_status}</span>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box flex={1} textAlign="left" padding={"2%"}>
                      <span
                        style={{
                          color: "#565656",
                          fontWeight: "600",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Occupation
                      </span>
                    </Box>
                    <Box flex={1} textAlign="left" marginLeft="21%">
                      <span>{leadDetails?.client?.occupation}</span>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box
                      flex={1}
                      textAlign="left"
                      style={{
                        color: "#565656",
                        fontWeight: "600",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                      }}
                      padding={"2%"}
                    >
                      Vehicle Owned
                    </Box>
                    <Box flex={1} textAlign="left" marginLeft="10%">
                      <span>
                        {leadDetails?.client?.vehicle_owned ? "Yes" : "No"}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box>
                  <Avatar src={ClientCategoryIcon} />
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Client Category
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    {leadDetails?.client?.category}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "2%" }}>
          <Grid item md={12}>
            <Card
              style={{
                padding: "10px",
                backgroundColor: "#ffffff",
                boxShadow: "0px 5px 13px -3px rgba(0, 0, 0, 0.15)",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              <Box
                style={{
                  color: "#565656",
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Action Journey
              </Box>
              {leadDetails.workflow.length > 0 &&
                leadDetails.workflow[0].stages.length > 0 &&
                leadDetails.workflow[0].stages[0].tasks.map((task) => (
                  <>
                    {console.log("lead details", task)}
                    <Box
                      alignItems="center"
                      display="grid"
                      style={{ marginBottom: "10px" }}
                      gridTemplateColumns="0.5fr 1.5fr 8fr"
                    >
                      <Box className={classes.indicator}>
                        {task.id ? task.id : ""}
                        <Box className={classes.indicatorConnector}></Box>
                      </Box>
                      <Box paddingLeft="10px">
                        <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                          {task.name ? task.name : ""}
                        </Box>
                        <Box style={{ color: "#565656", fontSize: "16px" }}>
                          {task.time
                            ? moment(task.time).format("DD-MMM-yyyy hh:mm a")
                            : ""}
                        </Box>
                      </Box>
                      <Box marginLeft="10px" paddingLeft="10px" border="1px solid #f2f2f2" display="flex" alignItems="center" height="45px" borderRadius="5px" backgroundColor="#f4f4f4" color="#9B9B9B" fontSize="12px" position="relative">
                        {task.details ? task.details : ""}
                      </Box>
                    </Box>
                  </>
                ))}
              {/* <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box className={classes.indicator}>
                  11
                  <Box className={classes.indicatorConnector}></Box>
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Sent Document
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    Today,9:25 AM
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box className={classes.indicator}>
                  10
                  <Box className={classes.indicatorConnector}></Box>
                </Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#9B9B9B", fontSize: "14px" }}>
                    Make a call
                  </Box>
                  <Box style={{ color: "#565656", fontSize: "16px" }}>
                    Today,9:05 AM
                  </Box>
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                style={{ marginBottom: "10px" }}
              >
                <Box className={classes.indicator}>...</Box>
                <Box paddingLeft="10px">
                  <Box style={{ color: "#FF5674", fontSize: "14px" }}>
                    View All Activity
                  </Box>
                </Box>
              </Box> */}
            </Card>
          </Grid>
        </Grid>
        {viewAction ? (
          <>
            {/* <Box
              style={{
                position: "fixed",
                bottom: "48%",
                right: "1%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              onClick={() => {
                setShare(true);
              }}
            >
              <img src={ShareDocument} />
            </Box>
            <Box
              style={{
                width: "10%",
                position: "fixed",
                bottom: "51%",
                right: "5%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              onClick={() => {
                setShare(true);
              }}
            >
              <span
                style={{
                  marginRight: "4%",
                  color: "white",
                  backgroundColor: "#565656",
                  borderRadius: "36px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                Share Document
              </span>
              &nbsp;&nbsp;
            </Box> */}
            <Box
              style={{
                position: "fixed",
                bottom: "38%",
                right: "1%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              onClick={() => {
                setShare(true);
              }}
            >
              <img src={ShareDocument} />

              {/* <img src={Emails} /> */}
            </Box>
            <Box
              style={{
                width: "10%",
                position: "fixed",
                bottom: "41%",
                right: "5%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              onClick={() => {
                setShare(true);
              }}
            >
              <span
                style={{
                  marginRight: "4%",
                  color: "white",
                  backgroundColor: "#565656",
                  borderRadius: "36px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                Share Document
              </span>
            </Box>
            <Box
              style={{
                position: "fixed",
                bottom: "28%",
                right: "1%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              onClick={() => setEventModal(true)}
            >
              <img src={AddEvent1} />
            </Box>
            <Box
              style={{
                width: "10%",
                position: "fixed",
                bottom: "31%",
                right: "5%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              onClick={() => setEventModal(true)}
            >
              <span
                style={{
                  marginRight: "4%",
                  color: "white",
                  backgroundColor: "#565656",
                  borderRadius: "36px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                Add an Event
              </span>
              &nbsp;
            </Box>
            <Box
              style={{
                position: "fixed",
                bottom: "18%",
                right: "1%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
              onClick={() => {
                setEdit(true);
              }}
            >
              <img src={EditLead} />
            </Box>
            <Box
              style={{
                width: "10%",
                position: "fixed",
                bottom: "21%",
                right: "5%",
                textAlign: "center",
                zIndex: "100",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  marginRight: "4%",
                  color: "white",
                  backgroundColor: "#565656",
                  borderRadius: "36px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                Edit Lead
              </span>
            </Box>
          </>
        ) : (
          ""
        )}
        <Box
          onClick={() => {
            setViewAction(!viewAction);
          }}
          style={{
            display: viewAction ? "block" : "none",
            position: "fixed",
            bottom: "1%",
            right: "1%",
            textAlign: "center",
            zIndex: "100",
          }}
        >
          <img src={Cancel} />
        </Box>
        <Box
          onClick={() => {
            setViewAction(!viewAction);
          }}
          style={{
            display: viewAction ? "none" : "block",
            position: "fixed",
            bottom: "1%",
            right: "1%",
            textAlign: "center",
            zIndex: "100",
          }}
        >
          <img src={Action} />
        </Box>
      </Box>
      <Box className={clsx({ [responsive.app]: true, "app-dashboard": true })}>
        <Card style={{ padding: "10px" }}>
          <Box display="flex" alignItems="center" justifyItems="center">
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackIosIcon
                  style={{ color: "black", height: "50px", width: "16px" }}
                />
              </IconButton>
            </Box>
            <Box fontSize="18px" fontWeight="bold" marginTop="2px">
              {leadDetails.name}
            </Box>
          </Box>
          <Box fontSize="12px" display="flex">
            {/* <Box flex={1}>#01/50</Box> */}
            <Box display="flex" fontSize="12px">
              <Box marginLeft="20px" fontWeight="bold" color="#9B9B9B">
                Created
              </Box>{" "}
              <Box fontWeight="bold" color="#565656">
                &nbsp;&nbsp;
                {moment(leadDetails.created_at).format("DD-MMM-yyyy")}
              </Box>
            </Box>
          </Box>
          <Box fontSize="12px" display="flex">
            {/* <Box color="#9B9B9B" flex={1}>
              About to expire{" "}
              <Box component="span" color="#00BF86">
                {leadDetails.days_to_expire} days
              </Box>
            </Box> */}
            <Box marginLeft="20px" fontSize="12px" display="flex">
              <Box fontWeight="bold" color="#9B9B9B">
                Last updated
              </Box>{" "}
              <Box fontWeight="bold" color="#565656">
                &nbsp;&nbsp;
                {moment(leadDetails.last_updated_at).format("DD-MMM-yyyy")}
              </Box>
            </Box>
          </Box>

          <Box style={{ marginTop: "20px" }}>
            <Box display="flex">
              <Avatar />
              <Box paddingLeft="10px">
                <Box marginBottom="4px" fontWeight="bold">
                  {leadDetails.name}
                </Box>
                <Box
                  color="#9B9B9B"
                  fontSize="12px"
                  display="flex"
                  alignItems="center"
                >
                  <Box>
                    <img src={renderPriority(leadDetails.priority)} />
                  </Box>
                  <Box marginLeft="10px">Personal Lead</Box>
                </Box>
              </Box>

              {/* <Box flex={1} textAlign="left" marginLeft="10%">
                <div style={{ color: "#00BF86", marginBottom: "10px" }}>
                  <img src={Edit} />
                  &nbsp;Edit Lead
                </div>
                <div style={{ color: "#FF5674" }}>
                  <img src={Delete} />
                  &nbsp;Delete Lead
                </div>
              </Box> */}

              <Box
                style={{
                  display: "flex",
                  textAlign: "right",
                  //   justifyContent: "flex-end",
                  marginLeft: "14%",
                }}
              >
                <Box
                  onClick={() => {
                    history.push(`/lead/${id}/edit`);
                  }}
                  style={{
                    display: "flex",
                    color: "#958570",
                    width: "80px",
                    textDecoration: "none",
                  }}
                >
                  {/* <Box>
                    <img src={Edit} />
                  </Box> */}
                  &nbsp;Edit Lead
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
        <br />
        <Card height="100%">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600", color: "#565656" }}>
                Sales & Specific Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box display="flex">
                <Box>
                  <Avatar src={FavIcon} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>
                    Interested Properties
                  </Box>
                  <Box color="#565656">
                    {leadDetails.interested_in
                      .map((property) => property.property_name)
                      .join(", ")}
                  </Box>
                </Box>
              </Box>

              <Box display="flex" style={{ marginTop: "10px" }}>
                <Box>
                  <Avatar src={FollowUp} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>Next Follow up</Box>
                  <Box color="#565656">
                    {moment(leadDetails.follow_up_date).format("DD-MMM-YYYY hh:mm a")}
                  </Box>
                </Box>
              </Box>

              <Box display="flex" style={{ marginTop: "10px" }}>
                <Box>
                  <Avatar src={LastCallIcon} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>Last Call Status</Box>
                  <Box color="#565656">{leadDetails.last_call_status}</Box>
                </Box>
              </Box>

              <Box display="flex" style={{ marginTop: "10px" }}>
                <Box>
                  <Avatar src={RemarkIcon} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>Remark</Box>
                  <Box color="#565656">{leadDetails.remarks}</Box>
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "23px 0 7px 0",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => {
                    updateSalesAction(leadDetails);
                  }}
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontSize: "12px",
                    backgroundColor: "#8B7862",
                    borderRadius: "5px",
                    width: "100%",
                    border: "1px solid #8B7862",
                    padding: "10px 25px",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img backgroundColor="white" src={Update} />
                  &nbsp;Update Sales Flow
                </button>
                {/* <Box marginTop="2px">
                  <img src={Update} />
                </Box>
                <Box style={{ color: "#FF5674" }}>
                  &nbsp;&nbsp;Update Sales Flow
                </Box> */}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600", color: "#565656" }}>
                Follow up Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box display="flex">
                <Box>
                  <Avatar src={EmailLeadIcon} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>Email</Box>
                  <Box color="#565656">{leadDetails?.client?.email}</Box>
                </Box>
              </Box>

              <Box display="flex" style={{ marginTop: "10px" }}>
                <Box>
                  <Avatar src={CallLeadIcon} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>Phone</Box>
                  <Box color="#565656">{leadDetails?.client?.number}</Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600", color: "#565656" }}>
                General Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box display="flex">
                <Box>
                  <Avatar src={PersonalDetailIcon} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>Personal Details</Box>
                  <Box color="#565656">
                    <Box display="flex">
                      <Box
                        flex={1}
                        className={classes.accordianLabel}
                        style={{ color: "#565656" }}
                        width="240px"
                      >
                        Marital Status
                      </Box>
                      <Box flex={1}>{leadDetails?.client?.marital_status}</Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        flex={1}
                        className={classes.accordianLabel}
                        style={{ color: "#565656" }}
                        width="240px"
                      >
                        Occupation
                      </Box>
                      <Box flex={1}>{leadDetails?.client?.occupation}</Box>
                    </Box>
                    <Box display="flex">
                      <Box
                        flex={1}
                        className={classes.accordianLabel}
                        style={{ color: "#565656" }}
                        width="240px"
                      >
                        Vehicle Owned
                      </Box>
                      <Box flex={1}>{leadDetails?.client?.vehicle_owned}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" style={{ marginTop: "10px" }}>
                <Box>
                  <Avatar src={ClientCategoryIcon} />
                </Box>
                <Box style={{ marginLeft: "10px" }}>
                  <Box className={classes.accordianLabel}>Client Category</Box>
                  <Box color="#565656">{leadDetails?.client?.category}</Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600", color: "#565656" }}>
                Action Journey
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column">
                {leadDetails.workflow.length > 0 &&
                  leadDetails.workflow[0].stages.length > 0 &&
                  leadDetails.workflow[0].stages[0].tasks.map((task, index) => {
                    return (
                      <Box
                        alignItems="center"
                        display="flex"
                        style={{ marginBottom: "10px" }}
                      >
                        <Box className={classes.indicator}>
                          {index + 1}
                          <Box
                            className={clsx({
                              [classes.indicatorConnector]:
                                index + 1 <
                                leadDetails.workflow[0].stages[0].tasks.length,
                            })}
                          ></Box>
                        </Box>
                        <Box paddingLeft="10px">
                          <Box className={classes.accordianLabel}>
                            {task.name}
                          </Box>
                          <Box style={{ color: "#565656", fontSize: "16px" }}>
                            {moment(task.time).format("DD-MMM-YYYY hh:mm a")}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}

                {/* <Box
                  alignItems="center"
                  display="flex"
                  style={{ marginBottom: "10px" }}
                >
                  <Box className={classes.indicator}>
                    10
                    <Box className={classes.indicatorConnector}></Box>
                  </Box>
                  <Box paddingLeft="10px">
                    <Box className={classes.accordianLabel}>Make a call</Box>
                    <Box style={{ color: "#565656", fontSize: "16px" }}>
                      Today,9:05 AM
                    </Box>
                  </Box>
                </Box> */}
                {/* <Box
                  alignItems="center"
                  display="flex"
                  style={{ marginBottom: "10px" }}
                >
                  <Box className={classes.indicator}>...</Box>
                  <Box paddingLeft="10px">
                    <Box style={{ color: "#FF5674", fontSize: "14px" }}>
                      View All Activity
                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box display="flex" padding="10px" flexDirection="column">
            <Box
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginTop: "13px",
              }}
            >
              Upcoming events
            </Box>
            <Box
              style={{
                marginBottom: "10px",
                fontSize: "12px",
                color: "#787878",
              }}
            >
              TODAY
            </Box>
            <Grid container>
              {task.map((tk) => (
                <>
                  <Grid item xs={12} md={12} style={{ marginTop: "1%" }}>
                    <Box style={{ position: "relative" }} display="flex">
                      <Box
                        style={{
                          backgroundColor: "rgb(12 110 120 / 44%)",
                          padding: "26px 11px",
                          marginRight: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        {new Date(tk.time).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </Box>
                      <Box
                        style={{
                          border: "1px solid #D4D6DE",
                          borderRadius: "5px",
                          borderLeft: "10px solid #0C6E78",
                          padding: "10px",
                          backgroundColor: "white",
                          width: "100%",
                        }}
                      >
                        <Box
                          style={{
                            fontWeight: "600",
                            color: "#0C6E78",
                          }}
                        >
                          {tk.name}{" "}
                        </Box>
                        <Box
                          style={{
                            fontSize: "12px",
                            color: "#8A9EBC",
                          }}
                        >
                          {"with "}
                          {tk.lead}
                        </Box>
                        <Box
                          style={{
                            fontSize: "12px",
                            color: "#8A9EBC",
                          }}
                        >
                          {tk.details}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </>
              ))}
              {task.length == 0 ? "No events today" : ""}
            </Grid>
          </Box>
          {/* <Accordion expanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "600", color: "#565656" }}>
                Upcoming events
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              
            </AccordionDetails>
          </Accordion> */}
        </Card>

        <Box
          display="flex"
          justifyContent="space-around"
          padding="30px 0"
          marginTop="10px"
        >
          <Button
            onClick={() => {
              setConfirmationModal(true);
            }}
            variant="outlined"
            color="primary"
          >
            <Box display="flex" alignItems="center">
              <img src={delet} />
              <Box style={{ paddingLeft: "10px" }}>Delete lead</Box>
            </Box>
          </Button>
          <Button
            style={{ width: "150px" }}
            variant="contained"
            onClick={() => {
              setModal(true);
            }}
            color="primary"
          >
            Action
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default LeadDetail;
