import * as yup from "yup";

const loginValdation = yup.object().shape({
  username: yup.string().email().required("email is required"),
  password: yup.string().required("password is required"),
});

const forgotPasswordValdation = yup.object().shape({
  username: yup.string().email().required("email is required"),
});

const resetValdation = yup.object().shape({
  password: yup.string().required("password is required"),
  confirmpassword: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Confirm Password Must be match with new password"
    )
    .required("password is required"),
});

const addEventValidation = yup.object().shape({
  name: yup.string().required("Event Name is required"),
  appointment_with: yup.string().required("participant is required"),
  // date:"",
  // time:"",
  details: yup.string().required("Remark is required"),
});

const createLeadValidation2 = yup.object().shape({
  name: yup.string().required("name is required"),
  number: yup
    .string()
    .min(10, "mobile number should be 10 digit")
    // .max(10,"mobile number should be 10 digit")
    .required("phone is required"),
  assigned: yup.string().required("please select broker"),
  sale_value: yup
    .string()
    .required("sales value required")
    .nullable("sales value required"),
  interested_in: yup.string().required("properties is required"),
  // .array()
  // .max(1, "only one brik required")
});

const createLeadValidation = yup.object().shape({
  name: yup.string().required("name is required"),
  // email: yup.string().email().required("email is required"),
  number: yup
    .string()
    .min(10, "mobile number should be 10 digit")
    // .max(10,"mobile number should be 10 digit")
    .required("phone is required"),
  // address: yup.string().required("address is required"),
  // marital_status: yup.string().required("status is required"),
  // occupation: yup.string().required("occupation is required"),
  // vehicle_owned: yup.string().required("vehicle is required"),
  // source: yup
  //   .string()
  //   .required("source is required")
  //   .oneOf([
  //     "organic",
  //     "online promotion",
  //     "website",
  //     "personal",
  //     "offline promotion",
  //   ]),
  // marital_status: yup
  //   .string()
  //   .required("Marital Status is required")
  //   .oneOf(["Married", "UnMarried"]),
  // category: yup.string().required("category is required"),
  // interested_in: yup.array().required("properties is required"),
  // follow_up_date: yup.string().required("followUp is required"),
  // last_call_status: yup
  //   .string()
  //   .required("Call status is required")
  //   .notOneOf(["none"], "Call status is required"),
  // remarks: yup.string().required("Remark is required"),
});

const createLeadWebValidation = yup.object().shape({
  name: yup.string().required("name is required"),
  email: yup.string().email().required("email is required"),
  phone: yup
    .string()
    .min(10, "mobile number should be 10 digit")
    .max(10, "mobile number should be 10 digit")
    .required("phone is required"),
  address: yup.string().required("address is required"),
  martial_status: yup.string().required("status is required"),
  occupation: yup.string().required("occupation is required"),
  vehicle: yup.string().required("vehicle is required"),
  lead_status_source: yup.string().required("lead_status_source is required"),
  lead_status_category: yup
    .string()
    .required("lead_status_category is required"),
  properties: yup.string().required("properties is required"),
  follow_up: yup.string().required("followUp is required"),
  lead_call_status: yup.string().required("lead_call_status is required"),
  remark: yup.string().required("remark is required"),
  client_category: yup.string().required("client_category is required"),
  last_call_status: yup.string().required("last_call_status is required"),
  vehicle_owned: yup.string().required("vehicle_owned is required"),
});

export {
  addEventValidation,
  loginValdation,
  forgotPasswordValdation,
  resetValdation,
  createLeadValidation,
  createLeadWebValidation,
  createLeadValidation2,
};
