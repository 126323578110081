import { Grid, Box, Card, IconButton, Avatar, Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles, useTheme, Button, Breadcrumbs } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { responsiveStyles } from '../../assets/css/generalStyling'
import BarIcon from '../../assets/icons/Bar.png'
import MessageIcon from '../../assets/icons/message_icon.svg';
import Modal from '../../component/Modal';
import { Close } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DetailCard from './DetailCard';
import { getPropertyCowners } from '../../store/actions/apis';
import { useParams } from 'react-router-dom';
// import CallIcon from '@material/icons-material/Call';
import CallIcon from '../../assets/icons/Call.svg';
import { useGeneralProvider } from '../../providers/GeneralProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setCoownersData } from '../../store/actions/ownershipActions';


const useStyles = makeStyles((theme) => {

})

function COwners({ propertyDetails, display }) {
  const classes = useStyles();
  const { id } = useParams()
  const responsive = responsiveStyles();
  const [modal, setModal] = useState(false)
  const dispatch = useDispatch()
  const { cownersData } = useSelector(state => {
    return {
      cownersData: state.myOwnerShip.coowners
    }
  })
  const [viewOwnerDetails, setViewOwnerDetails] = useState({})
  // const [cownersData, setCOwners] = useState([])
  const general = useGeneralProvider();
  const styleImg = {
    height: '65px',
    width: '65px'
  }

  const ModalHeader = () => {
    return (
      <Box style={{ zIndex: 1, color: "white" }} padding="14px 14px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box></Box>
        <Box>
          <IconButton style={{ color: "white" }} onClick={() => {
            setModal(false)
          }} size="small"><Close /></IconButton>
        </Box>
      </Box>
    )
  }

  // useEffect(() => {
  //     general.setHeaderComponent(() => {
  //         return (
  //             <Breadcrumbs style={{fontSize:"14px"}} aria-label="breadcrumb">
  //                 <Link style={{textDecoration:"none"}} color="inherit">
  //                     Home
  //                 </Link>
  //                 <Link style={{textDecoration:"none"}} color="inherit">
  //                     My Ownership
  //                 </Link>
  //                 <Link color="inherit" style={{textDecoration:"none"}}>
  //                     {propertyDetails.property_name}
  //                 </Link>
  //             </Breadcrumbs>
  //         )
  //     });
  // }, [])

  useEffect(() => {
    // if (cownersData.length == 0)
      getPropertyCowners(id).then(result => {
        console.log("params: ", result.data.owner)
        dispatch(setCoownersData(result.data.owner))
        // setCOwners(result.data.owner)
      })
  }, [])

  return (
    <>
      <Modal width="450px" height="500px" headerComponent={ModalHeader} status={modal}>
        <img style={{
          position: "absolute",
          backgroundPosition: "cover",
          width: "100%",
          height: "154px",
          left: "0px",
          top: "0px"
        }} width="100%" src={propertyDetails.brik_cover_image} />
        <Box style={{ display: "flex", alignItems: "flex-end", position: "relative", top: "63px" }}>
          <Avatar style={{ height: "70px", width: "70px" }} src={viewOwnerDetails.owner_profile_pic} />
          <Box style={{ paddingLeft: "12px" }}>
            <Box fontWeight={600}>{viewOwnerDetails.owner_name}</Box>
            <Box>
              <img src={BarIcon} style={{ height: "21px" }} />{viewOwnerDetails.ownership}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box style={{ height: "269px", overflow: "auto", position: "relative", top: "73px" }}>
            <Card style={{ padding: "10px" }}>
              <Box>Bio : </Box>
              <Box style={{ color: "#979797" }}>{viewOwnerDetails.owner_bio}</Box>
            </Card>
            <Box paddingTop="10px">
              <Accordion>
                <AccordionSummary
                  disabled
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Contact Info</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <Box flexDirection="column" width="100%" >
                    <Box display='flex' flexDirection='column'>
                      <Box display='flex' justifyContent='space-between'>
                        <Box flex={1}>Email</Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>{viewOwnerDetails.owner__email ? viewOwnerDetails.owner__email : "-"}</Box>
                      </Box>
                      <Box display='flex' justifyContent='space-between'>
                        <Box flex={1}>Phone</Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>99786533</Box>
                      </Box>
                      <Box display='flex' justifyContent='space-between'>
                        <Box flex={1}>Address</Box>
                        <Box flex={1}>:</Box>
                        <Box flex={1}>skyline21b, Bandras, Mumbai</Box>
                      </Box>
                    </Box>
                    <Box marginTop="10px" padding='10px 0' width='100%' display='flex' flexDirection='row' justifyContent='space-between' style={{ background: "#FFEEEE", padding: "20px" }}>
                      <Box textAlign='center'>
                        <IconButton style={{ background: "#3183FF" }}>
                          <img src={CallIcon} />
                        </IconButton>
                        <Box>Phone</Box>
                      </Box>
                      <Box textAlign='center'>
                        <IconButton style={{ background: "#1FA24A" }}>
                          <img src={CallIcon} />
                        </IconButton>
                        <Box>Whatsapp</Box>
                      </Box>
                      <Box textAlign='center'>
                        <IconButton style={{ background: "#FF5674" }}>
                          <img src={CallIcon} />
                        </IconButton>
                        <Box>Email</Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box style={{ display: "flex" }}>
            <Button fullWidth variant='contained' color="secondary">REQUEST CONTACT DETAILS</Button>
          </Box>
        </Box>
      </Modal>
      <Box>
        <Box style={{ display: display }} className={responsive.web}>
          <Grid container>
            <Grid spacing={3} container item xs={12} sm={12}>
              <Grid item xs={12}>
                <Box fontSize="22px" fontWeight="bold">{propertyDetails.property_name}</Box>
              </Grid>
              <Grid item xs={12}>
                <DetailCard propertyDetails={propertyDetails} />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12}>
              <Grid item xs={12}>
                <Box style={{ fontSize: "20px", padding: "14px 0px" }}>Co Owners</Box>
              </Grid>
              {cownersData.map(ownerData => {
                return (
                  <Grid item xs={12} sm={6}>
                    <Card style={{ display: "flex", width: "95%", justifyContent: "space-between", alignItems: "center", padding: "15px", marginBottom: "20px" }}>
                      <Box style={{ cursor: "pointer" }} onClick={() => {
                        setViewOwnerDetails(ownerData)
                        setModal(true)
                      }} display='flex' alignItems='center'>
                        <Avatar style={{ height: "70px", width: "70px" }} src={ownerData.owner_profile_pic} />
                        {/* <img src={(ownerData.owner__profile_pic == '') ? Logo : ownerData.owner__profile_pic} alt='logo' style={styleImg} /> */}
                        <Box flexDirection='column' padding='0 15px'>
                          <Box> {ownerData.owner_name} </Box>
                          <Box><img src={BarIcon} style={{ height: "21px" }} />{ownerData.ownership}</Box>
                        </Box>
                      </Box>
                      {/* <Box>
                        <IconButton onClick={() => {
                          window.open("mailto:" + ownerData.owner_email, '_blank')
                        }}><img src={MessageIcon} /></IconButton>
                      </Box> */}
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>

        </Box>

        <Box style={{ display: display }} className={responsive.app}>

          <Grid container >
            <Grid item xs={12}>
              <Box style={{ fontSize: "20px", padding: "14px 0px" }}>Co Owners</Box>
            </Grid>
            {cownersData.map(ownerData => {
              return (
                <Grid item xs={12} sm={6}>
                  <Box padding="8px 0">
                    <Card style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", padding: "15px" }}>
                      <Box style={{ cursor: "pointer" }} onClick={() => {
                        setViewOwnerDetails(ownerData)
                        setModal(true)
                      }} display='flex' alignItems='center'>
                        <Avatar style={{ height: "70px", width: "70px" }} src={ownerData.owner_profile_pic} />
                        {/* <img src={(ownerData.owner__profile_pic == '') ? Logo : ownerData.owner__profile_pic} alt='logo' style={styleImg} /> */}
                        <Box flexDirection='column' padding='0 15px'>
                          <Box> {ownerData.owner_name} </Box>
                          <Box><img src={BarIcon} style={{ height: "21px" }} />{ownerData.ownership}</Box>
                        </Box>
                      </Box>
                      {/* <Box>
                        <IconButton onClick={() => {
                          window.open("mailto:" + ownerData.owner_email, '_blank')
                        }}><img src={MessageIcon} /></IconButton>
                      </Box> */}
                    </Card>
                  </Box>
                </Grid>
              )
            })}
          </Grid>

        </Box>
      </Box>
    </>
  )
}

export default COwners
