import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DatePicker from "react-date-picker";
import moment from "moment";
import { responsiveStyles } from "../../assets/css/generalStyling";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/moment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import { updatePersonalDetails } from "../../store/actions/apis";
import { useAuth } from "../../providers/AuthProvider";
import { serialize } from "object-to-formdata";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderColor: `#979797 !important`,
  },
  notchedOutlineRed: {
    borderColor: `red !important`,
  },
  input: {
    padding: "10px 14px",
  },
  disabledInput: {
    "& .MuiInputBase-root.Mui-disabled": {
      cursor: "pointer",
      color: "black",
      padding: "6px 0px",
    },
  },
}));

let schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  // dob: yup.string().required('DOB is required').typeError('DOB is required'),
  // marital_status: yup.string().required('Martial status is required'),
  // anniversary_date: yup.string().required('Anniversary date is required'),
  // bio: yup.string().required('Bio is required'),
  // occupation: yup.string().required('Occupation is required')
});

function PersonalDetail(props) {
  const [age, setAge] = React.useState("");
  const [formMode, setFormMode] = useState("add");
  const classes = useStyles();
  const auth = useAuth();
  const responsive = responsiveStyles();
  const notification = useSnackbar();
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const PersonalData = useSelector((state) => {
    return state.account.personal_info;
  });

  let fd = new FormData();

  let {
    values,
    touched,
    errors,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      dob: moment(),
      marital_status: "",
      anniversary_date: "",
      occupation: "",
      profile_pic: "",
      bio: "",
    },
    validationSchema: schema,
    onSubmit: () => {
      let apiData = {
        ...values,
        profile_pic: props.profilePic,
      };

      if (typeof apiData.profile_pic == "string") {
        delete apiData.profile_pic;
      } else {
        fd.append("profile_pic", apiData.profile_pic);
        apiData.profile_pic = apiData.profile_pic;
      }

      for (var pair of Object.entries(apiData)) {
        console.log(pair[0], " ", pair[1]);
        if (pair[1] !== null) fd.append(pair[0], pair[1]);
      }

      // fd.append('first_name', apiData.first_name)
      // fd.append('last_name', apiData.last_name)
      // fd.append('dob', apiData.dob)
      // fd.append('marital_status', apiData.marital_status)
      // fd.append('anniversary_date', apiData.anniversary_date)
      // fd.append('occupation', apiData.occupation)
      // fd.append('bio', apiData.bio)

      // for (var pair of fd.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      if (formMode == "add") {
        updatePersonalDetails({
          apiBody: fd,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
          },
        }).then((result) => {
          console.log("RESULT: ", result);
          // notification.enqueueSnackbar('Profile Updated Successfully', {
          // variant: "success",
          // autoHideDuration: 2000
        });
      } else console.log("APIDAtA: ", apiData);
      updatePersonalDetails({
        user_id: auth.getUserId(),
        apiBody: fd,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
        },
      }).then((result) => {
        console.log("RESULT: ", result);
        notification.enqueueSnackbar("Profile Updated Successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      });
    },
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setFormMode("edit");
    setValues(PersonalData);
    // console.log("USER DATA: ",auth.getUserId())
  }, [PersonalData]);
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            fontWeight="700"
            display="flex"
            fontSize="20px"
            padding="0 0 15px 0"
            onClick={() => {
              props.onBack();
            }}
          >
            <Box className={responsive.app}>
              <ChevronLeftIcon />
              &nbsp;
            </Box>
            <Box>Personal Info</Box>
          </Box>

          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              FIRST NAME
            </Box>
            <TextField
              id="first_name"
              name="first_name"
              value={values.first_name}
              onChange={handleChange}
              error={touched.first_name && errors.first_name ? true : false}
              helperText={touched.first_name && errors.first_name}
              placeholder="Enter First Name"
              fullWidth
              variant="outlined"
              color="secondary"
              style={{ paddingBottom: "10px" }}
            />
          </Box>

          <Box padding="10px 0">
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              LAST NAME
            </Box>
            <TextField
              id="last_name"
              name="last_name"
              value={values.last_name}
              onChange={handleChange}
              error={touched.last_name && errors.last_name ? true : false}
              helperText={touched.last_name && errors.last_name}
              placeholder="Enter Last Name"
              fullWidth
              variant="outlined"
              color="secondary"
              style={{ paddingBottom: "10px" }}
            />
          </Box>

          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              DATE OF BIRTH
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TextField
                id="dob"
                name="dob"
                error={touched.dob && errors.dob ? true : false}
                helperText={touched.dob && errors.dob}
                disabled
                fullWidth
                className={classes.disabledInput}
                variant="outlined"
                margin="dense"
                // type="date"
                value={moment(values.dob).format("DD-MMM-YYYY")}
                placeholder="Write here"
                onClick={() => {
                  document.getElementById(`datePicker1`).click();
                }}
                inputProps={{
                  style: { cursor: "pointer" },
                }}
                // style={{
                //   minWidth: "180px",
                //   backgroundColor: "#fff",
                //   marginLeft: "12px",
                // }}
                InputProps={{
                  classes: {
                    notchedOutline:
                      touched.dob && errors.dob
                        ? classes.notchedOutlineRed
                        : classes.notchedOutline,
                  },
                }}
              />
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  style={{ display: "none" }}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  format="YYYY-MM-DD"
                  value={values.dob}
                  disableFuture
                  onChange={(date) => {
                    console.log(
                      "DOB DATE SELECTED: ",
                      moment(date).format("DD-MMM-YYYY")
                    );
                    setFieldValue("dob", moment(date).format("YYYY-MM-DD"));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: `datePicker1`,
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>

          <Box padding="10px 0">
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              MARITAL STATUS
            </Box>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ color: "secondary" }}
            >
              <Select
                id="marital_status"
                name="marital_status"
                input={<OutlinedInput classes={{ input: classes.input }} />}
                value={values.marital_status}
                onChange={handleChange}
                error={
                  touched.marital_status && errors.marital_status ? true : false
                }
                helperText={touched.marital_status && errors.marital_status}
                labelId="demo-simple-select-outlined-label"
              >
                <MenuItem value="none" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Single">UnMarried</MenuItem>
              </Select>

              <FormHelperText style={{ color: "red" }}>
                {errors.marital_status}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              ANNIVERSARY DATE
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TextField
                id="anniversary_date"
                name="anniversary_date"
                error={
                  touched.anniversary_date && errors.anniversary_date
                    ? true
                    : false
                }
                helperText={touched.anniversary_date && errors.anniversary_date}
                disabled
                fullWidth
                className={classes.disabledInput}
                variant="outlined"
                margin="dense"
                // type="date"
                value={moment(values.anniversary_date).format("DD-MMM-YYYY")}
                placeholder="Write here"
                onClick={() => {
                  document.getElementById(`datePicker2`).click();
                }}
                inputProps={{
                  style: { cursor: "pointer" },
                }}
                // style={{
                //   minWidth: "180px",
                //   backgroundColor: "#fff",
                //   marginLeft: "12px",
                // }}
                InputProps={{
                  classes: {
                    notchedOutline:
                      touched.anniversary_date && errors.anniversary_date
                        ? classes.notchedOutlineRed
                        : classes.notchedOutline,
                  },
                }}
              />
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  style={{ display: "none" }}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  format="YYYY-MM-DD"
                  value={selectedDate}
                  disableFuture
                  // value={formData.slot_sessions[index].day}
                  onChange={(date) => {
                    console.log(
                      "DOB DATE SELECTED: ",
                      moment(date).format("DD-MMM-YYYY")
                    );
                    setFieldValue(
                      "anniversary_date",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: `datePicker2`,
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            {/* <TextField
              id="anniversary_date"
              name="anniversary_date"
              value={values.anniversary_date}
              onChange={handleChange}
              error={touched.anniversary_date && errors.anniversary_date ? true : false}
              helperText={touched.anniversary_date && errors.anniversary_date} placeholder='Enter Anniversary Date' fullWidth id="fullWidth" variant='outlined' color='secondary' style={{ paddingBottom: '10px' }} /> */}
          </Box>

          <Box padding="10px 0">
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              OCCUPATION
            </Box>
            <TextField
              id="occupation"
              name="occupation"
              value={values.occupation}
              onChange={handleChange}
              error={touched.occupation && errors.occupation ? true : false}
              helperText={touched.occupation && errors.occupation}
              placeholder="Business"
              fullWidth
              variant="outlined"
              color="secondary"
              style={{ paddingBottom: "10px" }}
            />
          </Box>
          <Box>
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              BIO
            </Box>
            <TextField
              id="bio"
              name="bio"
              value={values.bio}
              onChange={handleChange}
              error={touched.bio && errors.bio ? true : false}
              helperText={touched.bio && errors.bio}
              placeholder="Write here.."
              fullWidth
              variant="outlined"
              color="secondary"
              style={{ paddingBottom: "10px" }}
            />
          </Box>

          <Box padding="20px 0" display="flex" justifyContent="center">
            <Button
              onClick={submitForm}
              variant="contained"
              color="secondary"
              style={{ width: "65%", borderRadius: "5px" }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default PersonalDetail;
