import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { responsiveStyles } from "../../assets/css/generalStyling";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilePicker from "../../utils/FilePicker";
import { useSelector } from "react-redux";
import { addIdCard, updateIdCard } from "../../store/actions/apis";
import { useSnackbar } from "notistack";
// import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "10px 14px",
  },
}));

let schema = yup.object().shape({
  // dob: yup.string().required('DOB is required').typeError('DOB is required'),
  document_type: yup.string().required("Document type is required"),
  document_number: yup.string().required("Document Number is required"),
  // bio: yup.string().required('Bio is required'),
  // occupation: yup.string().required('Occupation is required')
});

function IDCard(props) {
  const responsive = responsiveStyles();
  const [age, setAge] = React.useState("");
  const [formMode, setFormMode] = useState("add");
  const classes = useStyles();
  const notification = useSnackbar();
  const [image, setImage] = useState("");

  const IDCards = useSelector((state) => {
    return state.account.id_cards;
  });

  // const handleChange = (event) => {
  //    setAge(event.target.value);
  // };

  let schema = yup.object().shape({
    document_number: yup.string().required("Document Number is required"),
  });

  let {
    values,
    touched,
    errors,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues: {
      document_type: "driving_license",
      document_number: "",
      id_front: "",
      id_front_file: "",
      id_back: "",
      id_back_file: "",
    },
    validationSchema: schema,
    onSubmit: () => {
      let fd = new FormData();

      let apiBody = {
        document_type: values.document_type,
        document_number: values.document_number,
        front_side: values.id_front_file,
        back_side: values.id_back_file,
      };

      if (typeof values.id_front_file !== "object") {
        delete apiBody.front_side;
      }

      if (typeof values.id_back_file !== "object") {
        delete apiBody.back_side;
      }

      for (var pair of Object.entries(apiBody)) {
        console.log(pair[0] + ", " + pair[1]);
        fd.append(pair[0], pair[1]);
      }

      console.log("submitted values: ", values, apiBody);

      if (formMode == "add") {
        addIdCard({
          apiBody: fd,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
          },
        }).then((result) => {
          console.log("RESULT ID CARDS UPDATED ", result);
          notification.enqueueSnackbar("ID Card Saved Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
      } else
        updateIdCard({
          card_id: values.id,
          apiBody: fd,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
          },
        }).then((result) => {
          console.log("RESULT ID CARDS UPDATED ", result);
          notification.enqueueSnackbar("ID Card Saved Successfully", {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
    },
  });

  useEffect(() => {
    if (IDCards !== null) {
      setFormMode("edit");

      if (IDCards.length > 0) {
        setValues({
          id: IDCards[0].id,
          document_type: IDCards[0].document_type,
          document_number: IDCards[0].document_number,
          id_front: IDCards[0].front_side,
          id_front_file: IDCards[0].front_side,
          id_back: IDCards[0].back_side,
          id_back_file: IDCards[0].back_side,
        });
      }
    }
  }, [IDCards]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            padding="10px 0"
            fontWeight="700"
            fontSize="19px"
            onClick={() => {
              props.onBack();
            }}
          >
            <Box className={responsive.app}>
              <ChevronLeftIcon />
              &nbsp;
            </Box>
            <Box>ID Cards</Box>
          </Box>

          <Box padding="10px 0">
            <Box padding="5px 0" fontWeight="700" fontSize="13px">
              DOCUMENT TYPE
            </Box>
            <FormControl
              variant="outlined"
              fullWidth
              color="secondary"
              padding="5px 0"
            >
              <Select
                // labelId="demo-simple-select-outlined-label"
                id="document_type"
                name="document_type"
                value={values.document_type}
                input={<OutlinedInput classes={{ input: classes.input }} />}
                onChange={handleChange}
                error={
                  touched.document_type && errors.document_type ? true : false
                }
                helperText={touched.document_type && errors.document_type}
                labelId="demo-simple-select-outlined-label"
                // id="demo-simple-select-outlined"
              >
                {/* <MenuItem value=""></MenuItem> */}
                <MenuItem value="Aadhaar">Aadhaar Card</MenuItem>
                <MenuItem value="DrivingLicense">Driving License</MenuItem>
                <MenuItem value="PAN">PAN</MenuItem>
                <MenuItem value="VoterId">Voter ID</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box padding="10px 0">
            <Box fontWeight="700" fontSize="13px" padding="5px 0">
              DOCUMENT NUMBER
            </Box>
            <TextField
              id="document_number"
              name="document_number"
              value={values.document_number}
              onChange={handleChange}
              error={
                touched.document_number && errors.document_number ? true : false
              }
              helperText={touched.document_number && errors.document_number}
              fullWidth
              variant="outlined"
              placeholder="Enter Document Number"
              style={{ paddingBottom: "10px" }}
            />
          </Box>

          <Box>
            <Box fontWeight="700" fontSize="13px" padding="8px 0">
              UPLOAD
            </Box>
            <Box display="flex">
              <Box textAlign="center" flexDirection="column" marginRight="15px">
                <FilePicker
                  type="component"
                  fileHandler={(file, alt) => {
                    // console.log("FILE PICKED: ", URL.createObjectURL(file))
                    setFieldValue("id_front_file", file);
                    setFieldValue("id_front", URL.createObjectURL(file));
                  }}
                >
                  {values.id_front_file === "" ? (
                    <Box
                      border="1px dashed black"
                      padding="10px"
                      display="flex"
                    >
                      <IconButton>
                        <AddIcon color="secondary" />
                      </IconButton>
                    </Box>
                  ) : (
                    <img
                      style={{
                        height: "70px",
                        width: "70px",
                        objectFit: "contain",
                      }}
                      src={values.id_front}
                    />
                  )}
                </FilePicker>
                <Box style={{ fontSize: "12px", marginTop: "3px" }}>
                  Front Side
                </Box>
              </Box>
              <Box textAlign="center" flexDirection="column" marginRight="15px">
                <FilePicker
                  type="component"
                  fileHandler={(file, alt) => {
                    // console.log("FILE PICKED: ", URL.createObjectURL(file))
                    setFieldValue("id_back_file", file);
                    setFieldValue("id_back", URL.createObjectURL(file));
                  }}
                >
                  {values.id_back_file == "" ? (
                    <Box
                      border="1px dashed black"
                      padding="10px"
                      display="flex"
                    >
                      <IconButton>
                        <AddIcon color="secondary" />
                      </IconButton>
                    </Box>
                  ) : (
                    <img
                      style={{
                        height: "70px",
                        width: "70px",
                        objectFit: "contain",
                      }}
                      src={values.id_back}
                    />
                  )}
                </FilePicker>
                <Box style={{ fontSize: "12px", marginTop: "3px" }}>
                  Back Side
                </Box>
              </Box>
            </Box>
          </Box>

          <Box padding="50px 0" display="flex" justifyContent="center">
            <Button
              onClick={submitForm}
              variant="contained"
              color="secondary"
              style={{ width: "65%", borderRadius: "5px" }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default IDCard;
