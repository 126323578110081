import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import cameraIcon from "../assets/icons/camera.png";
import {
  getProfileData,
  addPersonalDetails,
  updatePersonalDetails,
  updateProfilePicture,
} from "../store/actions/apis";
import { useAuth } from "../providers/AuthProvider";
import { useFormik } from "formik";
import { responsiveStyles } from "../assets/css/generalStyling";
import FilePicker from "../utils/FilePicker";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "17px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "2px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "60px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function AccountEdit({ deviceType, onBack, formMode = "edit", id }) {
  const uId = localStorage.getItem("userId");
  const [profileImageFile, setProfileImageFile] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const auth = useAuth();
  const notification = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      number: "",
      dob: "",
      number: "",
      pan_number: "",
      aadhaar_number: "",
      address: "",
      rera_number: "",
      profile_pic: null,
      bank_name: "",
      ifsc_code: "",
      account_number: "",
      account_holder_name: "",
      account_type: "",
    },
    onSubmit: () => {
      if (mode == "add") {
        const apiData = {
          name: values.name,
          email: values.email,
          number: values.number,
          dob: values.dob,
          number: values.number,
          pan_number: values.pan_number,
          aadhaar_number: values.aadhaar_number,
          address: values.address,
          rera_number: values.rera_number,
          profile_pic: null,
          bank_details: {
            bank_name: values.bank_name,
            ifsc_code: values.ifsc_code,
            account_number: values.account_number,
            account_holder_name: values.account_holder_name,
            account_type: values.account_type,
          },
        };
        addPersonalDetails({
          apiData,
        }).then((result) => {
          if (deviceType == "website") {
            onBack();
          }
          // else history.goBack();
          notification.enqueueSnackbar("Profile Added Successfully", {
            variant: "success",
          });
        });
      } else {
        if (uId) {
          const apiData = {
            name: values.name,
            email: values.email,
            number: values.number,
            dob: values.dob,
            number: values.number,
            pan_number: values.pan_number,
            aadhaar_number: values.aadhaar_number,
            address: values.address,
            rera_number: values.rera_number,
            bank_details: [
              {
                id: null,
                bank_name: values.bank_name,
                ifsc_code: values.ifsc_code,
                account_number: values.account_number,
                account_holder_name: values.account_holder_name,
                account_type: values.account_type,
              },
            ],
          };

          if (typeof profileImageFile == "object") {
            let fd = new FormData();
            fd.append("profile_pic", profileImageFile);
            updateProfilePicture(uId, fd).then((result) => {
              console.log("RESULT HERE: ", result);
            });
          }

          // updatePersonalDetails(uId, apiData).then((result) => {
          //   if (deviceType == "website") {
          //     onBack();
          //   }
          //   // else history.goBack();
          //   notification.enqueueSnackbar("Profile Updated Successfully", {
          //     variant: "success",
          //   });
          // });
        }
        if (formMode == "edit") {
          const apiData = {
            name: values.name,
            email: values.email,
            number: values.number,
            dob: values.dob,
            number: values.number,
            pan_number: values.pan_number,
            aadhaar_number: values.aadhaar_number,
            address: values.address,
            rera_number: values.rera_number,
            bank_details: [
              {
                id: null,
                bank_name: values.bank_name,
                ifsc_code: values.ifsc_code,
                account_number: values.account_number,
                account_holder_name: values.account_holder_name,
                account_type: values.account_type,
              },
            ],
          };
          updatePersonalDetails(uId, apiData).then((result) => {
            if (deviceType == "website") {
              onBack();
            }
            // else history.goBack();
            notification.enqueueSnackbar("Profile Updated Successfully", {
              variant: "success",
            });
          });
        }
      }
    },
    // validationSchema: createLeadValidation,
  });
  const [mode, setMode] = useState("edit");

  useEffect(() => {
    if (uId) {
      setMode("edit");
      getProfileData(uId).then((result) => {
        setProfileImage(result.data.profile_pic);
        setValues({
          name: result.data.name,
          email: result.data.email,
          number: result.data.number,
          dob: result.data.dob,
          number: result.data.number,
          pan_number: result.data.pan_number,
          aadhaar_number: result.data.aadhaar_number,
          address: result.data.address,
          rera_number: result.data.rera_number,
          profile_pic: null,
          bank_name: result.data.bank_details[0].bank_name,
          ifsc_code: result.data.bank_details[0].ifsc_code,
          account_number: result.data.bank_details[0].account_number,
          account_holder_name: result.data.bank_details[0].account_holder_name,
          account_type: result.data.bank_details[0].account_type,
        });
      });
    }
    if (formMode == "edit") {
      setMode("edit");
      getProfileData(uId).then((result) => {
        setValues({
          name: result.data.name,
          email: result.data.email,
          number: result.data.number,
          dob: result.data.dob,
          number: result.data.number,
          pan_number: result.data.pan_number,
          aadhaar_number: result.data.aadhaar_number,
          address: result.data.address,
          rera_number: result.data.rera_number,
          profile_pic: null,
          bank_name: result.data.bank_details[0].bank_name,
          ifsc_code: result.data.bank_details[0].ifsc_code,
          account_number: result.data.bank_details[0].account_number,
          account_holder_name: result.data.bank_details[0].account_holder_name,
          account_type: result.data.bank_details[0].account_type,
        });
      });
    }
  }, []);

  return (
    <>
      <Box
        style={{ backgroundColor: "#F1E8DA", padding: "10px" }}
        className="app-dashboard"
      >
        <Box style={{ backgroundColor: "white", padding: "12px" }}>
          <Box marginBottom="10px" display="flex" alignItems="center">
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <Box fontSize="22px" fontWeight={600}>
              Edit
            </Box>
          </Box>
          <Box position="relative">
            <FilePicker
              type="component"
              fileHandler={(file, alt) => {
                // console.log("FILE PICKED: ", URL.createObjectURL(file))
                setProfileImageFile(file);
                setProfileImage(URL.createObjectURL(file));
              }}
            >
              <Box
                style={{
                  paddingBottom: "8px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  round={true}
                  size="100"
                  style={{ height: "85px", width: "86px", cursor: "pointer" }}
                  src={profileImage}
                  name={auth.getUser().name}
                />
              </Box>
              <Box bottom="17px" left="168px" position="relative">
                <img src={cameraIcon} />
              </Box>
            </FilePicker>
          </Box>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <TextField
                id="name"
                name="name"
                fullWidth
                placeholder="Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid xs={12} item>
              <TextField
                fullWidth
                id="outlined-password-input"
                name="email"
                placeholder="Email"
                value={values.email}
                type="email"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid xs={12} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog"
                  disableFuture
                  // label="Date picker dialog"
                  format="DD-MM-YYYY"
                  // value={values.dob}
                  onChange={(date) => {
                    console.log(
                      "DOB DATE SELECTED: ",
                      moment(date).format("DD-MMM-YYYY")
                    );
                    // setFieldValue('dob', moment(date).format('YYYY-MM-DD'))
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: `datePicker1`,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid xs={12} item>
              <TextField
                fullWidth
                id="number"
                name="number"
                placeholder="Phone"
                value={values.number}
                type="number"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.number && errors.number}
                helperText={touched.number && errors.number}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                id="pan_number"
                name="pan_number"
                fullWidth
                placeholder="pan_number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pan_number}
                error={touched.pan_number && errors.pan_number}
                helperText={touched.pan_number && errors.pan_number}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                id="aadhaar_number"
                name="aadhaar_number"
                fullWidth
                placeholder="Aadhar Number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.aadhaar_number}
                error={touched.aadhaar_number && errors.aadhaar_number}
                helperText={touched.aadhaar_number && errors.aadhaar_number}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                id="rera_number"
                name="rera_number"
                fullWidth
                placeholder="Rera Number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.rera_number}
                error={touched.rera_number && errors.rera_number}
                helperText={touched.rera_number && errors.rera_number}
              />
            </Grid>
          </Grid>

          <Box padding="7px" fontWeight="600">
            Bank Details
          </Box>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <TextField
                name="bank_name"
                fullWidth
                placeholder="Bank Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bank_name}
                error={touched.bank_name && errors.bank_name}
                helperText={touched.bank_name && errors.bank_name}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="account_number"
                name="account_number"
                fullWidth
                placeholder="account_number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_number}
                error={touched.account_number && errors.account_number}
                helperText={touched.account_number && errors.account_number}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="account_holder_name"
                name="account_holder_name"
                fullWidth
                placeholder="Account Holder Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_holder_name}
                error={
                  touched.account_holder_name && errors.account_holder_name
                }
                helperText={
                  touched.account_holder_name && errors.account_holder_name
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="account_holder_name"
                name="account_holder_name"
                fullWidth
                placeholder="Account Holder Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_holder_name}
                error={
                  touched.account_holder_name && errors.account_holder_name
                }
                helperText={
                  touched.account_holder_name && errors.account_holder_name
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="ifsc_code"
                name="ifsc_code"
                fullWidth
                placeholder="IFSC Code"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ifsc_code}
                error={touched.ifsc_code && errors.ifsc_code}
                helperText={touched.ifsc_code && errors.ifsc_code}
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                label="Account Type"
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Account Type"
                name="account_type"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_type}
                error={touched.account_type && errors.account_type}
              >
                <MenuItem value="none" disabled>
                  Account Type
                </MenuItem>
                <MenuItem value="Savings">Savings</MenuItem>
                <MenuItem value="Current">Current</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.account_type && errors.account_type}
              </FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Box flex={1}>
                  <Button
                    fullWidth
                    onClick={() => {}}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Box>
                <Box style={{ marginLeft: "10px" }} flex={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={submitForm}
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>{" "}
    </>
  );
}

export default AccountEdit;
