import { createTheme } from "@material-ui/core";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    // primary: {
    //     main: '#ffffff',        //blue
    //     contrastText: '#282827',
    // },
    // secondary: {
    //     main: '#4A8EAB',        //green
    //     contrastText: '#ffffff',
    // },
    primary: {
      main: "#8B7862",
      contrastText: "#ffffff",
      // main: '#ffffff',        //blue
      // contrastText: '#282827',
    },
    secondary: {
      main: "#FFEAED", //green
      contrastText: "#8B7862",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Proxima Nova"',
      "Lato",
    ].join(","),
  },
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        // minWidth: "367px",
      },
    },
    MuiSelect: {
      outlined: {
        paddingTop: "6px",
        fontSize: "13px",
        paddingBottom: "6px",
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#FF5B79",
        border: "none !important",
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          textDecoration: "none",
          // borderRadius: "27px"
        },
      },
    },
    MuiChip: {
      root: {},
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "0px",
        marginBottom: "0px",
      },
    },
    MuiOutlinedInput: {
      input: {
        // height: "10px",
        // padding: "13.5px 11px"
      },
      root: {
        "& $notchedOutline": {
          borderColor: "#979797",
        },
        "&:hover $notchedOutline": {
          borderColor: "#979797",
        },
        "&$focused $notchedOutline": {
          borderColor: "#979797",
        },
      },
    },
  },
});

export default theme;
