import {
  FormHelperText,
  OutlinedInput,
  Box,
  IconButton,
  makeStyles,
  Button,
  Card,
  Select,
  MenuItem,
  Grid,
  TextField,
} from "@material-ui/core";
import Avatar from "react-avatar";
import { useFormik } from "formik";
import MyPropertyIcon from "../assets/icons/myProperties.svg";
import userAgreement from "../assets/icons/userAgreement.svg";
import PrivacyPolicyIcon from "../assets/icons/Group 3877.svg";
import TANDCIcon from "../assets/icons/TandC.svg";
import { Logo } from "../assets/assets";
import Modal from "../component/Modal";
import moment from "moment";
import BankIcon from "../../src/assets/icons/Bank.png";
import ArrowUp from "../../src/assets/icons/ArrowHeadingUp.png";
import ArrowDown from "../../src/assets/icons/ArrowHeadingDown.png";
import DateFnsUtils from "@date-io/moment";
import { Close } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import Slider from "../assets/images/Slider1.png";
import PersonalInfo from "../assets/icons/PersonalInfo.svg";
import IDCards from "../assets/icons/IDCards.svg";
import cameraIcon from "../assets/icons/camera.svg";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { responsiveStyles } from "../assets/css/generalStyling";
import { useHistory } from "react-router";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IDCardImg from "../assets/BellIcon.png";
import { Link } from "react-router-dom";
import {
  getProfileData,
  addPersonalDetails,
  updatePersonalDetails,
  updateProfilePicture,
} from "../store/actions/apis";
import { useAuth } from "../providers/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../store/actions/accountActions";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import signOut from "../assets/icons/signOut.png";
import Chart from "react-apexcharts";
import RedBullet from "../assets/icons/graph/red.png";
import BankDetail from "../assets/icons/Bank.png";
import g1 from "../assets/icons/graph/g1.png";
import g2 from "../assets/icons/graph/g2.png";
import g3 from "../assets/icons/graph/g3.png";
import g4 from "../assets/icons/graph/g4.png";
import { useSnackbar } from "notistack";
import FilePicker from "../utils/FilePicker";
import { setUserData } from "../store/actions/userActions";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "17px 14px",
  },
  editBtn: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: "red",
    },
  },
  AccordionDetails: {
    // paddingLeft: "20px"
  },
  [theme.breakpoints.down("sm")]: {
    AccordionDetails: {
      marginTop: "20px",
      paddingLeft: "0px",
    },
  },
}));

function Account({ deviceType, onBack, formMode = "edit", id }) {
  const [progress, setProgress] = React.useState(70);
  const responsive = responsiveStyles();
  const classes = useStyles();
  const [userId, setUserId] = useState();
  const [editView, setEditView] = useState(false);
  const [edit, setEdit] = useState(false);
  const history = useHistory();
  const auth = useAuth();
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [ifsc, setIfsc] = useState();
  const [accountHolder, setAccountHolder] = useState();
  const [leaderBoard, setLeaderBoard] = useState([{}, {}]);
  const [rera, setRera] = useState();
  const [aadhar, setAadhar] = useState();
  const [pan, setPan] = useState();
  const [profileImageFile, setProfileImageFile] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [accountType, setAccountType] = useState();
  const [viewProfilePicture, setViewProfilePicture] = useState(false);

  let [graphData, setGraphData] = useState({
    series: [
      {
        data: [
          [1350943200000, 0],
          [1351029600000, 0],
          [1351116000000, 0],
          [1351202400000, 0],
          [1351638000000, 0],
          [1351724400000, 0],
          [1351810800000, 0],
          [1352070000000, 0],
          [1352156400000, 0],
          [1352242800000, 0],
          [1352329200000, 0],
          [1352415600000, 0],
          [1352674800000, 0],
          [1352761200000, 0],
          [1352847600000, 0],
          [1352934000000, 0],
          [1353020400000, 0],
          [1353279600000, 0],
          [1353366000000, 0],
          [1353452400000, 0],
          [1353625200000, 0],
          [1353884400000, 0],
          [1353970800000, 0],
          [1354057200000, 0],
          [1354143600000, 0],
          [1354230000000, 0],
          [1354489200000, 0],
          [1354575600000, 0],
          [1354662000000, 0],
          [1354748400000, 0],
          [1354834800000, 0],
          [1355094000000, 0],
          [1355180400000, 0],
          [1355266800000, 0],
          [1355353200000, 0],
          [1355439600000, 0],
          [1355698800000, 0],
          [1355785200000, 0],
          [1355871600000, 0],
          [1355958000000, 0],
          [1356044400000, 0],
          [1356303600000, 0],
          [1356476400000, 0],
          [1356562800000, 0],
          [1356649200000, 0],
          [1356908400000, 0],
          [1357081200000, 0],
          [1357167600000, 0],
          [1357254000000, 0],
          [1357513200000, 0],
          [1357599600000, 0],
          [1357686000000, 0],
          [1357772400000, 0],
          [1357858800000, 0],
          [1358118000000, 0],
          [1358204400000, 0],
          [1358290800000, 0],
          [1358377200000, 0],
        ],
      },
    ],
    options: {
      stroke: {
        colors: ["#B907F8"],
        width: 2,
      },
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        // min: new Date('01 Mar 2012').getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        colors: ["#B907F8"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
  });

  let [graphData2, setGraphData2] = useState({
    series: [
      {
        data: [
          [1350943200000, 0],
          [1351029600000, 0],
          [1351116000000, 0],
          [1351202400000, 0],
          [1351638000000, 0],
          [1351724400000, 0],
          [1351810800000, 0],
          [1352070000000, 0],
          [1352156400000, 0],
          [1352242800000, 0],
          [1352329200000, 0],
          [1352415600000, 0],
          [1352674800000, 0],
          [1352761200000, 0],
          [1352847600000, 0],
          [1352934000000, 0],
          [1353020400000, 0],
          [1353279600000, 0],
          [1353366000000, 0],
          [1353452400000, 0],
          [1353625200000, 0],
          [1353884400000, 0],
          [1353970800000, 0],
          [1354057200000, 0],
          [1354143600000, 0],
          [1354230000000, 0],
          [1354489200000, 0],
          [1354575600000, 0],
          [1354662000000, 0],
          [1354748400000, 0],
          [1354834800000, 0],
          [1355094000000, 0],
          [1355180400000, 0],
          [1355266800000, 0],
          [1355353200000, 0],
          [1355439600000, 0],
          [1355698800000, 0],
          [1355785200000, 0],
          [1355871600000, 0],
          [1355958000000, 0],
          [1356044400000, 0],
          [1356303600000, 0],
          [1356476400000, 0],
          [1356562800000, 0],
          [1356649200000, 0],
          [1356908400000, 0],
          [1357081200000, 0],
          [1357167600000, 0],
          [1357254000000, 0],
          [1357513200000, 0],
          [1357599600000, 0],
          [1357686000000, 0],
          [1357772400000, 0],
          [1357858800000, 0],
          [1358118000000, 0],
          [1358204400000, 0],
          [1358290800000, 0],
          [1358377200000, 0],
        ],
      },
    ],
    options: {
      stroke: {
        colors: ["#0784F8"],
        width: 2,
      },
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        // min: new Date('01 Mar 2012').getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        colors: ["#0784F8"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
  });

  let [graphData3, setGraphData3] = useState({
    series: [
      {
        data: [
          [1350943200000, 0],
          [1351029600000, 0],
          [1351116000000, 0],
          [1351202400000, 0],
          [1351638000000, 0],
          [1351724400000, 0],
          [1351810800000, 0],
          [1352070000000, 0],
          [1352156400000, 0],
          [1352242800000, 0],
          [1352329200000, 0],
          [1352415600000, 0],
          [1352674800000, 0],
          [1352761200000, 0],
          [1352847600000, 0],
          [1352934000000, 0],
          [1353020400000, 0],
          [1353279600000, 0],
          [1353366000000, 0],
          [1353452400000, 0],
          [1353625200000, 0],
          [1353884400000, 0],
          [1353970800000, 0],
          [1354057200000, 0],
          [1354143600000, 0],
          [1354230000000, 0],
          [1354489200000, 0],
          [1354575600000, 0],
          [1354662000000, 0],
          [1354748400000, 0],
          [1354834800000, 0],
          [1355094000000, 0],
          [1355180400000, 0],
          [1355266800000, 0],
          [1355353200000, 0],
          [1355439600000, 0],
          [1355698800000, 0],
          [1355785200000, 0],
          [1355871600000, 0],
          [1355958000000, 0],
          [1356044400000, 0],
          [1356303600000, 0],
          [1356476400000, 0],
          [1356562800000, 0],
          [1356649200000, 0],
          [1356908400000, 0],
          [1357081200000, 0],
          [1357167600000, 0],
          [1357254000000, 0],
          [1357513200000, 0],
          [1357599600000, 0],
          [1357686000000, 0],
          [1357772400000, 0],
          [1357858800000, 0],
          [1358118000000, 0],
          [1358204400000, 0],
          [1358290800000, 0],
          [1358377200000, 0],
        ],
      },
    ],
    options: {
      stroke: {
        colors: ["#F6CE3F"],
        width: 2,
      },
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        // min: new Date('01 Mar 2012').getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        colors: ["#F6CE3F"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
  });

  let [graphData4, setGraphData4] = useState({
    series: [
      {
        data: [
          [1350943200000, 0],
          [1351029600000, 0],
          [1351116000000, 0],
          [1351202400000, 0],
          [1351638000000, 0],
          [1351724400000, 0],
          [1351810800000, 0],
          [1352070000000, 0],
          [1352156400000, 0],
          [1352242800000, 0],
          [1352329200000, 0],
          [1352415600000, 0],
          [1352674800000, 0],
          [1352761200000, 0],
          [1352847600000, 0],
          [1352934000000, 0],
          [1353020400000, 0],
          [1353279600000, 0],
          [1353366000000, 0],
          [1353452400000, 0],
          [1353625200000, 0],
          [1353884400000, 0],
          [1353970800000, 0],
          [1354057200000, 0],
          [1354143600000, 0],
          [1354230000000, 0],
          [1354489200000, 0],
          [1354575600000, 0],
          [1354662000000, 0],
          [1354748400000, 0],
          [1354834800000, 0],
          [1355094000000, 0],
          [1355180400000, 0],
          [1355266800000, 0],
          [1355353200000, 0],
          [1355439600000, 0],
          [1355698800000, 0],
          [1355785200000, 0],
          [1355871600000, 0],
          [1355958000000, 0],
          [1356044400000, 0],
          [1356303600000, 0],
          [1356476400000, 0],
          [1356562800000, 0],
          [1356649200000, 0],
          [1356908400000, 0],
          [1357081200000, 0],
          [1357167600000, 0],
          [1357254000000, 0],
          [1357513200000, 0],
          [1357599600000, 0],
          [1357686000000, 0],
          [1357772400000, 0],
          [1357858800000, 0],
          [1358118000000, 0],
          [1358204400000, 0],
          [1358290800000, 0],
          [1358377200000, 0],
        ],
      },
    ],
    options: {
      stroke: {
        colors: ["#41E1B1"],
        width: 2,
      },
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        // min: new Date('01 Mar 2012').getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        colors: ["#41E1B1"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
  });
  const uId = localStorage.getItem("userId");
  const notification = useSnackbar();
  const [renderBankDetails, setRenderBankDetails] = useState(true);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      number: "",
      dob: "",
      number: "",
      pan_number: "",
      aadhaar_number: "",
      address: "",
      rera_number: "",
      profile_pic: null,
      bank_name: "",
      ifsc_code: "",
      account_number: "",
      account_holder_name: "",
      account_type: "",
    },
    onSubmit: () => {
      if (mode == "add") {
        addPersonalDetails({
          user_id: auth.getUserId(),
          name: values.name,
          email: values.email,
          number: values.number,
          dob: values.dob,
          number: values.number,
          pan_number: values.pan_number,
          aadhaar_number: values.aadhaar_number,
          address: values.address,
          rera_number: values.rera_number,
          profile_pic: null,
          bank_name: values.bank_name,
          ifsc_code: values.ifsc_code,
          account_number: values.account_number,
          account_holder_name: values.account_holder_name,
          account_type: values.account_type,
        }).then((result) => {
          if (deviceType == "website") {
            onBack();
          } else history.goBack();
          notification.enqueueSnackbar("Profile Added Successfully", {
            variant: "success",
          });
        });
      } else {
        // if (uId) {
        //   const apiData = {
        //     name: values.name,
        //     email: values.email,
        //     number: values.number,
        //     dob: values.dob,
        //     number: values.number,
        //     pan_number: values.pan_number,
        //     aadhaar_number: values.aadhaar_number,
        //     address: values.address,
        //     rera_number: values.rera_number,
        //     profile_pic: null,
        //     bank_details: [
        //       {
        //         id: null,
        //         bank_name: values.bank_name,
        //         ifsc_code: values.ifsc_code,
        //         account_number: values.account_number,
        //         account_holder_name: values.account_holder_name,
        //         account_type: values.account_type,
        //       },
        //     ],
        //   };
        //   updatePersonalDetails(uId, apiData).then((result) => {
        //     if (deviceType == "website") {
        //       onBack();
        //     } else history.goBack();
        //     notification.enqueueSnackbar("Profile Updated Successfully", {
        //       variant: "success",
        //     });
        //   });
        // }
        if (formMode == "edit") {
          console.log("typeof profileImage: ", typeof profileImageFile);
          if (typeof profileImageFile == "object") {
            let fd = new FormData();
            fd.append("profile_pic", profileImageFile);
            updateProfilePicture(uId, fd).then((result) => {
              console.log("RESULT HERE: ", result);
            });
          }

          const apiData = {
            user_id: auth.getUserId(),
            name: values.name,
            email: values.email,
            number: values.number,
            dob: values.dob,
            number: values.number,
            pan_number: values.pan_number,
            aadhaar_number: values.aadhaar_number,
            address: values.address,
            rera_number: values.rera_number,
            profile_pic: null,
            bank_details: [
              {
                id: null,
                bank_name: values.bank_name,
                ifsc_code: values.ifsc_code,
                account_number: values.account_number,
                account_holder_name: values.account_holder_name,
                account_type: values.account_type,
              },
            ],
          };
          updatePersonalDetails(uId, apiData).then((result) => {
            if (deviceType == "website") {
              onBack();
            } else {
              getProfileData(auth.getUser().id).then((result) => {
                setName(result.data.name);
                setEmail(result.data.email);
                setNumber(result.data.number);
                setBankName(result.data.bank_details[0]?.bank_name);
                setIfsc(result.data.bank_details[0]?.ifsc_code);
                setAccountNumber(result.data.bank_details[0]?.account_number);
                setAccountHolder(
                  result.data.bank_details[0]?.account_holder_name
                );
                setLeaderBoard(result.data.leaderboard);
                setRera(result.data.rera_number);
                setProfileImage(result.data.profile_pic);

                dispatch(
                  setUserData({
                    key: localStorage.getItem("token"),
                    user: result.data,
                  })
                );

                setAadhar(result.data.aadhaar_number);
                setPan(result.data.pan_number);
                setAccountType(result.data.bank_details[0]?.account_type);
              });
              setEdit(false);
              // alert("else");
              // history.goBack();
            }
            notification.enqueueSnackbar("Profile Updated Successfully", {
              variant: "success",
            });
          });
        }
      }
    },
    // validationSchema: createLeadValidation,
  });
  const [mode, setMode] = useState("edit");

  function handleBankClick() {
    setRenderBankDetails(!renderBankDetails);
    console.log("renderBankDetails: " + renderBankDetails);
  }
  useEffect(() => {
    getProfileData(auth.getUser().id).then((result) => {
      setName(result.data.name);
      setEmail(result.data.email);
      setNumber(result.data.number);
      setBankName(result.data.bank_details[0]?.bank_name);
      setIfsc(result.data.bank_details[0]?.ifsc_code);
      setAccountNumber(result.data.bank_details[0]?.account_number);
      setAccountHolder(result.data.bank_details[0]?.account_holder_name);
      setLeaderBoard(result.data.leaderboard);
      setRera(result.data.rera_number);
      setProfileImage(result.data.profile_pic);
      setAadhar(result.data.aadhaar_number);
      setPan(result.data.pan_number);
      setAccountType(result.data.bank_details[0]?.account_type);
      dispatch(setProfileData(result.data));
    });
    if (uId) {
      setMode("edit");
      getProfileData(uId).then((result) => {
        setValues({
          name: result.data.name,
          email: result.data.email,
          number: result.data.number,
          dob: result.data.dob,
          number: result.data.number,
          pan_number: result.data.pan_number,
          aadhaar_number: result.data.aadhaar_number,
          address: result.data.address,
          rera_number: result.data.rera_number,
          profile_pic: result.data.profile_pic,
          bank_name: result.data.bank_details[0]?.bank_name,
          ifsc_code: result.data.bank_details[0]?.ifsc_code,
          account_number: result.data.bank_details[0]?.account_number,
          account_holder_name: result.data.bank_details[0]?.account_holder_name,
          account_type: result.data.bank_details[0]?.account_type,
        });
      });
    }
    if (formMode == "edit") {
      setMode("edit");
      getProfileData(uId).then((result) => {
        setValues({
          name: result.data.name,
          email: result.data.email,
          number: result.data.number,
          dob: result.data.dob,
          number: result.data.number,
          pan_number: result.data.pan_number,
          aadhaar_number: result.data.aadhaar_number,
          address: result.data.address,
          rera_number: result.data.rera_number,
          profile_pic: result.data.profile_pic,
          bank_name: result.data.bank_details[0]?.bank_name,
          ifsc_code: result.data.bank_details[0]?.ifsc_code,
          account_number: result.data.bank_details[0]?.account_number,
          account_holder_name: result.data.bank_details[0]?.account_holder_name,
          account_type: result.data.bank_details[0]?.account_type,
        });
      });
    }
  }, []);

  //Large Screen Form
  const ModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "#282827" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "20px" }}>Edit Profile</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setEdit(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ModalHeader2 = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "#282827" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "20px" }}>View Profile Picture</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setViewProfilePicture(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Modal
        width="300px"
        height="350px"
        headerComponent={ModalHeader2}
        status={viewProfilePicture}
      >
        <img style={{ width: "100%" }} src={auth.getUser().profile_pic} />
      </Modal>
      <Modal
        width="90%"
        height="70%"
        headerComponent={ModalHeader}
        status={edit}
      >
        <Grid container>
          <Grid md={12} item style={{ padding: "0px 220px" }}>
            <Box position="relative">
              <FilePicker
                type="component"
                fileHandler={(file, alt) => {
                  // console.log("FILE PICKED: ", URL.createObjectURL(file))
                  setProfileImageFile(file);
                  setProfileImage(URL.createObjectURL(file));
                }}
              >
                <Box style={{ paddingBottom: "8px" }}>
                  <Avatar
                    round={true}
                    size="100"
                    style={{ cursor: "pointer" }}
                    src={profileImage}
                    // src={auth.getUser().profile_pic}
                    name={auth.getUser().name}
                  />
                  {/* <Avatar
                  style={{
                    height: "85px",
                    width: "86px",
                    verticalAlign: "center",
                  }}
                /> */}
                </Box>
                <Box
                  style={{ position: "relative", bottom: "22px", left: "16px" }}
                >
                  <img
                    className="img-responsive center-block"
                    src={cameraIcon}
                  />
                </Box>
              </FilePicker>
            </Box>
          </Grid>

          <Grid spacing={2} container>
            <Grid md={6} item>
              <TextField
                id="name"
                name="name"
                fullWidth
                placeholder="Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
                helperText={touched.name && errors.name}
              />
            </Grid>

            <Grid md={6} item>
              <TextField
                name="bank_name"
                fullWidth
                placeholder="Bank Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bank_name}
                error={touched.bank_name && errors.bank_name}
                helperText={touched.bank_name && errors.bank_name}
              />
            </Grid>

            <Grid md={6} item>
              <TextField
                fullWidth
                id="outlined-password-input"
                name="email"
                placeholder="Email"
                value={values.email}
                type="email"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid md={6} item>
              <TextField
                id="account_number"
                name="account_number"
                fullWidth
                placeholder="account_number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_number}
                error={touched.account_number && errors.account_number}
                helperText={touched.account_number && errors.account_number}
              />
            </Grid>

            <Grid xs={6} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog"
                  disableFuture
                  // label="Date picker dialog"
                  format="DD-MM-YYYY"
                  // value={values.dob}
                  onChange={(date) => {
                    console.log(
                      "DOB DATE SELECTED: ",
                      moment(date).format("DD-MMM-YYYY")
                    );
                    // setFieldValue('dob', moment(date).format('YYYY-MM-DD'))
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: `datePicker1`,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid md={6} item>
              <TextField
                id="account_holder_name"
                name="account_holder_name"
                fullWidth
                placeholder="Account Holder Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_holder_name}
                error={
                  touched.account_holder_name && errors.account_holder_name
                }
                helperText={
                  touched.account_holder_name && errors.account_holder_name
                }
              />
            </Grid>

            <Grid md={6} item>
              <TextField
                fullWidth
                id="number"
                name="number"
                placeholder="Phone"
                value={values.number}
                type="number"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.number && errors.number}
                helperText={touched.number && errors.number}
              />
            </Grid>

            <Grid md={6} item>
              <TextField
                id="ifsc_code"
                name="ifsc_code"
                fullWidth
                placeholder="IFSC Code"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ifsc_code}
                error={touched.ifsc_code && errors.ifsc_code}
                helperText={touched.ifsc_code && errors.ifsc_code}
              />
            </Grid>

            <Grid md={6} item>
              <TextField
                id="pan_number"
                name="pan_number"
                fullWidth
                placeholder="pan_number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pan_number}
                error={touched.pan_number && errors.pan_number}
                helperText={touched.pan_number && errors.pan_number}
              />
            </Grid>

            <Grid md={6} item>
              <TextField
                id="aadhaar_number"
                name="aadhaar_number"
                fullWidth
                placeholder="Aadhar Number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.aadhaar_number}
                error={touched.aadhaar_number && errors.aadhaar_number}
                helperText={touched.aadhaar_number && errors.aadhaar_number}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                id="rera_number"
                name="rera_number"
                fullWidth
                placeholder="Rera Number"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.rera_number}
                error={touched.rera_number && errors.rera_number}
                helperText={touched.rera_number && errors.rera_number}
              />
            </Grid>

            <Grid md={6} item>
              <Select
                label="Account Type"
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Account Type"
                name="account_type"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.account_type}
                error={touched.account_type && errors.account_type}
              >
                <MenuItem value="none" disabled>
                  Account Type
                </MenuItem>
                <MenuItem value="Savings">Savings</MenuItem>
                <MenuItem value="Current">Current</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.account_type && errors.account_type}
              </FormHelperText>
            </Grid>
            <Grid item md={12}>
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Box flex={1}>
                  <Button
                    fullWidth
                    onClick={() => {
                      setEdit(false);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Box>
                <Box style={{ marginLeft: "10px" }} flex={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={submitForm}
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Box className={responsive.web}>
        <Box
          style={{ marginBottom: "10px", fontSize: "18px", fontWeight: "700" }}
        >
          Profile
        </Box>
        <Card style={{ padding: "10px", marginBottom: "9px" }}>
          <Box display="flex" justifyContent="space-between" padding="10px 8px">
            <Grid container direction="row" md={6} item>
              <Grid
                container
                direction="row"
                md={12}
                justifyContent={"space-evenly"}
                item
              >
                <Grid container direction="row" md={2} item>
                  <Box>
                    <Avatar
                      onClick={() => setViewProfilePicture(true)}
                      round={true}
                      size="100"
                      style={{ cursor: "pointer" }}
                      src={auth.getUser().profile_pic}
                      name={auth.getUser().name}
                    />
                  </Box>
                </Grid>
                <Grid container direction="row" md={4} item>
                  <Box marginLeft="10px" marginTop="13px">
                    <Box
                      fontWeight="600"
                      style={{
                        textTransform: "capitalize",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      fontSize="15px"
                      paddingTop="5px"
                    >
                      {name}
                    </Box>
                    <Box color="#565656" fontSize="12px" paddingTop="5px">
                      {email}
                    </Box>
                    <Box color="#565656" fontSize="12px" paddingTop="5px">
                      {number}
                    </Box>
                  </Box>
                </Grid>
                <Grid container direction="row" md={6}>
                  <Box>
                    <Box
                      color="#565656"
                      marginTop="13px"
                      fontSize="12px"
                      paddingTop="5px"
                    >
                      <Grid container direction="row" md={12}>
                        <Grid container direction="row" md={6} item>
                          Rera Number:
                        </Grid>
                        <Grid container direction="row" md={6} item>
                          {rera ? rera : "-"}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box color="#565656" fontSize="12px" paddingTop="5px">
                      <Grid container direction="row" md={12} item>
                        <Grid container direction="row" md={6} item>
                          Aadhar Number:
                        </Grid>
                        <Grid container direction="row" md={5} item>
                          {aadhar ? aadhar : "-"}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      color="#565656"
                      style={{ textTransform: "uppercase" }}
                      fontSize="12px"
                      paddingTop="5px"
                    >
                      <Grid container direction="row" md={12} item>
                        <Grid container direction="row" md={6} item>
                          PAN:
                        </Grid>
                        <Grid container direction="row" md={6} item>
                          {pan ? pan : "-"}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" md={6} item>
              <Box>
                {renderBankDetails ? (
                  <>
                    <Box>
                      <Grid container direction="row" md={12} item>
                        <Grid container direction="row" md={9} item>
                          <Button
                            onClick={handleBankClick}
                            style={{
                              borderColor: "#8A9EBC",
                              width: "700px",
                              borderRadius: "22px",
                              backgroundColor: "#F6F6F6",
                            }}
                            variant="outlined"
                          >
                            <span
                              style={{ marginTop: "-2px", marginRight: "5px" }}
                            >
                              <img src={BankIcon} />
                            </span>
                            <Grid container direction="row" md={6} item>
                              <span
                                style={{ color: "#000", paddingLeft: "0px" }}
                              >
                                Bank Details
                              </span>
                            </Grid>
                            <Grid container direction="row" md={5} item></Grid>
                            <span style={{ right: "200%" }}>
                              <img src={ArrowDown} />
                            </span>
                          </Button>
                        </Grid>
                        <Grid container direction="row" md={2} item>
                          <Box
                            marginLeft="10px"
                            onClick={() => {
                              setEdit(true);
                            }}
                          >
                            <Button
                              style={{
                                borderColor: "#4A8EAB",
                                borderRadius: "22px",
                              }}
                              variant="outlined"
                            >
                              <span style={{ color: "#4A8EAB" }}>Edit</span>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : (
                  (bankName != null && (
                    <>
                      <Box>
                        <Grid container direction="row" md={12} item>
                          <Grid container direction="row" md={9} item>
                            <Button
                              onClick={handleBankClick}
                              style={{
                                borderColor: "#8A9EBC",
                                width: "700px",
                                borderRadius: "22px",
                                backgroundColor: "#F6F6F6",
                              }}
                              variant="outlined"
                            >
                              <span
                                style={{
                                  marginTop: "-40px",
                                  marginRight: "5px",
                                }}
                              >
                                <img src={BankIcon} />
                              </span>
                              <Grid container direction="row" md={6} item>
                                <span
                                  style={{
                                    color: "#000",
                                    paddingLeft: "0px",
                                    marginTop: "5px",
                                  }}
                                >
                                  Bank Details
                                </span>
                                <span>Bank Name : {bankName}</span>
                                <span>Account No: {accountNumber}</span>
                              </Grid>
                              <Grid container direction="row" md={5} item>
                                <span>Type : {accountType}</span>
                                <span>IFSC Code : {ifsc}</span>
                                <span>Acc Holder: {accountHolder}</span>
                              </Grid>
                              <span
                                style={{ right: "200%", marginTop: "-50px" }}
                              >
                                <img src={ArrowUp} />
                              </span>
                            </Button>
                          </Grid>
                          <Grid container direction="row" md={2} item>
                            <Box
                              marginLeft="10px"
                              onClick={() => {
                                setEdit(true);
                              }}
                            >
                              <Button
                                style={{
                                  borderColor: "#4A8EAB",
                                  borderRadius: "22px",
                                }}
                                variant="outlined"
                              >
                                <span style={{ color: "#4A8EAB" }}>Edit</span>
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )) ||
                  (bankName == null && (
                    <>
                      <Box>
                        <Grid container direction="row" md={12} item>
                          <Grid container direction="row" md={9} item>
                            <Button
                              onClick={handleBankClick}
                              style={{
                                borderColor: "#8A9EBC",
                                width: "700px",
                                borderRadius: "22px",
                                backgroundColor: "#F6F6F6",
                              }}
                              variant="outlined"
                            >
                              <span
                                style={{
                                  marginTop: "-2px",
                                  marginRight: "5px",
                                }}
                              >
                                <img src={BankIcon} />
                              </span>
                              <Grid container direction="row" md={6} item>
                                <span
                                  style={{ color: "#000", paddingLeft: "0px" }}
                                >
                                  Bank Details
                                </span>
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                md={5}
                                item
                              ></Grid>
                              <span style={{ right: "200%" }}>
                                <img src={ArrowDown} />
                              </span>
                            </Button>
                          </Grid>
                          <Grid container direction="row" md={2} item>
                            <Box
                              marginLeft="10px"
                              onClick={() => {
                                setEdit(true);
                              }}
                            >
                              <Button
                                style={{
                                  borderColor: "#4A8EAB",
                                  borderRadius: "22px",
                                }}
                                variant="outlined"
                              >
                                <span style={{ color: "#4A8EAB" }}>Edit</span>
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ))
                )}
              </Box>
            </Grid>
          </Box>
        </Card>
        {/* <Card>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box fontWeight="600" paddingLeft="1%" paddingTop="10px">
              My Performance
            </Box>
            <Box display="flex" alignItems="center">
              <Box fontWeight="600" fontSize="12px">
                LAST 30 DAYS
              </Box>
              <IconButton>
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" padding="10px 0px">
            <Grid style={{ opacity: "0.5" }} container direction="row" md={12}>
              <Grid container direction="row" md={3} item>
                <Box
                  style={{
                    backgroundColor: "#EBF1FA",
                    margin: "2px",
                    borderRadius: "5px",
                    margin: "0 10px",
                    padding: "2%",
                  }}
                >
                  <Box alignItems="center" display="flex">
                    <Typography style={{ fontWeight: "600", color: "#565656" }}>
                      $60K
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "10px",
                        paddingLeft: "8px",
                        fontWeight: "600",
                        color: "#565656",
                      }}
                    >
                      COMMISSION EARNED
                    </Typography>
                  </Box>

                  <Box>
                    <Box display="flex">
                      <Box>
                        <Chart
                          width="100%"
                          type="area"
                          options={graphData.options}
                          series={graphData.series}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid container direction="row" md={3} item>
                <Box
                  style={{
                    backgroundColor: "#EBF1FA",

                    margin: "0 10px",
                    borderRadius: "5px",
                    padding: "2%",
                  }}
                >
                  <Box alignItems="center" display="flex">
                    <Typography style={{ fontWeight: "600", color: "#565656" }}>
                      07
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "10px",
                        paddingLeft: "8px",
                        fontWeight: "600",
                        color: "#565656",
                      }}
                    >
                      SALES DONE
                    </Typography>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Box>
                        <Chart
                          width="100%"
                          type="area"
                          options={graphData2.options}
                          series={graphData2.series}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid container direction="row" md={3} item>
                <Box
                  style={{
                    backgroundColor: "#EBF1FA",
                    margin: "0 10px",
                    borderRadius: "5px",
                    padding: "2%",
                  }}
                >
                  <Box alignItems="center" display="flex">
                    <Typography style={{ fontWeight: "600", color: "#565656" }}>
                      17
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "10px",
                        paddingLeft: "8px",
                        fontWeight: "600",
                        color: "#565656",
                      }}
                    >
                      PRESENTAION DONE
                    </Typography>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Box>
                        <Chart
                          width="100%"
                          type="area"
                          options={graphData3.options}
                          series={graphData3.series}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid container direction="row" md={3} item>
                <Box
                  style={{
                    backgroundColor: "#EBF1FA",

                    margin: "0 10px",
                    borderRadius: "5px",
                    padding: "2%",
                  }}
                >
                  <Box alignItems="center" display="flex">
                    <Typography style={{ fontWeight: "600", color: "#565656" }}>
                      25
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "10px",
                        paddingLeft: "8px",
                        fontWeight: "600",
                        color: "#565656",
                      }}
                    >
                      NEW LEAD ADDED
                    </Typography>
                  </Box>
                  <Box>
                    <Box display="flex">
                      <Box>
                        <Chart
                          width="100%"
                          type="area"
                          options={graphData4.options}
                          series={graphData4.series}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card> */}

        <Card
          style={{ padding: "10px", marginTop: "9px", marginBottom: "9px" }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box fontWeight="600">Revenue Leaderboard</Box>
            {/* {leaderBoard.length > 0 ? (
              
            ) : (
              <Box
                style={{ margin: "5px", textAlign: "center", width: "100%" }}
                fontWeight="600"
              >
                No Records
              </Box>
            )} */}

            {/* <Box display="flex" alignItems="center">
              <Box fontWeight="600" fontSize="12px">
                LAST 30 DAYS
              </Box>
              <IconButton>
                <ExpandMoreIcon />
              </IconButton>
            </Box> */}
          </Box>
          {/* {leaderBoard.length > 0 ? (
              
            ) : (
              <Box
                style={{ margin: "5px", textAlign: "center", width: "100%" }}
                fontWeight="600"
              >
                No Records
              </Box>
            )} */}
          {leaderBoard.length <= 0 && (
            <Box
              style={{ margin: "5px", textAlign: "center", width: "100%" }}
              fontWeight="600"
            >
              No Records
            </Box>
          )}
          {leaderBoard.length > 0 &&
            leaderBoard.map((leader) => {
              return (
                <Box
                  style={
                    leader.self == true
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "7px 12px",
                          margin: "5px",
                          borderRadius: "8px",
                          backgroundColor: "#8B7862",
                          color: "#ffffff",
                        }
                      : {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "7px 12px",
                          margin: "5px",
                          borderRadius: "8px",
                          backgroundColor: "#FFF0D7",
                        }
                  }
                >
                  <Box display="flex" alignItems="center">
                    <Box>0 {leader.rank}.</Box>
                    <Box padding="5px 10px 0 6px">
                      <Avatar
                        round={true}
                        size="40"
                        style={{ cursor: "pointer" }}
                        src={leader.profile_pic}
                        name={leader.name}
                      />
                    </Box>
                    <Box>{leader.name}</Box>
                  </Box>
                  {leader.self == true ? (
                    <Box
                      style={{
                        color: "#000000",
                        padding: "4px 16px",
                        borderRadius: "14px",
                        backgroundColor: "#FFFFFF",
                        fontSize: "10px",
                      }}
                    >
                      &#8377; {leader.total_commission}
                    </Box>
                  ) : (
                    <Box
                      style={{
                        color: "white",
                        padding: "4px 16px",
                        borderRadius: "14px",
                        backgroundColor: "#534C45",
                        fontSize: "10px",
                      }}
                    >
                      &#8377; {leader.total_commission}
                    </Box>
                  )}
                </Box>
              );
            })}

          {/* <Box
            style={{
              margin: "5px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "7px 12px",
              borderRadius: "8px",
              backgroundColor: "#FFF0D7",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>02.</Box>
              <Box padding="0 10px 0 6px">
                <Avatar />
              </Box>
              <Box>Name1</Box>
            </Box>
            <Box
              style={{
                color: "white",
                padding: "4px 16px",
                borderRadius: "14px",
                backgroundColor: "#534C45",
                fontSize: "10px",
              }}
            >
              $ 70000
            </Box>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "7px 12px",
              borderRadius: "8px",
              backgroundColor: "#FFF0D7",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>03.</Box>
              <Box padding="0 10px 0 6px">
                <Avatar />
              </Box>
              <Box>You</Box>
            </Box>
            <Box
              style={{
                color: "white",
                padding: "4px 16px",
                borderRadius: "14px",
                backgroundColor: "#534C45",
                fontSize: "10px",
              }}
            >
              $ 70000
            </Box>
          </Box>

          <Box
            style={{
              margin: "5px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "7px 12px",
              borderRadius: "8px",
              backgroundColor: "#FFF0D7",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>04.</Box>
              <Box padding="0 10px 0 6px">
                <Avatar />
              </Box>
              <Box>Name1</Box>
            </Box>
            <Box
              style={{
                color: "white",
                padding: "4px 16px",
                borderRadius: "14px",
                backgroundColor: "#534C45",
                fontSize: "10px",
              }}
            >
              $ 70000
            </Box>
          </Box> */}
          {leaderBoard.length > 6 && (
            <Box>
              <Box
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  padding: "12px 0",
                  cursor: "pointer",
                }}
              >
                View More
              </Box>
            </Box>
          )}
        </Card>
        <Card style={{ marginTop: "10px" }}>
          <Box
            style={{
              display: "flex",
              padding: "15px 10px",
              backgroundColor: "#FFF0D7",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              auth.logout();
            }}
          >
            <Box>
              <img src={signOut} />
            </Box>
            <Box style={{ textAlign: "center", paddingLeft: "10px" }}>
              SignOut
            </Box>
          </Box>
        </Card>
      </Box>
      <Box className={responsive.app}>
        <Box
          style={{ backgroundColor: "#F1E8DA", padding: "10px" }}
          className="app-dashboard"
        >
          <Card style={{ padding: "10px", marginBottom: "9px" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <IconButton size="small">
                    <ArrowBackIosIcon
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                  </IconButton>
                </Box>
                <Box fontSize="20px" fontWeight="600">
                  Profile
                </Box>
              </Box>
              <Box
                onClick={() => {
                  history.push("/account-edit");
                }}
              >
                <Button variant="outlined">Edit</Button>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" padding="10px 8px">
              <Box>
                <Avatar
                  round={true}
                  size="40"
                  style={{ cursor: "pointer" }}
                  src={auth.getUser().profile_pic}
                  name={auth.getUser().name}
                />
              </Box>
              <Box paddingLeft="15px">
                <Box fontWeight="600" fontSize="15px">
                  {name}
                </Box>
                <Box color="#565656" fontSize="12px">
                  {email}
                </Box>
                <Box color="#565656" fontSize="12px">
                  {number}
                </Box>
              </Box>
            </Box>

            <Box padding="5px">
              <Box display="flex" paddingBottom="6px">
                <Box>
                  <img src={BankDetail} />
                </Box>
                <Box paddingLeft="10px">Bank Details</Box>
              </Box>

              <Box color="#565656" fontSize="12px">
                <Box style={{ display: "flex" }}>
                  <Box flex={1}>Bank Name</Box>
                  <Box flex={1}>:</Box>
                  <Box flex={1}>{bankName}</Box>
                </Box>

                <Box style={{ display: "flex" }}>
                  <Box flex={1}>Acc No</Box>
                  <Box flex={1}>:</Box>
                  <Box flex={1}>{accountNumber}</Box>
                </Box>

                <Box display="flex">
                  <Box flex={1}>Acc Holder</Box>
                  <Box flex={1}>:</Box>
                  <Box flex={1}>{accountHolder}</Box>
                </Box>

                <Box display="flex">
                  <Box flex={1}>IFSC Code</Box>
                  <Box flex={1}>:</Box>
                  <Box flex={1}>{ifsc}</Box>
                </Box>

                <Box display="flex">
                  <Box flex={1}>Type</Box>
                  <Box flex={1}>:</Box>
                  <Box flex={1}>{accountType}</Box>
                </Box>
              </Box>
            </Box>

            {/* <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box fontWeight="600">My Performance</Box>
          </Box>

          <Box>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box alignItems="center" display="flex">
                  <Typography style={{ fontWeight: "600", color: "#565656" }}>
                    60K
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "10px",
                      paddingLeft: "8px",
                      fontWeight: "600",
                      color: "#565656",
                    }}
                  >
                    COMMISSION EARNED
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box>
                  <Box display="flex">
                    <Box>
                      <Chart
                        width="100%"
                        type="area"
                        options={graphData.options}
                        series={graphData.series}
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box padding="8px 0">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box alignItems="center" display="flex">
                  <Typography style={{ fontWeight: "600", color: "#565656" }}>
                    07
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "10px",
                      paddingLeft: "8px",
                      fontWeight: "600",
                      color: "#565656",
                    }}
                  >
                    SALES DONE
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box>
                  
                  <Box display="flex">
                    <Box>
                      <Chart
                        width="100%"
                        type="area"
                        options={graphData2.options}
                        series={graphData2.series}
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box alignItems="center" display="flex">
                  <Typography style={{ fontWeight: "600", color: "#565656" }}>
                    17
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "10px",
                      paddingLeft: "8px",
                      fontWeight: "600",
                      color: "#565656",
                    }}
                  >
                    PRESENTAION DONE
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box>
                  <Box display="flex">
                    <Box>
                      <Chart
                        width="100%"
                        type="area"
                        options={graphData3.options}
                        series={graphData3.series}
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box padding="8px 0">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box alignItems="center" display="flex">
                  <Typography style={{ fontWeight: "600", color: "#565656" }}>
                    25
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "10px",
                      paddingLeft: "8px",
                      fontWeight: "600",
                      color: "#565656",
                    }}
                  >
                    NEW LEAD ADDED
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box>
                  <Box display="flex">
                    <Box>
                      <Chart
                        width="100%"
                        type="area"
                        options={graphData4.options}
                        series={graphData4.series}
                      />
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box> */}
          </Card>

          <Card style={{ padding: "10px" }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box marginBottom="10px" fontWeight="600">
                Revenue Leaderboard
              </Box>
              {/* <Box display="flex" alignItems="center">
              <Box fontWeight="600" fontSize="12px">
                LAST 30 DAYS
              </Box>
              <IconButton>
                <ExpandMoreIcon />
              </IconButton>
            </Box> */}
            </Box>

            {leaderBoard.length > 0 &&
              leaderBoard.map((leader) => {
                return (
                  <Box
                    style={
                      leader.self == true
                        ? {
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "7px 12px",
                            borderRadius: "8px",
                            color: "white",
                            backgroundColor: "#8B7862",
                          }
                        : {
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "7px 12px",
                            borderRadius: "8px",
                            backgroundColor: "#FFF0D7",
                          }
                    }
                  >
                    <Box display="flex" alignItems="center">
                      <Box>0{leader.rank}.</Box>
                      <Box padding="0 10px 0 6px">
                        <Avatar
                          round={true}
                          size="40"
                          style={{ cursor: "pointer" }}
                          src={leader.profile_pic}
                          name={leader.name}
                        />
                      </Box>
                      <Box>{leader.name}</Box>
                    </Box>
                    {leader.self == true ? (
                      <Box
                        style={{
                          color: "black",
                          padding: "4px 16px",
                          borderRadius: "14px",
                          backgroundColor: "white",
                          fontSize: "10px",
                        }}
                      >
                        &#8377; {leader.total_commission}
                      </Box>
                    ) : (
                      <Box
                        style={{
                          color: "white",
                          padding: "4px 16px",
                          borderRadius: "14px",
                          backgroundColor: "#534C45",
                          fontSize: "10px",
                        }}
                      >
                        &#8377; {leader.total_commission}
                      </Box>
                    )}
                  </Box>
                );
              })}
            {/* <Box
            style={{
              margin: "5px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "7px 12px",
              borderRadius: "8px",
              backgroundColor: "#FFF0D7",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>02.</Box>
              <Box padding="0 10px 0 6px">
                <Avatar />
              </Box>
              <Box>Name1</Box>
            </Box>
            <Box
              style={{
                color: "white",
                padding: "4px 16px",
                borderRadius: "14px",
                backgroundColor: "#534C45",
                fontSize: "10px",
              }}
            >
              $ 70000
            </Box>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "7px 12px",
              borderRadius: "8px",
              backgroundColor: "#FFF0D7",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>03.</Box>
              <Box padding="0 10px 0 6px">
                <Avatar />
              </Box>
              <Box>You</Box>
            </Box>
            <Box
              style={{
                color: "white",
                padding: "4px 16px",
                borderRadius: "14px",
                backgroundColor: "#534C45",
                fontSize: "10px",
              }}
            >
              $ 70000
            </Box>
          </Box>

          <Box
            style={{
              margin: "5px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "7px 12px",
              borderRadius: "8px",
              backgroundColor: "#FFF0D7",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box>04.</Box>
              <Box padding="0 10px 0 6px">
                <Avatar />
              </Box>
              <Box>Name1</Box>
            </Box>
            <Box
              style={{
                color: "white",
                padding: "4px 16px",
                borderRadius: "14px",
                backgroundColor: "#534C45",
                fontSize: "10px",
              }}
            >
              $ 70000
            </Box>
          </Box> */}

            <Box>
              {leaderBoard.length > 6 && (
                <Box
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    padding: "12px 0",
                    cursor: "pointer",
                  }}
                >
                  View More
                </Box>
              )}
            </Box>
          </Card>
          <Card style={{ marginTop: "9px" }}>
            <Box
              style={{
                display: "flex",
                padding: "15px 10px",
                backgroundColor: "#FFF0D7",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                auth.logout();
              }}
            >
              <Box>
                <img src={signOut} />
              </Box>
              <Box style={{ textAlign: "center", paddingLeft: "10px" }}>
                SignOut
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default Account;
