import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import {
  Tabs,
  Tab,
  Switch,
  Button,
  TextField,
  OutlinedInput,
  Select,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Box, Grid, MenuItem } from "@mui/material";
import { TabContext } from "@material-ui/lab";
import TabTable from "../Table/index";
import Modal from "../../component/Modal";
import clsx from "clsx";
import HotSqIcon from "../../assets/icons/leadDetail/HotSqIcon.png";
import WarmSqIcon from "../../assets/icons/leadDetail/WarmSqIcon.png";
import CoolSqIcon from "../../assets/icons/leadDetail/CoolSqIcon.png";
import ColdSqIcon from "../../assets/icons/leadDetail/ColdSqIcon.png";
import UploadIcon from "../../assets/images/upload-lead.svg";
import { Close } from "@material-ui/icons";
import { useFormik } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createLeadWebValidation } from "../../validations";
import { useDispatch, useSelector } from "react-redux";
import { getLeads } from "../../store/actions/dashboardActions";
import CreateLead from "../../screens/CreateLead";
import { uploadBulkLeads } from "../../store/actions/apis";
import { useSnackbar } from "notistack";
import Clear from "../../assets/icons/graph/clear.png";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "10px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "1px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "60px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function TabView({ updateSalesFlowModal, ...props }) {
  const classes = useStyles();
  const { setExpand = () => {}, expand = false } = props;
  // const {
  //   open = () => { },
  //   newLeadList = null,
  //   inProgressList = null,
  //   lostLead = null,
  //   convertedLead = null,
  //   sharedLead = null,
  //   isBusyFetching = false,
  //   allLead = null,
  //   getAllLead = () => { },
  //   getSharedLead = () => { },
  //   getConvertedLead = () => { },
  //   getLeads = () => { },
  //   getInProgressLead = () => { },
  //   getLostLead = () => { },
  //   token,
  //   setShare = () => { },
  //   getCompletedLeadList = () => { },
  //   completedLead,
  //   convertedCount = 0,
  //   sharedCount = 0,
  //   newCount = 0,
  //   allCount = 0,
  //   lostCount = 0,
  //   inprogreeCount = 0,
  //   completedCount = 0,
  //   setExpand = () => { },
  //   expand = false,
  // } = props;

  const notification = useSnackbar();
  const [value, setValue] = React.useState(0);
  const [clear, setClear] = React.useState(false);
  const [modal, setModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [filter, setFilter] = useState("In progress");
  const dispatch = useDispatch();
  const { leads } = useSelector((state) => state.dashboard);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const { values, touched, errors, handleSubmit, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        address: "",
        martial_status: "",
        occupation: "",
        vehicle: "",
        lead_status_source: "",
        lead_status_category: "",
        properties: "",
        followUp: "",
        lead_call_status: "",
        remark: "",
        follow_up: "",
        vehicle_owned: "",
        last_call_status: "",
        client_category: "",
        lead_status: 0,
      },
      onSubmit: () => {},
      validationSchema: createLeadWebValidation,
    });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getLeads({ status: filter }));
  }, [filter]);

  const [selfLeadStatus, setSelfLeadStatus] = useState(false);

  const lablewithbuble = (
    // convertedLead && convertedLead.notify && convertedLead.notify > 0 ? (
    <span className={`spanStyle`}>CONVERTED</span>
  );
  // ) : (
  //     <span className={`spanStyle`}>Converted </span>
  //   );
  const new_lead = (
    // newLeadList && newLeadList.notify && newLeadList.notify > 0 ? (
    <span className={`spanStyle`}>NEW LEADS</span>
  );
  // ) : (
  //     <span className={`spanStyle`}>New Leads</span>
  //   );
  const inprogress = (
    // inProgressList && inProgressList.notify && inProgressList.notify > 0 ? (
    <span className={`spanStyle`}>IN PROGRESS</span>
  );
  // ) : (
  //     <span className={`spanStyle`}>In progress</span>
  //   );
  const sales_done = (
    // sharedLead && sharedLead.notify && sharedLead.notify > 0 ? (
    <span className={`spanStyle`}>SALES DONE</span>
  );
  // ) : (
  //     <span className={`spanStyle`}>shared</span>
  //   );
  const lost_lead = (
    // lostLead && lostLead.notify && lostLead.notify > 0 ? (
    <span className={`spanStyle`}>LOST</span>
  );
  // ) : (
  //     <span className={`spanStyle`}>Lost</span>
  //   );
  const completed = (
    // completedLead && completedLead.notify && completedLead.notify > 0 ? (
    <span className={`spanStyle`}>Completed</span>
  );
  // ) : (
  //     <span className={`spanStyle`}>Completed</span>
  //   );
  const all_lead = <span className={`spanStyle`}>All Leads </span>;

  // const [allLeadList, setAllLeadsList] = useState(allLead);
  // const [newLeadListState, setNewLeadListState] = useState(newLeadList);

  const ModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "#282827" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "20px" }}>Create New Lead</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ImportModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "#282827" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ fontSize: "20px" }}>Bulk Upload</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setImportModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const tableRef = useRef();

  return (
    <>
      <Modal
        width="90%"
        height="500"
        headerComponent={ModalHeader}
        status={modal}
      >
        <CreateLead
          deviceType="website"
          onBack={() => {
            setModal(false);
            dispatch(getLeads({ status: filter }));
          }}
        />
      </Modal>
      <Modal
        width="90%"
        height="450px"
        headerComponent={ImportModalHeader}
        status={importModal}
        closeBtnText="CANCEL"
        closeHandler={() => setImportModal(false)}
        saveBtnText="IMPORT"
        saveHandler={() => {
          document.getElementById("upload-file").click();
        }}
      >
        <Box textAlign="center">
          <input
            style={{ display: "none" }}
            onChange={(event) => {
              uploadBulkLeads({
                apiBody: {
                  file_uploaded: event.target.files[0],
                },
                headers: {
                  "Content-Type":
                    "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
                },
              })
                .then((result) => {
                  setImportModal(false);
                  notification.enqueueSnackbar("Lead Uploaded Successfully", {
                    variant: "success",
                  });
                })
                .catch((er) => {
                  notification.enqueueSnackbar("something went wrong", {
                    variant: "error",
                  });
                });
            }}
            id="upload-file"
            type="file"
          />
          <img src={UploadIcon} />
        </Box>
        <Box
          textAlign="center"
          fontSize="24px"
          fontWeight="bold"
          color="#8B7862"
        >
          Select CSV or Excel file to import
        </Box>
        <Box fontSize="12px" textAlign="center">
          Excel files are recommended as fields formatting is automatic.
        </Box>
      </Modal>
      <div className={`tab-component_continer`}>
        <div className={`tab-header_section`}>
          <div className={`tab-heading-text`}>
            {all_lead}
            {/* (53 Leads) */}
          </div>
          <div
            className={`tab-heading-button d-flex flex-row align-items-center`}
          >
            <div
              style={{
                position: "relative",
                zIndex: "1",
              }}
            >
              <button
                // onClick={() => {
                //   setGuidedTour((prev) => ({ ...prev, currentStep: 3 }));
                //   open();
                // }}
                onClick={() => {
                  console.log("filter cleared");
                  setClear(true);
                }}
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  fontSize: "12px",
                  backgroundColor: "white",
                  color: "#8B7862",
                  cursor: "pointer",
                  // margin:"auto"
                }}
              >
                <span>
                  <img src={Clear} />
                </span>
                &nbsp; Clear Filter
              </button>
              <button
                // onClick={() => {
                //   setGuidedTour((prev) => ({ ...prev, currentStep: 3 }));
                //   open();
                // }}
                onClick={() => {
                  setImportModal(true);
                }}
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  fontSize: "12px",
                  backgroundColor: "white",
                  border: "1px solid #8B7862",
                  borderRadius: "7px",
                  color: "#8B7862",
                  cursor: "pointer",
                  // margin:"auto"
                }}
              >
                Bulk Upload
              </button>
              &nbsp;&nbsp; &nbsp;
              <button
                // onClick={() => {
                //   setGuidedTour((prev) => ({ ...prev, currentStep: 3 }));
                //   open();
                // }}
                onClick={() => {
                  setModal(true);
                }}
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  cursor: "pointer",
                  fontSize: "12px",
                  backgroundColor: "#8B7862",
                  borderRadius: "7px",
                  // margin:"auto"
                }}
              >
                Add New Lead +
              </button>
              &nbsp;&nbsp; &nbsp;
              <button
                // onClick={() => {
                //   setGuidedTour((prev) => ({ ...prev, currentStep: 3 }));
                //   open();
                // }}
                onClick={() => {
                  tableRef.current.downloadResult();
                  console.log("refrence: ", tableRef.current);
                }}
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                  cursor: "pointer",
                  fontSize: "12px",
                  backgroundColor: "#8B7862",
                  borderRadius: "7px",
                  // margin:"auto"
                }}
              >
                Download
              </button>
              &nbsp;&nbsp; &nbsp;
              <img
                onClick={() => setExpand(!expand)}
                style={{ cursor: "pointer", marginBottom: "0%" }}
                src={require("../../assets/images/Vector.png")}
                height="32"
                // marginBottom="-3%"
                alt="expand"
              />
            </div>
          </div>
        </div>
        <div className={`tab-body`}>
          <TabContext value={value}>
            <div
              style={{
                position: "relative",
                fontFamily: "Proxima Nova",
                fontWeight: "bold",
              }}
            >
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                fontFamily="Proxima Nova"
                fontWeight="bold"
                value={value}
                classes={{ flexContainer: classes.flexContainer }}
                onChange={(event, newValue) => {
                  switch (newValue) {
                    case 1:
                      setFilter("new");
                      break;
                    case 2:
                      setFilter("converted");
                      break;
                    case 3:
                      setFilter("Sales Done");
                      break;
                    case 4:
                      setFilter("lost");
                      break;
                    default:
                      setFilter("In progress");
                  }
                  setValue(newValue);
                }}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{ fontWeight: "bold" }}
                  label="In Progress"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{ fontWeight: "bold" }}
                  label="New Lead"
                  {...a11yProps(1)}
                />
                <Tab
                  style={{ fontWeight: "bold" }}
                  label="Converted Lead"
                  {...a11yProps(2)}
                />
                <Tab
                  style={{ fontWeight: "bold" }}
                  label="Sales Done"
                  {...a11yProps(3)}
                />
                <Tab
                  style={{ fontWeight: "bold" }}
                  label="Lost"
                  {...a11yProps(4)}
                />
              </Tabs>
            </div>
            {/* <TabPanel value={0}> */}

            <TabTable
              filter={filter}
              ref={tableRef}
              updateSalesFlowModal={updateSalesFlowModal}
              tableName={"header"}
              data={leads.results}
              setClear={(bool) => setClear(bool)}
              clear={clear}
              // isBusyFetching={isBusyFetching}
              // // getData={(t, u) => {
              // //   getAllLead(t, u)
              // // }}
              // token={token}
              // searchString={""}
            />
            {/* </TabPanel> */}
          </TabContext>
        </div>
      </div>
    </>
  );
}

export default TabView;
