import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store';
import AuthProvider from './providers/AuthProvider';

import theme from './theme';
import { ThemeProvider } from '@material-ui/styles';
import GeneralProvider from './providers/GeneralProvider';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GeneralProvider>
        <SnackbarProvider anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }} maxSnack={3}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </AuthProvider>
        </SnackbarProvider>
      </GeneralProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
