import {
  Avatar,
  Button,
  Card,
  Fab,
  FormHelperText,
  IconButton,
  InputAdornment,
  makeStyles,
  Menu,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { Box, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardPeopleIcon from "../assets/icons/graph/peoples.png";
import DashboardSaleIcon from "../assets/icons/graph/sale.png";
import DashboardCommissionIcon from "../assets/icons/graph/commission.png";
import DashboardPropertyIcon from "../assets/icons/graph/property.png";
import Chart from "react-apexcharts";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { responsiveStyles } from "../assets/css/generalStyling";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { graphBulltes, icons, statisticsProperties } from "../assets/assets";
import Modal from "../component/Modal";
import TabView from "../component/Tab/index";
import HotSqIcon from "../assets/icons/leadDetail/HotSqIcon.png";
import WarmSqIcon from "../assets/icons/leadDetail/WarmSqIcon.png";
import CoolSqIcon from "../assets/icons/leadDetail/CoolSqIcon.png";
import ColdSqIcon from "../assets/icons/leadDetail/ColdSqIcon.png";
import { Close } from "@material-ui/icons";
import { useFormik } from "formik";
import EventCard from "./component/EventCard";
import searchModal from "../assets/icons/searchModal.png";
import Delete from "../assets/icons/graph/delete.png";
import Edit from "../assets/icons/graph/edit.png";
import Update from "../assets/icons/graph/update.svg";
import clsx from "clsx";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/moment";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import ArrowBackwardIcon from "@material-ui/icons/ArrowBackwardIcon";
import {
  getDashboardSatistics,
  getLeads,
} from "../store/actions/dashboardActions";
import { useAuth } from "../providers/AuthProvider";
import { deleteLead, editLead } from "../store/actions/apis";
import { useSnackbar } from "notistack";
import { getTaskDetails } from "../store/actions/apis";
import AddEvent from "./AddEvent";
import ArrowBack from "@material-ui/icons/ArrowBack";
import "../assets/css/dashboard.css";
import { SET_ALL_LEADS } from "../store/constants";

const useStyles = makeStyles((theme) => ({
  notchedOutlineInput: {
    borderRadius: "20px",
  },
  selectedTab: {
    backgroundColor: "#8B7862",
    color: "#FFFFFF",
    // padding: "8px",
  },
  input: {
    padding: "10px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "2px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "70px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const responsive = responsiveStyles();
  const [tab, setTab] = useState("lead");
  const [search, setSearch] = useState("");
  const [task, setTask] = useState([]);
  const [task1, setTask1] = useState([]);
  const { statistics, leads } = useSelector((state) => state.dashboard);
  const [filter, setFilter] = useState("In progress");
  const [priority, setPriority] = useState([]);
  const [modal, setModal] = useState(false);
  const notification = useSnackbar();
  const {
    meta_data: { statuses, priorities, briks, call_statuses, users },
  } = useSelector((state) => state.dashboard);
  const auth = useAuth();
  const dispatch = useDispatch();
  const [tdy, setTdy] = useState(false);
  const [tom, setTom] = useState(false);
  const [expand, setExpand] = React.useState(false);
  const [eventModal, setEventModal] = useState(false);

  let [graphData, setGraphData] = useState({
    options: {
      fill: {
        colors: ["#D83854", "#FE9F00", "#00BF86", "#233F78", "#FF6666"],
      },
      labels: ["New Leads", "In Progress", "Converted Lead", "Sales Done"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                fontSize: "18px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    },
    series: [0, 0, 0, 0, 0],
  });

  const today = new Date();
  const tomm = new Date(today);

  const fetchTasks = () => {
    getTaskDetails(moment().add(1, "day").format("YYYY-MM-DD"))
      .then((result) => {
        setTask1(result.data);
      })
      .catch((er) => {
        console.log("error: ", er);
      });
    getTaskDetails(moment().format("YYYY-MM-DD"))
      .then((result) => {
        const { data } = result;
        console.log("data", data);
        setTask(data);
      })
      .catch((er) => {
        console.log("error: ", er);
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getLeads({ search, status: filter, priority }));
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filter]);

  useEffect(() => {
    dispatch(getDashboardSatistics());
  }, []);

  useEffect(() => {
    tomm.setDate(tomm.getDate() + 1);
    console.log("tomm", tomm.toISOString().split("T")[0]);
    fetchTasks();
  }, []);

  const [value, setValue] = React.useState(0);
  const [barChart, setBarChartData] = React.useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
      colors: ["#6FDC7A"],
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
  });

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChang = (event) => {
    if (event.target.checked) {
      priority.push(event.target.value);
      setPriority([...priority]);
    } else {
      let index = priority.indexOf(event.target.value);
      priority.splice(index, 1);
      setPriority([...priority]);
    }
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    submitForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      priority: "Hot",
      broker_id: "none",
      last_call_status: "none",
      follow_up_date: moment().format("YYYY-MM-DD"),
      interested_in: [],
      remarks: "",
    },
    onSubmit: () => {
      delete values.budget;
      editLead(values.id, {
        ...values,
        interested_in: values.interested_in,
      }).then((result) => {
        setModal(false);
        dispatch(
          getLeads({
            search,
            status: filter,
            priority,
          })
        );
      });
    },
  });

  const renderBottomTextWidget = (stats) => {
    switch (stats.type) {
      case "Property Sold":
        return (
          <>
            <Box component="span" fontWeight="bold">
              Last Sold:
            </Box>{" "}
            <Box>&nbsp;{stats.last_lead.property_name}</Box>
          </>
        );
      case "new":
        return (
          <>
            <Box component="span" fontWeight="bold">
              Last Lead:
            </Box>{" "}
            <Box>&nbsp;{stats.last_lead.name}</Box>
          </>
        );
      case "Commission Earned":
        return (
          <>
            <Box component="span" fontWeight="bold">
              Last Commission Earned:
            </Box>{" "}
            <Box>
              &nbsp;₹
              {stats.last_lead.amount}
            </Box>
          </>
        );

      case "Total Sales":
        return (
          <>
            <Box component="span" fontWeight="bold">
              Last Sale:
            </Box>{" "}
            <Box>
              &nbsp;₹
              {stats.last_lead.amount}
            </Box>
          </>
        );
    }
  };

  useEffect(() => {
    // console.log("today's date:",new Date().getDate)
    setGraphData({
      ...graphData,
      series: _.map(Object.values(statistics.graphData), "count"),
    });
  }, [statistics]);

  const ModalHeader1 = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px 0 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box fontSize="22px" fontWeight={600}>
          Add Event
        </Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setEventModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const ModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px 0 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        fontSize={"24px"}
        fontWeight="bold"
      >
        <Box>Update Sales & Specific Details2</Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const renderPriority = (priority) => {
    switch (priority) {
      case "Hot":
        return icons.HotIcon;
      case "Warm":
        return icons.WarmIcon;
      case "Cool":
        return icons.CoolIcon;
      default:
        return icons.ColdIcon;
    }
  };

  const updateSalesAction = (lead) => {
    console.log("VALUE: ", lead);
    setValues({
      ...lead,
      last_call_status: values.last_call_status
        ? values.last_call_status
        : "none",
      interested_in: values.interested_in,
      broker: values.broker,

      // lead.interested_in.map((item) => item.id),
    });
    setModal(true);
  };

  return (
    <>
      <Modal
        width="450px"
        height="510px"
        headerComponent={ModalHeader1}
        status={eventModal}
      >
        <AddEvent
          deviceType="website"
          onBack={() => {
            setEventModal(false);
            fetchTasks();
          }}
        />
      </Modal>

      <Modal
        width="500px"
        height="600px"
        headerComponent={ModalHeader}
        status={modal}
      >
        <Box color="#979797">Lead Type</Box>

        <Box display="flex" padding="9px 0">
          <Box
            onClick={() => {
              setFieldValue("priority", "Hot");
            }}
            flex={1}
          >
            <img
              style={{ marginLeft: "10px" }}
              className={clsx({
                [classes.selected]: values.priority == "Hot",
              })}
              src={HotSqIcon}
            />
            <Box margin="auto">Hot</Box>
          </Box>

          <Box onClick={() => setFieldValue("priority", "Warm")} flex={1}>
            <img
              style={{ marginLeft: "10px" }}
              className={clsx({
                [classes.selected]: values.priority == "Warm",
              })}
              src={WarmSqIcon}
            />
            <Box margin="auto">Warm</Box>
          </Box>

          <Box onClick={() => setFieldValue("priority", "Cool")} flex={1}>
            <img
              style={{ marginLeft: "10px" }}
              className={clsx({
                [classes.selected]: values.priority == "Cool",
              })}
              src={CoolSqIcon}
            />
            <Box margin="auto">Cool</Box>
          </Box>

          <Box onClick={() => setFieldValue("priority", "Cold")} flex={1}>
            <img
              style={{ marginLeft: "10px" }}
              className={clsx({
                [classes.selected]: values.priority == "Cold",
              })}
              src={ColdSqIcon}
            />
            <Box margin="auto">Cold</Box>
          </Box>
        </Box>

        <Box>
          <Box marginBottom="10px" color="#FD4243">
            Hot:{" "}
            <Box color="black" fontSize="13px" component="span">
              Highly Interested
            </Box>
          </Box>
          <Box marginBottom="10px" color="#FE8F02">
            Warm:{" "}
            <Box color="black" fontSize="13px" component="span">
              Interested
            </Box>
          </Box>
          <Box marginBottom="10px" color="#02A6E9">
            Cool:{" "}
            <Box color="black" fontSize="13px" component="span">
              May be Intrested
            </Box>
          </Box>
          <Box marginBottom="10px" color="#0B79A5">
            Cold:{" "}
            <Box color="black" fontSize="13px" component="span">
              Not Responding
            </Box>
          </Box>
        </Box>
        <Box marginBottom="10px">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              fullWidth
              margin="normal"
              label="Follow Up"
              id="date-picker-dialog"
              // label="Date picker dialog"
              format="DD-MM-YYYY"
              value={values.follow_up_date}
              onChange={(date) => {
                setFieldValue(
                  "follow_up_date",
                  moment(date).format("YYYY-MM-DD")
                );
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                id: `datePicker1`,
              }}
            />
          </MuiPickersUtilsProvider>
          <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
            {touched.follow_up_date && errors.follow_up_date}
          </FormHelperText>
        </Box>
        <Box marginBottom="10px">
          <Select
            multiple
            input={<OutlinedInput classes={{ input: classes.input }} />}
            fullWidth
            value={
              values.interested_in.length ? values.interested_in : ["none"]
            }
            onChange={(event) => {
              setFieldValue(
                "interested_in",
                event.target.value.filter((item) => item != "none")
              );
            }}
            error={touched.interested_in && errors.interested_in}
            labelId="demo-simple-select-outlined-label"
          >
            <MenuItem disabled value="none">
              Interested In
            </MenuItem>
            {briks.map((brik) => {
              return <MenuItem value={brik.id}>{brik.name}</MenuItem>;
            })}
          </Select>
          <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
            {touched.interested_in && errors.interested_in}
          </FormHelperText>
        </Box>
        <Box marginBottom="10px">
          <Select
            input={<OutlinedInput classes={{ input: classes.input }} />}
            fullWidth
            name="last_call_status"
            value={values.last_call_status}
            onChange={handleChange}
            // error={touched.document_type && errors.document_type ? true : false}
            // helperText={touched.document_type && errors.document_type}
            labelId="demo-simple-select-outlined-label"
          >
            <MenuItem disabled value="none">
              Call Status
            </MenuItem>
            {call_statuses.map((status) => {
              return <MenuItem value={status}>{status}</MenuItem>;
            })}
          </Select>
        </Box>
        <Box marginBottom="10px">
          <Select
            fullWidth
            input={<OutlinedInput classes={{ input: classes.input }} />}
            labelId="demo-simple-select-outlined-label"
            placeholder="Lead Status"
            name="status"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.status}
            error={touched.status && errors.status}
          >
            <MenuItem value="none" disabled>
              Lead Status
            </MenuItem>
            {statuses.map((status) => {
              return <MenuItem value={status}>{_.capitalize(status)}</MenuItem>;
            })}
          </Select>
          <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
            {touched.status && errors.status}
          </FormHelperText>
        </Box>
        {values.status === "Sales Done" ? (
          <>
            <Box marginBottom="10px" item xs="12">
              <TextField
                fullWidth
                multiline
                id="outlined-password-input"
                name="sale_value"
                label="Sales Value"
                placeholder="Sales Value"
                value={values.sale_value}
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.sale_value && errors.sale_value}
                helperText={touched.sale_value && errors.sale_value}
              />
            </Box>
          </>
        ) : (
          ""
        )}
        <Box marginBottom="10px">
          <TextField
            fullWidth
            multiline
            label="Remarks"
            id="outlined-password-input"
            placeholder="Remarks"
            type="text"
            variant="outlined"
            name="remarks"
            value={values.remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.remarks && errors.remarks}
            helperText={touched.remarks && errors.remarks}
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-around" padding="30px 0">
            <Button
              onClick={() => {
                setModal(false);
              }}
              variant="outlined"
              style={{ width: "123px" }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              style={{ width: "150px" }}
              variant="contained"
              onClick={() => {
                submitForm();
              }}
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box className={responsive.web}>
        <div
          style={{
            // backgroundColor: "#F0E2E5",
            padding: "10px",
            border: "none",
          }}
        >
          <Box
            style={{
              fontSize: "22px",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#282827",
              fontFamily: "Proxima Nova",
            }}
          >
            Dashboard
          </Box>
          {expand ? (
            <>
              <Grid container spacing={3}>
                <Grid item md="12">
                  <Box
                    style={{
                      display: "flex",
                      borderRadius: "12px 12px 0px 0px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
                      padding: "2px 0px",
                      backgroundColor: "#FFF0D7",
                      alignItems: "center",
                      justifyContent: "center",
                      // float:"left",
                      width: "254px",
                      border: "1px solid #F5F5F5",
                    }}
                  >
                    <Box
                      onClick={() => {
                        setTab("lead");
                      }}
                      className={clsx({ [classes.selectedTab]: tab == "lead" })}
                      // borderRadius="12px"
                      padding="12px 15px"
                      fontWeight="600"
                      borderRadius="10px 10px 0px 0px"
                      cursor="pointer"
                      display={"inline-block"}
                      whiteSpace="nowrap"
                    >
                      ALL LEADS
                    </Box>
                    <Box
                      onClick={() => {
                        setTab("graph");
                      }}
                      className={clsx({
                        [classes.selectedTab]: tab == "graph",
                      })}
                      padding="12px"
                      // borderRadius="12px"
                      fontWeight="600"
                      borderRadius="10px 10px 0px 0px"
                      cursor="pointer"
                      display={"inline-block"}
                      whiteSpace="nowrap"
                    >
                      GRAPH ANALYSIS
                    </Box>
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    style={{ padding: "0px 0px" }}
                  ></Box>
                  {tab == "lead" && (
                    <Grid
                      style={{ padding: "10px", backgroundColor: "white" }}
                      item
                      md={12}
                    >
                      <Box style={{ overflow: "scroll", display: "flex" }}>
                        <TabView
                          updateSalesFlowModal={updateSalesAction}
                          setExpand={(bool) => setExpand(bool)}
                          expand={expand}
                        />
                      </Box>
                    </Grid>
                  )}
                  {tab == "graph" && (
                    <Grid
                      style={{ padding: "10px", backgroundColor: "white" }}
                      item
                      md={12}
                    >
                      <Box style={{ overflow: "scroll", display: "flex" }}>
                        <Grid style={{ backgroundColor: "white" }} item md={12}>
                          <Carousel
                            showStatus={false}
                            autoPlay={false}
                            showThumbs={false}
                          >
                            <Box width="100%">
                              <Box
                                display="flex"
                                padding="20px 10px"
                                alignItems="center"
                              >
                                <Box fontSize="22px">All Leads</Box>
                                <Box flex={1} textAlign="right">
                                  {/* <Select value="monthly" variant="outlined">
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                  </Select> */}
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box
                                  flex={1}
                                  display="flex"
                                  alignItems="center"
                                  padding="50px"
                                  justifyContent="right"
                                >
                                  <Chart
                                    type="donut"
                                    options={graphData.options}
                                    series={graphData.series}
                                    width="100%"
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  display="flex"
                                  flexDirection="column"
                                  marginTop="60px"
                                >
                                  {Object.keys(statistics.graphData).map(
                                    (key, index) => {
                                      return (
                                        <Box
                                          key={index}
                                          display="flex"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <Box marginRight="10px">
                                            <img src={graphBulltes[index]} />
                                          </Box>
                                          <Box textAlign="left">
                                            <Box textAlign="left">
                                              {statistics.graphData[key].count}
                                            </Box>
                                            <Box
                                              style={{
                                                color: "#787878",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {key}{" "}
                                              {statistics.graphData[
                                                key
                                              ].percentage.toFixed(2)}
                                              %
                                            </Box>
                                          </Box>
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Box
                                display="flex"
                                padding="20px 10px"
                                alignItems="center"
                              >
                                <Box fontSize="22px">Commission Earned</Box>
                                <Box flex={1} textAlign="right">
                                  {/* <Select value="monthly" variant="outlined">
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                  </Select> */}
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box
                                  style={{ mariginTop: "15px" }}
                                  flex={1}
                                  display="flex"
                                  alignItems="center"
                                  padding="50px"
                                  justifyContent="center"
                                >
                                  <Chart
                                    options={barChart.options}
                                    series={barChart.series}
                                    type="bar"
                                    width="600"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Carousel>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={3}>
                {statistics.statistics.map((stats, index) => {
                  return (
                    <Grid item xs={6} md={3}>
                      <Box
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          borderRadius: "12px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box padding="10px">
                          <Box style={{ display: "flex" }}>
                            <Box flex={1}>
                              <Avatar
                                style={{ width: "50px", height: "50px" }}
                                src={statisticsProperties[index].icon}
                              />
                            </Box>
                            <Box>
                              <Box
                                fontSize="28px"
                                textAlign="right"
                                fontFamily="Red Hat Display"
                                fontWeight="bold"
                              >
                                {["Commission Earned", "Total Sales"].includes(
                                  stats.type
                                ) && "₹"}
                                {stats.count}
                              </Box>
                              <Box
                                fontSize="14px"
                                color={statisticsProperties[index].bgcolor}
                                fontFamily="Proxima Nova"
                                fontWeight="bold"
                                textAlign="right"
                                textTransform="uppercase"
                              >
                                {stats.type}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            background: `${statisticsProperties[index].bgcolor}65`,
                            padding: "5px",
                            fontSize: "14px",
                            borderRadius: "0px 0px 10px 10px",
                            fontFamily: "Proxima Nova",
                            color: "#323232",
                            display: "flex",
                          }}
                        >
                          {renderBottomTextWidget(stats)}
                          {/* <div
                            style={{
                              fontWeight: "700",
                              color: "#565656",
                              mixBlendMode: "normal",
                            }}
                          >
                            {stats.last_lead.name ? "Last Lead" : "Amount"}:
                            {stats.last_lead.name
                              ? stats.last_lead.name
                              : stats.last_lead.amount}
                          </div> */}
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid style={{ paddingTop: "20px" }} container spacing={3}>
                <Grid item xs={6} md={9}>
                  <Box
                    style={{
                      display: "flex",
                      borderRadius: "12px 12px 0px 0px",
                      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
                      padding: "0px 0px",
                      backgroundColor: "#FFF0D7",
                      alignItems: "center",
                      justifyContent: "center",
                      // float:"left",
                      width: "265px",
                      border: "1px solid #F5F5F5",
                      //marginLeft:"1%"
                    }}
                  >
                    <Box
                      onClick={() => {
                        setTab("lead");
                      }}
                      className={clsx({ [classes.selectedTab]: tab == "lead" })}
                      style={{ cursor: "pointer" }}
                      padding="12px 20px"
                      fontWeight="600"
                      borderRadius="10px 10px 0px 0px"
                      pointer="cursor"
                      display={"inline-block"}
                      whiteSpace="nowrap"
                    >
                      ALL LEADS
                    </Box>
                    <Box
                      onClick={() => {
                        setTab("graph");
                      }}
                      className={clsx({
                        [classes.selectedTab]: tab == "graph",
                      })}
                      padding="15px 12px"
                      style={{ cursor: "pointer" }}
                      fontWeight="600"
                      borderRadius="10px 10px 0px 0px"
                      pointer="cursor"
                      display={"inline-block"}
                      whiteSpace="nowrap"
                    >
                      GRAPH ANALYSIS
                    </Box>
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    style={{ padding: "0px 0px" }}
                  ></Box>
                  {tab == "lead" && (
                    <Grid
                      style={{ padding: "10px", backgroundColor: "white" }}
                      item
                      xs={12}
                    >
                      <Box style={{ overflow: "scroll", display: "flex" }}>
                        <TabView
                          updateSalesFlowModal={updateSalesAction}
                          setExpand={(bool) => setExpand(bool)}
                        />
                      </Box>
                    </Grid>
                  )}
                  {tab == "graph" && (
                    <Grid
                      style={{ padding: "10px", backgroundColor: "white" }}
                      item
                      xs={12}
                    >
                      <Box style={{ overflow: "scroll", display: "flex" }}>
                        <Grid style={{ backgroundColor: "white" }} item xs={12}>
                          <Carousel
                            showStatus={false}
                            autoPlay={false}
                            showThumbs={false}
                          >
                            <Box width="100%">
                              <Box
                                display="flex"
                                padding="20px 10px"
                                alignItems="center"
                              >
                                <Box fontSize="22px">All Leads</Box>
                                <Box flex={1} textAlign="right">
                                  {/* <Select value="monthly" variant="outlined">
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                  </Select> */}
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box
                                  flex={1}
                                  display="flex"
                                  alignItems="center"
                                  padding="50px"
                                  justifyContent="right"
                                >
                                  <Chart
                                    type="donut"
                                    options={graphData.options}
                                    series={graphData.series}
                                    width="100%"
                                  />
                                </Box>
                                <Box
                                  flex={1}
                                  display="flex"
                                  flexDirection="column"
                                  marginTop="60px"
                                >
                                  {Object.keys(statistics.graphData).map(
                                    (key, index) => {
                                      return (
                                        <Box
                                          key={index}
                                          display="flex"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <Box marginRight="10px">
                                            <img src={graphBulltes[index]} />
                                          </Box>
                                          <Box textAlign="left">
                                            <Box textAlign="left">
                                              {statistics.graphData[key].count}
                                            </Box>
                                            <Box
                                              style={{
                                                color: "#787878",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {key}{" "}
                                              {statistics.graphData[
                                                key
                                              ].percentage.toFixed(2)}
                                              %
                                            </Box>
                                          </Box>
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Box
                                display="flex"
                                padding="20px 10px"
                                alignItems="center"
                              >
                                <Box fontSize="22px">Commission Earned</Box>
                                <Box flex={1} textAlign="right">
                                  {/* <Select value="monthly" variant="outlined">
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                  </Select> */}
                                </Box>
                              </Box>
                              <Box display="flex">
                                <Box
                                  style={{ mariginTop: "15px" }}
                                  flex={1}
                                  display="flex"
                                  alignItems="center"
                                  padding="50px"
                                  justifyContent="center"
                                >
                                  <Chart
                                    options={barChart.options}
                                    series={barChart.series}
                                    type="bar"
                                    width="600"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Carousel>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={6} md={3}>
                  <Card
                    style={{
                      marginTop: "57px",
                      padding: "10px",
                      borderRadius: "12px",
                      fontFamily: "Proxima Nova",
                    }}
                  >
                    <Box
                      style={{
                        fontSize: "17px",
                        marginTop: "5px",
                        fontWeight: "bold",
                        marginBottom: "2%",
                      }}
                    >
                      Upcoming Events
                    </Box>
                    <hr style={{ marginBottom: "2%", color: "#F5F5F5" }} />
                    <Box
                      style={{
                        marginBottom: "10px",
                        fontSize: "12px",
                        color: "#787878",
                      }}
                    >
                      TODAY
                    </Box>
                    <Grid container>
                      {task.map((tk) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: "1%" }}
                          >
                            <Box
                              style={{ position: "relative" }}
                              display="flex"
                            >
                              <Box
                                style={{
                                  backgroundColor: "rgb(12 110 120 / 44%)",
                                  padding: "26px 11px",
                                  marginRight: "10px",
                                  borderRadius: "5px",
                                }}
                              >
                                {tk.name === "Follow up"
                                  ? "TO DO"
                                  : new Date(tk.time).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                              </Box>
                              <Box
                                style={{
                                  border: "1px solid #D4D6DE",
                                  borderRadius: "5px",
                                  borderLeft: "10px solid #0C6E78",
                                  padding: "10px",
                                  backgroundColor: "white",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  style={{
                                    fontWeight: "600",
                                    color: "#0C6E78",
                                  }}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      history.push(`/lead/${tk.lead_id}`, {
                                        leadId: tk.lead_id,
                                      });
                                    }}
                                  >
                                    {tk.name}{" "}
                                  </span>
                                </Box>
                                <Box
                                  style={{
                                    fontSize: "12px",
                                    color: "#8A9EBC",
                                  }}
                                >
                                  {"with "}
                                  {tk.lead}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      ))}
                      {task.length == 0 ? "No events today" : ""}
                    </Grid>
                    <Box
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontSize: "13px",
                        color: "#787878",
                      }}
                    >
                      {moment().add(1, "day").format("YYYY-MM-DD")}
                      {" Tomorrow"}
                    </Box>
                    <Grid container>
                      {task1.map((tk) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: "1%" }}
                          >
                            <Box
                              style={{ position: "relative" }}
                              display="flex"
                            >
                              <Box
                                style={{
                                  backgroundColor: "rgb(12 110 120 / 44%)",
                                  padding: "26px 11px",
                                  marginRight: "10px",
                                  borderRadius: "5px",
                                }}
                              >
                                {tk.name === "Follow up"
                                  ? "TO DO"
                                  : new Date(tk.time).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                              </Box>
                              <Box
                                style={{
                                  border: "1px solid #D4D6DE",
                                  borderRadius: "5px",
                                  borderLeft: "10px solid #0C6E78",
                                  padding: "10px",
                                  backgroundColor: "white",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  style={{
                                    fontWeight: "600",
                                    color: "#0C6E78",
                                  }}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      history.push(`/lead/${tk.lead_id}`, {
                                        leadId: tk.lead_id,
                                      });
                                    }}
                                  >
                                    {tk.name}{" "}
                                  </span>
                                </Box>
                                <Box
                                  style={{
                                    fontSize: "12px",
                                    color: "#8A9EBC",
                                  }}
                                >
                                  {"with "}
                                  {tk.lead}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      ))}
                      {task1.length === 0 ? "No events for tomorrow" : ""}
                    </Grid>
                    <Grid container style={{ marginTop: "5%" }}>
                      <Box textAlign="center" style={{ width: "100%" }}>
                        <Button
                          onClick={() => {
                            setEventModal(true);
                          }}
                          variant="contained"
                          disableElevation
                          style={{ backgroundColor: "#0C6E78", color: "white" }}
                          fullWidth
                        >
                          ADD NEW EVENT
                        </Button>
                      </Box>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </Box>

      <Box className={responsive.app}>
        <Card
          style={{ backgroundColor: "#F1E8DA", padding: "10px" }}
          className="app-dashboard"
        >
          <Box
            style={{ fontSize: "18px", fontWeight: "bold", marginTop: "13px" }}
          >
            Dashboard
          </Box>
          <Box
            style={{ marginBottom: "10px", fontSize: "14px", color: "#787878" }}
          >
            Welcome back {auth.getUser().name}!
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box className="dashboard-widget">
                {statistics.statistics.map((stats, index) => {
                  return (
                    <Box
                      style={{
                        backgroundColor: "white",
                        minWidth: "250px",
                        color: "black",
                        marginRight: "10px",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box padding="10px">
                        <Box style={{ display: "flex" }}>
                          <Box flex={1}>
                            <Avatar src={statisticsProperties[index].icon} />
                          </Box>
                          <Box>
                            <Box fontSize="18px" textAlign="right">
                              {stats.count}
                            </Box>
                            <Box
                              fontSize="11px"
                              fontWeight="bold"
                              color={statisticsProperties[index].bgcolor}
                            >
                              {stats.type}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          backgroundColor: `${statisticsProperties[index].bgcolor}61`,
                          padding: "5px",
                          fontSize: "12px",
                          color: "#323232",
                          borderRadius: "0px 0px 10px 10px",
                          display: "flex",
                        }}
                      >
                        {renderBottomTextWidget(stats)}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Grid>

            <Grid container style={{ marginTop: "24px", marginBottom: "8px" }}>
              <Grid item xs={8} md={8}>
                <Box
                  style={{
                    display: "flex",
                    borderRadius: "14px",
                    padding: "3px",
                    backgroundColor: "#F3F3F3",
                    width: "242px",
                    alignItems: "center",
                  }}
                  className="dashboard-tabs"
                >
                  <Box
                    onClick={() => {
                      setTab("lead");
                    }}
                    className={clsx({ [classes.selectedTab]: tab == "lead" })}
                    borderRadius="12px"
                    padding="8px 12px"
                    ontWeight="600"
                    style={{ cursor: "pointer" }}
                  >
                    All Leads
                  </Box>
                  <Box
                    onClick={() => {
                      setTab("graph");
                    }}
                    className={clsx({ [classes.selectedTab]: tab == "graph" })}
                    padding="8px 26px"
                    borderRadius="12px"
                    fontWeight="600"
                    style={{ cursor: "pointer" }}
                  >
                    Graph Analysis
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {tab == "graph" && (
              <>
                <Grid
                  className="tab-view"
                  style={{ backgroundColor: "white" }}
                  item
                  xs={12}
                >
                  <Carousel
                    showStatus={false}
                    autoPlay={false}
                    showThumbs={false}
                  >
                    <Box width="100%">
                      <Box
                        display="flex"
                        padding="20px 10px"
                        alignItems="center"
                      >
                        <Box fontSize="22px" fontWeight="bold">
                          All Lead
                        </Box>
                        <Box flex={1} textAlign="right">
                          {/* <Select value="monthly" variant="outlined">
                            <MenuItem value="monthly">Monthly</MenuItem>
                          </Select> */}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box>
                          <Chart
                            type="donut"
                            options={graphData.options}
                            series={graphData.series}
                            width="220"
                          />
                        </Box>
                        <Box flex={1}>
                          {Object.keys(statistics.graphData).map(
                            (key, index) => {
                              return (
                                <Box
                                  key={index}
                                  display="flex"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Box marginTop="5px" marginRight="10px">
                                    <img src={graphBulltes[index]} />
                                  </Box>
                                  <Box textAlign="left">
                                    <Box textAlign="left" fontWeight="bold">
                                      {statistics.graphData[key].count}
                                    </Box>
                                    <Box
                                      fontWeight="bold"
                                      style={{
                                        color: "#787878",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {key}{" "}
                                      {statistics.graphData[
                                        key
                                      ].percentage.toFixed(2)}
                                      %
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        display="flex"
                        padding="20px 10px"
                        alignItems="center"
                      >
                        <Box fontSize="22px">Commission Earned</Box>
                        <Box flex={1} textAlign="right">
                          {/* <Select value="monthly" variant="outlined">
                            <MenuItem value="monthly">Monthly</MenuItem>
                          </Select> */}
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box style={{ mariginTop: "15px" }}>
                          <Chart
                            options={barChart.options}
                            series={barChart.series}
                            type="bar"
                            width="300"
                          />
                          {/* <Chart
                            width="100%"
                            type="area"
                            options={graphData2.options}
                            series={graphData2.series}
                          /> */}
                        </Box>
                      </Box>
                    </Box>
                  </Carousel>
                </Grid>
              </>
            )}

            {tab == "lead" && (
              <>
                <Grid
                  style={{ padding: "10px", backgroundColor: "white" }}
                  className="tab-view"
                  item
                  xs={12}
                >
                  <Box style={{ overflow: "scroll", display: "flex" }}>
                    <Tabs
                      indicatorColor="primary"
                      textColor="primary"
                      value={value}
                      classes={{ flexContainer: classes.flexContainer }}
                      onChange={(event, newValue) => {
                        switch (newValue) {
                          case 1:
                            setFilter("In progress");
                            break;
                          case 2:
                            setFilter("converted");
                            break;
                          case 3:
                            setFilter("Sales Done");
                            break;
                          case 4:
                            setFilter("lost");
                            break;
                          default:
                            setFilter("new");
                        }
                        setValue(newValue);
                      }}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        style={{ fontWeight: "bold" }}
                        label="In Progress"
                        {...a11yProps(0)}
                      />
                      <Tab
                        style={{ fontWeight: "bold" }}
                        label="New Lead"
                        {...a11yProps(1)}
                      />
                      <Tab
                        style={{ fontWeight: "bold" }}
                        label="Converted Lead"
                        {...a11yProps(2)}
                      />
                      <Tab
                        style={{ fontWeight: "bold" }}
                        label="Sales Done"
                        {...a11yProps(3)}
                      />
                      <Tab
                        style={{ fontWeight: "bold" }}
                        label="Lost"
                        {...a11yProps(4)}
                      />
                    </Tabs>
                  </Box>
                  <Box padding="10px 0px">
                    <TextField
                      style={{
                        backgroundColor: "#dbdbdb91",
                        borderRadius: "18px",
                        color: "#787878",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutlineInput,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ color: "#787878" }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="small">
                              {/* <img src={searchModal} /> */}
                              <Box>
                                <PopupState
                                  variant="popover"
                                  popupId="demoMenu"
                                >
                                  {(popupState) => (
                                    <React.Fragment>
                                      <IconButton
                                        size="small"
                                        variant="contained"
                                        {...bindTrigger(popupState)}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          bindTrigger(popupState).onClick(
                                            event
                                          );
                                        }}
                                      >
                                        <img src={searchModal} />
                                      </IconButton>

                                      <Menu
                                        {...bindMenu(popupState)}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "center",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "center",
                                        }}
                                        style={{ top: "5px", left: "1px" }}
                                      >
                                        <Box
                                          style={{
                                            padding: "10px",
                                            minWidth: "200px",
                                          }}
                                        >
                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                          >
                                            <Box fontWeight="bold">
                                              Filter by
                                            </Box>
                                            <Box
                                              style={{
                                                color: "#897860",
                                                textDecoration: "underline",
                                              }}
                                              onClick={() => setPriority([])}
                                            >
                                              CLEAR ALL
                                            </Box>
                                          </Box>
                                          <Box marginTop="10px">
                                            <FormGroup>
                                              {priorities.map((status) => {
                                                return (
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        value={status}
                                                        checked={priority.includes(
                                                          status
                                                        )}
                                                        onChange={handleChang}
                                                        name={status}
                                                        color="primary"
                                                      />
                                                    }
                                                    label={_.capitalize(status)}
                                                  />
                                                );
                                              })}
                                            </FormGroup>
                                          </Box>
                                        </Box>
                                      </Menu>
                                    </React.Fragment>
                                  )}
                                </PopupState>
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search leads"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </Box>
                  <Box>
                    {leads.results.map((lead) => {
                      return (
                        <Card
                          onClick={() => {
                            history.push(`/lead/${lead.id}`);
                          }}
                          style={{
                            marginBottom: "10px",
                            padding: "10px",
                            border: "1px solid 1px solid rgb(86 86 86 / 9%)",
                            display: "flex",
                          }}
                        >
                          <Box>
                            <Avatar style={{ width: "35px", height: "35px" }} />
                          </Box>
                          <Box flex={1} paddingLeft="10px">
                            <Box
                              style={{ lineHeight: "1.7", fontWeight: "bold" }}
                            >
                              {lead.name}
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Box paddingRight="10px">
                                {lead?.client?.number}
                              </Box>{" "}
                              <img src={renderPriority(lead.priority)} />
                            </Box>
                          </Box>
                          <Box>
                            <PopupState variant="popover" popupId="demoMenu">
                              {(popupState) => (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      bindTrigger(popupState).onClick(event);
                                    }}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>

                                  <Menu
                                    {...bindMenu(popupState)}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    style={{ top: "5px", left: "1px" }}
                                  >
                                    <MenuItem
                                      onClick={(event) => {
                                        updateSalesAction(lead);
                                        event.stopPropagation();
                                        popupState.close();
                                      }}
                                    >
                                      <img src={Update} />
                                      &nbsp;Update Sales Flow
                                    </MenuItem>
                                    <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        popupState.close();
                                        history.push(`/lead/${lead.id}/edit`);
                                      }}
                                    >
                                      <img src={Edit} />
                                      &nbsp;Edit Lead
                                    </MenuItem>

                                    <MenuItem
                                      onClick={(event) => {
                                        // event.stopPropagation();
                                        // deleteLead(lead.id).then((result) => {
                                        //   dispatch(
                                        //     getLeads({
                                        //       search,
                                        //       status: filter,
                                        //       priority,
                                        //     })
                                        //   );
                                        // });

                                        // notification.enqueueSnackbar(
                                        //   "Lead Deleted Successfully",
                                        //   {
                                        //     variant: "success",
                                        //   }
                                        // );

                                        popupState.close();
                                      }}
                                    >
                                      <img src={Delete} />
                                      &nbsp;Delete Lead
                                    </MenuItem>
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          </Box>
                        </Card>
                      );
                    })}
                    <Box>
                      <Box
                        display="flex"
                        width="100%"
                        padding={2}
                        justifyContent="center"
                      >
                        <Box
                          onClick={() => {
                            dispatch(
                              getLeads({
                                search,
                                status: filter,
                                priority,
                                limit: leads.limit,
                                offset: leads.offset - leads.limit,
                                override: false,
                              })
                            );
                          }}
                        >
                          <ArrowBack />
                        </Box>
                        <Box
                          onClick={() => {
                            dispatch(
                              getLeads({
                                search,
                                status: filter,
                                priority,
                                limit: leads.limit,
                                offset: leads.offset,
                                override: false,
                              })
                            );
                          }}
                        >
                          <ArrowForwardIcon />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Card
                    className="app-dashboard-upcoming-events"
                    style={{ padding: "10px" }}
                  >
                    <Box
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginTop: "13px",
                      }}
                    >
                      Upcoming Events
                    </Box>
                    <Box
                      style={{
                        marginBottom: "10px",
                        fontSize: "12px",
                        color: "#787878",
                      }}
                    >
                      TODAY
                    </Box>
                    <Grid container>
                      {task.map((tk) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginTop: "1%" }}
                          >
                            <Box
                              style={{ position: "relative" }}
                              display="flex"
                            >
                              <Box
                                style={{
                                  backgroundColor: "rgb(12 110 120 / 44%)",
                                  padding: "26px 11px",
                                  marginRight: "10px",
                                  borderRadius: "5px",
                                }}
                              >
                                {tk.name === "Follow up"
                                  ? "TO DO"
                                  : new Date(tk.time).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                              </Box>
                              <Box
                                style={{
                                  border: "1px solid #D4D6DE",
                                  borderRadius: "5px",
                                  borderLeft: "10px solid #0C6E78",
                                  padding: "10px",
                                  backgroundColor: "white",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  style={{
                                    fontWeight: "600",
                                    color: "#0C6E78",
                                  }}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      history.push(`/lead/${tk.lead_id}`, {
                                        leadId: tk.lead_id,
                                      });
                                    }}
                                  >
                                    {tk.name}{" "}
                                  </span>
                                </Box>
                                <Box
                                  style={{
                                    fontSize: "12px",
                                    color: "#8A9EBC",
                                  }}
                                >
                                  {"with "}
                                  {tk.lead}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      ))}
                      {task.length == 0 ? "No events today" : ""}
                    </Grid>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </Card>

        <br />
        <Box className={classes.root}>
          <Fab
            onClick={() => {
              history.push("/lead/create-lead");
            }}
            variant="extended"
            size="small"
            color="primary"
            aria-label="add"
          >
            <AddIcon />
            Create Lead
          </Fab>
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;
