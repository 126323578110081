import { Box, Grid } from '@material-ui/core'
import React from 'react'
import { responsiveStyles } from '../../assets/css/generalStyling'
import DetailCard from './DetailCard'
import ScheduleIcon from '../../assets/images/Coming soon.png'

function Schedule({ propertyDetails }) {
    const responsive = responsiveStyles()
    return (
        <>
        <Box className={responsive.web}>
            <Grid  spacing={3} style={{ paddingBottom: "12px" }} container item xs={12} sm={12}>
                <Grid item xs={12}>
                    <Box fontSize="22px" fontWeight="bold">{propertyDetails.property_name}</Box>
                </Grid>
                <Grid item xs={12}>
                    <DetailCard propertyDetails={propertyDetails} />
                </Grid>
            </Grid>
            </Box>
            <Grid textAlign="center" spacing={2} container item xs={12} sm={12}>
                <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} item xs={12} sm={12}>
                    <Box>
                        <img style={{ width: "100px",height:"100px" }} src={ScheduleIcon} />
                    </Box>
                    <Box style={{ fontSize: "22px", color: "#D83854", fontWeight: 600, marginTop: "20px" }}>
                        Coming Soon !
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Schedule
