import {
  Box,
  Button,
  FormHelperText,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import HotSqIcon from "../assets/icons/leadDetail/HotSqIcon.png";
import WarmSqIcon from "../assets/icons/leadDetail/WarmSqIcon.png";
import CoolSqIcon from "../assets/icons/leadDetail/CoolSqIcon.png";
import ColdSqIcon from "../assets/icons/leadDetail/ColdSqIcon.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { createLeadValidation, createLeadValidation2 } from "../validations";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import _ from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createLead, editLead, getLeadDetails } from "../store/actions/apis";
import { useSnackbar } from "notistack";
import { responsiveStyles } from "../assets/css/generalStyling";
import { getMetaData } from "../store/actions/dashboardActions";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "17px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "2px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "60px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

function CreateLead({ deviceType, onBack, formMode, id }) {
  const classes = useStyles();
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [mode, setMode] = useState("add");
  const { lead_id } = useParams();
  const [leadStatus, setLeadStatus] = useState("new");
  const notification = useSnackbar();
  const responsive = responsiveStyles();
  const dispatch = useDispatch();
  const {
    meta_data: {
      sources,
      orgs,
      statuses,
      briks,
      users,
      call_statuses,
      client_categories,
      occupation_types,
    },
  } = useSelector((state) => state.dashboard);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues: {
      name: "",
      assigned: "none",
      sale_value: "",
      organization: "none",
      source: "none",
      status: "new", // need to confirm with backend
      priority: "Hot",
      email: "",
      number: "",
      address: "",
      city: "",
      marital_status: "Married",
      occupation: "none",
      vehicle_owned: "none",
      category: "none", // need to confirm with backend
      interested_in: "none",
      // interested_in: [],
      last_call_status: "none",
      follow_up_date: moment().format("YYYY-MM-DD h:mm a"),
      remarks: "",
    },
    onSubmit: () => {
      console.log("VALUES OF Create LEAD FGorm: ", values);
      setUpdatingStatus(true);
      if (mode == "add") {
        let apiBody = {
          name: values.name,
          source: values.source,
          status: values.status,
          priority: values.priority,
          client: {
            name: values.name,
            email: values.email,
            number: values.number,
            address: values.address,
            city: values.city,
            marital_status: values.marital_status,
            occupation: values.occupation,
            vehicle_owned: values.vehicle_owned,
            category: values.category,
          },
          remarks: values.remarks,
          interested_in: [values.interested_in],
          last_call_status: values.last_call_status,
          follow_up_date: moment(values.follow_up_date).format(),
          organization: values.organization,
          assigned: values.assigned,
        };

        if (values.assigned === "none") {
          delete apiBody.assigned;
        }

        if (values.organization === "none") {
          delete apiBody.organization;
        }

        if (values.source === "none") {
          delete apiBody.source;
        }

        if (values.occupation === "none") {
          delete apiBody?.client?.occupation;
        }

        if (values.vehicle_owned === "none") {
          delete apiBody?.client?.vehicle_owned;
        }

        if (values.category === "none") {
          delete apiBody?.client?.category;
        }

        if (values.last_call_status === "none") {
          delete apiBody.last_call_status;
        }

        createLead(apiBody)
          .then((result) => {
            setUpdatingStatus(false);
            if (deviceType == "website") {
              onBack();
            } else history.goBack();
            notification.enqueueSnackbar("Lead Added Successfully", {
              variant: "success",
            });
          })
          .catch((er) => {
            setUpdatingStatus(false);
            notification.enqueueSnackbar(
              "Lead already assigned. Please contact admin.",
              {
                variant: "error",
              }
            );
          });
      } else {
        let apiBody = {
          name: values.name,
          source: values.source,
          status: values.status,
          priority: values.priority,
          client: {
            name: values.name,
            email: values.email,
            number: values.number,
            address: values.address,
            city: values.city,
            marital_status: values.marital_status,
            occupation: values.occupation,
            vehicle_owned: values.vehicle_owned,
            category: values.category,
          },
          sale_value: values.sale_value,
          remarks: values.remarks,
          interested_in: [values.interested_in],
          last_call_status: values.last_call_status,
          follow_up_date: moment(values.follow_up_date).format(),
          organization: values.organization,
          assigned: values.assigned,
        };

        if (values.status !== "Sales Done") {
          delete apiBody.sale_value;
        }

        if (values.assigned === "none") {
          delete apiBody.assigned;
        }

        if (values.organization === "none") {
          delete apiBody.organization;
        }

        if (values.source === "none") {
          delete apiBody.source;
        }

        if (values.occupation === "none") {
          delete apiBody?.client?.occupation;
        }

        if (values.vehicle_owned === "none") {
          delete apiBody?.client?.vehicle_owned;
        }

        if (values.category === "none") {
          delete apiBody?.client?.category;
        }

        if (values.last_call_status === "none") {
          delete apiBody.last_call_status;
        }

        if (lead_id) {
          editLead(lead_id, apiBody).then((result) => {
            setUpdatingStatus(false);
            if (deviceType == "website") {
              onBack();
            } else history.goBack();
            notification.enqueueSnackbar("Lead Updated Successfully", {
              variant: "success",
            });
          });
        }

        if (formMode == "edit") {
          editLead(id, apiBody).then((result) => {
            setUpdatingStatus(false);
            if (deviceType == "website") {
              onBack();
            } else history.goBack();
            notification.enqueueSnackbar("Lead Updated Successfully", {
              variant: "success",
            });
          });
        }
      }
    },
    validationSchema:
      leadStatus == "Sales Done" ? createLeadValidation2 : createLeadValidation,
  });

  useEffect(() => {
    dispatch(getMetaData({ org: values.organization }));
  }, [values.organization]);

  useEffect(() => {
    setFetching(true);
    if (lead_id) {
      setMode("edit");

      getLeadDetails(lead_id).then((result) => {
        setFetching(false);
        
        setValues({
          name: result.name,
          source: result.source ? result.source : "none",
          status: result.status, // need to confirm with backend
          priority: result.priority,
          email: result?.client?.email,
          sale_value: result.sale_value,
          number: result?.client?.number,
          address: result?.client?.address,
          city: result?.client?.city,
          marital_status: result?.client?.marital_status,
          occupation: result?.client?.occupation
            ? result?.client?.occupation
            : "none",
          vehicle_owned: result?.client?.vehicle_owned,
          category: result?.client?.category
            ? result?.client?.category
            : "none", // need to confirm with backend

          interested_in:
            result.interested_in.length > 0
              ? result.interested_in[0].id
              : "none",
          // interested_in:
          //   result.interested_in.length > 0
          //     ? result.interested_in.map((item) => item.id)
          //     : [],
          last_call_status: result.last_call_status
            ? result.last_call_status
            : "none",
          follow_up_date:
            result.follow_up_date == null
              ? moment().format("YYYY-MM-DD h:mm a")
              : moment(result.follow_up_date).format("YYYY-MM-DD h:mm a"),
          remarks: result.remarks,
          assigned: result.assigned ? result.assigned : "none",
          organization: result.organization ? result.organization : "none",
        });
      });
    }

    if (formMode == "edit") {
      setMode("edit");
      getLeadDetails(id).then((result) => {
        setFetching(false);
        setValues({
          name: result.name,
          source: result.source ? result.source : "none",
          status: result.status, // need to confirm with backend
          priority: result.priority,
          email: result?.client?.email,
          number: result?.client?.number,
          address: result?.client?.address,
          city: result?.client?.city,
          sale_value: result.sale_value,
          marital_status: result?.client?.marital_status,
          occupation: result?.client?.occupation
            ? result?.client?.occupation
            : "none",
          vehicle_owned: result?.client?.vehicle_owned,
          category: result?.client?.category
            ? result?.client?.category
            : "none", // need to confirm with backend
          interested_in:
            result.interested_in.length > 0
              ? result.interested_in[0].id
              : "none",
          // interested_in: result.interested_in.map((item) => item.id),
          // interested_in:
          //   result.interested_in.length > 0
          //     ? result.interested_in.map((item) => item.id)
          //     : [],
          last_call_status: result.last_call_status
            ? result.last_call_status
            : "none",
          follow_up_date:
            result.follow_up_date == null
              ? moment().format("YYYY-MM-DD h:mm a")
              : moment(result.follow_up_date).format("YYYY-MM-DD h:mm a"),
          remarks: result.remarks,
          assigned: result.assigned ? result.assigned : "none",
          organization: result.organization ? result.organization : "none",
        });
        // setValues({
        //   name: result.name,
        //   source: result.source,
        //   status: result.status, // need to confirm with backend
        //   priority: result.priority,
        //   email: result?.client?.email,
        //   number: result?.client?.number,
        //   address: result?.client?.address,
        //   marital_status: result?.client?.marital_status,
        //   occupation: result?.client?.occupation,
        //   vehicle_owned: result?.client?.vehicle_owned,
        //   category: result?.client?.category, // need to confirm with backend
        //   interested_in: result.interested_in.map((item) => item.id),
        //   last_call_status: result.last_call_status,
        //   follow_up_date: moment(result.follow_up_date).format("YYYY-MM-DD"),
        //   remarks: result.remarks,
        //   assigned: result.assigned,
        //   organization: result.organization,
        // });
      });
    }
  }, []);

  const handleClickNumber = async (e) => {
    if (
      "contacts" in navigator &&
      "select" in navigator.contacts &&
      "getProperties" in navigator.contacts
    ) {
      try {
        const availableProperties = await navigator.contacts.getProperties();

        if (availableProperties.includes("address")) {
          const contactProperties = ["name", "tel", "address"];

          const contacts = await navigator.contacts.select(contactProperties, {
            multiple: false,
          });

          setFieldValue("number", contacts[0].tel[0]);
        } else {
          console.log(
            "Contact Picker API on your device doesn't support address property"
          );
        }
      } catch (er) {
        console.log("Unexpected error happened in Contact Picker API");
      }
    } else {
      setFieldValue("number", e.target.value);
      console.log("Your browser doesn't support Contact Picker API");
    }
  };

  if (formMode == "edit" && fetching) {
    return `Loading..`;
  }

  return (
    <>
      <Box
        className={responsive.web}
        style={{ backgroundColor: "white", padding: "12px" }}
      >
        {deviceType == "mobile" && (
          <Box marginBottom="10px" display="flex" alignItems="center">
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <Box fontSize="22px" fontWeight={600}>
              {mode == "add" ? "Create" : "Edit"} Lead{" "}
            </Box>
          </Box>
        )}
        <Grid spacing={3} container>
          <Grid item xs={6} spacing={2} container>
            <Grid item xs="12">
              <TextField
                id="name"
                name="name"
                label="Name"
                fullWidth
                placeholder="Name"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && errors.name}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs="12">
              <TextField
                fullWidth
                id="outlined-password-input"
                name="email"
                label="Email"
                placeholder="Email"
                value={values.email}
                type="email"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-password-input"
                name="number"
                label="Phone"
                placeholder="Phone"
                value={values.number}
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.number && errors.number}
                helperText={touched.number && errors.number}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                id="outlined-password-input"
                name="address"
                label="Address"
                placeholder="Address"
                value={values.address}
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.address && errors.address}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                id="outlined-password-input"
                name="city"
                label="City"
                placeholder="City"
                value={values.city}
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.city && errors.city}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Marital Status"
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Marital Status"
                name="marital_status"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.marital_status}
                error={touched.marital_status && errors.marital_status}
              >
                <MenuItem value="none" disabled>
                  Maritial Status
                </MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="UnMarried">Unmarried</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.marital_status && errors.marital_status}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Marital Status"
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Occupation"
                name="occupation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.occupation}
                error={touched.occupation && errors.occupation}
              >
                <MenuItem value="none" disabled>
                  Occupation
                </MenuItem>
                {occupation_types.map((source) => {
                  return (
                    <MenuItem value={source}>{_.capitalize(source)}</MenuItem>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.occupation && errors.occupation}
              </FormHelperText>
              {/* <TextField
              fullWidth
              id="outlined-password-input"
              name="occupation"
              label="Occupation"
              placeholder="Occupation"
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.occupation}
              error={touched.occupation && errors.occupation}
              helperText={touched.occupation && errors.occupation}
            /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                fullWidth
                id="outlined-password-input"
                name="vehicle_owned"
                placeholder="vehicle owned"
                label="Vehicle Owned"
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.vehicle_owned}
                error={touched.vehicle_owned && errors.vehicle_owned}
                helperText={touched.vehicle_owned && errors.vehicle_owned}
              /> */}
              <Select
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Vehicle Owned"
                name="vehicle_owned"
                value={values.vehicle_owned}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.vehicle_owned && errors.vehicle_owned}
              >
                <MenuItem value="none" disabled>
                  Vehicle Owned
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </Grid>
            <Grid item xs="12">
              <Select
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="source"
                value={values.source}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.source && errors.source}
              >
                <MenuItem value="none" disabled>
                  Source
                </MenuItem>
                {sources.map((source) => {
                  return (
                    <MenuItem value={source}>{_.capitalize(source)}</MenuItem>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.source && errors.source}
              </FormHelperText>
            </Grid>
            <Grid item xs="12">
              <Select
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="organization"
                value={values.organization}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.organization && errors.organization}
              >
                <MenuItem value="none" disabled>
                  Organisation
                </MenuItem>
                {orgs.map((organisation) => {
                  return (
                    <MenuItem value={organisation.id}>
                      {_.capitalize(organisation.name)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.source && errors.source}
              </FormHelperText>
            </Grid>
            <Grid item xs="12">
              <Select
                fullWidth
                value={values.category}
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="category"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.category && errors.category}
              >
                <MenuItem value="none" disabled>
                  Client Category
                </MenuItem>
                {client_categories.map((category) => {
                  return (
                    <MenuItem value={category}>
                      {_.capitalize(category)}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.category && errors.category}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid spacing={2} item xs={6} container>
            <Grid item xs={12}>
              <Box display="flex">
                <Box
                  padding="5px"
                  textAlign="center"
                  onClick={() => {
                    setFieldValue("priority", "Hot");
                  }}
                  flex={1}
                >
                  <img
                    className={clsx({
                      [classes.selected]:
                        values.priority === "Hot" || values.priority === "hot",
                    })}
                    src={HotSqIcon}
                  />
                  <Box textAlign="center">Hot</Box>
                </Box>

                <Box
                  textAlign="center"
                  onClick={() => setFieldValue("priority", "Warm")}
                  flex={1}
                  padding="5px"
                >
                  <img
                    className={clsx({
                      [classes.selected]:
                        values.priority === "Warm" ||
                        values.priority === "warm",
                    })}
                    src={WarmSqIcon}
                  />
                  <Box textAlign="center">Warm</Box>
                </Box>

                <Box
                  textAlign="center"
                  onClick={() => setFieldValue("priority", "Cool")}
                  flex={1}
                  padding="5px"
                >
                  <img
                    className={clsx({
                      [classes.selected]:
                        values.priority === "Cool" ||
                        values.priority === "cool",
                    })}
                    src={CoolSqIcon}
                  />
                  <Box textAlign="center">Cool</Box>
                </Box>

                <Box
                  textAlign="center"
                  onClick={() => setFieldValue("priority", "Cold")}
                  flex={1}
                  padding="5px"
                >
                  <img
                    className={clsx({
                      [classes.selected]:
                        values.priority === "Cold" ||
                        values.priority === "cold",
                    })}
                    src={ColdSqIcon}
                  />
                  <Box textAlign="center">Cold</Box>
                </Box>
              </Box>

              <Box>
                <Box marginBottom="10px" color="#FD4243">
                  Hot:{" "}
                  <Box color="black" fontSize="10px" component="span">
                    Highly Interested
                  </Box>
                </Box>
                <Box marginBottom="10px" color="#FE8F02">
                  Warm:{" "}
                  <Box color="black" fontSize="10px" component="span">
                    Interested
                  </Box>
                </Box>
                <Box marginBottom="10px" color="#02A6E9">
                  Cool:{" "}
                  <Box color="black" fontSize="10px" component="span">
                    May be Intrested
                  </Box>
                </Box>
                <Box marginBottom="10px" color="#0B79A5">
                  Cold:{" "}
                  <Box color="black" fontSize="10px" component="span">
                    Not Responding
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs="12">
              <Select
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="interested_in"
                onChange={(event) => {
                  setFieldValue("interested_in", event.target.value);
                }}
                onBlur={handleBlur}
                value={values.interested_in}
                error={touched.interested_in && errors.interested_in}
              >
                <MenuItem value="none" disabled>
                  Interested Properties
                </MenuItem>
                {briks.map((brik) => {
                  return <MenuItem value={brik.id}>{brik.name}</MenuItem>;
                })}
              </Select>
              {/* <Select
                multiple
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="interested_in"
                onChange={(event) => {
                  setFieldValue(
                    "interested_in",
                    event.target.value.filter((item) => item != "none")
                  );
                }}
                onBlur={handleBlur}
                value={
                  values.interested_in.length ? values.interested_in : ["none"]
                }
                error={touched.interested_in && errors.interested_in}
              >
                <MenuItem value="none" disabled>
                  Interested Properties
                </MenuItem>
                {briks.map((brik) => {
                  return <MenuItem value={brik.id}>{brik.name}</MenuItem>;
                })}
              </Select> */}
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.interested_in && errors.interested_in}
                {/* {values.status === "Sales Done" &&
                values.interested_in.length < 1
                  ? "Interested In cannot be empty"
                  : ""}
                {values.status === "Sales Done" &&
                values.interested_in.length > 1
                  ? "Select only one property"
                  : ""} */}
              </FormHelperText>
            </Grid>
            <Grid item xs="12">
              {/* <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="follow_up_date"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.follow_up_date && errors.follow_up_date}
            >
              <MenuItem value="follow_up_date">Next Folloup</MenuItem>
            </Select> */}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  fullWidth
                  margin="normal"
                  label="Follow Up"
                  id="date-picker-dialog"
                  // label="Date picker dialog"
                  format="DD-MM-YYYY hh:mm a"
                  value={values.follow_up_date}
                  onChange={(date) => {
                    // if (date == null) {
                    //   setFieldValue("follow_up_date", null);
                    // } else
                    setFieldValue(
                      "follow_up_date",
                      date
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: `datePicker1`,
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.follow_up_date && errors.follow_up_date}
              </FormHelperText>
            </Grid>
            <Grid item xs="12">
              <Select
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="last_call_status"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_call_status}
                error={touched.last_call_status && errors.last_call_status}
              >
                <MenuItem value="none" disabled>
                  Last Call Status
                </MenuItem>
                {call_statuses.map((status) => {
                  return <MenuItem value={status}>{status}</MenuItem>;
                })}
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.last_call_status && errors.last_call_status}
              </FormHelperText>
            </Grid>
            <Grid item xs="12">
              <Select
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="status"
                onChange={(e) => {
                  setLeadStatus(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.status}
                error={touched.status && errors.status}
              >
                <MenuItem value="none" disabled>
                  Lead Status
                </MenuItem>
                {statuses.map((status) => {
                  return <MenuItem value={status}>{status}</MenuItem>;
                })}
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.status && errors.status}
              </FormHelperText>
            </Grid>
            {values.status === "Sales Done" ? (
              <>
                <Grid item xs="12">
                  <TextField
                    fullWidth
                    multiline
                    id="outlined-password-input"
                    name="sale_value"
                    label="Sales Value"
                    placeholder="Sales Value"
                    value={values.sale_value}
                    type="text"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.sale_value && errors.sale_value}
                    helperText={touched.sale_value && errors.sale_value}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid item xs="12">
              <Select
                fullWidth
                input={<OutlinedInput classes={{ input: classes.input }} />}
                labelId="demo-simple-select-outlined-label"
                placeholder="Lead Status"
                name="assigned"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.assigned}
                error={touched.assigned && errors.assigned}
              >
                <MenuItem value="none" disabled>
                  Assigned To
                </MenuItem>
                {users.map((user) => {
                  return <MenuItem value={user.id}>{user.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
                {touched.assigned && errors.assigned}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                label="Remarks"
                id="outlined-password-input"
                placeholder="Remarks"
                type="text"
                variant="outlined"
                name="remarks"
                value={values.remarks}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.remarks && errors.remarks}
                helperText={touched.remarks && errors.remarks}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                if (deviceType == "website") onBack();
                else history.push("/dashboard");
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={updatingStatus}
              fullWidth
              variant="contained"
              onClick={submitForm}
              color="primary"
            >
              {updatingStatus ? <CircularProgress size={23} /> : "Save"}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box
        className={clsx({ [responsive.app]: true, "app-dashboard": true })}
        style={{ backgroundColor: "white", padding: "12px" }}
      >
        {deviceType == "mobile" && (
          <Box marginBottom="10px" display="flex" alignItems="center">
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <Box fontSize="22px" fontWeight={600}>
              {mode == "add" ? "Create new" : "Edit"} lead{" "}
            </Box>
          </Box>
        )}

        <Grid spacing={2} container>
          <Grid item xs="12">
            <TextField
              id="name"
              name="name"
              label="Name"
              fullWidth
              placeholder="Name"
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && errors.name}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item xs="12">
            <TextField
              fullWidth
              id="outlined-password-input"
              name="email"
              label="Email"
              placeholder="Email"
              value={values.email}
              type="email"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && errors.email}
              helperText={touched.email && errors.email}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-password-input"
              name="number"
              label="Phone"
              placeholder="Phone"
              value={values.number}
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickNumber}
                    >
                      <AccountBoxIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={touched.number && errors.number}
              helperText={touched.number && errors.number}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              id="outlined-password-input"
              name="address"
              label="Address"
              placeholder="Address"
              value={values.address}
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.address && errors.address}
              helperText={touched.address && errors.address}
            />
          </Grid>
          <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                id="outlined-password-input"
                name="city"
                label="City"
                placeholder="City"
                value={values.city}
                type="text"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.city && errors.city}
                helperText={touched.city && errors.city}
              />
            </Grid>
          <Grid item xs={12}>
            <Select
              label="Marital Status"
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Marital Status"
              name="marital_status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.marital_status}
              error={touched.marital_status && errors.marital_status}
            >
              <MenuItem value="Married">Married</MenuItem>
              <MenuItem value="UnMarried">Unmarried</MenuItem>
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.marital_status && errors.marital_status}
            </FormHelperText>
          </Grid>

          <Grid item xs={12}>
            <Select
              label="Marital Status"
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Occupation"
              name="occupation"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.occupation}
              error={touched.occupation && errors.occupation}
            >
              <MenuItem value="none" disabled>
                Occupation
              </MenuItem>
              {occupation_types.map((source) => {
                return (
                  <MenuItem value={source}>{_.capitalize(source)}</MenuItem>
                );
              })}
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.occupation && errors.occupation}
            </FormHelperText>
            {/* <TextField
              fullWidth
              id="outlined-password-input"
              name="occupation"
              label="Occupation"
              placeholder="Occupation"
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.occupation}
              error={touched.occupation && errors.occupation}
              helperText={touched.occupation && errors.occupation}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              id="vehicle_owned"
              name="vehicle_owned"
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Vehicle Owned"
              value={values.vehicle_owned}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.vehicle_owned && errors.vehicle_owned}
            >
              <MenuItem value="none" disabled>
                Vehicle Owned
              </MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
            {/* <TextField
              fullWidth
              id="outlined-password-input"
              name="vehicle_owned"
              placeholder="vehicle owned"
              label="Vehicle Owned"
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vehicle_owned}
              error={touched.vehicle_owned && errors.vehicle_owned}
              helperText={touched.vehicle_owned && errors.vehicle_owned}
            /> */}
          </Grid>
          <Grid item xs="12">
            <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="source"
              value={values.source}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.source && errors.source}
            >
              <MenuItem value="none" disabled>
                Lead Source
              </MenuItem>
              {sources.map((source) => {
                return (
                  <MenuItem value={source}>{_.capitalize(source)}</MenuItem>
                );
              })}
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.source && errors.source}
            </FormHelperText>
          </Grid>
          <Grid item xs="12">
            <Select
              fullWidth
              value={values.category}
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="category"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.category && errors.category}
            >
              <MenuItem value="none" disabled>
                Client Category
              </MenuItem>
              {client_categories.map((category) => {
                return (
                  <MenuItem value={category}>{_.capitalize(category)}</MenuItem>
                );
              })}
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.category && errors.category}
            </FormHelperText>
          </Grid>
          <Grid item xs="12">
            <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="organization"
              value={values.organization}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.organization && errors.organization}
            >
              <MenuItem value="none" disabled>
                Organisation
              </MenuItem>
              {orgs.map((organisation) => {
                return (
                  <MenuItem value={organisation.id}>
                    {_.capitalize(organisation.name)}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.source && errors.source}
            </FormHelperText>
          </Grid>
          <Grid item xs="12">
            <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="assigned"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.assigned}
              error={touched.assigned && errors.assigned}
            >
              <MenuItem value="none" disabled>
                Assigned To
              </MenuItem>
              {users.map((user) => {
                return <MenuItem value={user.id}>{user.name}</MenuItem>;
              })}
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.assigned && errors.assigned}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Box
                textAlign="center"
                onClick={() => {
                  setFieldValue("priority", "Hot");
                }}
                flex={1}
              >
                <img
                  className={clsx({
                    [classes.selected]:
                      values.priority === "Hot" || values.priority === "hot",
                  })}
                  src={HotSqIcon}
                />
                <Box textAlign="center">Hot</Box>
              </Box>

              <Box
                textAlign="center"
                onClick={() => setFieldValue("priority", "Warm")}
                flex={1}
              >
                <img
                  className={clsx({
                    [classes.selected]:
                      values.priority === "Warm" || values.priority === "warm",
                  })}
                  src={WarmSqIcon}
                />
                <Box textAlign="center">Warm</Box>
              </Box>

              <Box
                textAlign="center"
                onClick={() => setFieldValue("priority", "Cool")}
                flex={1}
              >
                <img
                  className={clsx({
                    [classes.selected]:
                      values.priority === "Cool" || values.priority === "cool",
                  })}
                  src={CoolSqIcon}
                />
                <Box textAlign="center">Cool</Box>
              </Box>

              <Box
                textAlign="center"
                onClick={() => setFieldValue("priority", "Cold")}
                flex={1}
              >
                <img
                  className={clsx({
                    [classes.selected]:
                      values.priority === "Cold" || values.priority === "cold",
                  })}
                  src={ColdSqIcon}
                />
                <Box textAlign="center">Cold</Box>
              </Box>
            </Box>

            <Box>
              <Box marginBottom="10px" color="#FD4243">
                Hot:{" "}
                <Box color="black" fontSize="10px" component="span">
                  Lorem ipsum donor festhsiojd
                </Box>
              </Box>
              <Box marginBottom="10px" color="#FE8F02">
                Warm:{" "}
                <Box color="black" fontSize="10px" component="span">
                  Lorem ipsum donor festhsiojd
                </Box>
              </Box>
              <Box marginBottom="10px" color="#02A6E9">
                Cool:{" "}
                <Box color="black" fontSize="10px" component="span">
                  Lorem ipsum donor festhsiojd
                </Box>
              </Box>
              <Box marginBottom="10px" color="#0B79A5">
                Cold:{" "}
                <Box color="black" fontSize="10px" component="span">
                  Lorem ipsum donor festhsiojd
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs="12">
            <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="interested_in"
              onChange={(event) => {
                setFieldValue("interested_in", event.target.value);
              }}
              onBlur={handleBlur}
              value={values.interested_in}
              error={touched.interested_in && errors.interested_in}
            >
              <MenuItem value="none" disabled>
                Interested Properties
              </MenuItem>
              {briks.map((brik) => {
                return <MenuItem value={brik.id}>{brik.name}</MenuItem>;
              })}
            </Select>
            {/* <Select
              multiple
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="interested_in"
              onChange={(event) => {
                setFieldValue(
                  "interested_in",
                  event.target.value.filter((item) => item != "none")
                );
              }}
              onBlur={handleBlur}
              value={
                values.interested_in.length ? values.interested_in : ["none"]
              }
              error={touched.interested_in && errors.interested_in}
            >
              <MenuItem value="none" disabled>
                Intrested Properties
              </MenuItem>
              {briks.map((brik) => {
                return <MenuItem value={brik.id}>{brik.name}</MenuItem>;
              })}
            </Select>*/}
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.interested_in && errors.interested_in}
            </FormHelperText>
          </Grid>
          <Grid item xs="12">
            {/* <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="follow_up_date"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.follow_up_date && errors.follow_up_date}
            >
              <MenuItem value="follow_up_date">Next Folloup</MenuItem>
            </Select> */}

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                inputVariant="outlined"
                fullWidth
                margin="normal"
                label="Next follow up"
                id="date-picker-dialog"
                // label="Date picker dialog"
                format="DD-MM-YYYY hh:mm a"
                value={values.follow_up_date}
                onChange={(date) => {
                  setFieldValue(
                    "follow_up_date",
                    date
                  );
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  id: `datePicker1`,
                }}
              />
            </MuiPickersUtilsProvider>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.follow_up_date && errors.follow_up_date}
            </FormHelperText>
          </Grid>
          <Grid item xs="12">
            <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="last_call_status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_call_status}
              error={touched.last_call_status && errors.last_call_status}
            >
              <MenuItem value="none" disabled>
                Lead Call Status
              </MenuItem>
              {call_statuses.map((status) => {
                return <MenuItem value={status}>{status}</MenuItem>;
              })}
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.last_call_status && errors.last_call_status}
            </FormHelperText>
          </Grid>
          <Grid item xs="12">
            <Select
              fullWidth
              input={<OutlinedInput classes={{ input: classes.input }} />}
              labelId="demo-simple-select-outlined-label"
              placeholder="Lead Status"
              name="status"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.status}
              error={touched.status && errors.status}
            >
              <MenuItem value="none" disabled>
                Lead Status
              </MenuItem>
              {statuses.map((status) => {
                return (
                  <MenuItem value={status}>{_.capitalize(status)}</MenuItem>
                );
              })}
            </Select>
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.status && errors.status}
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Remark"
              id="outlined-password-input"
              placeholder="Remark"
              type="text"
              variant="outlined"
              name="remarks"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.remarks}
              error={touched.remarks && errors.remarks}
              helperText={touched.remarks && errors.remarks}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex">
              <Box flex={1}>
                <Button
                  fullWidth
                  onClick={() => {
                    if (deviceType == "website") onBack();
                    else history.push("/dashboard");
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </Box>
              <Box style={{ marginLeft: "10px" }} flex={1}>
                <Button
                  disabled={updatingStatus}
                  fullWidth
                  variant="contained"
                  onClick={submitForm}
                  color="primary"
                >
                  {updatingStatus ? <CircularProgress size={23} /> : "Save"}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CreateLead;
