import {
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  makeStyles,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../assets/icons/graph/Brikitt.png";
import LoginSideImage from "../assets/icons/graph/login.png";
import { useAuth } from "../providers/AuthProvider";
import { useFormik } from "formik";
import { loginValdation } from "../validations";
import { useSelector } from "react-redux";
import { useGeneralProvider } from "../providers/GeneralProvider";
import clsx from "clsx";

function Login() {
  const useStyles = makeStyles((theme) => ({
    header: {
      height: "100vh",
      width: "100%",
      color: "white",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundSize: "cover",
      background: `linear-gradient(179.75deg, #000000 0.22%, rgba(0, 0, 0, 0.1) 88.05%), url(${LoginSideImage})`,
    },
    web: {
      visibility: "block",
    },
    app: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      web: {
        display: "none",
      },
      app: {
        display: "block",
      },
    },
    // notchedOutline: {
    //     borderWidth: '1px',
    //     borderColor: `${theme.palette.secondary .main} !important`
    // }
  }));

  const history = useHistory();
  const classes = useStyles();
  const general = useGeneralProvider();
  const auth = useAuth();
  const { isComponentBusy } = useSelector((state) => {
    return { isComponentBusy: state.userReducer.isComponentBusy };
  });
  const login = () => {
    auth.login(values);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) history.push("/dashboard");
  }, [login]);

  const { values, touched, errors, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      onSubmit: () => {
        login();
      },
      validationSchema: loginValdation,
    });

  return (
    <>
      <Grid alignItems="center" className={classes.web} container>
        <Grid
          style={{
            color: "white",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
          item
          xs={3}
        >
          <div className={classes.header}>
            <Box
              style={{
                fontSize: "16px",
                fontWeight: 400,
                letterSpacing: "0.15em",
              }}
            >
              WELCOME TO{" "}
            </Box>
            <Box
              style={{
                fontSize: "36px",
                fontWeight: 700,
                paddingBottom: "12px",
                paddingTop: "4px",
              }}
            >
              BRIK itt
            </Box>
            <hr
              style={{
                width: "171px",
                height: "1px",
                color: "#fff",
              }}
            />
            <Box fontSize="16px">Please login to continue</Box>
            <Box
              style={{
                color: "#E5E5E5",
                fontSize: "35px",
                padding: "20px",
                fontWeight: 700,
              }}
            >
              Enjoy Brik iit Experience in simple way
            </Box>
            <Box
              style={{
                color: "#E5E5E5",
                fontSize: "12px",
                padding: "0px 25px",
              }}
            >
              BRIK itt is India’s Leading Proptech Company unlocking Fractional
              Ownership in Private & Residential Real Estate. It brings to the
              people the opportunity to own Exclusive luxury residential &
              vacational assets.
            </Box>
          </div>
        </Grid>
        {/* <Grid style={{ color: "white", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", backgroundImage: `url(${LoginSideImage})`, opacity: 1, height: "100vh" }} item xs={3} >
                    <Box fontSize="12px">WELCOME TO </Box>
                    <Box style={{ fontSize: "18px", fontWeight: 600, paddingBottom: "12px", paddingTop: "4px" }}>BRIK itt</Box>
                    <Box fontSize="11px">Please login to continue</Box>
                    <Box style={{ color: "#E5E5E5", fontSize: "22px", padding: "40px", fontWeight: 600 }}>Enjoy Ownership Experience in simple way</Box>
                    <Box style={{ color: "#E5E5E5", fontSize: '12px', padding: "0px 25px" }}>BRIK itt is India’s Leading  Proptech Company unlocking Fractional Ownership in Private & Residential Real Estate. It brings to the people the opportunity to own Exclusive luxury residential & vacational assets.</Box>
                </Grid> */}
        <Grid container justifyContent="center" item xs={8}>
          <Grid item xs={12}>
            <Box
              padding={3}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Avatar src={Logo} />
              <Box
                style={{
                  padding: "0px 10px",
                  color: "#8B7862",
                  fontWeight: 500,
                }}
              >
                BRIKITT
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Box style={{ padding: "30px" }}>
                <Box style={{ textAlign: "center", fontSize: "24px" }}>
                  Login
                </Box>
                <Box style={{ padding: "20px 0px" }}>
                  <TextField
                    id="email"
                    name="username"
                    error={touched.username && errors.username}
                    helperText={touched.username && errors.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                  />
                </Box>
                <Box style={{ paddingBottom: "20px" }}>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{
                    //     classes: {
                    //         root: classes.cssOutlinedInput,
                    //         focused: classes.cssFocused,
                    //         notchedOutline: classes.notchedOutline,
                    //     },
                    //     inputMode: "numeric"
                    // }}
                    variant="outlined"
                    fullWidth
                    placeholder="Password"
                  />
                </Box>
                <Box>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={isComponentBusy}
                  >
                    {/* {isComponentBusy ? <CircularProgress /> : "Continue"} */}
                    Continue
                  </Button>
                </Box>
                <Box style={{ textAlign: "center", padding: "5px" }}>
                  <Link
                    href="/forgotpassword"
                    style={{ cursor: "pointer", color: "grey", fontSize: 14 }}
                  >
                    Forgot password ?
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className={clsx({ "app-dashboard": true, [classes.app]: true })}
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          style={{
            overflow: "hidden",
            height: "80vh",
            marginTop: "36px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box style={{ color: "#1A4F5D", fontWeight: 600, fontSize: 20 }}>
              Hi Welcome !
            </Box>
            <Box style={{ padding: "10px 0", color: "#5C7B83" }}>
              Explore your Broker’s portal & Enjoy the management experience
            </Box>
          </Box>

          <Box style={{ fontWeight: 600, fontSize: 20, padding: "15px 0" }}>
            Log in
          </Box>
          <Box>
            <Box paddingBottom="13px">
              <TextField
                id="email"
                name="username"
                error={touched.username && errors.username}
                helperText={touched.username && errors.username}
                onChange={handleChange}
                value={values.username}
                onBlur={handleBlur}
                // label="Email"
                variant="outlined"
                fullWidth
                placeholder="Email"
              />
            </Box>
            <Box paddingBottom="13px">
              <TextField
                id="password"
                name="password"
                type="password"
                // label="Password"
                value={values.password}
                error={touched.password && errors.password}
                helperText={touched.password && errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                // InputProps={{
                //     classes: {
                //         root: classes.cssOutlinedInput,
                //         focused: classes.cssFocused,
                //         notchedOutline: classes.notchedOutline,
                //     },
                //     inputMode: "numeric"
                // }}
                variant="outlined"
                fullWidth
                placeholder="Password"
              />
            </Box>

            <Button
              disabled={isComponentBusy}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              required
            >
              {isComponentBusy ? <CircularProgress /> : "Continue"}
            </Button>
            <Typography style={{ textAlign: "center", padding: "10px 0" }}>
              <Link
                href="/forgotpassword"
                style={{ cursor: "pointer", color: "grey", fontSize: 14 }}
              >
                Forgot password ?
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
