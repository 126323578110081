import { Box, IconButton } from '@material-ui/core'
import React, { useRef } from 'react'

function FilePicker(props) {
    const fileInput=useRef({
        current: {
          files: [],
        },
    })

    const fileSelector=()=>{
        fileInput.current.alt = props.filetype;
        fileInput.current.accept = `${props.filetype}/*`;
        fileInput.current.click()
    }

    const buttonRender=()=>{
        switch(props.type){
            case 'iconButton':
                return (
                    <IconButton size="small" onClick={fileSelector}><img height="20px" src={props.icon} /></IconButton>
                )
            case "component":
                return <Box onClick={fileSelector}>{props.children}</Box>
        }
    }

    const FileHandler = (e) => {
        if(props.multiple)
            props.fileHandler(e.target.files, e.target.alt);
        else
            props.fileHandler(e.target.files[0], e.target.alt);
    }

    return (
        <>
            <input multiple={props.multiple} onChange={FileHandler} style={{display:"none"}} ref={fileInput} type="file" />
            {buttonRender()}     
        </>
    )
}

// FilePicker.prototype

export default FilePicker
