import {
  Box,
  IconButton,
  makeStyles,
  Card,
  Button,
  Grid,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
} from "@material-ui/core";
import Avatar from "react-avatar";
import { getLeads, getLeadsSearching } from "../store/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreVert";
import List from "../assets/icons/graph/list.png";
import Grids from "../assets/icons/graph/grid.png";
import List1 from "../assets/icons/graph/list1.png";
import Grids1 from "../assets/icons/graph/grid1.png";
import Star1 from "../assets/icons/graph/star1.png";
import Star2 from "../assets/icons/graph/star2.png";
import Dots from "../assets/icons/graph/dots.png";
import Pdf from "../assets/icons/graph/pdf.png";
import Doc from "../assets/icons/graph/doc.png";
import Mp4 from "../assets/icons/graph/mp4.png";
import Xls from "../assets/icons/graph/xls.png";
import Ppt from "../assets/icons/graph/ppt.png";
import Documents from "../assets/icons/graph/document.png";

import clsx from "clsx";
import { responsiveStyles } from "../assets/css/generalStyling";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import pdfIcon from "../assets/icons/pdf.svg";
import { Close } from "@material-ui/icons";
import Modal from "../component/Modal";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import searchModal from "../assets/icons/searchModal.png";
import {
  getDocumentDetails,
  starDocument,
  metaData,
} from "../store/actions/apis";
import Share from "../utils/Share";
import { useAuth } from "../providers/AuthProvider";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "10px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "2px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "60px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  hasAppointmentColor: {
    color: "orange",
  },
  hasAppointmentonDay: {
    display: "block !important",
  },
  hasAppointment: {
    fontSize: "37px",
    display: "none",
    position: "absolute",
    margin: "0px",
    bottom: "-3px",
    left: "14px",
  },
  notchedOutlineInput: {
    borderRadius: "20px",
  },
  selectedDay: {
    // paddingTop: "10px",
    // borderRadius: "50px",
    cursor: "pointer",
    // color: "white",
    // backgroundColor: "#e8845f50",
  },
  selectedDate: {
    backgroundColor: "#FF5D7A",
    color: "white",
  },
  day: {
    // paddingTop: "10px",
    // borderRadius: "50px",
    cursor: "pointer",
    // "&:hover": {
    //     color: "white",
    //     backgroundColor: "#e8845f50",
    // },
    // "&:hover $date": {
    //     backgroundColor: "#e8845f",
    //     color: "white",
    // },
  },
  date: {
    padding: "8px 10px",
    borderRadius: "5px",
    position: "relative",
    // minHeight: "30px"
    // background: "#e8845f",
    // color: "white"
  },
  time: {
    fontSize: "30px",
    fontWeight: 500,
    color: "#fff",
    fontFamily: "lato",
  },
  text: {
    fontSize: "13px",
    fontWeight: "normal",
  },
  verticalBar: {
    height: "30px",
    width: "3px",
    backgroundColor: "#fff",
    color: "white",
    margin: "5px 10px",
  },
  activeText: {
    color: "#825ffc",
  },
  inactiveText: {
    color: "grey",
  },
  gradientColor: {
    backgroundImage: "linear-gradient(110deg, #22cfc7, #7c79e4 100%)",
  },
  selectedDocument: {
    backgroundColor: "#a1a1a1",
    padding: "5px",
    borderRadius: "5px",
  },
}));

function Document() {
  const auth = useAuth();
  const [tab, setTab] = useState("all");
  const dispatch = useDispatch();
  const [grid, setGrid] = useState(true);
  const [starred, setStarred] = useState([]);
  const responsive = responsiveStyles();
  const [documentDetails, setDocumentDetails] = useState({});
  const history = useHistory();
  const classes = useStyles();
  const { searchLeads } = useSelector((state) => state.dashboard);
  const {
    meta_data: { briks },
  } = useSelector((state) => state.dashboard);
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState("");
  const [filter, setFilter] = useState("all");
  const [value, setValue] = React.useState(0);
  const [modal, setModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [selectedDoc1, setSelectedDoc1] = useState([]);
  const [meta, setMeta] = useState([]);
  const [property, setProperty] = useState([]);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: true,
    checkedD: true,
    checkedE: false,
    checkedF: false,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getLeadsSearching({ search: searching, status: "", priority: [] })
      );
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searching]);

  const fetchAllDocument = ({ starred, brik_id = [] }) => {
    if (starred)
      getDocumentDetails({ starred: 1, document_name: search, brik_id })
        .then((result) => {
          console.log("starred result", result.data);
          setStarred(result.data);
          setSelectedDoc1(result.data[0]);
          setDocumentDetails(result.data[0]);
          // console.log("data.due_remainders: ", _.isEmpty(data.due_remainders))
          // setDueRemainders(data.due_remainders)
        })
        .catch((er) => {
          console.log("error: ", er);
        });
    else
      getDocumentDetails({ starred: "", document_name: search, brik_id })
        .then((result) => {
          console.log("result", result.data);
          setDocs(result.data);
          setSelectedDoc(result.data[0]);
          setDocumentDetails(result.data[0]);
          // console.log("data.due_remainders: ", _.isEmpty(data.due_remainders))
          // setDueRemainders(data.due_remainders)
        })
        .catch((er) => {
          console.log("error: ", er);
        });
  };

  useEffect(() => {
    fetchAllDocument({ starred: true });
    // if (Object.keys(myplanPayment).length == 0)
    fetchAllDocument({ starred: false });
  }, [search]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (e, id) => {
    const checked = e.target.checked;
    if (checked) property.push(id);
    else {
      for (var i = property.length - 1; i >= 0; i--) {
        if (property[i] === id) {
          property.splice(i, 1);
        }
      }
    }
    setProperty([...property]);
  };

  const ModalHeader = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          {/* <Box>
                        <IconButton size="small" onClick={() => {
                            history.goBack()
                        }}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Box> */}
          <Box fontSize="20px" fontWeight="600">
            Filter
          </Box>
        </Box>

        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Modal
        width="450px"
        height="400px"
        headerComponent={ModalHeader}
        status={modal}
        // saveBtnText="Apply"
        // saveHandler={() => {
        //   getDocumentDetails({
        //     starred: 1,
        //     document_name: search,
        //     brik_id: property,
        //   }).then((result) => {
        //     setModal(false);
        //   });
        // }}
      >
        <Box padding="10px 0">
          <TextField
            // onChange={(e) => {
            //   setSearch(e.target.value);
            // }}
            placeholder="Search Documents"
            style={{
              backgroundColor: "#dbdbdb91",
              borderRadius: "18px",
              color: "#787878",
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutlineInput,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="#787878" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box>
          <FormGroup>
            {briks
              ? briks.map((brik) => (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={property.includes(brik.id)}
                          onClick={(e) => handleChange(e, brik.id)}
                          name={brik.id}
                          color="primary"
                        />
                      }
                      label={brik.name}
                    />
                  </>
                ))
              : "No briks to filter"}
          </FormGroup>
        </Box>
        <Box
          display="flex"
          style={{
            position: "relative",
            width: "100%",
            left: "0px",
            bottom: "0px",
          }}
          justifyContent="space-between"
          padding="0"
        >
          <Box>{briks.length} Properties</Box>
          <Button
            onClick={() => {
              console.log("HELLO DATA: ", {
                starred: false,
                brik_id: property,
              });
              if (tab == "starred")
                fetchAllDocument({
                  starred: true,
                  brik_id: property,
                });
              else
                fetchAllDocument({
                  starred: false,
                  brik_id: property,
                });
              setModal(false);
            }}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </Box>
      </Modal>

      <Box className={responsive.web}>
        <Box
          style={{ marginBottom: "10px", fontSize: "18px", fontWeight: "700" }}
        >
          Documents
        </Box>
        <Grid container spacing={2} style={{ marginTop: "1%" }}>
          <Grid item md={9}>
            <Card style={{ backgroundColor: "white", borderRadius: "12px" }}>
              <Box style={{ padding: "10px", marginBottom: "10px" }}>
                <Box style={{ padding: "10px", float: "left" }}>
                  {tab == "all" ? (
                    <>
                      <Button
                        onClick={() => {
                          setTab("all");
                        }}
                        style={{
                          color: "#8B7862",
                          borderBottom: "3px solid #8B7862",
                          borderRadius: "0px",
                        }}
                      >
                        ALL
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          setTab("all");
                        }}
                        style={{
                          color: "#8A9EBC",
                        }}
                      >
                        ALL
                      </Button>
                    </>
                  )}
                  {tab == "starred" ? (
                    <>
                      <Button
                        onClick={() => {
                          setTab("starred");
                        }}
                        style={{
                          color: "#8B7862",
                          borderBottom: "3px solid #8B7862",
                          borderRadius: "0px",
                        }}
                      >
                        STARRED
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          setTab("starred");
                        }}
                        style={{
                          color: "#8A9EBC",
                        }}
                      >
                        STARRED
                      </Button>
                    </>
                  )}
                </Box>
                {/* <SearchBar
                  // onChange={() => console.log('onChange')}
                  // onRequestSearch={() => console.log('onRequestSearch')}
                  placeholder="Search Documents"
                  style={{
                    margin: "auto",
                    maxWidth: "40%",
                    float: "left",
                    backgroundColor: "#EFEFEF",
                    textAlign: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                  }}
                /> */}
                <Box
                  style={{
                    padding: "0px 0px",
                    maxWidth: "50%",
                    float: "left",
                  }}
                >
                  <TextField
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search Documents"
                    style={{
                      backgroundColor: "#dbdbdb91",
                      borderRadius: "18px",
                      color: "#787878",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutlineInput,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="#787878" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModal(true);
                          }}
                        >
                          {/* <FormatListBulletedIcon color="#787878" /> */}
                          <img src={searchModal} />
                        </InputAdornment>
                      ),
                      // endAdornment: (
                      //     <InputAdornment position="end">
                      //         <IconButton size="small">
                      //             <SearchIcon color="#787878" />
                      //         </IconButton>
                      //     </InputAdornment>
                      // )
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box
                  style={{
                    float: "right",
                    backgroundColor: "#EFEFEF",
                    borderRadius: "4px",
                    paddingTop: "5px",
                    paddingRight: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  {grid ? (
                    <>
                      <img
                        onClick={() => {
                          setGrid(false);
                        }}
                        src={List}
                      />
                      <img
                        onClick={() => {
                          setGrid(true);
                        }}
                        src={Grids}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        onClick={() => {
                          setGrid(false);
                        }}
                        src={List1}
                      />
                      <img
                        onClick={() => {
                          setGrid(true);
                        }}
                        src={Grids1}
                      />
                    </>
                  )}
                </Box>
              </Box>
              <br />
              <br />
              {tab == "all" &&
                (grid ? (
                  <Grid container spacing={2} style={{ padding: "0 10px" }}>
                    {docs.map((doc) => (
                      <>
                        <Grid item md={3}>
                          {/* {setFileExtenstion(doc.document.split('.').pop())}
                          {console.log(fileExtension)} */}
                          {doc === selectedDoc ? (
                            <>
                              <Card
                                style={{
                                  backgroundColor: "white",
                                  border: "2px solid #8B7862",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setDocumentDetails(doc);
                                  setSelectedDoc(doc);
                                }}
                              >
                                <Box style={{ padding: "5%" }}>
                                  <Box
                                    onClick={() => {
                                      starDocument({
                                        document_id: doc.id,
                                        status: doc.is_starred,
                                        userId: auth.getUserId(),
                                      }).then((result) => {
                                        // fetchAllDocument(true);
                                        // fetchAllDocument(true);
                                      });
                                    }}
                                    style={{ float: "left", cursor: "pointer" }}
                                  >
                                    {doc.is_starred ? (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = false;
                                          setDocs([...docs]);
                                        }}
                                        src={Star2}
                                      />
                                    ) : (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = true;
                                          setDocs([...docs]);
                                        }}
                                        src={Star1}
                                      />
                                    )}
                                  </Box>
                                  <Box style={{ float: "right" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "50%",
                                    paddingTop: "2%",
                                  }}
                                >
                                  <br />
                                  <center>
                                    {console.log(doc.document.split(".").pop())}
                                    {doc.doc_type === "pdf" ? (
                                      <img src={Pdf} />
                                    ) : doc.doc_type === "document" ? (
                                      <img src={Doc} />
                                    ) : doc.doc_type === "excel" ? (
                                      <img src={Xls} />
                                    ) : doc.doc_type === "presentation" ? (
                                      <img src={Ppt} />
                                    ) : (
                                      <img src={Mp4} />
                                    )}
                                    {/* <img src={Pdf} /> */}
                                  </center>
                                  <center style={{ fontSize: "12px" }}>
                                    {doc.document_name}
                                  </center>
                                </Box>
                                <br />
                                <hr
                                  style={{
                                    backgroundColor: "#C4C4C4",
                                    borderColor: "#C4C4C4",
                                    color: "#C4C4C4",
                                  }}
                                />
                                <Box>
                                  <Box style={{ float: "left" }}>
                                    <Box
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      File Size
                                    </Box>
                                    <Box
                                      style={{
                                        color: "#8A9EBC",
                                        fontSize: "12px",
                                      }}
                                    >
                                      8.25MB
                                    </Box>
                                  </Box>
                                  <Box style={{ float: "right" }}></Box>
                                </Box>
                              </Card>
                            </>
                          ) : (
                            <>
                              <Card
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid #F3F3F3",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setSelectedDoc(doc);
                                  setDocumentDetails(doc);
                                }}
                              >
                                <Box style={{ padding: "5%" }}>
                                  <Box
                                    onClick={() => {
                                      starDocument({
                                        document_id: doc.id,
                                        status: doc.is_starred,
                                        userId: auth.getUserId(),
                                      }).then((result) => {
                                        fetchAllDocument({ starred: false });
                                        fetchAllDocument({ starred: true });
                                      });
                                    }}
                                    style={{ float: "left", cursor: "pointer" }}
                                  >
                                    {doc.is_starred ? (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = false;
                                          setDocs([...docs]);
                                        }}
                                        src={Star2}
                                      />
                                    ) : (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = true;
                                          setDocs([...docs]);
                                        }}
                                        src={Star1}
                                      />
                                    )}
                                  </Box>
                                  <Box style={{ float: "right" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "50%",
                                    paddingTop: "2%",
                                  }}
                                >
                                  <br />
                                  <center>
                                    {doc.doc_type === "pdf" ? (
                                      <img src={Pdf} />
                                    ) : doc.doc_type === "document" ? (
                                      <img src={Doc} />
                                    ) : doc.doc_type === "excel" ? (
                                      <img src={Xls} />
                                    ) : doc.doc_type === "presentation" ? (
                                      <img src={Ppt} />
                                    ) : (
                                      <img src={Mp4} />
                                    )}
                                  </center>
                                  <center style={{ fontSize: "12px" }}>
                                    {doc.document_name}
                                  </center>
                                </Box>
                                <br />
                                <hr
                                  style={{
                                    backgroundColor: "#C4C4C4",
                                    borderColor: "#C4C4C4",
                                    color: "#C4C4C4",
                                  }}
                                />
                                <Box>
                                  <Box style={{ float: "left" }}>
                                    <Box
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      File Size
                                    </Box>
                                    <Box
                                      style={{
                                        color: "#8A9EBC",
                                        fontSize: "12px",
                                      }}
                                    >
                                      8.25MBB
                                    </Box>
                                  </Box>
                                  <Box style={{ float: "right" }}></Box>
                                </Box>
                              </Card>
                            </>
                          )}
                        </Grid>
                      </>
                    ))}
                  </Grid>
                ) : (
                  <>
                    <Box style={{ padding: "10px" }}>
                      {docs.map((doc) => (
                        <>
                          {doc === selectedDoc ? (
                            <>
                              <Card
                                style={{
                                  marginTop: "15px",
                                  backgroundColor: "white",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  border: "2px solid #8B7862",
                                }}
                                onClick={() => {
                                  setSelectedDoc(doc);
                                  setDocumentDetails(doc);
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={1}>
                                    {doc.doc_type === "pdf" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Pdf}
                                      />
                                    ) : doc.doc_type === "document" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Doc}
                                      />
                                    ) : doc.doc_type === "excel" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Xls}
                                      />
                                    ) : doc.doc_type === "presentation" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Ppt}
                                      />
                                    ) : (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Mp4}
                                      />
                                    )}
                                    {/* <img width="30px" height="30px" src={Pdf} /> */}
                                  </Grid>
                                  <Grid
                                    item
                                    md={3}
                                    style={{ fontSize: "14px", margin: "auto" }}
                                  >
                                    <span>{doc.document_name}</span>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    style={{
                                      fontSize: "12px",
                                      margin: "auto",
                                      color: "#8A9EBC",
                                    }}
                                  >
                                    8.5MBA
                                  </Grid>
                                  <Grid item md={4}></Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <Box
                                      onClick={() => {
                                        starDocument({
                                          document_id: doc.id,
                                          status: doc.is_starred,
                                          userId: auth.getUserId(),
                                        }).then((result) => {
                                          fetchAllDocument(true);
                                          fetchAllDocument(true);
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {doc.is_starred ? (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = false;
                                            setDocs([...docs]);
                                          }}
                                          src={Star2}
                                        />
                                      ) : (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = true;
                                            setDocs([...docs]);
                                          }}
                                          src={Star1}
                                        />
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Grid>
                                </Grid>
                              </Card>
                            </>
                          ) : (
                            <>
                              <Card
                                style={{
                                  marginTop: "10px",
                                  backgroundColor: "white",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "10px",
                                }}
                                onClick={() => {
                                  setSelectedDoc(doc);
                                  setDocumentDetails(doc);
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={1}>
                                    {doc.doc_type === "pdf" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Pdf}
                                      />
                                    ) : doc.doc_type === "document" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Doc}
                                      />
                                    ) : doc.doc_type === "excel" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Xls}
                                      />
                                    ) : doc.doc_type === "presentation" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Ppt}
                                      />
                                    ) : (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Mp4}
                                      />
                                    )}
                                    {/* <img width="30px" height="30px" src={Pdf} /> */}
                                  </Grid>
                                  <Grid item md={3} style={{ margin: "auto" }}>
                                    <span style={{ fontSize: "12px" }}>
                                      {doc.document_name}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    style={{
                                      fontSize: "12px",
                                      margin: "auto",
                                      color: "#8A9EBC",
                                    }}
                                  >
                                    8.5MB
                                  </Grid>
                                  <Grid item md={4}></Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <Box
                                      onClick={() => {
                                        starDocument({
                                          document_id: doc.id,
                                          status: doc.is_starred,
                                          userId: auth.getUserId(),
                                        }).then((result) => {
                                          fetchAllDocument(true);
                                          fetchAllDocument(true);
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {doc.is_starred ? (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = false;
                                            setDocs([...docs]);
                                          }}
                                          src={Star2}
                                        />
                                      ) : (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = true;
                                            setDocs([...docs]);
                                          }}
                                          src={Star1}
                                        />
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Grid>
                                </Grid>
                              </Card>
                            </>
                          )}
                        </>
                      ))}
                    </Box>
                  </>
                ))}
              {tab == "starred" &&
                (grid ? (
                  <Grid container spacing={2} style={{ padding: "0 10px" }}>
                    {starred.map((doc) => (
                      <>
                        <Grid item md={3}>
                          {selectedDoc1 === doc ? (
                            <>
                              <Card
                                style={{
                                  backgroundColor: "white",
                                  border: "2px solid #8B7862",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setSelectedDoc1(doc);
                                  setDocumentDetails(doc);
                                }}
                              >
                                <Box style={{ padding: "5%" }}>
                                  <Box
                                    onClick={() => {
                                      starDocument({
                                        document_id: doc.id,
                                        status: doc.is_starred,
                                        userId: auth.getUserId(),
                                      }).then((result) => {
                                        fetchAllDocument(true);
                                        fetchAllDocument(true);
                                      });
                                    }}
                                    style={{ float: "left", cursor: "pointer" }}
                                  >
                                    {doc.is_starred ? (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = false;
                                          setDocs([...docs]);
                                        }}
                                        src={Star2}
                                      />
                                    ) : (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = true;
                                          setDocs([...docs]);
                                        }}
                                        src={Star1}
                                      />
                                    )}
                                  </Box>
                                  <Box style={{ float: "right" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "50%",
                                    paddingTop: "2%",
                                  }}
                                >
                                  <br />
                                  <center>
                                    {doc.doc_type === "pdf" ? (
                                      <img src={Pdf} />
                                    ) : doc.doc_type === "document" ? (
                                      <img src={Doc} />
                                    ) : doc.doc_type === "excel" ? (
                                      <img src={Xls} />
                                    ) : doc.doc_type === "presentation" ? (
                                      <img src={Ppt} />
                                    ) : (
                                      <img src={Mp4} />
                                    )}
                                  </center>
                                  <center style={{ fontSize: "12px" }}>
                                    {doc.document_name}
                                  </center>
                                </Box>
                                <br />
                                <hr
                                  style={{
                                    backgroundColor: "#C4C4C4",
                                    borderColor: "#C4C4C4",
                                    color: "#C4C4C4",
                                  }}
                                />
                                <Box>
                                  <Box style={{ float: "left" }}>
                                    <Box
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      File Size
                                    </Box>
                                    <Box
                                      style={{
                                        color: "#8A9EBC",
                                        fontSize: "12px",
                                      }}
                                    >
                                      8.25MB
                                    </Box>
                                  </Box>
                                  <Box style={{ float: "right" }}></Box>
                                </Box>
                              </Card>
                            </>
                          ) : (
                            <>
                              <Card
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid #F3F3F3",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "5px",
                                }}
                                onClick={() => {
                                  setSelectedDoc1(doc);
                                  setDocumentDetails(doc);
                                }}
                              >
                                <Box style={{ padding: "5%" }}>
                                  <Box
                                    onClick={() => {
                                      starDocument({
                                        document_id: doc.id,
                                        status: doc.is_starred,
                                        userId: auth.getUserId(),
                                      }).then((result) => {
                                        fetchAllDocument(true);
                                        fetchAllDocument(true);
                                      });
                                    }}
                                    style={{ float: "left", cursor: "pointer" }}
                                  >
                                    {doc.is_starred ? (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = false;
                                          setDocs([...docs]);
                                        }}
                                        src={Star2}
                                      />
                                    ) : (
                                      <img
                                        onClick={() => {
                                          doc.is_starred = true;
                                          setDocs([...docs]);
                                        }}
                                        src={Star1}
                                      />
                                    )}
                                  </Box>
                                  <Box style={{ float: "right" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Box>
                                </Box>
                                <Box
                                  style={{
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "50%",
                                    paddingTop: "2%",
                                  }}
                                >
                                  <br />
                                  {doc.doc_type === "pdf" ? (
                                    <img src={Pdf} />
                                  ) : doc.doc_type === "document" ? (
                                    <img src={Doc} />
                                  ) : doc.doc_type === "excel" ? (
                                    <img src={Xls} />
                                  ) : doc.doc_type === "presentation" ? (
                                    <img src={Ppt} />
                                  ) : (
                                    <img src={Mp4} />
                                  )}
                                  <center style={{ fontSize: "12px" }}>
                                    {doc.document_name}
                                  </center>
                                </Box>
                                <br />
                                <hr
                                  style={{
                                    backgroundColor: "#C4C4C4",
                                    borderColor: "#C4C4C4",
                                    color: "#C4C4C4",
                                  }}
                                />
                                <Box>
                                  <Box style={{ float: "left" }}>
                                    <Box
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      File Size
                                    </Box>
                                    <Box
                                      style={{
                                        color: "#8A9EBC",
                                        fontSize: "12px",
                                      }}
                                    >
                                      8.25MB
                                    </Box>
                                  </Box>
                                  <Box style={{ float: "right" }}></Box>
                                </Box>
                              </Card>
                            </>
                          )}
                        </Grid>
                      </>
                    ))}
                  </Grid>
                ) : (
                  <>
                    <Box style={{ padding: "10px" }}>
                      {starred.map((doc) => (
                        <>
                          {selectedDoc1 === doc ? (
                            <>
                              <Card
                                style={{
                                  marginTop: "10px",
                                  backgroundColor: "white",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  border: "2px solid #8B7862",
                                }}
                                onClick={() => {
                                  setSelectedDoc1(doc);
                                  setDocumentDetails(doc);
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={1}>
                                    {doc.doc_type === "pdf" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Pdf}
                                      />
                                    ) : doc.doc_type === "document" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Doc}
                                      />
                                    ) : doc.doc_type === "excel" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Xls}
                                      />
                                    ) : doc.doc_type === "presentation" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Ppt}
                                      />
                                    ) : (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Mp4}
                                      />
                                    )}
                                    {/* <img width="30px" height="30px" src={Pdf} /> */}
                                  </Grid>
                                  <Grid item md={3} style={{ margin: "auto" }}>
                                    <span style={{ fontSize: "12px" }}>
                                      {doc.document_name}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    style={{
                                      fontSize: "12px",
                                      margin: "auto",
                                      color: "#8A9EBC",
                                    }}
                                  >
                                    8.5MB
                                  </Grid>
                                  <Grid item md={4}></Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <Box
                                      onClick={() => {
                                        starDocument({
                                          document_id: doc.id,
                                          status: doc.is_starred,
                                          userId: auth.getUserId(),
                                        }).then((result) => {
                                          fetchAllDocument(true);
                                          fetchAllDocument(true);
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {doc.is_starred ? (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = false;
                                            setDocs([...docs]);
                                          }}
                                          src={Star2}
                                        />
                                      ) : (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = true;
                                            setDocs([...docs]);
                                          }}
                                          src={Star1}
                                        />
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Grid>
                                </Grid>
                              </Card>
                            </>
                          ) : (
                            <>
                              <Card
                                style={{
                                  marginTop: "10px",
                                  backgroundColor: "white",
                                  boxShadow: "0px 4px 14px #E7E7E7",
                                  borderSizing: "border-box",
                                  borderRadius: "5px",
                                  padding: "10px",
                                }}
                                onClick={() => {
                                  setSelectedDoc1(doc);
                                  setDocumentDetails(doc);
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={1}>
                                    {doc.doc_type === "pdf" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Pdf}
                                      />
                                    ) : doc.doc_type === "document" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Doc}
                                      />
                                    ) : doc.doc_type === "excel" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Xls}
                                      />
                                    ) : doc.doc_type === "presentation" ? (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Ppt}
                                      />
                                    ) : (
                                      <img
                                        height="30px"
                                        width="30px"
                                        src={Mp4}
                                      />
                                    )}
                                    {/* <img width="30px" height="30px" src={Pdf} /> */}
                                  </Grid>
                                  <Grid item md={3} style={{ margin: "auto" }}>
                                    <span style={{ fontSize: "12px" }}>
                                      {doc.document_name}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    style={{
                                      fontSize: "12px",
                                      margin: "auto",
                                      color: "#8A9EBC",
                                    }}
                                  >
                                    8.5MB
                                  </Grid>
                                  <Grid item md={4}></Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <Box
                                      onClick={() => {
                                        starDocument({
                                          document_id: doc.id,
                                          status: doc.is_starred,
                                          userId: auth.getUserId(),
                                        }).then((result) => {
                                          fetchAllDocument(true);
                                          fetchAllDocument(true);
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {doc.is_starred ? (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = false;
                                            setDocs([...docs]);
                                          }}
                                          src={Star2}
                                        />
                                      ) : (
                                        <img
                                          onClick={() => {
                                            doc.is_starred = true;
                                            setDocs([...docs]);
                                          }}
                                          src={Star1}
                                        />
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item md={1} style={{ margin: "auto" }}>
                                    <PopupState
                                      variant="popover"
                                      popupId="demoMenu"
                                    >
                                      {(popupState) => (
                                        <React.Fragment>
                                          <IconButton
                                            size="small"
                                            variant="contained"
                                            {...bindTrigger(popupState)}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              bindTrigger(popupState).onClick(
                                                event
                                              );
                                            }}
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                          <Menu
                                            {...bindMenu(popupState)}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            style={{ top: "5px", left: "1px" }}
                                          >
                                            <MenuItem
                                              onClick={(event) => {
                                                window.open(doc.document);
                                                popupState.close();
                                              }}
                                            >
                                              View
                                            </MenuItem>
                                          </Menu>
                                        </React.Fragment>
                                      )}
                                    </PopupState>
                                  </Grid>
                                </Grid>
                              </Card>
                            </>
                          )}
                        </>
                      ))}
                    </Box>
                  </>
                ))}
              <br />
              <br />
            </Card>
          </Grid>

          <Grid
            item
            md={3}
            style={{
              borderRadius: "12px",
            }}
          >
            {tab == "all" ? (
              <>
                {console.log("selectedDoc------: ", selectedDoc)}
                <Card
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    position: "relative",
                    borderRadius: "12px",
                  }}
                >
                  <Box
                    style={{ textAlign: "left", padding: "12px 12px 0px 12px" }}
                  >
                    {/* <img src={Documents} />
                &nbsp;&nbsp; */}
                    <span
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#565656",
                      }}
                    >
                      {selectedDoc?.document_name}
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#8A9EBC",
                      }}
                    >
                      8.25 MB
                    </span>
                  </Box>
                  <hr
                    style={{
                      backgroundColor: "#C4C4C4",
                      borderColor: "#C4C4C4",
                      color: "#C4C4C4",
                      width: "90%",
                    }}
                  />
                  <Box
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(selectedDoc.document, "_blank");
                    }}
                  >
                    <center>
                      {selectedDoc?.doc_type === "pdf" ? (
                        <img height="100px" width="100px" src={Pdf} />
                      ) : selectedDoc?.doc_type === "document" ? (
                        <img height="100px" width="100px" src={Doc} />
                      ) : selectedDoc?.doc_type === "excel" ? (
                        <img height="100px" width="100px" src={Xls} />
                      ) : selectedDoc?.doc_type === "presentation" ? (
                        <img height="100px" width="100px" src={Ppt} />
                      ) : (
                        <img height="100px" width="100px" src={Mp4} />
                      )}
                      {/* <img src={Pdf} height="100px" width="100px" /> */}
                    </center>
                  </Box>
                  <Box
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      paddingLeft: "12px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#8A9EBC",
                      }}
                    >
                      Property:{" "}
                      {selectedDoc?.property_name
                        ? selectedDoc?.property_name
                        : "No property"}
                    </span>
                  </Box>
                  <Box
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#8A9EBC",
                    }}
                  ></Box>
                  <hr
                    style={{
                      backgroundColor: "#C4C4C4",
                      borderColor: "#C4C4C4",
                      color: "#C4C4C4",
                      width: "90%",
                    }}
                  />
                  <Box
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#282827",
                      padding: "12px",
                    }}
                  >
                    Description
                  </Box>
                  <Box
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#8A9EBC",
                      padding: "0px 12px 12px 12px",
                    }}
                  >
                    {selectedDoc?.description
                      ? selectedDoc?.description
                      : "No description"}
                  </Box>
                  <hr
                    style={{
                      backgroundColor: "#C4C4C4",
                      borderColor: "#C4C4C4",
                      color: "#C4C4C4",
                      width: "90%",
                    }}
                  />
                  <Box>
                    <Box
                      style={{
                        float: "left",
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#282827",
                        paddingLeft: "12px",
                        marginBottom: "10px",
                      }}
                    >
                      Share with
                    </Box>
                    <Box padding="12px">
                      <TextField
                        style={{
                          backgroundColor: "#dbdbdb91",
                          borderRadius: "18px",
                          color: "#787878",
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutlineInput,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: "#787878" }} />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Search leads"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setSearching(e.target.value);
                        }}
                      />
                    </Box>
                    {/* <Box
                      style={{
                        float: "right",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#8A9EBC",
                      }}
                    >
                      View all
                    </Box> */}
                  </Box>

                  <Box marginBottom="50px" height="300px" overflow="scroll">
                    {searchLeads.results.map((lead) => {
                      return (
                        <Box
                          onClick={() => {
                            setSelectedDocument(lead);
                          }}
                          className={clsx({
                            [classes.selectedDocument]:
                              lead.id == selectedDocument.id,
                          })}
                          margin="10px"
                          style={{ cursor: "pointer" }}
                          display="flex"
                          alignItems="center"
                        >
                          <Avatar
                            round={true}
                            size="40"
                            style={{ cursor: "pointer" }}
                            // src={auth.getUser().profile_pic}
                            name={lead.name}
                          />
                          <Box marginLeft="8px">{lead.name}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Card
                    elevation={4}
                    className="share-via"
                    style={{
                      borderRadius: "20px 20px 0px 0px",
                      padding: "10px",
                      marginBottom: "0%",
                    }}
                  >
                    <Box
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#8A9EBC",
                        paddingBottom: "4px",
                      }}
                    >
                      SHARE VIA
                    </Box>
                    <Box>
                      <Box style={{ float: "left", width: "50%" }}>
                        <Button
                          style={{
                            width: "90%",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#8B7862",
                            borderRadius: "7px",
                            color: "white",
                          }}
                          onClick={() => {
                            console.log(
                              "Selecyed Lead WEBSS: ",
                              selectedDocument
                            );

                            let text = `Hi%20${
                              selectedDocument.client.name
                            }%0A%0A${encodeURI(
                              selectedDoc?.description
                            )}%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                              documentDetails.document
                            )}`;
                            // let text = `Hi%20${
                            //   selectedDocument.client.name
                            // }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                            //   documentDetails.document
                            // )}`;

                            window.open(
                              `https://api.whatsapp.com/send/?phone=+91${selectedDocument.client.number}&text=${text}&app_absent=0`
                            );
                          }}
                        >
                          WHATSAPP
                        </Button>
                      </Box>
                      <Box style={{ float: "right", width: "50%" }}>
                        <Button
                          variant="outlined"
                          style={{
                            width: "90%",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            border: "2px solid #8B7862",
                            borderRadius: "7px",
                            color: "#8B7862",
                          }}
                          onClick={() => {
                            // let text = `Hi%20${
                            //   selectedDocument.client.name
                            // }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                            //   documentDetails.document
                            // )}`;

                            let text = `Hi%20${
                              selectedDocument.client.name
                            }%0A%0A${encodeURI(
                              selectedDoc?.description
                            )}%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                              documentDetails.document
                            )}`;

                            window.open(
                              `mailto:${selectedDocument.client.email}?subject=Sharing Brik Document&body=${text}`
                            );
                          }}
                        >
                          EMAIL
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Card>
              </>
            ) : (
              <>
                <Card
                  style={{
                    position: "relative",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "12px",
                  }}
                >
                  <Box style={{ textAlign: "left" }}>
                    {/* <img src={Documents} />
                &nbsp;&nbsp; */}
                    <span style={{ fontWeight: "700", fontSize: "18px" }}>
                      {selectedDoc1?.document_name}
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#8A9EBC",
                      }}
                    >
                      8.25 MB
                    </span>
                  </Box>
                  <hr
                    style={{
                      backgroundColor: "#C4C4C4",
                      borderColor: "#C4C4C4",
                      color: "#C4C4C4",
                      width: "90%",
                    }}
                  />
                  <Box
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <center>
                      {selectedDoc?.doc_type === "pdf" ? (
                        <img src={Pdf} />
                      ) : selectedDoc?.doc_type === "document" ? (
                        <img src={Doc} />
                      ) : selectedDoc?.doc_type === "excel" ? (
                        <img src={Xls} />
                      ) : selectedDoc?.doc_type === "presentation" ? (
                        <img src={Ppt} />
                      ) : (
                        <img src={Mp4} />
                      )}
                    </center>
                  </Box>
                  <Box
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#8A9EBC",
                      }}
                    >
                      Property:{" "}
                      {selectedDoc1?.property_name
                        ? selectedDoc1?.property_name
                        : "No property"}
                    </span>
                  </Box>
                  <Box
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#8A9EBC",
                    }}
                  ></Box>
                  <hr
                    style={{
                      backgroundColor: "#C4C4C4",
                      borderColor: "#C4C4C4",
                      color: "#C4C4C4",
                      width: "90%",
                    }}
                  />
                  <Box
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      color: "#282827",
                    }}
                  >
                    Description
                  </Box>
                  <Box
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#8A9EBC",
                    }}
                  >
                    {selectedDoc1?.description
                      ? selectedDoc1?.description
                      : "No description"}
                  </Box>
                  <hr
                    style={{
                      backgroundColor: "#C4C4C4",
                      borderColor: "#C4C4C4",
                      color: "#C4C4C4",
                      width: "90%",
                    }}
                  />
                  <Box>
                    <Box
                      style={{
                        float: "left",
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#282827",
                        marginBottom: "10px",
                      }}
                    >
                      Share with
                    </Box>
                    <Box padding="12px">
                      <TextField
                        style={{
                          backgroundColor: "#dbdbdb91",
                          borderRadius: "18px",
                          color: "#787878",
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutlineInput,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: "#787878" }} />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Search leads"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          setSearching(e.target.value);
                        }}
                      />
                    </Box>
                    {/* <Box
                      style={{
                        float: "right",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#8A9EBC",
                      }}
                    >
                      View all
                    </Box> */}
                  </Box>
                  <Box marginBottom="50px" height="300px" overflow="scroll">
                    {searchLeads.results.map((lead) => {
                      return (
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedDocument(lead);
                          }}
                          className={clsx({
                            [classes.selectedDocument]:
                              lead.id == selectedDocument.id,
                          })}
                          margin="10px"
                          display="flex"
                          alignItems="center"
                        >
                          <Avatar
                            round={true}
                            size="40"
                            style={{ cursor: "pointer" }}
                            // src={auth.getUser().profile_pic}
                            name={lead.name}
                          />
                          <Box marginLeft="8px">{lead.name}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Card
                    elevation={4}
                    style={{
                      borderRadius: "20px 20px 0px 0px",
                      padding: "10px",
                    }}
                    className="share-via"
                  >
                    <Box
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#8A9EBC",
                        paddingBottom: "4px",
                      }}
                    >
                      SHARE VIA
                    </Box>
                    <Box>
                      <Box style={{ float: "left", width: "50%" }}>
                        <Button
                          style={{
                            width: "90%",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#8B7862",
                            borderRadius: "7px",
                            color: "white",
                          }}
                          onClick={() => {
                            console.log(
                              "Lead Data: ",
                              selectedDocument.client.number
                            );
                            let text = `Hi%20${
                              selectedDocument.client.name
                            }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                              documentDetails.document
                            )}`;
                            window.open(
                              `https://api.whatsapp.com/send/?phone=+91${selectedDocument.client.number}&text=${text}&app_absent=0`
                            );
                          }}
                        >
                          WHATSAPP
                        </Button>
                      </Box>
                      <Box style={{ float: "right", width: "50%" }}>
                        <Button
                          style={{
                            width: "90%",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#8B7862",
                            borderRadius: "7px",
                            color: "white",
                          }}
                          onClick={() => {
                            let text = `Hi%20${
                              selectedDocument.client.name
                            }%0A%0Awe%20are%20sharing%20document%2C%20you%20can%20access%20it%20using%20below%20link%3A%0A${encodeURI(
                              documentDetails.document
                            )}`;

                            window.open(
                              `mailto:${selectedDocument.client.email}?subject=Sharing Brik Document&body=${text}`
                            );
                          }}
                        >
                          EMAIL
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Card>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className={clsx({ [responsive.app]: true, "app-dashboard": true })}>
        <Box style={{ backgroundColor: "white", padding: "12px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Box marginBottom="10px" display="flex" alignItems="center">
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                </Box>
                <Box fontSize="22px" fontWeight={600}>
                  Document
                </Box>
              </Box>

              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={value}
                classes={{ flexContainer: classes.flexContainer }}
                onChange={(event, newValue) => {
                  switch (newValue) {
                    case 1:
                      setTab("starred");
                      break;

                    default:
                      setTab("all");
                  }
                  setValue(newValue);
                }}
                aria-label="basic tabs example"
              >
                <Tab
                  style={{ fontWeight: "bold" }}
                  label="ALL"
                  {...a11yProps(0)}
                />
                <Tab
                  style={{ fontWeight: "bold" }}
                  label="STARRED"
                  {...a11yProps(1)}
                />
              </Tabs>

              <Box style={{ padding: "10px 0px" }}>
                <TextField
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  placeholder="Search Documents"
                  style={{
                    backgroundColor: "#dbdbdb91",
                    borderRadius: "18px",
                    color: "#787878",
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutlineInput,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="#787878" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        {/* <FormatListBulletedIcon color="#787878" /> */}
                        <img src={searchModal} />
                      </InputAdornment>
                    ),
                    // endAdornment: (
                    //     <InputAdornment position="end">
                    //         <IconButton size="small">
                    //             <SearchIcon color="#787878" />
                    //         </IconButton>
                    //     </InputAdornment>
                    // )
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Grid>
            {tab === "all" ? (
              <Grid item xs={12}>
                {/* <input
                  type="file"
                  onChange={(e) => {
                    let filesArray = e.target.files;

                    if (navigator.canShare({ files: filesArray })) {
                      navigator
                        .share({
                          files: filesArray,
                          title: "Pictures",
                          text: "Our Pictures.",
                        })
                        .then(() => console.log("Share was successful."))
                        .catch((error) => console.log("Sharing failed", error));
                    } else {
                      console.log(`Your system doesn't support sharing files.`);
                    }
                  }}
                /> */}

                {docs.map((doc, index) => (
                  <>
                    <Card key={index} style={{ marginTop: "2%" }}>
                      <Box
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "15px 7px",
                        }}
                      >
                        <Box
                          display="flex"
                          onClick={() => {
                            history.push(`document/${doc.id}`);
                          }}
                        >
                          <Box>
                            {doc.doc_type === "pdf" ? (
                              <img src={Pdf} />
                            ) : doc.doc_type === "document" ? (
                              <img src={Doc} />
                            ) : doc.doc_type === "excel" ? (
                              <img src={Xls} />
                            ) : doc.doc_type === "presentation" ? (
                              <img src={Ppt} />
                            ) : (
                              <img src={Mp4} />
                            )}
                          </Box>
                          <Box marginLeft="15px">
                            <Box fontWeight="600">{doc.document_name}</Box>
                            <Box>8.25 MB</Box>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box marginRight="10px">
                            <Box>
                              <IconButton
                                onClick={() => {
                                  starDocument({
                                    document_id: doc.id,
                                    status: doc.is_starred,
                                    userId: auth.getUserId(),
                                  }).then((result) => {
                                    fetchAllDocument({ starred: false });
                                    fetchAllDocument({ starred: true });
                                  });
                                }}
                              >
                                {doc.is_starred ? (
                                  <img
                                    onClick={() => {
                                      doc.is_starred = false;
                                      setDocs([...docs]);
                                    }}
                                    src={Star2}
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      doc.is_starred = true;
                                      setDocs([...docs]);
                                    }}
                                    src={Star1}
                                  />
                                )}
                                {/* <StarBorderIcon /> */}
                              </IconButton>
                            </Box>
                            {/* <Box style={{ color: "#8A9EBC", fontSize: "12px" }}>
                        8.25MB
                      </Box> */}
                          </Box>
                          <Box>
                            <PopupState variant="popover" popupId="demoMenu">
                              {(popupState) => (
                                <React.Fragment>
                                  <IconButton
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      bindTrigger(popupState).onClick(event);
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>

                                  <Menu
                                    {...bindMenu(popupState)}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    style={{ top: "5px", left: "1px" }}
                                  >
                                    <MenuItem
                                      onClick={(event) => {
                                        window.open(doc.document);
                                        popupState.close();
                                      }}
                                    >
                                      View
                                    </MenuItem>
                                    {/* <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        history.push(`/document/${doc.id}`);
                                        popupState.close();
                                      }}
                                    >
                                      Share
                                    </MenuItem> */}
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                {starred.map((doc) => (
                  <>
                    <Card style={{ marginTop: "2%" }}>
                      <Box
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "15px 7px",
                        }}
                      >
                        <Box
                          display="flex"
                          onClick={() => {
                            history.push(`/document/${doc.id}`);
                          }}
                        >
                          <Box>
                            {doc.doc_type === "pdf" ? (
                              <img src={Pdf} />
                            ) : doc.doc_type === "document" ? (
                              <img src={Doc} />
                            ) : doc.doc_type === "excel" ? (
                              <img src={Xls} />
                            ) : doc.doc_type === "presentation" ? (
                              <img src={Ppt} />
                            ) : (
                              <img src={Mp4} />
                            )}
                          </Box>
                          <Box marginLeft="15px">
                            <Box fontWeight="600">{doc.document_name}</Box>
                            <Box>8.25 MB</Box>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box marginRight="10px">
                            <Box>
                              <IconButton
                                onClick={() => {
                                  starDocument({
                                    document_id: doc.id,
                                    status: doc.is_starred,
                                    userId: auth.getUserId(),
                                  }).then((result) => {
                                    fetchAllDocument({ starred: false });
                                    fetchAllDocument({ starred: true });
                                  });
                                }}
                              >
                                <img
                                  onClick={() => {
                                    doc.is_starred = false;
                                    setDocs([...docs]);
                                  }}
                                  src={Star2}
                                />
                                {/* {doc.is_starred ? (
                                  <img
                                    onClick={() => {
                                      doc.is_starred = false;
                                      setDocs([...docs]);
                                    }}
                                    src={Star2}
                                  />
                                ) : (
                                  <img
                                    onClick={() => {
                                      doc.is_starred = true;
                                      setDocs([...docs]);
                                    }}
                                    src={Star1}
                                  />
                                )} */}
                              </IconButton>
                            </Box>
                            {/* <Box style={{ color: "#8A9EBC", fontSize: "12px" }}>
                      8.25MB
                    </Box> */}
                          </Box>
                          <Box>
                            <PopupState variant="popover" popupId="demoMenu">
                              {(popupState) => (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      bindTrigger(popupState).onClick(event);
                                    }}
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>

                                  <Menu
                                    {...bindMenu(popupState)}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    style={{ top: "5px", left: "1px" }}
                                  >
                                    <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        popupState.close();
                                      }}
                                    >
                                      {/* <img src={Update} /> */}
                                      &nbsp;Share
                                    </MenuItem>
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </>
                ))}
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Document;
