import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import Header from "./Header";
import Footer from "./Footer";
import { Avatar, Box } from "@material-ui/core";
import { icons } from "../assets/assets";
import { useGeneralStyles } from "../assets/css/generalStyling";
import { useHistory } from "react-router-dom";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import FaqIcon from "../assets/icons/graph/FaqIcon.svg";
import FaqIcon1 from "../assets/icons/graph/faq2.png";
import DashboardIcon1 from "../assets/icons/graph/DasboardIcon1.png";
import DashboardIcon2 from "../assets/icons/graph/dash.png";
import CalendarIcon1 from "../assets/icons/graph/CalendarIcon1.svg";
import CalendarIcon2 from "../assets/icons/graph/calendar.png";
import DocumentIcon1 from "../assets/icons/graph/doc1.svg";
import DocumentIcon2 from "../assets/icons/graph/docs.png";
import ProfileIcon1 from "../assets/icons/graph/ProfileIcon1.svg";
import ProfileIcon2 from "../assets/icons/graph/profilee.png";
import GavelIcon from "@material-ui/icons/Gavel";

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootListItem: {
    color: "#8B7862",
    // paddingLeft: '10px'
    padding: "15px",
    // textAlign:"center"
  },
  selectedListItem: {
    color: "white",
    background:
      "linear-gradient(90deg, #FFE4F9 0%, #EAD2AB 0.01%, #FFF6E7 100%)",
    // borderRadius: "27px",
    // '&:hover': {
    //   borderRadius: "27px !important"
    // }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: "56px",
    backgroundColor: "white",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    // justifyContent: 'flex-end',
  },
  content: {
    backgroundColor: "#434343",
    flexGrow: 1,
    minHeight: "100vh",
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  mobWidth: {
    backgroundColor: "#F1E8DA",
    height: "100%",
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    mobWidth: { width: "100vw !important" },
  },
}));

function DashboardLayoutWeb({ children }) {
  const classes = useStyles();
  const styles = useGeneralStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const [selected, setSelected] = useState(1);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    switch (history.location.pathname.split("/")[1]) {
      case "dashboard":
        setSelected(1);
        break;
      case "my-ownership":
        setSelected(2);
        break;
      case "detail":
        setSelected(2);
        break;
      case "document":
        setSelected(3);
        break;
      case "account":
        setSelected(4);
        break;
      case "agreement":
        setSelected(6);
        break;
      default:
        setSelected(1);
    }
    console.log("history: ", history.location.pathname.split("/")[1]);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* <div className={classes.drawerHeader}>
          <Box padding={3} justifyContent="center" alignItems="center" display="flex">
            <Avatar style={{border:"1px solid white", borderRadius:"10px", padding:"5px", backgroundColor:"#FFFFFF"}} src={Logo} />
            <Box style={{ padding: "0px 10px", color: "#FFFFFF", fontWeight: 500 }}>BRIK ITT</Box>
          </Box>
        </div> */}
        <List
        // style={{ padding: "20px" }}
        >
          <ListItem
            disableGutters
            onClick={() => {
              setSelected(1);
              history.push("/dashboard");
            }}
            button
            selected={1 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {1 == selected ? (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={DashboardIcon1} style={{ color: "white" }} />
                  <Box fontSize="10px" color="#8B7862">
                    Dashboard
                  </Box>
                </Box>
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={DashboardIcon2} style={{ color: "#6B778C" }} />
                  <Box fontSize="10px" style={{ color: "#6B778C" }}>
                    Dashboard
                  </Box>
                </Box>
              </ListItemIcon>
            )}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setSelected(2);
              history.push("/calendar");
            }}
            selected={2 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {2 == selected ? (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={CalendarIcon1} style={{ color: "white" }} />
                  <Box fontSize="10px" color="#8B7862">
                    Calendar
                  </Box>
                </Box>
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={CalendarIcon2} style={{ color: "#6B778C" }} />
                  <Box fontSize="10px" color="#6B778C">
                    Calendar
                  </Box>
                </Box>
              </ListItemIcon>
            )}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setSelected(3);
              history.push("/document");
            }}
            selected={3 == selected}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
          >
            {3 == selected ? (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={DocumentIcon1} style={{ color: "white" }} />
                  <Box fontSize="10px" color="#8B7862">
                    Document
                  </Box>
                </Box>
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={DocumentIcon2} style={{ color: "#6B778C" }} />
                  <Box fontSize="10px" color="#6B778C">
                    Document
                  </Box>
                </Box>
              </ListItemIcon>
            )}
          </ListItem>
          <ListItem
            button
            selected={4 == selected}
            onClick={() => {
              setSelected(4);
              history.push("/account");
            }}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
            style={{ padding: "15px 23px" }}
          >
            {4 == selected ? (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={ProfileIcon1} style={{ color: "white" }} />
                  <Box fontSize="10px" color="#8B7862">
                    Profile
                  </Box>
                </Box>
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={ProfileIcon2} style={{ color: "#6B778C" }} />
                  <Box fontSize="10px" color="#6B778C">
                    Profile
                  </Box>
                </Box>
              </ListItemIcon>
            )}
          </ListItem>
          <ListItem
            button
            selected={6 == selected}
            onClick={() => {
              setSelected(6);
              history.push("/agreement");
            }}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
            // style={{ padding: "15px 23px" }}
          >
            {6 == selected ? (
              <ListItemIcon>
                <Box textAlign="center">
                  <GavelIcon style={{ height: "20px", width: "20px" }} />
                  <Box fontSize="10px" color="#8B7862">
                    Agreement
                  </Box>
                </Box>
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Box textAlign="center">
                  <GavelIcon
                    style={{ height: "20px", width: "20px", color: "none" }}
                  />
                  {/* <img src={ProfileIcon2} style={{ color: "#6B778C" }} /> */}
                  <Box fontSize="10px" color="#6B778C">
                    Agreement
                  </Box>
                </Box>
              </ListItemIcon>
            )}
          </ListItem>
          <ListItem
            button
            selected={5 == selected}
            onClick={() => {
              setSelected(5);
              history.push("/faq");
            }}
            classes={{
              selected: classes.selectedListItem,
              root: classes.rootListItem,
            }}
            style={{ padding: "15px 23px" }}
          >
            {5 == selected ? (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={FaqIcon} style={{ color: "white" }} />
                  <Box fontSize="10px" color="#8B7862">
                    FAQ
                  </Box>
                </Box>
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <Box textAlign="center">
                  <img src={FaqIcon1} style={{ color: "#6B778C" }} />
                  <Box fontSize="10px" color="#6B778C">
                    FAQ
                  </Box>
                </Box>
              </ListItemIcon>
            )}
          </ListItem>
        </List>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box className={classes.mobWidth}>
          <Header type="web" open={open} handleDrawerOpen={handleDrawerOpen} />
          <Box marginTop="5%">{children}</Box>
        </Box>
        <Footer />
      </main>
      {/* <Footer/> */}
    </div>
  );
}

export default DashboardLayoutWeb;
