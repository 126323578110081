import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import { icons } from "../assets/assets";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Box, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAuth } from "../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import exploreIcon from "../assets/icons/Explore.svg";
import MyOwnership from "../assets/icons/My ownership.svg";
import acountIcon from "../assets/icons/account icon.svg";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Close from "../assets/icons/menu.png";
import GavelIcon from "@material-ui/icons/Gavel";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootListItem: {
    color: "#8B7862",
    // textAlign:"center"
  },
  selectedListItem: {
    color: "#white",
    background: "#897860",
    borderRadius: "27px",
    "&:hover": {
      borderRadius: "27px !important",
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: "white",
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  selectedBottomNavigation: {
    color: "#8B7862 !important",
  },
  BottomNavigation: {
    position: "fixed",
    width: "100%",
    bottom: "0px",
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    BottomNavigation: {
      display: "block",
    },
  },
}));

function MainDrawerApp({ children, ...props }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const auth = useAuth();
  const classes2 = useStyles2();
  const history = useHistory();
  const [selected, setSelected] = React.useState(1);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  React.useEffect(() => {
    switch (history.location.pathname.split("/")[1]) {
      case "calendar":
        setSelected(2);
        setValue(2);
        break;

      case "document":
        setSelected(3);
        setValue(3);
        break;

      case "account":
        setSelected(4);
        setValue(4);
        break;

      case "agreement":
        setSelected(6);
        break;

      case "faq":
        setSelected(5);
        setValue(5);
        break;

      default:
        setSelected(1);
    }
    console.log("history: ", history.location.pathname.split("/")[1]);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        type="app"
        open={mobileOpen}
        handleDrawerOpen={handleDrawerToggle}
      />

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/* <Hidden smUp implementation="css"> */}
        <Drawer
          // container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.drawerHeader} style={{ textAlign: "end" }}>
            <Box display="flex" alignItems="center">
              <Box flex={1} textAlign="left" paddingLeft="30px" color="white">
                {/* {auth.getUser() !== null && `Welcome ${auth.getUser().name}`} */}
              </Box>
              <Box>
                <IconButton
                  onClick={handleDrawerToggle}
                  style={{ color: "#FF5674" }}
                >
                  {/* {theme.direction === "ltr" ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )} */}
                  <img src={Close} />
                </IconButton>
              </Box>
            </Box>
          </div>
          <Divider />
          <List style={{ padding: "20px", marginTop: "30px" }}>
            <ListItem
              onClick={() => {
                setSelected(1);
                history.push("/dashboard");
              }}
              button
              selected={1 == selected}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {1 == selected ? (
                <ListItemIcon>
                  <DashboardIcon
                    style={{ height: "20px", width: "20px", color: "#8B7862" }}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <DashboardIcon style={{ height: "20px", width: "20px" }} />
                </ListItemIcon>
              )}
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                if (auth.getUser() !== null) {
                  setSelected(2);
                  history.push("/calendar");
                }
              }}
              selected={2 == selected}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {2 == selected ? (
                <ListItemIcon>
                  <CalendarTodayOutlinedIcon
                    style={{ height: "20px", width: "20px", color: "#8B7862" }}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <CalendarTodayOutlinedIcon
                    style={{ height: "20px", width: "20px" }}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary="Calendar" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                if (auth.getUser() !== null) {
                  setSelected(3);
                  history.push("/document");
                }
              }}
              selected={3 == selected}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {3 == selected ? (
                <ListItemIcon>
                  <DescriptionOutlinedIcon
                    style={{ height: "20px", width: "20px", color: "#8B7862" }}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <DescriptionOutlinedIcon
                    style={{ height: "20px", width: "20px" }}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary="Document" />
            </ListItem>
            <ListItem
              button
              selected={4 == selected}
              onClick={() => {
                setSelected(4);
                history.push("/account");
              }}
              // onClick={() => {
              //   setSelected(4)
              //   history.push('/faq')
              // }}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {4 == selected ? (
                <ListItemIcon>
                  <AccountCircleOutlinedIcon
                    style={{ height: "20px", width: "20px", color: "#8B7862" }}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <AccountCircleOutlinedIcon
                    style={{ height: "20px", width: "20px" }}
                  />
                </ListItemIcon>
              )}

              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem
              button
              selected={6 == selected}
              onClick={() => {
                setSelected(6);
                history.push("/agreement");
              }}
              // onClick={() => {
              //   setSelected(4)
              //   history.push('/faq')
              // }}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {6 == selected ? (
                <ListItemIcon>
                  <GavelIcon
                    style={{ height: "20px", width: "20px", color: "#8B7862" }}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <GavelIcon style={{ height: "20px", width: "20px" }} />
                </ListItemIcon>
              )}

              <ListItemText primary="Agreement" />
            </ListItem>
            <ListItem
              button
              selected={5 == selected}
              onClick={() => {
                setSelected(5);
                history.push("/faq");
              }}
              // onClick={() => {
              //   setSelected(4)
              //   history.push('/faq')
              // }}
              classes={{
                selected: classes.selectedListItem,
                root: classes.rootListItem,
              }}
            >
              {5 == selected ? (
                <ListItemIcon>
                  <AccountCircleOutlinedIcon
                    style={{ height: "20px", width: "20px", color: "#FF5674" }}
                  />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <AccountCircleOutlinedIcon
                    style={{ height: "20px", width: "20px" }}
                  />
                </ListItemIcon>
              )}

              <ListItemText primary="FAQ" />
            </ListItem>
          </List>
          {auth.getUserToken() && (
            <Box
              style={{
                padding: "13px",
                position: "absolute",
                bottom: "0px",
                width: "100%",
              }}
            >
              <Button
                onClick={() => {
                  auth.logout();
                }}
                color="primary"
                variant="contained"
                fullWidth
              >
                Sign Out
              </Button>
            </Box>
          )}
        </Drawer>
        {/* </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden> */}
      </nav>
      <main className={classes.content}>
        <Box
          style={{
            maxHeight: "75vh",
            overflowX: "hidden",
            // width: "100vw",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          {children}
        </Box>
      </main>
      {auth.getUserToken() && (
        <Box className={classes2.BottomNavigation}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            className={classes2.root}
          >
            <BottomNavigationAction
              // style={{ color: "#ACACAC" }}
              onClick={() => {
                history.push("/dashboard");
              }}
              classes={{ selected: classes2.selectedBottomNavigation }}
              label="Dashboard"
              icon={1 == value ? <DashboardIcon /> : <DashboardIcon />}
            />

            {auth.getUserToken() == undefined && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Login"
                icon={<LocationOnIcon />}
              />
            )}

            {auth.getUserToken() !== null && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/calendar");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Calendar"
                icon={
                  2 == value ? (
                    <CalendarTodayOutlinedIcon />
                  ) : (
                    <CalendarTodayOutlinedIcon />
                  )
                }
              />
            )}

            {auth.getUserToken() !== null && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/document");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Document"
                icon={
                  3 == value ? (
                    <DescriptionOutlinedIcon />
                  ) : (
                    <DescriptionOutlinedIcon />
                  )
                }
              />
            )}

            {auth.getUserToken() !== null && (
              <BottomNavigationAction
                onClick={() => {
                  history.push("/account");
                }}
                classes={{ selected: classes2.selectedBottomNavigation }}
                label="Profile"
                icon={
                  4 == value ? (
                    <AccountCircleOutlinedIcon />
                  ) : (
                    <AccountCircleOutlinedIcon />
                  )
                }
              />
            )}
          </BottomNavigation>
        </Box>
      )}
    </div>
  );
}

export default MainDrawerApp;
