import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { addEventValidation } from "../validations";
import { useParams } from "react-router-dom";
import { addEvent, updateEvent } from "../store/actions/apis";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { getLeads, getLeadsSearching } from "../store/actions/dashboardActions";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  input: {
    padding: "12.5px 13px",
    width: "450px",
  },
  rootOutlinedInput: {
    border: "1px solid #979797",
    borderRadius: "6px",

    // borderRadius: "0px 50px 50px 0px",
    // paddingLeft: "10px",
  },
  notchedOutline: {
    border: "none",
  },
}));

function AddEvent({ name, deviceType, from, leadData, onBack }) {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [mode, setMode] = useState("add");
  const { leads, searchLeads } = useSelector((state) => state.dashboard);
  const notification = useSnackbar();
  const dispatch = useDispatch();
  const [searching, setSearching] = useState("");
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues: {
      name: "",
      appointment_with: "none",
      date: moment(),
      time: moment(),
      details: "",
      order: "",
      stage: "",
      workflow: "",
      task_type: "",
    },
    onSubmit: () => {
      if (mode == "edit") {
        addEvent({
          ...values,
          name: values.name,
          appointment_with: values.appointment_with,
          time: `${moment(values.date).format("YYYY-MM-DD")} ${moment(
            values.time
          ).format("HH:mm:ss")}`,
          details: values.details,
        }).then((result) => {
          if (deviceType == "website") {
            onBack();
          }

          notification.enqueueSnackbar("Event updated successfully", {
            variant: "success",
          });
        });
      } else
        addEvent({
          ...values,
          name: values.name,
          appointment_with: values.appointment_with,
          time: `${moment(values.date).format("YYYY-MM-DD")} ${moment(
            values.time
          ).format("HH:mm:ss")}`,
          details: values.details,
        }).then((result) => {
          if (deviceType == "website") {
            onBack();
          }

          notification.enqueueSnackbar("Event created successfully", {
            variant: "success",
          });
        });
    },
    validationSchema: addEventValidation,
  });

  useEffect(() => {
    dispatch(
      getLeadsSearching({ search: searching, status: "", priority: [] })
    );
  }, [searching]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log("searching string: ", searching);
      // dispatch(getAllProducts({ search_string: searching }));
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searching]);

  // useEffect(() => {
  //   dispatch(getLeads({ search, status: filter, priority }));
  // }, [search, filter]);

  useEffect(() => {
    dispatch(getLeads({ search: "", status: "", priority: "" }));
    if (id) setMode("edit");
  }, []);

  useEffect(() => {
    if (from == "leadDetail") {
      setFieldValue("appointment_with", leadData.name);
    }
  }, [leadData, from]);

  return (
    <>
      <Box style={{ backgroundColor: "white", padding: "12px" }}>
        {deviceType == "mobile" && (
          <Box marginBottom="10px" display="flex" alignItems="center">
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <Box fontSize="22px" fontWeight={600}>
              {mode == "add" ? "Add" : "Edit"} an event
            </Box>
          </Box>
        )}

        <Grid spacing={2} container>
          <Grid xs={12} item>
            <TextField
              id="name"
              name="name"
              label="Subject"
              fullWidth
              placeholder="Subject"
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={touched.name && errors.name}
              helperText={touched.name && errors.name}
            />
          </Grid>

          <Grid xs={12} item>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              options={searchLeads.results.map((option) => {
                return option;
              })}
              onChange={(event, newValue) => {
                setFieldValue("appointment_with", newValue.name);
                setFieldValue("order", newValue.workflow[0].stages[0].order);
                setFieldValue("stage", newValue.workflow[0].stages[0].id);
                setFieldValue("workflow", newValue.workflow[0].id);
                setFieldValue("task_type", "appointment");
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => {
                console.log("params : ", params);
                return (
                  <TextField
                    {...params}
                    onChange={(event) => {
                      setSearching(event.target.value);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        input: classes.input,
                        root: classes.rootOutlinedInput,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    variant="outlined"
                    placeholder="Type and select lead"
                  />
                );
              }}
            />
            {/* <Select
              label="With"
              fullWidth
              input={
                <OutlinedInput
                // classes={{ input: classes.input }}
                />
              }
              labelId="demo-simple-select-outlined-label"
              placeholder={name ? name : "With"}
              name="appointment_with"
              onChange={(e) => {
                let leadData = leads.results.filter(
                  (item) => item.name == e.target.value
                )[0];
                console.log("leadData.workflow: ", leadData.workflow);
                setFieldValue("order", leadData.workflow[0].stages[0].order);
                setFieldValue("stage", leadData.workflow[0].stages[0].id);
                setFieldValue("workflow", leadData.workflow[0].id);
                setFieldValue("task_type", "appointment");

                setFieldValue("appointment_with", e.target.value);
              }}
              onBlur={handleBlur}
              value={values.appointment_with}
              error={touched.appointment_with && errors.appointment_with}
            >
              <MenuItem disabled value="none">
                With
              </MenuItem>
              {leads.results.map((lead) => {
                return <MenuItem value={lead.name}>{lead.name}</MenuItem>;
              })}
            </Select> */}
            <FormHelperText style={{ color: "red", paddingLeft: "15px" }}>
              {touched.appointment_with && errors.appointment_with}
            </FormHelperText>
            {/* <TextField
              id="appointment_with"
              name="appointment_with"
              label="Appointment with"
              fullWidth
              placeholder="Appointment with"
              type="text"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.appointment_with}
              error={touched.appointment_with && errors.appointment_with}
              helperText={touched.appointment_with && errors.appointment_with}
            /> */}
          </Grid>

          <Grid xs={12} item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                emptyLabel="Select Date"
                fullWidth
                margin="normal"
                id="date-picker-dialog"
                label="Select Date"
                format="DD-MM-YYYY"
                value={values.date ? moment(values.date) : null}
                onChange={(date) => {
                  console.log(
                    "DOB DATE SELECTED: ",
                    moment(date).format("DD-MMM-YYYY")
                  );
                  setFieldValue("date", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  id: `datePicker1`,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid xs={12} item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                inputVariant="outlined"
                placeholder="Select Time"
                fullWidth
                label="Select Time"
                // placeholder="08:00 AM"
                mask="__:__ _M"
                value={moment(values.time)}
                onChange={(date) => {
                  setFieldValue("time", date);
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid xs={12} item>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              placeholder="Details"
              id="details"
              name="details"
              label="Details"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.details}
              error={touched.details && errors.details}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex">
              <Box flex={1}>
                <Button
                  fullWidth
                  onClick={() => {
                    if (deviceType == "website") {
                      onBack();
                    } else history.push("/dashboard");
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </Box>
              <Box style={{ marginLeft: "10px" }} flex={1}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={submitForm}
                  color="primary"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AddEvent;
