import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  Menu,
  IconButton,
  Drawer,
  Typography,
  CircularProgress,
  FormGroup,
} from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import Hot from "../../assets/icons/leadDetail/Hot.svg";
import Warm from "../../assets/icons/leadDetail/Warm.svg";
import Cool from "../../assets/icons/leadDetail/Cool.svg";
import Cold from "../../assets/icons/leadDetail/Cold.svg";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Table } from "antd";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { exportResults, getLeads } from "../../store/actions/dashboardActions";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import Column from "antd/lib/table/Column";
import { Close } from "@material-ui/icons";
import Edit from "../../assets/icons/graph/edit.png";
import Update from "../../assets/icons/graph/update.svg";
import Delete from "../../assets/icons/graph/delete.png";
import Modal from "../Modal";
import { useSnackbar } from "notistack";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Chart from "react-apexcharts";
import {
  deleteBulkLead,
  deleteLead,
  updateBulkLead,
  uploadBulkLeads,
} from "../../store/actions/apis";
import UploadIcon from "../../assets/images/upload-lead.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CreateLead from "../../screens/CreateLead";
import Arr from "../../assets/icons/graph/arr.png";
import Arr1 from "../../assets/icons/graph/arr1.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { values } from "lodash";
// import "../assets/css/datepicker.css";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "300px",
    backgroundColor: "white",
  },
  selectedStatus: {
    backgroundColor: "#8B7862 !important",
    color: "white !important",
  },
  textFieldRootStyles: {
    backgroundColor: "white",
  },
  statusButtons: {
    cursor: "pointer",
    marginRight: "2px",
    backgroundColor: "#D8CEC0",
    color: "#6F6F6F",
    borderRadius: "10px 10px 0px 0px",
    textAlign: "center",
    width: "150px",
    fontWeight: "bold",
    padding: "10px 0px",
  },
  notchedOutlineInput: {
    borderRadius: "20px",
  },
  selectedTab: {
    backgroundColor: "#8B7862",
    color: "#FFFFFF",
    // padding: "8px",
  },
  input: {
    padding: "10px 14px",
  },
  flexContainer: {
    overflow: "scroll",
  },
  selected: { border: "1px solid gray", padding: "2px" },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "70px",
    right: "30px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

{
  /* <style>
.ant-table-thead .ant-table-cell {
  backgroundColor: "green";
}
</style> */
}

export function addDays(date, days) {
  const date1 = date;
  date1.setDate(date1.getDate() + 1);
  return date1;
}

const LeadDataTable = forwardRef(
  ({ statistics, graphData, graphBulltes, barChart, ...props }, ref) => {
    useImperativeHandle(ref, () => ({
      setFilterSideBarStatus,
    }));

    const [isDownloading, setIsDownloading] = useState(false);

    const [modal, setModal] = useState(false);
    const [createLeadModal, setCreateLeadModalModal] = useState(false);
    const [userName, setUserName] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filter, setFilter] = useState("In progress");
    const [filters, setFilters] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationModal2, setConfirmationModal2] = useState(false);
    const [leadId, setLeadId] = useState("");
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
    });

    const onChangeHandler = ({ label, key, value }) => {
      setFilters({ ...filters, [key]: { label: label, value: value } });
    };

    const onSelectChange = (newSelectedRowKeys) => {
      console.log(newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const [expended, setExpended] = useState();

    const expend = (index) => {
      if (expended === index) setExpended(undefined);
      else setExpended(index);
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: "20%",
      },
      {
        title: "Lead Source",
        dataIndex: "source",
        key: "source",
        width: "20%",
      },
      {
        title: "Mobile Number",
        dataIndex: "client",
        key: "client",
        render: (item) => {
          return item.number;
        },
      },
      {
        title: "Client Category",
        dataIndex: "client",
        key: "client",
        render: (item) => {
          return item.category;
        },
      },
      {
        title: "Lead Type",
        key: "priority",
        dataIndex: "priority",
        key: "priority",
      },
      {
        title: "Created on",
        dataIndex: "created_on",
        key: "created_on",
        render: (item) => {
          return moment(item).format("DD-MMM-YYYY");
        },
      },
      {
        title: "Updated on",
        dataIndex: "updated_on",
        key: "updated_on",
        render: (item) => {
          return moment(item).format("DD-MMM-YYYY");
        },
      },
    ];

    const [priority, setPriority] = useState("none");
    const [importModal, setImportModal] = useState(false);
    const [loadingDatatable, setLoadingDatatable] = useState(false);
    const [filterSideBarStatus, setFilterSideBarStatus] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      console.log(start, end);
      setFilters({
        ...filters,
        date__lte: {
          label: "Date End",
          value: moment(end).format("YYYY-MM-DD"),
        },
        date__gte: {
          label: "Date Start",
          value: moment(start).format("YYYY-MM-DD"),
        },
      });
    };
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { leads, meta_data } = useSelector((state) => state.dashboard);
    const [page, setPage] = React.useState(1);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const notification = useSnackbar();

    const refreshLeads = () => {
      let appliedFilters = {};
      Object.keys(filters).map((value) => {
        appliedFilters[value] = filters[value].value;
      });

      console.log("APPLIED FILTERS: ", appliedFilters);

      dispatch(
        getLeads({
          status: filter,
          limit: rowsPerPage,
          offset: (page - 1) * rowsPerPage,
          ...appliedFilters,
        })
      );
    };

    useEffect(() => {
      refreshLeads();
      setSelectedRowKeys([]);
    }, [filter, rowsPerPage, page, filters]);

    useEffect(() => {
      setPage(1);
    }, [filter]);

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event.target.value);
      // setClear(false);
      // dispatch(getLeads({ limit: `${event.target.value}` }));
      setPage(1);
    };

    const incrementPage = (num) => {
      setPage(page + num);
    };

    const decrementPage = (num) => {
      if (page > 1) setPage(page - num);
    };

    const renderUsersList = (organisation, userName) => {
      {
        let result = organisation.users.map((user, index) => {
          return (
            // index <= 5 && (
            <Box key={index}>
              {userName == "" ? (
                <FormControlLabel
                  value={user.id}
                  control={<Radio />}
                  label={user.name}
                />
              ) : (
                user.name.includes(userName) && (
                  <FormControlLabel
                    value={user.id}
                    control={<Radio />}
                    label={user.name}
                  />
                )
              )}
            </Box>
            // )
          );
        });

        return (
          <>
            <TextField
              InputProps={{
                classes: {
                  root: classes.textFieldRootStyles,
                  notchedOutline: classes.notchedOutlineStyles,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="#787878" />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: { height: "10px" },
              }}
              variant="outlined"
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
              placeholder="Search"
            />
            {result}
          </>
        );
      }
    };
    const ImportModalHeader = () => {
      return (
        <Box
          style={{ zIndex: 1, color: "#282827" }}
          padding="14px 14px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box style={{ fontSize: "20px" }}>Bulk Upload</Box>
          <Box>
            <IconButton
              style={{ color: "black" }}
              onClick={() => {
                setImportModal(false);
              }}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      );
    };

    const ModalHeader = () => {
      return (
        <Box
          style={{ zIndex: 1, color: "#282827" }}
          padding="14px 14px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box style={{ fontSize: "20px" }}>Edit Lead</Box>
          <Box>
            <IconButton
              style={{ color: "black" }}
              onClick={() => {
                setModal(false);
              }}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      );
    };

    const ModalHeader2 = () => {
      return (
        <Box
          style={{ zIndex: 1, color: "#282827" }}
          padding="14px 14px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box style={{ fontSize: "20px" }}>Create New Lead</Box>
          <Box>
            <IconButton
              style={{ color: "black" }}
              onClick={() => {
                setCreateLeadModalModal(false);
              }}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      );
    };

    const expandedRowRender = (record) => {
      console.log("expandedRowRender ", record);
      return (
        <Box style={{ backgroundColor: "red" }}>
          {/* {JSON.stringify(record.duplicates)} */}
          <Table
            loading={loadingDatatable}
            // expandable={{
            //   expandedRowRender: (record) => <></>,
            //   rowExpandable: (record) => record.name !== "Not Expandable",
            //   expandIcon: () => <></>,
            // }}
            rowKey="id"
            // rowSelection={{
            //   selectedRowKeys,
            //   onChange: onSelectChange,
            // }}
            // columns={columns}
            dataSource={record.duplicates}
            // loading={true}
            pagination={false}
            // title={() => "Header"}
          >
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              render={(_, lead) => {
                {
                  return _;
                  return lead.duplicate_count !== null ? (
                    <>
                      {lead.duplicate ? (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              expend(lead.id);
                            }}
                          >
                            <img src={Arr1} style={{ cursor: "pointer" }} />{" "}
                          </IconButton>
                          &nbsp;
                          {_}
                        </>
                      ) : (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => {
                              expend(lead.id);
                            }}
                          >
                            <img src={Arr} />{" "}
                          </IconButton>
                          &nbsp;
                          {_} {lead.id}
                        </>
                      )}
                    </>
                  ) : (
                    <>{_}</>
                  );
                }
              }}
            />
            <Column title="Lead Source" dataIndex="source" key="address" />
            <Column
              title="Mobile Number"
              dataIndex="client"
              key="client"
              render={(item) => {
                return item.number;
              }}
            />
            <Column
              title="Client Category"
              dataIndex="client"
              key="client"
              render={(item) => {
                return item.category;
              }}
            />
            <Column
              title="Lead Type"
              key="priority"
              dataIndex="priority"
              render={(item) => {
                return item === "Hot" || item === "hot" ? (
                  <img src={Hot} />
                ) : item === "Cold" || item === "cold" ? (
                  <img src={Cold} />
                ) : item === "Cool" || item === "cool" ? (
                  <img src={Cool} />
                ) : (
                  <img src={Warm} />
                );
              }}
            />
            <Column
              title="Client Category"
              dataIndex="client"
              key="client"
              render={(item) => {
                return item.category;
              }}
            />
            <Column
              title="Created on"
              dataIndex="created_on"
              key="created_on"
              render={(item) => {
                return moment(item).format("DD-MMM-YYYY");
              }}
            />
            <Column
              title="Updated on"
              dataIndex="updated_on"
              key="updated_on"
              render={(item) => {
                return moment(item).format("DD-MMM-YYYY");
              }}
            />
            <Column
              title="Action"
              key="action"
              render={(_, lead) => (
                <>
                  <PopupState variant="popover" popupId="demoMenu">
                    {(popupState) => (
                      <React.Fragment>
                        <IconButton
                          size="small"
                          variant="contained"
                          {...bindTrigger(popupState)}
                          onClick={(event) => {
                            event.stopPropagation();
                            bindTrigger(popupState).onClick(event);
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>

                        <Menu
                          {...bindMenu(popupState)}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          style={{
                            top: "5px",
                            left: "1px",
                            fontSize: "13px",
                          }}
                        >
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              popupState.close();
                              props.updateSalesFlowModal(lead);
                            }}
                          >
                            <img src={Update} />
                            &nbsp;Update Sales Flow
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              popupState.close();
                              setModal(true);
                              setLeadId(lead.id);
                            }}
                          >
                            <img src={Edit} />
                            &nbsp;Edit Lead
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              setConfirmationModal(true);
                              setLeadId(lead.id);
                              // event.stopPropagation();
                              popupState.close();
                            }}
                          >
                            <img src={Delete} />
                            &nbsp;Delete Lead
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </>
              )}
            />
          </Table>
        </Box>
      );
    };

    return (
      <>
        <Modal
          width="450px"
          height="150px"
          title="Are you sure want to delete?"
          status={confirmationModal2}
          closeBtnText="No"
          saveBtnText="Yes"
          closeHandler={() => {
            setConfirmationModal2(false);
          }}
          saveHandler={() => {
            deleteBulkLead({
              leads_ids: selectedRowKeys,
            }).then((result) => {
              refreshLeads();
              setConfirmationModal2(false);
              notification.enqueueSnackbar("Lead Deleted Successfully", {
                variant: "success",
              });
            });
          }}
        >
          <Box>Are you sure want to delete this lead?</Box>
        </Modal>

        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
          open={filterSideBarStatus}
          onClose={() => {
            setFilterSideBarStatus(false);
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
            textAlign="right"
          >
            <Box fontSize="20px">Filter</Box>
            <IconButton
              style={{ color: "black" }}
              onClick={() => {
                setFilterSideBarStatus(false);
              }}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
          <Box padding={1} flex={1}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", color: "#535353", flexShrink: 0 }}
                >
                  Lead Source
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  {meta_data.sources.map((source) => {
                    const isChecked = filters.source?.value.includes(source);

                    return (
                      <FormControlLabel
                        key={source}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(event) => {
                              const { checked } = event.target;
                              let updatedValue;

                              if (checked) {
                                updatedValue = [
                                  ...(filters.source?.value || []),
                                  source,
                                ];
                              } else {
                                updatedValue = (filters.source?.value || []).filter(
                                  (item) => item !== source
                                );
                              }

                              onChangeHandler({
                                label: "Lead Source",
                                key: "source",
                                value: updatedValue,
                              });
                            }}
                          />
                        }
                        label={source}
                      />
                    );
                  })}
                </FormGroup>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", color: "#535353", flexShrink: 0 }}
                >
                  Assigned
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    value={filters.org?.value}
                    onChange={(event) => {
                      console.log(event.target.value);
                      onChangeHandler({
                        label: "Organisation",
                        key: "org",
                        value: event.target.value,
                      });
                    }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {meta_data.orgs.map((organization) => {
                      return (
                        <>
                          <FormControlLabel
                            value={organization.id}
                            control={<Radio />}
                            label={organization.name}
                          />
                          {filters?.org?.value == organization.id &&
                            filters.org && (
                              <Box style={{ marginLeft: "20px" }}>
                                <RadioGroup
                                  value={filters.assigned?.value}
                                  onChange={(event) => {
                                    onChangeHandler({
                                      label: "Assigned",
                                      key: "assigned",
                                      value: event.target.value,
                                    });
                                  }}
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="female"
                                  name="radio-buttons-group"
                                >
                                  <Box
                                    style={{
                                      maxHeight: "390px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {renderUsersList(organization, userName)}
                                  </Box>
                                </RadioGroup>
                              </Box>
                            )}
                        </>
                      );
                    })}
                  </RadioGroup>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      delete filters.org;
                      delete filters.assigned;
                      setFilters({ ...filters });
                      setFilterSideBarStatus(false);
                    }}
                  >
                    Clear
                  </Box>
                </FormControl>
              </AccordionDetails>
            </Accordion>

            <Accordion
            // expanded={expanded === "lead_source"}
            // onChange={handleChange("lead_source")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", color: "#535353", flexShrink: 0 }}
                >
                  Client Categories
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <FormGroup>
                    {meta_data.client_categories.map((client) => {
                      const isChecked = filters.category?.value.includes(client);

                      return (
                        <FormControlLabel
                          key={client}
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={(event) => {
                                const { checked } = event.target;
                                let updatedValue;

                                if (checked) {
                                  updatedValue = [
                                    ...(filters.category?.value || []),
                                    client,
                                  ];
                                } else {
                                  updatedValue = (filters.category?.value || []).filter(
                                    (item) => item !== client
                                  );
                                }

                                onChangeHandler({
                                  label: "Category",
                                  key: "category",
                                  value: updatedValue,
                                });
                              }}
                            />
                          }
                          label={client}
                        />
                      );
                    })}
                  </FormGroup>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      delete filters.category;
                      setFilters({ ...filters });
                      setFilterSideBarStatus(false);
                    }}
                  >
                    Clear
                  </Box>
                </FormControl>
              </AccordionDetails>
            </Accordion>

            <Accordion
            // expanded={expanded === "lead_source"}
            // onChange={handleChange("lead_source")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", color: "#535353", flexShrink: 0 }}
                >
                  Lead Type
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <FormGroup>
                    {meta_data.priorities.map((priority) => {
                      const isChecked = filters.priority?.value.includes(priority);

                      return (
                        <FormControlLabel
                          key={priority}
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={(event) => {
                                const { checked } = event.target;
                                let updatedValue;

                                if (checked) {
                                  updatedValue = [
                                    ...(filters.priority?.value || []),
                                    priority,
                                  ];
                                } else {
                                  updatedValue = (filters.priority?.value || []).filter(
                                    (item) => item !== priority
                                  );
                                }

                                onChangeHandler({
                                  label: "Priority",
                                  key: "priority",
                                  value: updatedValue,
                                });
                              }}
                            />
                          }
                          label={priority}
                        />
                      );
                    })}
                  </FormGroup>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      delete filters.priority;
                      setFilters({ ...filters });
                      setFilterSideBarStatus(false);
                    }}
                  >
                    Clear
                  </Box>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", color: "#535353", flexShrink: 0 }}
                >
                  Modify on
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                  selectsRange
                  selectsDisabledDaysInRange
                  inline
                />
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    delete filters.date__lte;
                    delete filters.date__gte;
                    setFilters({ ...filters });
                    setFilterSideBarStatus(false);
                  }}
                >
                  Clear
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "33%", color: "#535353", flexShrink: 0 }}
                >
                  Last Call Status
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <FormGroup>
                    {meta_data.call_statuses.map((call_status) => {
                      const isChecked = filters.last_call_status?.value.includes(call_status);

                      return (
                        <FormControlLabel
                          key={call_status}
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={(event) => {
                                const { checked } = event.target;
                                let updatedValue;

                                if (checked) {
                                  updatedValue = [
                                    ...(filters.last_call_status?.value || []),
                                    call_status,
                                  ];
                                } else {
                                  updatedValue = (filters.last_call_status?.value || []).filter(
                                    (item) => item !== call_status
                                  );
                                }

                                onChangeHandler({
                                  label: "Call Status",
                                  key: "last_call_status",
                                  value: updatedValue,
                                });
                              }}
                            />
                          }
                          label={call_status}
                        />
                      );
                    })}
                  </FormGroup>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      delete filters.last_call_status;
                      setFilters({ ...filters });
                      setFilterSideBarStatus(false);
                    }}
                  >
                    Clear
                  </Box>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            {meta_data && meta_data.cities && meta_data.cities[0] && ( 
            <Accordion>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1bh-content"
    id="panel1bh-header"
  >
    <Typography
      sx={{ width: "33%", color: "#535353", flexShrink: 0 }}
    >
      City
    </Typography>
  </AccordionSummary>
  <AccordionDetails>
    <FormControl>
      <FormGroup>
        {meta_data && meta_data.cities && meta_data.cities.map((city) => {
          const isChecked = filters.city?.value.includes(city);

          return (
            <FormControlLabel
              key={city}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(event) => {
                    const { checked } = event.target;
                    let updatedValue;

                    if (checked) {
                      updatedValue = [
                        ...(filters.city?.value || []),
                        city,
                      ];
                    } else {
                      updatedValue = (filters.city?.value || []).filter(
                        (item) => item !== city
                      );
                    }

                    onChangeHandler({
                      label: "City",
                      key: "city",
                      value: updatedValue,
                    });
                  }}
                />
              }
              label={city}
            />
          );
        })}
      </FormGroup>
      <Box
        style={{ cursor: "pointer" }}
        onClick={() => {
          delete filters.city;
          setFilters({ ...filters });
          setFilterSideBarStatus(false);
        }}
      >
        Clear
      </Box>
    </FormControl>
  </AccordionDetails>
             </Accordion>
            )}
           
          </Box>
          <Box padding={2} display="flex" justifyContent="space-between">
            <Button
              style={{ fontSize: "12px" }}
              variant="outlined"
              onClick={() => {
                setFilters({
                  // search: { label: "Search", value: "" }
                });
                setFilterSideBarStatus(false);
              }}
            >
              Clear all filters
            </Button>
            <Button
              style={{ fontSize: "12px" }}
              onClick={() => {
                let appliedFilters = {
                  status: filter,
                };
                Object.keys(filters).map((value) => {
                  appliedFilters[value] = filters[value].value;
                });

                setIsDownloading(true);

                exportResults(appliedFilters)
                  .then((result) => {
                    setIsDownloading(false);
                    notification.enqueueSnackbar(
                      "You will recieve an email with requested data in 5 mins",
                      {
                        variant: "success",
                      }
                    );
                  })
                  .catch((error) => {
                    setIsDownloading(false);
                    notification.enqueueSnackbar("something went wrong", {
                      variant: "error",
                    });
                  });
                setFilterSideBarStatus(false);
              }}
              variant="contained"
              color="primary"
            >
              Download
            </Button>
          </Box>
        </Drawer>
        <Modal
          width="90%"
          height="500"
          headerComponent={ModalHeader2}
          status={createLeadModal}
        >
          <CreateLead
            deviceType="website"
            onBack={() => {
              setCreateLeadModalModal(false);
              dispatch(getLeads({ status: filter }));
            }}
          />
        </Modal>
        <Modal
          width="90%"
          height="450px"
          headerComponent={ImportModalHeader}
          status={importModal}
          closeBtnText="CANCEL"
          closeHandler={() => setImportModal(false)}
          saveBtnText="IMPORT"
          saveHandler={() => {
            document.getElementById("upload-file").click();
          }}
        >
          <Box textAlign="center">
            <input
              style={{ display: "none" }}
              onChange={(event) => {
                uploadBulkLeads({
                  apiBody: {
                    file_uploaded: event.target.files[0],
                  },
                  headers: {
                    "Content-Type":
                      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
                  },
                })
                  .then((result) => {
                    setImportModal(false);
                    notification.enqueueSnackbar("Lead Uploaded Successfully", {
                      variant: "success",
                    });
                  })
                  .catch((er) => {
                    notification.enqueueSnackbar("something went wrong", {
                      variant: "error",
                    });
                  });
              }}
              id="upload-file"
              type="file"
            />
            <img src={UploadIcon} />
          </Box>
          <Box
            textAlign="center"
            fontSize="24px"
            fontWeight="bold"
            color="#8B7862"
          >
            Select CSV or Excel file to import
          </Box>
          <Box fontSize="12px" textAlign="center">
            Excel files are recommended as fields formatting is automatic.
          </Box>
        </Modal>
        <Modal
          width="90%"
          height="500"
          headerComponent={ModalHeader}
          status={modal}
        >
          {(() => {
            return (
              <CreateLead
                formMode="edit"
                id={leadId}
                deviceType="website"
                onBack={() => {
                  // setSts(props.filter)
                  refreshLeads();
                  // dispatch(getLeads({ status: filter }));
                  setModal(false);
                }}
              />
            );
          })()}
        </Modal>
        <Modal
          width="450px"
          height="150px"
          title="Are you sure want to delete?"
          status={confirmationModal}
          closeBtnText="No"
          saveBtnText="Yes"
          closeHandler={() => {
            setConfirmationModal(false);
          }}
          saveHandler={() => {
            deleteLead(leadId).then((result) => {
              dispatch(getLeads({ status: props.filter }));
              setConfirmationModal(false);
              notification.enqueueSnackbar("Lead Deleted Successfully", {
                variant: "success",
              });
            });
          }}
        >
          <Box>Are you sure want to delete this lead?</Box>
        </Modal>
        <Grid item xs={6} md={6}>
          <TextField
            value={filters.search?.value}
            onChange={(event) => {
              onChangeHandler({
                label: "Search",
                key: "search",
                value: event.target.value,
              });
            }}
            variant="outlined"
            placeholder="Search Leads"
            inputProps={{
              style: { height: "10px", width: "400px" },
            }}
            InputProps={{
              classes: {
                root: classes.textFieldRootStyles,
                notchedOutline: classes.notchedOutlineStyles,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="#787878" />
                </InputAdornment>
              ),
            }}
          />

          <Button
            onClick={() => {
              setFilterSideBarStatus(true);
            }}
            style={{ marginLeft: "20px", height: "46px" }}
            variant="contained"
          >
            Filter by
          </Button>
        </Grid>
        <Grid style={{ textAlign: "end" }} item xs={6} md={6}>
          <Button
            onClick={() => {
              setImportModal(true);
            }}
            variant="outlined"
            color="primary"
            style={{
              marginLeft: "20px",
              height: "46px",
              backgroundColor: "white",
            }}
          >
            Bulk Upload
          </Button>
          <Button
            disabled={isDownloading}
            onClick={() => {
              // let appliedFilters = {
              //   status: filter,
              // };
              // Object.keys(filters).map((value) => {
              //   appliedFilters[value] = filters[value].value;
              // });
              setIsDownloading(true);

              exportResults({})
                .then((result) => {
                  setIsDownloading(false);
                  notification.enqueueSnackbar(
                    "You will recieve an email with requested data in 5 mins",
                    {
                      variant: "success",
                    }
                  );
                })
                .catch((error) => {
                  setIsDownloading(false);
                  notification.enqueueSnackbar("something went wrong", {
                    variant: "error",
                  });
                });
            }}
            variant="contained"
            color="primary"
            style={{ marginLeft: "20px", height: "46px" }}
          >
            {isDownloading ? (
              <CircularProgress color="secondary" />
            ) : (
              "Download"
            )}
          </Button>
          <Button
            onClick={() => {
              setCreateLeadModalModal(true);
            }}
            variant="contained"
            style={{
              backgroundColor: "#0C6E78",
              color: "white",
              marginLeft: "20px",
              height: "46px",
            }}
          >
            Add New Lead +
          </Button>
        </Grid>
        <Grid item xs={6} md={9}>
          <Box display="flex">
            <Box
              onClick={() => {
                setFilter("new");
              }}
              className={clsx({
                [classes.statusButtons]: true,
                [classes.selectedStatus]: filter == "new",
              })}
            >
              New Leads
            </Box>
            <Box
              onClick={() => {
                setFilter("In progress");
              }}
              className={clsx({
                [classes.statusButtons]: true,
                [classes.selectedStatus]: filter == "In progress",
              })}
            >
              In Progress
            </Box>
            <Box
              onClick={() => {
                setFilter("Presentation Done");
              }}
              className={clsx({
                [classes.statusButtons]: true,
                [classes.selectedStatus]: filter == "Presentation Done",
              })}
            >
              Presentation Done
            </Box>
            <Box
              onClick={() => {
                setFilter("converted");
              }}
              className={clsx({
                [classes.statusButtons]: true,
                [classes.selectedStatus]: filter == "converted",
              })}
            >
              Converted
            </Box>
            <Box
              onClick={() => {
                setFilter("Sales Done");
              }}
              className={clsx({
                [classes.statusButtons]: true,
                [classes.selectedStatus]: filter == "Sales Done",
              })}
            >
              Sales Done
            </Box>
            <Box
              onClick={() => {
                setFilter("lost");
              }}
              className={clsx({
                [classes.statusButtons]: true,
                [classes.selectedStatus]: filter == "lost",
              })}
            >
              Lost
            </Box>
            <Box
              onClick={() => {
                setFilter("Graph Analysis");
              }}
              className={clsx({
                [classes.statusButtons]: true,
                [classes.selectedStatus]: filter == "Graph Analysis",
              })}
            >
              Graph Analysis
            </Box>
          </Box>
          <Box
            style={{
              overflow: "auto",
              backgroundColor: "white",
              width: "100%",
              borderRadius: "0px 12px 0px 0px",
            }}
          >
            {" "}
            {filter !== "Graph Analysis" && (
              <Box alignItems="center" display="flex" padding={2}>
                <Box>
                  {selectedRowKeys.length === 0 ? (
                    <>Total Records: {leads.count}</>
                  ) : (
                    <>
                      {selectedRowKeys.length} &nbsp;
                      {selectedRowKeys.length > 1 ? "Records" : "Record"}{" "}
                      Selected
                    </>
                  )}
                </Box>

                <Box flex={1} display="flex">
                  {Object.keys(filters).map((key) => {
                    return (
                      <Box
                        style={{
                          fontSize: "13px",
                          backgroundColor: "#ECECEC",
                          padding: "5px 10px",
                          marginLeft: "10px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {filters[key].label}:
                        </span>{" "}
                        {filters[key].label == 'Search' || filters[key].label == 'Organisation' || filters[key].label == 'Assigned' || filters[key].label == 'Date Start' || filters[key].label == 'Date End' ? filters[key].value : filters[key].value.join(', ')}
                        <IconButton size="small">
                          <Close
                            onClick={() => {
                              delete filters[key];
                              setFilters({ ...filters });
                              setFilterSideBarStatus(false);
                            }}
                            style={{ height: "14px", width: "14px" }}
                          />
                        </IconButton>{" "}
                      </Box>
                    );
                  })}
                </Box>

                <Box>
                  {selectedRowKeys.length > 0 && (
                    <>
                      <Select
                        variant="outlined"
                        value={priority}
                        onChange={(event) => {
                          updateBulkLead({
                            leads_ids: selectedRowKeys,
                            status: event.target.value,
                          }).then((result) => {
                            setSelectedRowKeys([]);
                            refreshLeads();
                            notification.enqueueSnackbar(
                              "Lead Status Updated Successfully",
                              {
                                variant: "success",
                              }
                            );
                          });
                          setPriority("none");
                        }}
                      >
                        <MenuItem disabled value="none">
                          Update Priority
                        </MenuItem>
                        <MenuItem value="Cold">Cold</MenuItem>
                        <MenuItem value="Cool">Cool</MenuItem>
                        <MenuItem value="Hot">Hot</MenuItem>
                        <MenuItem value="Warm">Warm</MenuItem>
                      </Select>
                      {/* <Button
                        onClick={() => {
                          updateBulkLead({
                            leads_ids: selectedRowKeys,
                            status: "Hot",
                          }).then((result) => {
                            notification.enqueueSnackbar(
                              "Lead Status Updated Successfully",
                              {
                                variant: "success",
                              }
                            );
                          });
                        }}
                        style={{ marginLeft: "10px" }}
                        variant="outlined"
                      >
                        Change Lead Type
                      </Button> */}
                      <Button
                        onClick={() => {
                          setConfirmationModal2(true);
                        }}
                        style={{
                          marginLeft: "10px",
                          padding: "4px",
                          fontSize: "12px",
                        }}
                        variant="outlined"
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            )}
            {filter == "Graph Analysis" ? (
              <Grid
                style={{ padding: "10px", backgroundColor: "white" }}
                item
                xs={12}
              >
                <Box style={{ overflow: "scroll", display: "flex" }}>
                  <Grid style={{ backgroundColor: "white" }} item xs={12}>
                    <Carousel
                      showStatus={false}
                      autoPlay={false}
                      showThumbs={false}
                    >
                      <Box width="100%">
                        <Box
                          display="flex"
                          padding="20px 10px"
                          alignItems="center"
                        >
                          <Box fontSize="22px">All Leads</Box>
                          <Box flex={1} textAlign="right">
                            {/* <Select value="monthly" variant="outlined">
                            <MenuItem value="monthly">Monthly</MenuItem>
                          </Select> */}
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            padding="50px"
                            justifyContent="right"
                          >
                            <Chart
                              type="donut"
                              options={graphData.options}
                              series={graphData.series}
                              width="100%"
                            />
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            marginTop="60px"
                          >
                            {Object.keys(statistics.graphData).map(
                              (key, index) => {
                                return (
                                  <Box
                                    key={index}
                                    display="flex"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Box marginRight="10px">
                                      <img src={graphBulltes[index]} />
                                    </Box>
                                    <Box textAlign="left">
                                      <Box textAlign="left">
                                        {statistics.graphData[key].count}
                                      </Box>
                                      <Box
                                        style={{
                                          color: "#787878",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {key}{" "}
                                        {statistics.graphData[
                                          key
                                        ].percentage.toFixed(2)}
                                        %
                                      </Box>
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          display="flex"
                          padding="20px 10px"
                          alignItems="center"
                        >
                          <Box fontSize="22px">Commission Earned</Box>
                          <Box flex={1} textAlign="right">
                            {/* <Select value="monthly" variant="outlined">
                            <MenuItem value="monthly">Monthly</MenuItem>
                          </Select> */}
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box
                            style={{ mariginTop: "15px" }}
                            flex={1}
                            display="flex"
                            alignItems="center"
                            padding="50px"
                            justifyContent="center"
                          >
                            <Chart
                              options={barChart.options}
                              series={barChart.series}
                              type="bar"
                              width="600"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Carousel>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              <>
                <Table
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  expandable={{
                    expandedRowRender,
                    rowExpandable: (record) => 1,
                    expandIcon: () => <></>,
                  }}
                  expandedRowKeys={[expended]}
                  loading={loadingDatatable}
                  // expandable={{
                  //   expandedRowRender: (record) => <></>,
                  //   rowExpandable: (record) => record.name !== "Not Expandable",
                  // }}
                  rowKey="id"
                  rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                  }}
                  // columns={columns}
                  dataSource={leads.results}
                  // loading={true}
                  pagination={false}
                  // title={() => "Header"}
                >
                  <Column
                    title="Name"
                    dataIndex="name"
                    key="name"
                    render={(_, lead) => {
                      {
                        return lead.duplicate_count !== null ? (
                          <>
                            {expended == lead.id ? (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    expend(lead.id);
                                  }}
                                >
                                  <img
                                    src={Arr1}
                                    style={{ cursor: "pointer" }}
                                  />{" "}
                                </IconButton>
                                &nbsp;
                                <a
                                  style={{ color: "rgba(0, 0, 0, 0.85)" }}
                                  href={`/lead/${lead.id}`}
                                >
                                  {_}
                                </a>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    expend(lead.id);
                                  }}
                                >
                                  <img src={Arr} />{" "}
                                </IconButton>
                                &nbsp;
                                <a
                                  style={{ color: "rgba(0, 0, 0, 0.85)" }}
                                  href={`/lead/${lead.id}`}
                                >
                                  {_}
                                </a>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <a
                              style={{ color: "rgba(0, 0, 0, 0.85)" }}
                              href={`/lead/${lead.id}`}
                            >
                              {_}
                            </a>
                          </>
                        );
                      }
                    }}
                  />
                  <Column
                    title="Lead Source"
                    dataIndex="source"
                    key="address"
                  />
                  <Column
                    title="Mobile Number"
                    dataIndex="client"
                    key="client"
                    render={(item) => {
                      return item.number;
                    }}
                  />
                  <Column
                    title="Remark"
                    dataIndex="remarks"
                    key="remarks"
                    render={(item) => {
                      return (
                        <span
                          style={{ cursor: "pointer" }}
                          // onClick={() => {
                          //   history.push(`/lead/${lead.id}`, {
                          //     leadId: lead.id,
                          //   });
                          // }}
                        >
                          <div class="tooltip">
                            <div class="lead_remark">{item}</div>
                            <span class="tooltiptext"> {item}</span>
                          </div>
                        </span>
                      );
                    }}
                  />
                  <Column
                    title="Assigned"
                    dataIndex="workflow"
                    key="workflow"
                    render={(item) => {
                      return item[0]?.assigned_to?.name;
                    }}
                  />
                  <Column
                    title="Lead Type"
                    key="priority"
                    dataIndex="priority"
                    render={(item) => {
                      return item === "Hot" || item === "hot" ? (
                        <img src={Hot} />
                      ) : item === "Cold" || item === "cold" ? (
                        <img src={Cold} />
                      ) : item === "Cool" || item === "cool" ? (
                        <img src={Cool} />
                      ) : (
                        <img src={Warm} />
                      );
                    }}
                  />
                  <Column
                    title="Client Category"
                    dataIndex="client"
                    key="client"
                    render={(item) => {
                      return item.category;
                    }}
                  />
                  <Column
                    title="Updated on"
                    dataIndex="updated_on"
                    key="updated_on"
                    render={(item) => {
                      return moment(item).format("DD-MMM-YYYY");
                    }}
                  />
                  <Column
                    title="Action"
                    key="action"
                    render={(_, lead) => (
                      <>
                        <PopupState variant="popover" popupId="demoMenu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton
                                size="small"
                                variant="contained"
                                {...bindTrigger(popupState)}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  bindTrigger(popupState).onClick(event);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>

                              <Menu
                                {...bindMenu(popupState)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                style={{
                                  top: "5px",
                                  left: "1px",
                                  fontSize: "13px",
                                }}
                              >
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    popupState.close();
                                    props.updateSalesFlowModal(lead);
                                  }}
                                >
                                  <img src={Update} />
                                  &nbsp;Update Sales Flow
                                </MenuItem>
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    popupState.close();
                                    setModal(true);
                                    setLeadId(lead.id);
                                  }}
                                >
                                  <img src={Edit} />
                                  &nbsp;Edit Lead
                                </MenuItem>
                                <MenuItem
                                  onClick={(event) => {
                                    setConfirmationModal(true);
                                    setLeadId(lead.id);
                                    // event.stopPropagation();
                                    popupState.close();
                                  }}
                                >
                                  <img src={Delete} />
                                  &nbsp;Delete Lead
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </>
                    )}
                  />
                </Table>
                <Box alignItems="center" justifyContent="center" display="flex">
                  <Box style={{ padding: "10px" }}></Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    textAlign="center"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box>
                      <IconButton onClick={() => decrementPage(1)} size="small">
                        <ChevronLeftIcon />
                      </IconButton>
                    </Box>
                    <Box>
                      <Box style={{ padding: "0px 10px" }}>
                        Results {page * rowsPerPage - rowsPerPage + 1} -{" "}
                        {page * rowsPerPage} of {leads.count}
                      </Box>
                    </Box>
                    <Box>
                      <IconButton onClick={() => incrementPage(1)} size="small">
                        <ChevronRightIcon />
                      </IconButton>{" "}
                    </Box>
                  </Box>
                  <Box style={{ padding: "10px" }}>
                    <Select
                      onChange={handleChangeRowsPerPage}
                      value={rowsPerPage}
                      variant="outlined"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </>
    );
  }
);

export default LeadDataTable;
