import {
  GET_ALL_LEADS,
  SET_DASHBOARD_DATA,
  SET_META_DATA,
  SET_ALL_LEADS,
  SET_SEARCH_LEADS,
} from "../constants";

let initialState = {
  searchLeads: {
    count: 0,
    next: "",
    notify: "",
    previous: "",
    results: [],
  },
  leads: {
    count: 0,
    next: "",
    notify: "",
    previous: "",
    results: [],
  },
  meta_data: {
    sources: [],
    briks: [],
    call_statuses: [],
    client_categories: [],
    occupation_types: [],
    statuses: [],
    orgs: [],
    priorities: [],
    users: [],
  },
  statistics: {
    statistics: [],
    graphData: {},
  },
};

const DashboardReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_ALL_LEADS:
      // if (payload.override) {
      return { ...state, leads: payload };
    // } else {
    //   return {
    //     ...state,
    //     leads: {
    //       ...state.leads,
    //       ...payload.results,
    //       results: [...state.leads.results, ...payload.results],
    //     },
    //   };
    // }

    case SET_SEARCH_LEADS:
      // if (payload.override) {
      return { ...state, searchLeads: payload };
    // } else {
    //   return {
    //     ...state,
    //     leads: {
    //       ...state.leads,
    //       ...payload.results,
    //       results: [...state.leads.results, ...payload.results],
    //     },
    //   };
    // }

    case GET_ALL_LEADS:
      return { ...state };
    case SET_META_DATA:
      return { ...state, meta_data: payload };
    case SET_DASHBOARD_DATA:
      return { ...state, statistics: payload };
    default:
      return state;
  }
};

export default DashboardReducer;
