import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import _ from 'lodash'
import { CircularProgress, makeStyles } from '@material-ui/core'

function Modal({
    title,
    status,
    children,
    maxWidth,
    closeBtnText,
    saveBtnText,
    saveHandler,
    buttonAlign,
    closeHandler,
    height,
    width,
    headerComponent,
    loading,
}) {
    const useStyles = makeStyles((theme) => ({
        modalPaper: {
            width: _.isUndefined(width) ? '100%' : width,
            height: _.isUndefined(width) ? '440px' : height,
            borderRadius: '16px',
        },
    }))

    const classes = useStyles()

    return (
        <div>
            <Dialog
                // disableBackdropClick
                disableEscapeKeyDown
                classes={{ paper: classes.modalPaper }}
                style={{ borderRadius: '50px', width: '100%' }}
                open={status}
                maxWidth={_.isUndefined(maxWidth) ? 'sm' : maxWidth}
                onClose={closeHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {!_.isUndefined(title) ? (<DialogTitle
                    style={{ borderBottom: '1px solid #eaeaea', padding: '9px 16px' }}
                >
                    { title}
                </DialogTitle>) : headerComponent()}

                <DialogContent styles={{ position: "relative" }}>{children}</DialogContent>
                <DialogActions
                    style={{ justifyContent: buttonAlign ? buttonAlign : 'flax-end' }}
                >
                    {!_.isUndefined(closeBtnText) && (
                        <Button variant="outlined" onClick={closeHandler} color="primary">
                            {_.isUndefined(closeBtnText) ? 'Close' : closeBtnText}
                        </Button>
                    )}
                    {!_.isUndefined(saveBtnText) && (
                        <Button
                            variant="contained"
                            onClick={saveHandler}
                            color="primary"
                            autoFocus
                            disabled={loading}
                        >
                            {loading ? (
                                <CircularProgress size={23} />
                            ) : (
                                    <>{_.isUndefined(saveBtnText) ? 'Save' : saveBtnText}</>
                                )}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Modal
