import { Box } from '@material-ui/core'
import React from 'react'
import image from '../assets/images/404.png'

function PageNotFound() {
    return (
        <>
            {/* <Box display="flex" justifyContent="center" >
                <Box>
                    <img src={image} />
                </Box>
            </Box> */}
            <Box fontSize="35px" textAlign="center" fontWeight="600">404 Page Not Found</Box>
        </>
    )
}

export default PageNotFound
