import React, { createContext, useContext, useState } from 'react'

const GeneralContext = createContext({ setHeaderComponent:()=>{}, getHeader:()=>{}})

function GeneralProvider(props) {
    const [Header,setHeader]=useState('');

    const getHeader=()=>{
        return Header
    }

    const setHeaderComponent=(header,type)=>{
        setHeader(header)
    }
    
    return (
        <GeneralContext.Provider value={{getHeader,setHeaderComponent}}>
            {props.children}
        </GeneralContext.Provider>
    )
}

export const useGeneralProvider = () => {
    const context = useContext(GeneralContext)

    // if(context){
    //     return "useAuth must be used inside AuthProvider "
    // }else
    return context
}

export default GeneralProvider