import { Box, Button, Card, Grid, IconButton, makeStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import CertificateIcon from '../../assets/icons/certificate_icon.svg'
import ArrowIcon from '../../assets/icons/arrow_icon.svg'
import BuildIcon from '../../assets/icons/build_icon.svg'
import BSIcon from '../../assets/icons/bs_icon.svg'
import PNLICON from '../../assets/icons/profilt_loss_icon.svg'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TickIcon from '../../assets/icons/tick_icon.svg'
import Modal from '../../component/Modal'
import { Close } from '@material-ui/icons';
import DetailCard from './DetailCard'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { responsiveStyles } from '../../assets/css/generalStyling'
import { getPaymentReceipt, getDocumentList } from '../../store/actions/apis'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px"
    }
  },
  grandtotal: {
    lineHeight: "3.5",
    color: "#D85D17"
  },
  heading: {
    color: "#979797",
    fontSize: "16px",
    paddingBottom: "10px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "7px",
    }
  },
  conclueResultLabel: {
    color: "#979797",
    lineHeight: 1.5
  },
  gst: {
    padding: "18px 0px"
  }
}))
function Docs({ propertyDetails }) {
  const [paymentReciptView, setPaymentReciptView] = useState(false)
  const [reciptList, setReciptList] = useState([]);
  const [documentList, setDocumentList] = useState([
    {
      name: "",
      document: ""
    }
  ])
  const [modal, setModal] = useState(false)
  const responsive = responsiveStyles()
  const classes = useStyles()
  const { id } = useParams()

  useEffect(() => {
    getDocumentList(id).then(result => {
      setDocumentList(result.data)
    });

    getPaymentReceipt(id).then(result => {
      setReciptList(result.data)
    })
  }, [])

  const ModalHeader = () => {
    return (
      <Box padding="14px 14px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box>Payment Details</Box>
        <Box>
          <IconButton onClick={() => {
            setModal(false)
          }} size="small"><Close /></IconButton>
        </Box>
      </Box>
    )
  }

  const renderView = () => {
    if (paymentReciptView) {
      return (
        <>
          <Modal headerComponent={ModalHeader} status={modal}>
            <Box paddingBottom="10px" justifyContent="space-between" display="flex">
              <Box style={{ fontSize: "18px" }}>Invoice 41/2020</Box>
              <Box style={{ color: "#4A8EAB", cursor: "pointer" }}>Download PDF</Box>
            </Box>
            <Card style={{ padding: "10px" }} className={classes.card}>
              <Grid container>
                <Grid className={classes.heading} item xs={3}>
                  DESCRIPTION
                </Grid>
                <Grid className={classes.heading} item xs={3}>
                  RATE
                </Grid>
                <Grid className={classes.heading} item xs={3}>
                  BRIK QTY
                </Grid>
                <Grid className={classes.heading} item xs={3}>
                  SUBTOTAL
                </Grid>

                <Grid item xs={3}>
                  Initial booking
                </Grid>
                <Grid item xs={3}>
                  ₹ 1,28730
                </Grid>
                <Grid item xs={3}>
                  1
                </Grid>
                <Grid item xs={3}>
                  ₹ 1,28730
                </Grid>


                <Grid className={classes.gst} item xs={3}>
                  GST (18% )
                </Grid>
                <Grid className={classes.gst} item xs={3}>
                  ₹ 231714
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid className={classes.gst} item xs={3}>
                  ₹ 231714
                </Grid>


                <Grid className={classes.conclueResultLabel} item xs={9}>
                  Subtotal
                </Grid>
                <Grid item xs={3}>
                  ₹ 231714
                </Grid>
                <Grid className={classes.conclueResultLabel} item xs={9}>
                  Discount
                </Grid>
                <Grid item xs={3}>
                  ₹ 231714
                </Grid>
                <Grid className={classes.conclueResultLabel} item xs={9}>
                  TAX
                </Grid>
                <Grid item xs={3}>
                  ₹ 150000
                </Grid>
                <Grid className={classes.grandtotal} item xs={9}>
                  GRAND TOTAL
                </Grid>
                <Grid style={{ lineHeight: "3.5" }} item xs={3}>
                  ₹ 150000
                </Grid>
              </Grid>
            </Card>

            <Box textAlign="center" paddingTop="12px">
              <Button variant="contained" color="secondary">
                PRINT
              </Button>
            </Box>
          </Modal>

          <Grid alignItems="flex-start" style={{ flexDirection: "column" }} spacing={2} container item xs={12} sm={12}>
            <Box display="flex" fontSize="20px" alignItems="center">
              <Box><IconButton size="small" onClick={() => {
                setPaymentReciptView(false)
              }}><ChevronLeftIcon /></IconButton></Box>
              <Box>Documents</Box>
            </Box>
            <Box style={{ width: "100%", paddingTop: "20px" }}>
              <TableContainer style={{ backgroundColor: "#F7F8FB", padding: "12px" }} component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reciptList.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={4} style={{ textAlign: "center" }}>
                          No Receipt Yet
                        </TableCell>
                      </TableRow>
                    )}
                    {reciptList.map(recipt => {
                      return (
                        <TableRow component={Paper}>
                          <TableCell component="th" scope="row">
                            <Box onClick={() => {
                              // setModal(true)
                              window.open(recipt.pdf, '_blank')
                            }} style={{ fontWeight: 600, cursor: "pointer", color: "#4A8EAB" }}>{recipt.description}</Box>
                          </TableCell>
                          <TableCell align="right">{moment(recipt.invoice_date).format('DD-MM-YYYY')}</TableCell>
                          <TableCell align="right">₹ {recipt.invoice_amount}</TableCell>
                          <TableCell align="left">
                            {recipt.paid ? (
                              <Box style={{ color: "#3183FF" }} justifyContent="flex-end" display="flex" alignItems="center">
                                <Box>
                                  <img src={TickIcon} />
                                </Box>
                                <Box paddingLeft="8px">Paid</Box>
                              </Box>
                            ) : (
                                <Box style={{ color: "red" }} justifyContent="flex-end" display="flex" alignItems="center">
                                  <Box paddingLeft="8px">Not Paid</Box>
                                </Box>
                              )}

                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </>
      )
    }

    return (
      <>
        <Box className={responsive.web}>
          <Grid spacing={3} container item xs={12} sm={12}>
            <Grid item xs={12}>
              <Box fontSize="22px" fontWeight="bold">{propertyDetails.property_name}</Box>
            </Grid>
            <Grid item xs={12}>
              <DetailCard propertyDetails={propertyDetails} />
            </Grid>
          </Grid>
        </Box>
        {/* <Grid item container className={responsive.app}>
          <Grid item xs={12}>
            <Box style={{ padding: '25px' }} display='flex' flexDirection='row' justifyContent='space-between'>

              <Box display="flex" flexDirection='column' textAlign='center' paddingRight='15px'>
                <Box>
                  <IconButton style={{ backgroundColor: "white" }} disabled>
                    <img src={BuildIcon} />
                  </IconButton>
                </Box>
                <Box>
                  Account Statement
                </Box>
              </Box>

              <Box display="flex" flexDirection='column' textAlign='center' paddingRight='15px'>
                <Box>
                  <IconButton style={{ backgroundColor: "white" }} disabled>
                    <img src={BSIcon} />
                  </IconButton>
                </Box>
                <Box>
                  Balance Sheet
                </Box>
              </Box>
              <Box display="flex" flexDirection='column' textAlign='center' paddingRight='15px'>
                <Box>
                  <IconButton style={{ backgroundColor: "white" }} disabled>
                    <img src={PNLICON} />
                  </IconButton>
                </Box>
                <Box>
                  Profit & Loss
                </Box>
              </Box>

            </Box>
          </Grid>
        </Grid> */}

        <Grid alignItems="flex-start" style={{ marginTop: "30px" }} spacing={2} container item xs={12} sm={12}>
          {/* <Grid item xs={12} sm={12}>
            <Box fontSize="20px">Documents</Box>
          </Grid>         */}

          <Grid item xs={12} sm={6}>
            <Card onClick={() => {
              setPaymentReciptView(true)
            }} style={{ display: 'flex', alignItems: "center", padding: "10px" }}>
              <Box style={{ height: "22px" }}>
                <img src={CertificateIcon} />
              </Box>
              <Box flex={1} style={{fontWeight:600}} paddingLeft="10px">Payment Receipt
              </Box>
              <Box textAlign="right" flex={1}>
                <img src={ArrowIcon} />
              </Box>
            </Card>
          </Grid>

          {documentList.map(documentData => {
            return (
              <Grid item xs={12} sm={6}>
                <Card onClick={() => {
                  window.open(documentData.document, '_blank')
                }} style={{ display: 'flex', alignItems: "center", padding: "10px" }}>
                  <Box style={{ height: "22px" }}>
                    <img src={CertificateIcon} />
                  </Box>
                  <Box style={{fontWeight:600}} paddingLeft="10px">{documentData.document_name}
                    {documentData.tag != null && (
                      <span style={{ fontWeight: '500', borderRadius: '5px', fontSize: '10px', background: '#FFD5D5', color: '#E05151', marginLeft: '10px', padding: '4px 6px' }}>SPV</span>
                    )}
                  </Box>
                  <Box textAlign="right" flex={1}>
                    <img src={ArrowIcon} />
                  </Box>
                </Card>
              </Grid>
            )
          })}

        </Grid>
      </>
    )
  }

  return (
    <Grid item container>
      {renderView()}
      {/* <Grid className={responsive.web} style={{ paddingLeft: "20px" }} item sm={4}>
        <Box fontSize="20px" paddingBottom="12px">Direct pdf links</Box>
        <Box style={{ backgroundColor: "#F5F5F5", borderRadius: "5px" }}>

          <Box style={{ padding: "20px" }} display="flex" alignItems="center">
            <Box marginRight="20px">
              <IconButton style={{ backgroundColor: "white" }} disabled>
                <img src={BuildIcon} />
              </IconButton>
            </Box>
            <Box>
              Account Statement
            </Box>
          </Box>
          <Box style={{ padding: "20px" }} display="flex" alignItems="center">
            <Box marginRight="20px">
              <IconButton style={{ backgroundColor: "white" }} disabled>
                <img src={BSIcon} />
              </IconButton>
            </Box>
            <Box>
              Balance Sheet
            </Box>
          </Box>
          <Box style={{ padding: "20px" }} display="flex" alignItems="center">
            <Box marginRight="20px">
              <IconButton style={{ backgroundColor: "white" }} disabled>
                <img src={PNLICON} />
              </IconButton>
            </Box>
            <Box>
              Profit & Loss
            </Box>
          </Box>

        </Box>
      </Grid> */}

    </Grid>
  )
}
export default Docs
