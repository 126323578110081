import { applyMiddleware, createStore } from 'redux'
import { reducers } from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

let middlewares=[thunk]

let store = createStore(reducers, composeWithDevTools(
    applyMiddleware(...middlewares),
))

export default store
export * from './constants'

 