import { Box, makeStyles } from "@material-ui/core";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { useAuth } from "./providers/AuthProvider";
import Account from "./screens/Account";
import FAQ from "./screens/FAQ";
import Home from "./screens/Home";
import AdhaarEsign from "./screens/AdhaarEsign";
import HomePage from "./screens/HomePage";
import Login from "./screens/Login";
import MyOwnership from "./screens/MyOwnership";
import SliderImage from "./assets/images/Slider1.png";
import DetailView from "./screens/DetailView";
import ProfileEdit from "./screens/ProfileEdit";
import PageNotFound from "./screens/PageNotFound";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import Notification from "./layouts/Notification";
// import GenerateToken from './firebase/GenerateToken';
import { getNotificationsList, loggedInUser } from "./store/actions/apis";
import { useDispatch } from "react-redux";
import LeadDetail from "./screens/LeadDetail";
import CreateLead from "./screens/CreateLead";
import Calendar from "./screens/Calendar";
import AddEvent from "./screens/AddEvent";
import Document from "./screens/Document";
import DocumentPdf from "./screens/DocumentPdf";
import AccountEdit from "./screens/AccountEdit";
import Filter from "./screens/Filter";
import {
  getLoggedInUser,
  resetNotificationCounter,
  setNotificationList,
} from "./store/actions/userActions";
import { getMetaData } from "./store/actions/dashboardActions";
import Dashboard from "./screens/Dashboard";
import Dashboard1 from "./screens/Dashboard copy";
import DashboardLayoutApp from "./layouts/MainDrawerApp";
import DashboardLayoutWeb from "./layouts/DashboardLayoutWeb";
import { SIZE } from "./store/constants";
import { useMediaQuery } from "react-responsive";
import Agreement from "./screens/Agreement";

const useStyles = makeStyles((theme) => ({
  web: {
    visibility: "block",
  },
  app: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    web: {
      display: "none",
    },
    app: {
      display: "block",
    },
  },
}));

function PrivateRoute({ children, ...rest }) {
  const isExtraSmallMobileDevice = useMediaQuery({
    // query: "(max-device-width: 480px)"
    minWidth: 0,
    maxWidth: 480,
  });

  const isMobileDevice = useMediaQuery({
    // query: "(min-device-width: 480px)"

    minWidth: 481,
    maxWidth: 768,
  });

  //3550  ..double
  // 1450+90 =>2600
  const isTabletDevice = useMediaQuery({
    // query: "(min-device-width: 768px) and (min-device-width: 1023px)"
    minWidth: 768,
    maxWidth: 1024,
  });

  const isLaptop = useMediaQuery({
    // query: "(min-device-width: 1024px) and (max-device-width: 1199px)"
    minWidth: 1025,
    maxWidth: 1200,
  });

  const isDesktop = useMediaQuery({
    // query: "(min-device-width: 1200px)"
    minWidth: 1201,
  });

  let auth = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.getUser()) {
      getNotificationsList().then((result) => {
        var count = result.reduce(function (n, val) {
          return n + (val.read === false);
        }, 0);
        console.log("result Notification: ", result, count);
        dispatch(resetNotificationCounter(count));
        dispatch(setNotificationList(result));
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getLoggedInUser());
    dispatch(getMetaData());
  }, []);

  return (
    <>
      {/* && window.screen.width < SIZE.XL  */}
      {(isLaptop || isDesktop) && (
        <Box>
          <Route
            {...rest}
            render={({ location }) => (
              <DashboardLayoutWeb>{children}</DashboardLayoutWeb>
            )}
          />
        </Box>
      )}
      {(isTabletDevice || isMobileDevice || isExtraSmallMobileDevice) && (
        <Box style={{ marginTop: "60px" }}>
          <Route
            {...rest}
            render={({ location }) =>
              auth.getUserToken() ? (
                <DashboardLayoutApp open={true}>{children}</DashboardLayoutApp>
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          />
        </Box>
      )}
    </>
  );
}

function PublicRoute({ children, ...rest }) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.web}>
        <Route {...rest} render={({ location }) => children} />
      </Box>
      <Box className={classes.app}>
        <Route
          {...rest}
          render={({ location }) => (
            <DashboardLayoutApp open={true}>{children}</DashboardLayoutApp>
          )}
        />
      </Box>
    </>
  );
}

function App() {
  // const dispatch = useDispatch();
  const auth = useAuth();

  // useEffect(() => {
  //   if (auth.getUser())
  //     getNotificationsList().then((result) => {
  //       var count = result.data.reduce(function (n, val) {
  //         return n + (val.read === false);
  //       }, 0);

  //       dispatch(resetNotificationCounter(count));
  //       dispatch(setNotificationList(result.data));
  //     });
  // }, [auth.getUser]);

  return (
    <>
      <Suspense fallback="Loading">
        {/* <GenerateToken /> */}
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/home">
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboard">
              <Dashboard1 />
            </PrivateRoute>
            <PrivateRoute exact path="/lead/create-lead">
              <CreateLead deviceType="mobile" />
            </PrivateRoute>
            <PrivateRoute exact path="/lead/:id">
              <LeadDetail />
            </PrivateRoute>
            <PrivateRoute exact path="/lead/:lead_id/edit">
              <CreateLead deviceType="mobile" />
            </PrivateRoute>
            <PrivateRoute exact path="/event/:id/edit">
              <AddEvent deviceType="mobile" />
            </PrivateRoute>
            <PrivateRoute exact path="/event">
              <AddEvent deviceType="mobile" />
            </PrivateRoute>
            <PrivateRoute exact path="/account">
              <Account />
            </PrivateRoute>
            <PrivateRoute exact path="/calendar">
              <Calendar />
            </PrivateRoute>
            <PrivateRoute exact path="/document">
              <Document />
            </PrivateRoute>
            <PrivateRoute exact path="/document/:document_id">
              <DocumentPdf />
            </PrivateRoute>
            <PrivateRoute exact path="/account-edit">
              <AccountEdit deviceType="mobile" />
            </PrivateRoute>
            <PrivateRoute exact path="/filter">
              <Filter />
            </PrivateRoute>
            <PrivateRoute exact path="/agreement">
              <Agreement />
            </PrivateRoute>
            <PrivateRoute exact path="/faq">
              <FAQ />
            </PrivateRoute>
            {/* <PrivateRoute exact path="/detail/:id">
            <DetailView />
          </PrivateRoute>
          <PrivateRoute exact path="/account">
            <Account />
          </PrivateRoute>
          <PrivateRoute exact path="/account/edit">
            <ProfileEdit />
          </PrivateRoute>
          <PrivateRoute exact path="/faq">
            <FAQ />
          </PrivateRoute>
          <PrivateRoute exact path="/terms">
            <AdhaarEsign />
          </PrivateRoute> */}
            {/* <PublicRoute exact path="/Login">
            <Login />
          </PublicRoute> */}
            <PublicRoute exact path="/">
              <Login />
            </PublicRoute>
            <PublicRoute exact path="/forgotpassword">
              <ForgotPassword />
            </PublicRoute>
            <PublicRoute exact path="/resetpassword">
              <ResetPassword />
            </PublicRoute>
            <PublicRoute exact path="/notification">
              <Notification />
            </PublicRoute>
            <Route component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
