import React from 'react'
import { Avatar, Box, Grid, IconButton, Card, Tabs, Tab, makeStyles, TextField, InputAdornment, Button } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';


function Filter() {
    const history = useHistory()
    const [state, setState] = React.useState({
        checkedA: false,
        checkedB: false,
        checkedC: true,
        checkedD: true,
        checkedE: false,
        checkedF: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <>
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Box>
                    <IconButton size="small" onClick={() => {
                        history.goBack()
                    }}>
                        <ArrowBackIosIcon />
                    </IconButton>
                </Box>
                <Box fontSize='20px' fontWeight='600'>Filter</Box>
            </Box>

            <Box padding='10px 0'>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    placeholder='Search Property'
                    variant='outlined'
                    fullWidth
                />
            </Box>

            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={state.checkedA} onChange={handleChange} name="checkedA" color='primary' />}
                    label="Adora De Goa"
                />
                <FormControlLabel
                    control={<Checkbox checked={state.checkedB} onChange={handleChange} name="checkedB" color='primary' />}
                    label="RIO DE GOA"
                />
                <FormControlLabel
                    control={<Checkbox checked={state.checkedC} onChange={handleChange} name="checkedC" color='primary' />}
                    label="AMRUTH FARM"
                />
                <FormControlLabel
                    control={<Checkbox checked={state.checkedD} onChange={handleChange} name="checkedD" color='primary' />}
                    label="RIO DE GOA"
                />
                <FormControlLabel
                    control={<Checkbox checked={state.checkedE} onChange={handleChange} name="checkedE" color='primary' />}
                    label="AMRUTH FARM"
                />
                <FormControlLabel
                    control={<Checkbox checked={state.checkedF} onChange={handleChange} name="checkedF" color='primary' />}
                    label="Adora De Goa"
                />
            </FormGroup>

            <Box display='flex' justifyContent='space-between' padding="15px 0">
                <Box>2 Properties</Box>
                <Button variant='contained' color='primary'>Apply</Button>
            </Box>
        </>
    )
}

export default Filter
