import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function EventCard({ data }) {
  const history = useHistory();

  return (
    <>
      <Box
        style={{ cursor: "pointer", position: "relative" }}
        onClick={() => {
          history.push(`/lead/${data.lead_id}`);
        }}
        display="flex"
      >
        <Box
          style={{
            backgroundColor: "rgb(12 110 120 / 44%)",
            padding: "26px 11px",
            marginRight: "10px",
            borderRadius: "5px",
          }}
        >
          {moment(data?.time).format("HH:mm")}
        </Box>
        <Box
          style={{
            border: "1px solid #D4D6DE",
            borderRadius: "5px",
            borderLeft: "10px solid #0C6E78",
            padding: "10px",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Box style={{ fontWeight: "600", color: "#0C6E78" }}>
            {data?.name}
          </Box>
          <Box style={{ fontSize: "12px", color: "#8A9EBC" }}>
            {data?.appointment_with}
          </Box>
          <Box style={{ fontSize: "12px", color: "#8A9EBC" }}>
            {data?.details}
          </Box>
        </Box>
        <Box
          style={{
            position: "absolute",
            right: "2px",
            height: "4px",
            top: "2px",
            padding: "24px 10px",
            backgroundColor: "white",
          }}
        >
          <ArrowForwardIosIcon />
        </Box>
      </Box>
    </>
  );
}

export default EventCard;
