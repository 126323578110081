import React from "react";
import {
  AppBar,
  IconButton,
  Typography,
  makeStyles,
  Box,
  TextField,
  Avatar,
  Menu,
  MenuItem,
  Card,
  Grid,
} from "@material-ui/core";
import BellIcon from "../assets/BellIcon.png";
import { responsiveStyles } from "../assets/css/generalStyling";
// import { pushNotification } from '../store/actions/userActions';
import { onMessageListener } from "../firebase/firebaseInit";
import { useDispatch, useSelector } from "react-redux";
// import { pushNotification } from '../store/actions/userActions';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SalesIcon from "../assets/icons/Group 4312.png";
import SpeakerIcon from "../assets/icons/Group 4311.png";
import moment from "moment";
import { useAuth } from "../providers/AuthProvider";
import { getNotificationsList } from "../store/actions/apis";
import {
  resetNotificationCounter,
  setNotificationList,
} from "../store/actions/userActions";

function Notification() {
  const responsive = responsiveStyles();
  const dispatch = useDispatch();
  let auth = useAuth();

  const { notifications } = useSelector((state) => {
    return {
      notifications: state.userReducer.notifications,
    };
  });

  React.useEffect(() => {
    if (auth.getUser()) {
      getNotificationsList().then((result) => {
        var count = result.reduce(function (n, val) {
          return n + (val.read === false);
        }, 0);
        console.log("result Notification: ", result, count);
        dispatch(resetNotificationCounter(count));
        dispatch(setNotificationList(result));
      });
    }
  }, []);

  const renderNotification = (notification) => {
    switch (notification.notification_ype) {
      case "sales_done":
        return (
          <Box
            display="flex"
            style={{
              cursor: "pointer",
              backgroundColor: "#fcfafa",
            }}
            flexDirection="column"
          >
            <Box
              style={{
                padding: "5px 0",
                backgroundColor: "#ffffff",
                boxShadow: "0px 8px 25px rgba(83, 89, 144, 0.07)",
                borderRadius: "5px",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <Box paddingRight="20px">
                  <img src={SalesIcon} />
                </Box>
                <Box style={{ width: "100%" }}>
                  <Box display="flex">
                    <Box flex={1} style={{ fontWeight: "bold" }}>
                      Task Reminder
                    </Box>
                    <Box>{moment(notification.created).fromNow()}</Box>
                  </Box>
                  <Box>Google meet</Box>
                </Box>
              </Card>
            </Box>
          </Box>
        );

      default:
        return (
          <Box
            display="flex"
            style={{
              cursor: "pointer",
              backgroundColor: "#fcfafa",
            }}
            flexDirection="column"
          >
            <Box
              style={{
                padding: "5px 0",
                backgroundColor: "#ffffff",
                boxShadow: "0px 8px 25px rgba(83, 89, 144, 0.07)",
                borderRadius: "5px",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <Box paddingRight="20px">
                  <img src={SpeakerIcon} />
                </Box>
                <Box style={{ width: "100%" }}>
                  <Box display="flex">
                    <Box flex={1} style={{ fontWeight: "bold" }}>
                      Task Reminder
                    </Box>
                    <Box>{moment(notification.created).fromNow()}</Box>
                  </Box>
                  <Box>Google meet</Box>
                </Box>
              </Card>
            </Box>
          </Box>
        );
    }
  };

  // onMessageListener()
  //     .then((payload) => {
  //         console.log([payload.notification, ...notifications]);
  //         console.log("payload.notification: ", payload.notification);

  //         dispatch(pushNotification(payload.notification))

  //         const notification = new Notification(payload.notification.title, {
  //             body: payload.notification.body,
  //         });
  //     })
  //     .catch((err) => console.log("failed: ", err));

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} className={responsive.app}>
          <Box
            style={{ marginTop: "80px", display: "flex", alignItems: "center" }}
          >
            <IconButton
              style={{ fontSize: "20px", fontWeight: "600" }}
              onClick={() => {
                window.location.href = "/account";
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Box fontSize="20px" fontWeight="600">
              Notification
            </Box>
          </Box>
          <Box id="notifications" style={{ padding: "0 10px" }}>
            {notifications.map((notification, key) => {
              return renderNotification(notification);
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Notification;
