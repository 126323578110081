import {
  Box,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Card,
  Button,
  TabIndicatorProps,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import moment from "moment";
import clsx from "clsx";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import EventCard from "./component/EventCard";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { responsiveStyles } from "../assets/css/generalStyling";
import { getCalenderEvents, getTaskDetails } from "../store/actions/apis";
import Modal from "../component/Modal";
import AddEvent from "./AddEvent";
import calendar_loader from "../assets/calendar_loader.gif"

const useStyles = makeStyles((theme) => ({
  emptyBox: {
    width: "15px",
  },
  month: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    color: "#565656",
    marginLeft: "10px",
  },
  monthSelected: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    marginLeft: "10px",
    backgroundColor: "#0C6E78",
  },
  input: {
    padding: "10px 14px",
  },
  hasAppointmentColor: {
    color: "orange",
  },
  flexContainer: {
    overflow: "scroll",
  },
  hasAppointmentonDay: {
    display: "block !important",
  },
  hasAppointment: {
    fontSize: "37px",
    display: "none",
    position: "absolute",
    margin: "0px",
    top: "-5px",
    left: "14px",
  },
  selectedDay: {
    // paddingTop: "10px",
    // borderRadius: "50px",
    cursor: "pointer",
    // color: "white",
    // backgroundColor: "#e8845f50",
  },
  selectedDate: {
    backgroundColor: "#0C6E78",
    color: "white",
  },
  day: {
    // paddingTop: "10px",
    // borderRadius: "50px",
    cursor: "pointer",
    // "&:hover": {
    //     color: "white",
    //     backgroundColor: "#e8845f50",
    // },
    // "&:hover $date": {
    //     backgroundColor: "#e8845f",
    //     color: "white",
    // },
  },
  date: {
    padding: "8px 10px",
    borderRadius: "5px",
    position: "relative",
    // minHeight: "30px"
    // background: "#e8845f",
    // color: "white"
  },
  time: {
    fontSize: "30px",
    fontWeight: 500,
    color: "#fff",
    fontFamily: "lato",
  },
  text: {
    fontSize: "13px",
    fontWeight: "normal",
  },
  verticalBar: {
    height: "30px",
    width: "3px",
    backgroundColor: "#fff",
    color: "white",
    margin: "5px 10px",
  },
  activeText: {
    color: "#825ffc",
  },
  root: {
    position: "absolute",
    margin: "0px",
    bottom: "80px",
    right: "30px",
  },
  inactiveText: {
    color: "grey",
  },
  gradientColor: {
    backgroundImage: "linear-gradient(110deg, #22cfc7, #7c79e4 100%)",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#FF5674",
      height: 3,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#FF5674",
    },
  },
}));

function Calendar() {
  const classes = useStyles();
  const history = useHistory();
  const responsive = responsiveStyles();
  const endOfWeekYear = (date) => {
    const lastday = date.getDate() - (date.getDay() - 1) + 6;
    const year = new Date(date.setDate(lastday)).getFullYear();
    return year;
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [currentWeekMonth, setCurrentWeekMonth] = useState(
    monthNames[moment().startOf("isoWeek").toDate().getMonth()]
  );
  const [monthlyEvents, setMonthEvents] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [currentYear, setCurrentYear] = useState(moment().get("year"));
  const [month, setMonth] = React.useState(moment().format("MMM"));

  const [date, changeDate] = useState(moment());
  const [calendarFullView, setCalendarFullView] = useState(true);
  const [eventModal, setEventModal] = useState(false);
  const [startOfWeek, setStartOfWeek] = useState(
    moment().startOf("isoWeek").toDate()
  );
  const [endOfWeek, setEndOfWeek] = useState(
    moment().endOf("isoWeek").toDate()
  );
  const [value, setValue] = React.useState(0);
  const [filter, setFilter] = useState("calendar_view");
  const [taskList, setTask1] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentWeekendMonth, setCurrentWeekendMonth] = useState(
    monthNames[moment().month()]
  );

  const _goToPreviousWeek = () => {
    const start = moment(startOfWeek)
      .subtract(1, "isoWeeks")
      .startOf("isoWeek")
      .toDate();
    const end = moment(startOfWeek)
      .subtract(1, "isoWeeks")
      .endOf("isoWeek")
      .toDate();
    const checkStart = moment(start, "YYYY/MM/DD");
    const checkEnd = moment(end, "YYYY/MM/DD");
    setCurrentYear(endOfWeekYear(new Date(checkStart)));
    setCurrentWeekMonth(monthNames[checkStart.format("M") - 1]);
    setCurrentWeekendMonth(monthNames[checkEnd.format("M") - 1]);
    setStartOfWeek(start);
    setEndOfWeek(end);
    // getSessionsByDateRange(start, end, "weekly");
  };

  const _goToNextWeek = () => {
    const start = moment(startOfWeek)
      .add(1, "isoWeeks")
      .startOf("isoWeek")
      .toDate();
    const end = moment(startOfWeek)
      .add(1, "isoWeeks")
      .endOf("isoWeek")
      .toDate();
    const checkStart = moment(start, "YYYY/MM/DD");
    const checkEnd = moment(end, "YYYY/MM/DD");
    setCurrentYear(endOfWeekYear(new Date(checkStart)));
    setCurrentWeekMonth(monthNames[checkStart.format("M") - 1]);
    setCurrentWeekendMonth(monthNames[checkEnd.format("M") - 1]);
    setStartOfWeek(start);
    setEndOfWeek(end);
    // getSessionsByDateRange(start, end, "weekly");
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    // fetchTasks();
    setLoading(true);
    getCalenderEvents(currentYear, renderMonthNumber(month) + 1).then(
      (result) => {
        setMonthEvents(result.data);
        setLoading(false);
      }
    );
  }, [
    // selectedDate,
    month,
    currentYear,
  ]);

  const ModalHeader1 = () => {
    return (
      <Box
        style={{ zIndex: 1, color: "black" }}
        padding="14px 14px 0 14px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box fontSize="22px" fontWeight={600}>
          Add Event
        </Box>
        <Box>
          <IconButton
            style={{ color: "black" }}
            onClick={() => {
              setEventModal(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const fetchTasks = () => {
    let date1 = moment(
      `${selectedDate.format("D")}-${month}-${currentYear}`
    ).format("YYYY-MM-DD");
    getTaskDetails(date1)
      .then((result) => {
        const { data } = result;
        console.log("data", data);
        setTask1(data);
      })
      .catch((er) => {
        console.log("error: ", er);
      });

    // {selectedDate.format("D")} {month} {currentYear}
  };

  const renderYears = () => {
    let options = [];

    for (let year = 2020; year <= moment().format("Y"); year++) {
      options.push(<MenuItem value={year}>{year}</MenuItem>);
    }

    return options;
  };

  function leftPad(number, targetLength) {
    var output = number + "";
    while (output.length < targetLength) {
      output = "0" + output;
    }
    return output;
  }

  const renderMonthNumber = (month) => {
    switch (month) {
      case "Jan":
        return 0;
      case "Feb":
        return 1;
      case "Mar":
        return 2;
      case "Apr":
        return 3;
      case "May":
        return 4;
      case "Jun":
        return 5;
      case "Jul":
        return 6;
      case "Aug":
        return 7;
      case "Sep":
        return 8;
      case "Oct":
        return 9;
      case "Nov":
        return 10;
      default:
        return 11;
    }
  };

  return (
    <>
      <Modal
        width="450px"
        height="510px"
        headerComponent={ModalHeader1}
        status={eventModal}
      >
        <AddEvent
          deviceType="website"
          onBack={() => {
            setEventModal(false);
            // fetchTasks();
          }}
        />
      </Modal>
      <Box className={responsive.web}>
        <Box
          style={{ marginBottom: "10px", fontSize: "18px", fontWeight: "700" }}
        >
          Calendar
        </Box>
        <Card
          style={{
            backgroundColor: "white",
            padding: "2%",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Button
            onClick={() => setEventModal(true)}
            style={{
              float: "right",
              backgroundColor: "#8B7862",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              color: "white",
            }}
          >
            ADD NEW EVENT
          </Button>
          {/* <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={value}
            classes={({ flexContainer: classes.flexContainer }, classes.tabs)}
            onChange={(event, newValue) => {
              switch (newValue) {
                case 1:
                  setFilter("list_view");
                  break;
                default:
                  setFilter("calendar_view");
              }
              setValue(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab
              className={classes.tabs}
              label="Calendar View"
              {...a11yProps(0)}
            />
            <Tab label="List View" {...a11yProps(1)} />
          </Tabs> */}
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{
                padding: "10px 18px",
                width: "100%",
                marginTop: "2%",
              }}
            >
              <Box>
                <Select
                  input={<OutlinedInput classes={{ input: classes.input }} />}
                  value={currentYear}
                  onChange={(e) => setCurrentYear(e.target.value)}
                  // error={touched.document_type && errors.document_type ? true : false}
                  // helperText={touched.document_type && errors.document_type}
                  labelId="demo-simple-select-outlined-label"
                >
                  <MenuItem value="none">Select</MenuItem>;{renderYears()}
                </Select>
              </Box>
              <Box
                display="flex"
                flex={1}
                style={{
                  overflow: "scroll",
                  justifyContent: "space-between",
                  fontWeight: "700",
                }}
              >
                <Box
                  onClick={() => {
                    setMonth("Jan");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Jan" == month,
                  })}
                >
                  Jan
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Feb");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Feb" == month,
                  })}
                >
                  Feb
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Mar");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Mar" == month,
                  })}
                >
                  Mar
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Apr");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Apr" == month,
                  })}
                >
                  Apr
                </Box>
                <Box
                  onClick={() => {
                    setMonth("May");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "May" == month,
                  })}
                >
                  May
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Jun");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Jun" == month,
                  })}
                >
                  Jun
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Jul");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Jul" == month,
                  })}
                >
                  Jul
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Aug");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Aug" == month,
                  })}
                >
                  Aug
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Sep");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Sep" == month,
                  })}
                >
                  Sep
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Oct");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Oct" == month,
                  })}
                >
                  Oct
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Nov");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Nov" == month,
                  })}
                >
                  Nov
                </Box>
                <Box
                  onClick={() => {
                    setMonth("Dec");
                  }}
                  className={clsx({
                    [classes.month]: true,
                    [classes.monthSelected]: "Dec" == month,
                  })}
                >
                  Dec
                </Box>
              </Box>
            </Box>

            {loading ? <Box margin="auto" textAlign="center">
              <img src={calendar_loader} alt="Loading..." height="200px" />
            </Box>:<Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%", padding: "12px" }}
            >
              {monthlyEvents.map((item, index) => {
                return (
                  !item.emptyBox && (
                    <Box
                      onClick={() => {
                        // setAppointments(
                        // appointmentList[index].appointments
                        // );

                        // console.log(
                        //   "DATE HERE :",
                        //   moment([currentYear, renderMonthNumber(month)])
                        //     .add(index, "days")
                        //     .format("DD-MMM-YYYY"),
                        //   "Selected Days: ",
                        //   moment(startOfWeek)
                        //     .add(index, "days")
                        //     .format("DD-MMM-YYYY")
                        // );

                        console.log(
                          moment([currentYear, renderMonthNumber(month)])
                            .add(index, "days")
                            .format("DD-MMM-YYYY")
                        );

                        setSelectedDate(
                          moment([currentYear, renderMonthNumber(month)]).add(
                            index,
                            "days"
                          )
                        );

                        setTask1(item.event);
                        // setSelectedDate(moment(startOfWeek).add(index, "days"));
                      }}
                      className={clsx({
                        [classes.day]: true,
                        [classes.selectedDay]: true,
                        // selectedDate.startOf("day").unix() ==
                        // moment(startOfWeek)
                        //   .add(index, "days")
                        //   .startOf("day")
                        //   .unix(),
                      })}
                      key={index}
                    >
                      {/* <Box
                                                      style={{
                                                          color: "#2B354180",
                                                          textAlign: "center",
                                                          minHeight: "30px",
                                                      }}
                                                  >
                                                      {item}
                                                  </Box> */}
                      <Box
                        className={clsx({
                          [classes.date]: true,
                          [classes.selectedDate]:
                            selectedDate.startOf("day").unix() ===
                            moment([currentYear, renderMonthNumber(month)])
                              .add(index, "days")
                              .startOf("day")
                              .unix(),
                        })}
                      >
                        {leftPad(index + 1, 2)}
                        {/* {moment(startOfWeek).add(index, "days").format("DD")} */}
                        <div
                          className={clsx({
                            [classes.hasAppointment]: true,
                            [classes.hasAppointmentonDay]: item.hasEvent,
                            // appointmentList[index]
                            //     .hasAppointent,
                            [classes.hasAppointmentColor]:
                              selectedDate.startOf("day").unix() !==
                              moment(startOfWeek)
                                .add(index, "days")
                                .startOf("day")
                                .unix(),
                          })}
                        >
                          .
                        </div>
                      </Box>
                    </Box>
                  )
                );
              })}
            </Box>}
          </>
          <Box
            display="flex"
            marginBottom="10px"
            alignItems="flex-end"
            marginTop="22px"
          >
            <Box style={{ fontSize: "16px" }}>
              {moment(
                `${selectedDate.format("D")}-${month}-${currentYear}`
              ).format("dddd")}
            </Box>
            <Box
              style={{ fontSize: "12px", marginLeft: "8px", color: "#8A9EBC" }}
            >
              {taskList.length} Events
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {taskList.map((task) => {
                return (
                  <Box style={{ paddingBottom: "10px" }}>
                    <EventCard data={task} />
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box className={responsive.app}>
        <Card
          style={{ backgroundColor: "#F1E8DA", padding: "10px" }}
          className="app-dashboard"
        >
          <Box style={{ backgroundColor: "white" }}>
            <Box style={{ fontSize: "22px", padding: "12px" }}>Calendar</Box>

            {/* <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={value}
            classes={{ flexContainer: classes.flexContainer }}
            onChange={(event, newValue) => {
              switch (newValue) {
                case 1:
                  setFilter("list_view");
                  break;
                default:
                  setFilter("calendar_view");
              }
              setValue(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ fontWeight: "bold" }}
              label="Calendar View"
              {...a11yProps(0)}
            />
            <Tab
              style={{ fontWeight: "bold" }}
              label="List View"
              {...a11yProps(1)}
            />
          </Tabs> */}
            {calendarFullView ? (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    padding: "0px 18px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <Box>
                    <Select
                      input={
                        <OutlinedInput classes={{ input: classes.input }} />
                      }
                      value={currentYear}
                      onChange={(e) => setCurrentYear(e.target.value)}
                      // error={touched.document_type && errors.document_type ? true : false}
                      // helperText={touched.document_type && errors.document_type}
                      labelId="demo-simple-select-outlined-label"
                    >
                      {renderYears()}
                    </Select>
                  </Box>
                  <Box display="flex" flex={1} style={{ overflow: "scroll" }}>
                    <Box
                      onClick={() => {
                        setMonth("Jan");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Jan" == month,
                      })}
                    >
                      Jan
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Feb");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Feb" == month,
                      })}
                    >
                      Feb
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Mar");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Mar" == month,
                      })}
                    >
                      Mar
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Apr");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Apr" == month,
                      })}
                    >
                      Apr
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("May");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "May" == month,
                      })}
                    >
                      May
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Jun");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Jun" == month,
                      })}
                    >
                      Jun
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Jul");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Jul" == month,
                      })}
                    >
                      Jul
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Aug");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Aug" == month,
                      })}
                    >
                      Aug
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Sep");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Sep" == month,
                      })}
                    >
                      Sep
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Oct");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Oct" == month,
                      })}
                    >
                      Oct
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Nov");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Nov" == month,
                      })}
                    >
                      Nov
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Dec");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Dec" == month,
                      })}
                    >
                      Dec
                    </Box>
                  </Box>

                  {/* <Box>
                        <IconButton
                            color="primary"
                            onClick={_goToPreviousWeek}
                        >
                            <ArrowBackIosIcon
                                style={{ color: "primary" }}
                                fontSize="small"
                            />
                        </IconButton>
                    </Box>
                    <Box variant="h3">
                        {`${startOfWeek.getDate()} ${currentWeekMonth} - ${endOfWeek.getDate()} ${currentWeekendMonth}, ${currentYear}`}
                    </Box>
                    <Box>
                        <IconButton
                            color="primary"
                            onClick={_goToNextWeek}
                        >
                            <ArrowForwardIosIcon
                                style={{ color: "primary" }}
                                fontSize="small"
                            />
                        </IconButton>
                    </Box> */}
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ width: "100%", padding: "12px" }}
                >
                  {["M", "T", "W", "T", "F", "S", "S"].map((item, index) => {
                    return (
                      <Box
                        onClick={() => {
                          // setAppointments(
                          // appointmentList[index].appointments
                          // );
                          setSelectedDate(
                            moment(startOfWeek).add(index, "days")
                          );
                          setTask1(item.event);
                        }}
                        className={clsx({
                          [classes.day]: true,
                          [classes.selectedDay]:
                            selectedDate.startOf("day").unix() ==
                            moment(startOfWeek)
                              .add(index, "days")
                              .startOf("day")
                              .unix(),
                        })}
                        key={index}
                      >
                        {/* <Box
                                                style={{
                                                    color: "#2B354180",
                                                    textAlign: "center",
                                                    minHeight: "30px",
                                                }}
                                            >
                                                {item}
                                            </Box> */}
                        <Box
                          className={clsx({
                            [classes.date]: true,
                            [classes.selectedDate]:
                              selectedDate.startOf("day").unix() ===
                              moment(startOfWeek)
                                .add(index, "days")
                                .startOf("day")
                                .unix(),
                          })}
                        >
                          {moment(startOfWeek).add(index, "days").format("DD")}
                          <div
                            className={clsx({
                              [classes.hasAppointment]: true,
                              [classes.hasAppointmentonDay]: false,
                              [classes.hasAppointmentColor]:
                                selectedDate.startOf("day").unix() !==
                                moment(startOfWeek)
                                  .add(index, "days")
                                  .startOf("day")
                                  .unix(),
                            })}
                          >
                            .
                          </div>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box textAlign="center">
                  <IconButton
                    onClick={() => {
                      setCalendarFullView(false);
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Box>
              </>
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    padding: "0px 18px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <Box>
                    <Select
                      input={
                        <OutlinedInput classes={{ input: classes.input }} />
                      }
                      value={currentYear}
                      onChange={(e) => setCurrentYear(e.target.value)}
                      // onChange={handleChange}
                      // error={touched.document_type && errors.document_type ? true : false}
                      // helperText={touched.document_type && errors.document_type}
                      labelId="demo-simple-select-outlined-label"
                    >
                      {renderYears()}
                    </Select>
                  </Box>
                  <Box display="flex" flex={1} style={{ overflow: "scroll" }}>
                    <Box
                      onClick={() => {
                        setMonth("Jan");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Jan" == month,
                      })}
                    >
                      Jan
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Feb");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Feb" == month,
                      })}
                    >
                      Feb
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Mar");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Mar" == month,
                      })}
                    >
                      Mar
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Apr");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Apr" == month,
                      })}
                    >
                      Apr
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("May");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "May" == month,
                      })}
                    >
                      May
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Jun");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Jun" == month,
                      })}
                    >
                      Jun
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Jul");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Jul" == month,
                      })}
                    >
                      Jul
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Aug");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Aug" == month,
                      })}
                    >
                      Aug
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Sep");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Sep" == month,
                      })}
                    >
                      Sep
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Oct");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Oct" == month,
                      })}
                    >
                      Oct
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Nov");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Nov" == month,
                      })}
                    >
                      Nov
                    </Box>
                    <Box
                      onClick={() => {
                        setMonth("Dec");
                      }}
                      className={clsx({
                        [classes.month]: true,
                        [classes.monthSelected]: "Dec" == month,
                      })}
                    >
                      Dec
                    </Box>
                  </Box>
                </Box>

                  { loading ? <Box margin="auto" textAlign="center">
                    <img src={calendar_loader} alt="Loading..." height="200px" />
                  </Box> : <Box>
                    {_.chunk(monthlyEvents, 7).map((weekData, weekIndex) => {
                      let dateOfWeek = weekIndex * 7;
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-around"
                          style={{ width: "100%", padding: "12px" }}
                        >
                          {weekData.map((event, eventIndex) => {
                            return (
                              <>
                                <Box
                                  onClick={() => {
                                    setSelectedDate(
                                      moment([
                                        2022,
                                        renderMonthNumber(month),
                                      ]).add(dateOfWeek + eventIndex, "days")
                                    );

                                    setTask1(event.event);
                                  }}
                                  className={clsx({
                                    [classes.day]: true,
                                    [classes.selectedDay]:
                                      selectedDate.startOf("day").unix() ==
                                      moment([2022, renderMonthNumber(month)])
                                        .add(dateOfWeek + eventIndex, "days")
                                        .startOf("day")
                                        .unix(),
                                  })}
                                  key={dateOfWeek + eventIndex}
                                >
                                  <Box
                                    className={clsx({
                                      [classes.date]: true,
                                      [classes.selectedDate]:
                                        selectedDate.startOf("day").unix() ===
                                        moment([2022, renderMonthNumber(month)])
                                          .add(dateOfWeek + eventIndex, "days")
                                          .startOf("day")
                                          .unix(),
                                    })}
                                  >
                                    {event.emptyBox ? (
                                      <Box className={classes.emptyBox}></Box>
                                    ) : (
                                      <>
                                        {" "}
                                        {moment([2022, renderMonthNumber(month)])
                                          .add(dateOfWeek + eventIndex, "days")
                                          .format("DD")}
                                        <div
                                          className={clsx({
                                            [classes.hasAppointment]: true,
                                            [classes.hasAppointmentonDay]:
                                              event.event.length > 0,
                                            [classes.hasAppointmentColor]:
                                              selectedDate
                                                .startOf("day")
                                                .unix() !==
                                              moment([
                                                2022,
                                                renderMonthNumber(month),
                                              ])
                                                .add(
                                                  dateOfWeek + eventIndex,
                                                  "days"
                                                )
                                                .startOf("day")
                                                .unix(),
                                          })}
                                        >
                                          .
                                        </div>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </Box>}
                <Box textAlign="center">
                  <IconButton
                    onClick={() => {
                      setCalendarFullView(true);
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                </Box>
              </>
            )}

            <Box>
              <Box
                ml={2}
                display="flex"
                marginBottom="10px"
                alignItems="flex-end"
              >
                <Box style={{ fontSize: "16px" }}>
                  {moment(
                    `${selectedDate.format("D")}-${month}-${currentYear}`
                  ).format("dddd")}
                </Box>
                <Box
                  style={{
                    fontSize: "12px",
                    marginLeft: "8px",
                    color: "#8A9EBC",
                  }}
                >
                  {taskList.length} Events
                </Box>
              </Box>
              <Box margin={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {taskList.map((task) => {
                      return (
                        <Box style={{ paddingBottom: "10px" }}>
                          <EventCard data={task} />
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <br />
          <Box className={classes.root}>
            <Fab
              onClick={() => {
                history.push("/event");
              }}
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
            >
              <AddIcon />
              Add Event
            </Fab>
          </Box>
        </Card>
      </Box>
    </>
  );
}

export default Calendar;
