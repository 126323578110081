import { Box, Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import SliderImage from '../assets/images/Slider1.png'
import PropertyCard from './component/PropertyCard'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  slider: { padding: "20px", width: "1000px" },
  [theme.breakpoints.down('sm')]: {
    slider: { width: "300px" },
  }
}))

function Home() {
  const [sliders, setSliders] = useState([{ img: SliderImage }, { img: SliderImage }, { img: SliderImage }, { img: SliderImage }, { img: SliderImage }, { img: SliderImage }, { img: SliderImage }, { img: SliderImage }])
  
  const classes = useStyles()
  const history = useHistory();

  const { myOwnerships } = useSelector((state) => {
    console.log("REDUX DATA: ", state.myOwnerShip.myOwnerShips)
    return {
      myOwnerships: state.myOwnerShip.myOwnerShips
    }
  });

  return (
    <>
      {/* <Box className={classes.slider}>
        <Carousel data={sliders} swipeable={true} customButtonGroup={false} items={1} loop={false} data={sliders}>
          {(data) => {
            return <div>
              <img height="200px" src={data.img} />
            </div>
          }}
        </Carousel>
      </Box>

      <Box className={classes.slider}>
        <Box style={{ fontSize: "18px", paddingBottom: "12px" }}>
          New & Trending
        </Box>
        <Carousel responsive={{
          0: {
            items: 1,
          },
          450: {
            items: 2,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 3,
          },
        }} data={myOwnerships} dots={false} swipeable={true} customButtonGroup={false} items={3} loop={false} data={myOwnerships}>
          {(data, index) => {
            return <React.Fragment key={index}>
              <PropertyCard data={data} onClick={() => {
                history.push('/detail')
              }} />
            </React.Fragment>
          }}
        </Carousel>
      </Box> */}
      Home
    </>
  )
}

export default Home
