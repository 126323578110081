import { Button, Card, Box, Grid, makeStyles, Avatar, AccordionSummary, Typography, AccordionDetails, IconButton } from '@material-ui/core'
import React, { useEffect, useState, useRef } from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../component/TabPanel';
import DbIcon from '../../assets/icons/rs_image.svg'
import TickIcon from '../../assets/icons/tick_icon.svg'
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PaymentIcon from '../../assets/icons/payment_icon.svg'
import SettingsIcon from '../../assets/icons/settings_icon.svg'
import Payment from '../../assets/icons/PaymentPlan.png'
import DownloadIcon from '../../assets/icons/download_icon.svg'
import CalendarIcon from '../../assets/icons/Calendar.svg'
import Copy from '../../assets/icons/Copy.png'
import GPay from '../../assets/icons/GPay.png'
import PhonePay from '../../assets/icons/PhonePay.png'
import INB from '../../assets/icons/INB.png'
import APS from '../../assets/icons/APS.png'
import BankTrans from '../../assets/icons/BankTrans.png'
import DetailCard from './DetailCard';
import { responsiveStyles } from '../../assets/css/generalStyling';
import { getSpvTransactionPlan, getTransactionData } from '../../store/actions/apis';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash'
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDispatch, useSelector } from 'react-redux';
import { setTrasactionData } from "../../store/actions/ownershipActions"
import Modal from '../../component/Modal'
import { Close } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  // root:{
  //   "&.MuiButton-outlined": {
  //     color: "brown",
  //     "&.hover" : {
  //       backgroundColor: "red",
  //     }
  //   }
  // },
  heading: {
    fontSize: "14px",
    fontWeight: 600
  },
  paymentBox: {
    background: "#FCFCFC",
    width: "100%",
    // padding: "10px",
    borderRadius: "5px",
    marginBottom: "20px",
    border: "1px solid #E5E5E5"
  },
  selectedTab: {
    color: "white !important",
    backgroundColor: "#1FA24A !important",
    margin: "10px",
    borderRadius: "2px"
  },
  indicatorTabs: {
    backgroundColor: 'white !important'
  },
  AccordionDetails: {
    // paddingLeft: "20px"
  },
  [theme.breakpoints.down('sm')]: {
    AccordionDetails: {
      marginTop: "20px",
      paddingLeft: "0px"
    }
  }
}))

const center = {
  display: 'flex',
  padding: "8px 0px",
  color: "#4A8EAB",
  flexDirection: 'row',
  alignItems: 'center'
}

const flfo = {
  flex: 1,
  fontWeight: 600
}

const flex = {
  display: 'flex',
  justifyContent: 'space-between',
  // padding: '0px 0'
}

const root = {

}

function Transaction({ propertyDetails }) {
  const [value, setValue] = React.useState('income');
  const classes = useStyles();
  const [dueRemainderStatus, setDueRemainderStatus] = useState(true);
  const [brik, setSelectedBrik] = useState(0)
  const [maintananceData, setMaintananceData] = useState([])
  const [modal, setModal] = useState(false)
  const [copySuccess, setCopySuccess] = useState('')
  const [copyText,setCopyText]=useState('')
  const [beneficiaryView,setBeneficiaryView]=useState('none')
  const [expand, setExpand] = useState(false)
  const [receivers, setReceivers] = useState({
    id: "0",
    account_number: "",
    BeneficiaryName: "",
    ifsc: "",
    UPI: "",
  })
  // const [maintenanceAnnual, setMaintenanceAnnual] = useState([])
  // const [myplanPayment, setMyplanPayment] = useState([])
  // const [installments, setInstallments] = useState([])

  // function copyToClipboard(e) {
  //   // textAreaRef.current.select();
  //   // document.execCommand('copy');
  //   navigator.clipboard.writeText(copyText)
  //   setCopySuccess('Copied!');
  // };

  const dispatch = useDispatch()
  const { TransactionData, dueRemainders, maintenanceAnnual, myplanPayment, installments } = useSelector(state => {
    return {
      TransactionData: state.myOwnerShip.payments,
      dueRemainders: state.myOwnerShip.payments.due_remainders,
      maintenanceAnnual: state.myOwnerShip.payments.maintenance_annual,
      myplanPayment: state.myOwnerShip.payments.payment_details,
      installments: state.myOwnerShip.payments.installments,
    }
  })

  // const [dueRemainders, setDueRemainders] = useState({
  //   property_due_remainder: {},
  //   maintenance_due_remainder: {}
  // })
  const responsive = responsiveStyles()
  const { id } = useParams()

  useEffect(() => {
    // if (Object.keys(myplanPayment).length == 0)
    getTransactionData(id).then(result => {
      const { data } = result
      console.log("RRSULT TXN: ", data)

      if (data.length) {
        setMaintananceData(data)
        dispatch(setTrasactionData(data[0]))
      }

      // console.log("data.due_remainders: ", _.isEmpty(data.due_remainders))
      // setDueRemainders(data.due_remainders)
    }).catch(er => {
      console.log("ERROR TXN: ", er)
    })
  }, [])

  useEffect(() => {
    if (maintananceData.length)
      dispatch(setTrasactionData(maintananceData[brik]))
  }, [brik])


  const ModalHeader = () => {
    return (
      <Box style={{ zIndex: 1, color: "white" }} padding="14px 23px" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box style={{ fontSize: '21px', fontWeight: "600", color: "Black" }}>Beneficiary Account Details</Box>
        <Box>
          <IconButton style={{ color: "Black" }} onClick={() => {
            setModal(false)
          }} size="small"><Close /></IconButton>
        </Box>
      </Box>
    )
  }


  return (
    <>
      <Modal width="550px" height="500px" headerComponent={ModalHeader} status={modal}>
        <Box lineHeight='2.5' style={{ marginBottom: "8px" }}>
          <Box style={{ display: "flex" }}>
            <Box flex={1}>Account Number</Box>
            <Box flex={1} style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>{receivers[0]?.account_number}</Box>
              <Button variant='outlined' color='secondary' onClick={() => { const x = "Account Number: " + receivers[0]?.account_number + "\n Beneficiary Name:" + receivers[0]?.name + "\n IFSC Code: " + receivers[0]?.ifsc + "\n UPI Code: " + receivers[1]?.address; navigator.clipboard.writeText(x); setCopySuccess('Copied'); }}>{copySuccess ? copySuccess : "Copy"}</Button>
            </Box>
          </Box>
          <Box style={{ display: "flex" }}>
            <Box flex={1}>Beneficiary Name</Box>
            <Box flex={1}>{receivers[0]?.name}</Box>
          </Box>
          <Box style={{ display: "flex" }}>
            <Box flex={1}>IFSC Code</Box>
            <Box flex={1}>{receivers[0]?.ifsc}</Box>
          </Box>
          <Box style={{ display: "flex" }}>
            <Box flex={1}>UPI ID</Box>
            <Box flex={1}>{receivers[1]?.address}</Box>
          </Box>
        </Box>
        {/* <div>
          {
            <div>
              <Button color="secondary" variant="contained" onClick={()=>{const x="Account Number: "+receivers[0]?.account_number+"Beneficiary Name:"+receivers[0]?.name+"IFSC Code: "+receivers[0]?.ifsc+"UPI Code: "+receivers[1]?.address;navigator.clipboard.writeText(x);setCopySuccess('Copied!');}}>Copy</Button> 
              &nbsp;&nbsp;{copySuccess}
            </div>
          }
          <div style={{display:"none"}}>
          </div>
        </div> */}
        <Box style={{ backgroundColor: '#EDDEC0', marginTop: "12px" }}>
          <Box padding='12px 32px' fontSize='13px'>Maximum limit of UPI ID is 1 lakh. Please add account as beneficiary and pay for more</Box>
        </Box>

        <Box padding='20px 0' fontSize='15px' fontWeight='600'>You can pay through</Box>

        <Box style={{ display: "flex", justifyContent: "space-around" }}>
          <Box><img src={GPay} /></Box>
          <Box><img src={PhonePay} /></Box>
          <Box><img src={INB} /></Box>
          <Box><img src={APS} /></Box>
          <Box><img src={BankTrans} /></Box>
        </Box>
      </Modal>


      <Box className={responsive.web}>
        <Grid container >
          <Grid item spacing={3} container item xs={12} sm={12}>
            <Grid item xs={12}>
              <Box fontSize="22px" fontWeight="bold">{propertyDetails.property_name}</Box>
            </Grid>
            <Grid item xs={12}>
              <DetailCard propertyDetails={propertyDetails} />
            </Grid>

            <Grid item xs={12} sm={12} className={classes.AccordionDetails}>
              {maintananceData.map((item, index) => {
                return <Button className="brikk" style={{ borderRadius: "0px" }}
                  onClick={() => {
                    setSelectedBrik(index)
                  }} variant={brik == index ? "contained" : "outlined"} color="secondary">Brik {index + 1}</Button>
              })}

              <Accordion expanded={dueRemainderStatus} onClick={() => {
                setDueRemainderStatus((state) => {
                  return !state
                })
              }} style={{ marginTop: "5px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ alignItems: "center" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box><img src={PaymentIcon} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Payment Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box style={{ width: "100%" }}>
                    <Box className={classes.paymentBox}>
                      <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                        <Box style={center} fontWeight='600'>Payment Info</Box>
                        {/* <Box>1</Box> */}
                      </Box>
                      {/* <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5' }}>
                                        <Box style={center}>
                                            <Box fontWeight='600'>Payment Info</Box>
                                        </Box>
                                    </Box> */}
                      <Box style={{ ...flex, padding: "10px", fontSize: "12px" }} display='flex' padding="10px" flexDirection='column'>
                        {/* <Box style={{ display: 'flex' }}>
                                            <Box style={flfo} padding='3px 0'>Brik Name :</Box>
                                            <Box flex={1}>{myplanPayment.brik_name}</Box>
                                        </Box> */}
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Brik Value :</Box>
                          <Box flex={1}>₹ {myplanPayment.brik_value}</Box>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>
                            <Box>Invested Amount :</Box>
                          </Box>
                          <Box flex={1}>₹ {myplanPayment?.invested_amount}</Box>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>My Plan :</Box>
                          <Box flex={1}>{myplanPayment.my_plan}</Box>
                        </Box>
                        {/* <Box style={{ display: 'flex' }}>
                                            <Box style={flfo} padding='3px 0'>Plan Tenure:</Box>
                                            <Box flex={1}>{myplanPayment.plan_tenure}</Box>
                                        </Box> */}
                        {/* <Box style={{ display: 'flex' }}>
                        <Box style={flfo} padding='3px 0'>Booking Amount :</Box>
                        <Box flex={1}>₹ {myplanPayment.booking_amount} <small>(18% Gst)</small></Box>
                      </Box> */}
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Booking Amount :</Box>
                          <Box flex={1}>₹ {myplanPayment.booking_amount_without_gst}</Box>
                        </Box>
                        {myplanPayment.booking_amount_with_gst !== null && myplanPayment.booking_amount_with_gst !== 0 && (
                          <Box style={{ display: 'flex' }}>
                            <Box style={flfo} padding='3px 0'>Booking Amount :</Box>
                            <Box flex={1}>₹ {myplanPayment.booking_amount_with_gst} <small>(18% Gst Incl)</small></Box>
                          </Box>
                        )}

                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Total Amount Paid :</Box>
                          <Box flex={1}>₹ {myplanPayment.total_amount_paid_with_gst} </Box>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Total Due Amount :</Box>
                          <Box flex={1}>₹ {myplanPayment.total_due_amount}</Box>
                        </Box>
                        {myplanPayment.discount_on_booking != null && (
                          <Box style={{ display: 'flex' }}>
                            <Box style={flfo} padding='3px 0'>
                              <Box>Discount :</Box>
                              <Box style={{ color: "#979797" }}>(On Booking Amount)</Box>
                            </Box>
                            <Box flex={1}>₹ {myplanPayment.discount_on_booking}</Box>
                          </Box>
                        )}

                        {myplanPayment.discount_on_brik != null && (
                          <Box style={{ display: 'flex' }}>
                            <Box style={flfo} padding='3px 0'>
                              <Box>Discount :</Box>
                              <Box style={{ color: "#979797" }}>(On Brik Value)</Box>
                            </Box>
                            <Box flex={1}>₹ {myplanPayment.discount_on_brik}</Box>
                          </Box>)}
                      </Box>
                    </Box>

                    {/* <Box className={classes.paymentBox}>
                                    <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                                        <Box style={center} fontWeight='600'>Due Reminder</Box>
                                     
                                    </Box>

                                    <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                                        <Box>
                                            <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {myplanPayment.booking_amount - myplanPayment.booking_amount_paid}</Box>
                                            <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Total Due</Box>
                                        </Box>
                                        <Box textAlign='end'>
                                            <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ 20000</Box>
                                            <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Next Installment</Box>
                                            <Box style={{ color: "#979797", lineHeight: "2.0" }}>Due Date: 10 sep 2021</Box>
                                        </Box>
                                    </Box>
                                </Box> */}

                    <Box className={classes.paymentBox}>
                      <Box>
                        <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                          <Box style={center} fontWeight='600'>Disclaimer</Box>
                          {/* <Box>1</Box> */}
                        </Box>
                        <Box padding="10px" style={{ fontSize: "12px" }}>{myplanPayment.disclaimer}</Box>
                      </Box>
                    </Box>
                  </Box>


                </AccordionDetails>
              </Accordion>


              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ alignItems: "center" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box><img src={CalendarIcon} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Due Reminders</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  {/* <Box className={classes.paymentBox}>
                <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                  <Box style={center} fontWeight='600'>Property Due Reminder</Box>
                </Box>

                <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                  <Box>
                    <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Total Due</Box>
                    <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.property_due_remainder.total_due}</Box>
                  </Box>
                  <Box textAlign='end'>
                    <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Next Installment</Box>
                    <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.property_due_remainder.next_installment}</Box>
                    <Box style={{ color: "#979797", lineHeight: "2.0" }}>Due Date: {dueRemainders.property_due_remainder.due_date !== undefined ? moment(dueRemainders.property_due_remainder.due_date).format("DD MMM YYYY") : "-"}</Box>
                  </Box>
                </Box>
              </Box> */}

                  <Box className={classes.paymentBox}>
                    <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                      <Box style={center} fontWeight='600'>Property Due Reminder</Box>
                      {/* <Box>1</Box> */}
                    </Box>

                    <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                      <Box>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Next Installment</Box>
                        <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders?.property_due_remainder?.next_installment}</Box>
                      </Box>
                      <Box textAlign='end'>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Due Date:</Box>
                        {/* <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.property_due_remainder.next_installment}</Box> */}
                        <Box style={{ color: "#979797", lineHeight: "2.0" }}>{dueRemainders.property_due_remainder?.due_date !== undefined ? moment(dueRemainders.property_due_remainder.due_date).format("DD MMM YYYY") : "-"}</Box>
                      </Box>
                    </Box>
                  </Box>


                  <Box className={classes.paymentBox}>
                    <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                      <Box style={center} fontWeight='600'>Maintanance Due Reminder</Box>
                      {/* <Box>1</Box> */}
                    </Box>

                    <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                      <Box>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Total Due</Box>
                        <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ </Box>
                        {/* <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.maintenance_due_remainder.total_annual_due}</Box> */}
                      </Box>
                      <Box textAlign='end'>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Due Date</Box>
                        {/* <Box style={{ color: "#979797", lineHeight: "2.0" }}>{dueRemainders.maintenance_due_remainder.due_date !== undefined ? moment(dueRemainders.maintenance_due_remainder.due_date).format("DD MMM YYYY") : "-"}</Box> */}
                        <Box style={{ color: "#979797", lineHeight: "2.0" }}>-</Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion 
                expanded={expand}
                onChange={(e,expanded) => {
                  if(expanded){
                    setBeneficiaryView('block')
                    }
                  else if(!expanded){
                    setBeneficiaryView('none')
                  }
                  }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ alignItems: "center" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={()=>{setExpand(!expand)}}
                >
                  <Box><img src={Payment} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Payment Plans</Typography>
                  <Box style={{display:beneficiaryView}} >
                    <Box onClick={(e) => {
                      e.stopPropagation();
                      // myplanPayment.payment_plan_id
                      // alert(myplanPayment.payment_plan_id)
                      getSpvTransactionPlan(myplanPayment.payment_plan_id).then(result => {
                        if (result.data.length == 0) {
                          console.log("RESULT:  ---  ", result)
                        } else {
                          setReceivers(result.data.receivers)
                          console.log("RESULT:  ---  ", result)
                          setBeneficiaryView('block')
                          // setExpand(true)
                          setModal(true)
                        }
                      })
                    }} style={{ color: "#319418", cursor: "pointer", fontSize:"16px", marginLeft:"36px" }}><span style={{textDecoration: "underline"}}>View Beneficiary Account Details</span>&nbsp;&#62;</Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column", textAlign: "center" }}>
                  <table style={{ marginTop: "13px" }}>
                    <tr>
                      <td style={{ marginBottom: "10px", color: "#267DFF", fontWeight: "bold" }}>
                        Installment
                    </td>
                      <td style={{ marginBottom: "10px", color: "#267DFF", fontWeight: "bold" }}>
                        Amount
                    </td>
                      <td style={{ marginBottom: "10px", color: "#267DFF", fontWeight: "bold" }}>
                        Due Date
                    </td>
                      <td style={{ marginBottom: "10px", color: "#267DFF", fontWeight: "bold" }}>
                        Status
                    </td>
                      <td style={{ marginBottom: "10px", color: "#267DFF", fontWeight: "bold" }}>
                        Payment Date
                    </td>
                      <td style={{ marginBottom: "10px", color: "#267DFF", fontWeight: "bold" }}>
                        Download
                    </td>
                    </tr>
                    {installments.map((installment, index) => {
                      return (
                        <tr>
                          <td style={{ marginBottom: "10px" }}>
                            {installment.installment}
                          </td>
                          <td style={{ marginBottom: "10px" }}>
                            {installment.instalment_amount}
                          </td>
                          <td style={{ marginBottom: "10px" }}>
                            {installment.due_date == null ? "-" : moment(installment.due_date).format("DD MMM YYYY")}
                          </td>
                          <td style={{ marginBottom: "10px" }}>
                            {installment.paid ? (
                              <Box style={{ textAlign: "center", padding: "0px 45px" }}>
                                <Box color="#267DFF" padding="3px" border="1px solid white" style={{ backgroundColor: "#D0E3FF" }} borderRadius="7px">Paid</Box>
                              </Box>
                            ) : (
                                <Box style={{ textAlign: "center", padding: "0px 45px" }}>
                                  <Box color="#E05151" padding="3px" border="1px solid white" style={{ backgroundColor: "#FFD7D7" }} borderRadius="7px">Unpaid</Box>
                                </Box>
                              )}
                          </td>
                          <td style={{ marginBottom: "10px" }}>
                            {installment.paid_date == null ? "-" : moment(installment.paid_date).format("DD MMM YYYY")}
                          </td>
                          <td style={{ marginBottom: "10px" }}>
                            {installment.pdf !== null && (<IconButton onClick={() => {
                              // if (installment.pdf !== null)
                              window.open(installment.pdf, '_blank')
                            }} size="small">
                              <GetAppIcon />
                            </IconButton>)}
                          </td>
                        </tr>
                      )
                    })}
                  </table>
                  {/* <Grid container style={{ textAlign: "center" }}>
                  <Grid xs={2} item style={{ marginBottom: "10px", color: "#267DFF", fontWeight: "bold" }}>Installment</Grid>
                  <Grid xs={2} item style={{ color: "#267DFF", fontWeight: "bold" }}>Amount</Grid>
                  <Grid xs={2} item style={{ color: "#267DFF", fontWeight: "bold" }}>Due Date</Grid>
                  <Grid xs={2} item style={{ color: "#267DFF", fontWeight: "bold" }}>Status</Grid>
                  <Grid xs={2} item style={{ color: "#267DFF", fontWeight: "bold" }}>Payment Date</Grid>
                  <Grid xs={2} item style={{ color: "#267DFF", fontWeight: "bold" }}>Download</Grid>


                  {installments.map((installment, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid xs={2} item>{installment.installment}</Grid>
                       
                        <Grid xs={2} item>₹ {installment.instalment_amount}</Grid>
                        <Grid xs={2} item>{installment.due_date == null ? "-" : moment(installment.due_date).format("DD MMM YYYY")}</Grid>
                        <Grid xs={2} item>
                          {installment.paid ? (
                            <Box style={{ textAlign: "center", padding: "0px 45px" }}>
                              <Box color="#267DFF" padding="3px" border="1px solid white" style={{ backgroundColor: "#D0E3FF" }} borderRadius="7px">Paid</Box>
                            </Box>
                          ) : (
                              <Box style={{ textAlign: "center", padding: "0px 45px" }}>
                                <Box color="#E05151" padding="3px" border="1px solid white" style={{ backgroundColor: "#FFD7D7" }} borderRadius="7px">Unpaid</Box>
                              </Box>
                            )}

                        </Grid>
                        <Grid xs={2} item>
                          {installment.paid_date == null ? "-" : moment(installment.paid_date).format("DD MMM YYYY")}
                        </Grid>
                        <Grid xs={2} style={{padding:"2px"}}>
                            <IconButton size="small">
                              <GetAppIcon/>
                            </IconButton>
                        </Grid>
                      </React.Fragment>
                    )
                  })}
                </Grid> */}
                </AccordionDetails>
              </Accordion>



              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >

                  <Box><img src={SettingsIcon} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Maintanance</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  {maintenanceAnnual.map((maintenanceData) => {
                    return (
                      <Box className={classes.paymentBox}>
                        <Box>
                          <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                            <Box style={center} fontWeight='600'>Annual Maintanance Charge</Box>
                            <Box display="flex">
                              {/* <Box style={{ paddingRight: "10px" }}>1</Box> */}
                              <IconButton onClick={() => {
                                window.open(maintenanceData.payment_receipt, '_blank')
                              }} size="small">
                                <img src={DownloadIcon} />
                              </IconButton>
                            </Box>
                          </Box>
                          <Box padding="10px">
                            <Box style={flex}>
                              <Box>
                                <Box style={{ color: "#575757", fontSize: '13px', fontWeight: 700, padding: "2px 0px" }}>₹ {maintenanceData.cost_per_year} /Year</Box>
                                <Box fontSize='11px'>{moment(maintenanceData.payment_date).format("DD MMM YYYY")}</Box>
                              </Box>
                              <Box>
                                <Box style={{ color: "#575757", fontSize: '13px', fontWeight: 700, padding: "2px 0px" }}>Valid Till</Box>
                                <Box fontSize='11px'>{moment(maintenanceData.due_date).format("DD MMM YYYY")}</Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12} sm={12}>
              <Card style={{ marginTop: "30px", padding: "10px" }}>
                <Box display="flex" justifyContent="space-between" >
                  <Box>
                    <Box style={{ fontWeight: 700 }}>NA</Box>
                    <Box style={{ color: "#1FA24A" }}>Income</Box>
                  </Box>
                  <Box>
                    <Box style={{ fontWeight: 700 }}>₹ 10,000</Box>
                    <Box style={{ color: "#F14336" }}>Expense</Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: "10px", fontSize: "12px", padding: "1px", backgroundColor: "#EDDEC066" }}>
                  <ul>
                    <li>Expense is Fixed Annual maintanance charge</li>
                    <li>Booking vacation Coming Soon</li>
                  </ul>
                </Box>
              </Card>
              <Box paddingTop="20px">
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="secondary"
                  classes={{ indicator: classes.indicatorTabs }}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  aria-label="disabled tabs example"
                >
                  <Tab style={{ fontSize: "12px", fontWeight: "600" }} classes={{ selected: classes.selectedTab }} value="income" label="INCOME" />
                  <Tab style={{ fontSize: "12px", fontWeight: "600" }} classes={{ selected: classes.selectedTab }} value="expense" label="EXPENSE" />
                </Tabs>
                <TabPanel index="income" value={value}>
                  INCOME TAB
                        </TabPanel>
                <TabPanel index="expense" value={value}>
                  {maintenanceAnnual.map((maintenanceData) => {
                    return (
                      <Card style={{ marginTop: "10px", padding: "10px" }}>
                        <Box alignItems="center" display="flex">
                          <Box>
                            <img height="24px" width="42px" src={DbIcon} />
                          </Box>
                          <Box paddingLeft="10px">
                            <Box style={{ fontSize: "18px", fontWeight: 600, color: "#575757" }}>₹ {maintenanceData.cost_per_year}</Box>
                            <Box style={{ color: "#979797" }}>Annual Maintanance Charge</Box>
                          </Box>
                        </Box>
                        <Box marginTop="10px" justifyContent="space-between" display="flex">
                          <Box>
                            <Box style={{ color: "#3183FF" }}><img src={TickIcon} /> <Box component="span" paddingLeft="20">Paid</Box></Box>
                            <Box style={{ color: "#979797", lineHeight: 2, fontSize: "12px" }}>{moment(maintenanceData.payment_date).format("DD MMM YYYY")}</Box>
                          </Box>
                          <Box>
                            <Box style={{ color: "#3183FF", fontSize: "13px", fontWeight: 600 }}>Valid till</Box>
                            <Box style={{ color: "#979797", lineHeight: 2, fontSize: "12px" }}>{moment(maintenanceData.due_date).format("DD MMM YYYY")}</Box>
                          </Box>
                        </Box>
                      </Card>
                    )
                  })}
                </TabPanel>
              </Box>
            </Grid>
          </Grid>

        </Grid>
      </Box>

      <Box className={responsive.app}>
        <Grid container >
          <Grid item spacing={3} xs={12} sm={12}>
            {maintananceData.map((item, index) => {
              return <Button onClick={() => {
                setSelectedBrik(index)
              }} variant={brik == index ? "contained" : "outlined"} color="secondary">Brik {index + 1}</Button>
            })}
            {/* <Grid item xs={12}>
              <Box fontSize="22px" fontWeight="bold">{propertyDetails.property_name}</Box>
            </Grid>
            <Grid item xs={12}>
              <DetailCard propertyDetails={propertyDetails} />
            </Grid> */}


            <Grid item xs={12} sm={12} className={classes.AccordionDetails}>
              <Accordion expanded={dueRemainderStatus} onClick={() => {
                setDueRemainderStatus((state) => {
                  return !state
                })
              }} style={{ marginTop: "30px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ alignItems: "center" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box><img src={PaymentIcon} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Payment Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box style={{ width: "100%" }}>
                    <Box className={classes.paymentBox}>
                      <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                        <Box style={center} fontWeight='600'>Payment Info</Box>
                        {/* <Box>1</Box> */}
                      </Box>
                      {/* <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5' }}>
                                        <Box style={center}>
                                            <Box fontWeight='600'>Payment Info</Box>
                                        </Box>
                                    </Box> */}
                      <Box style={{ ...flex, padding: "10px", fontSize: "12px" }} display='flex' padding="10px" flexDirection='column'>
                        {/* <Box style={{ display: 'flex' }}>
                                            <Box style={flfo} padding='3px 0'>Brik Name :</Box>
                                            <Box flex={1}>{myplanPayment.brik_name}</Box>
                                        </Box> */}
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Brik Value :</Box>
                          <Box flex={1}>₹ {myplanPayment.brik_value}</Box>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>
                            <Box>Invested Amount :</Box>
                          </Box>
                          <Box flex={1}>₹ {myplanPayment?.invested_amount}</Box>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>My Plan :</Box>
                          <Box flex={1}>{myplanPayment.my_plan}</Box>
                        </Box>
                        {/* <Box style={{ display: 'flex' }}>
                                            <Box style={flfo} padding='3px 0'>Plan Tenure:</Box>
                                            <Box flex={1}>{myplanPayment.plan_tenure}</Box>
                                        </Box> */}
                        {/* <Box style={{ display: 'flex' }}>
                        <Box style={flfo} padding='3px 0'>Booking Amount :</Box>
                        <Box flex={1}>₹ {myplanPayment.booking_amount} <small>(18% Gst)</small></Box>
                      </Box> */}
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Booking Amount :</Box>
                          <Box flex={1}>₹ {myplanPayment.booking_amount_without_gst}</Box>
                        </Box>
                        {myplanPayment.booking_amount_with_gst && (
                          <Box style={{ display: 'flex' }}>
                            <Box style={flfo} padding='3px 0'>Booking Amount :</Box>
                            <Box flex={1}>₹ {myplanPayment.booking_amount_with_gst} <small>(18% Gst Incl)</small></Box>
                          </Box>
                        )}
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Total Amount Paid :</Box>
                          <Box flex={1}>₹ {myplanPayment.total_amount_paid_with_gst} </Box>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>Total Due Amount :</Box>
                          <Box flex={1}>₹ {myplanPayment.total_due_amount}</Box>
                        </Box>
                        {myplanPayment.discount_on_booking != null && (
                          <Box style={{ display: 'flex' }}>
                            <Box style={flfo} padding='3px 0'>
                              <Box>Discount :</Box>
                              <Box style={{ color: "#979797" }}>(On Booking Amount)</Box>
                            </Box>
                            <Box flex={1}>₹ {myplanPayment.discount_on_booking}</Box>
                          </Box>
                        )}

                        {myplanPayment.discount_on_brik != null && (
                          <Box style={{ display: 'flex' }}>
                            <Box style={flfo} padding='3px 0'>
                              <Box>Discount :</Box>
                              <Box style={{ color: "#979797" }}>(On Brik Value)</Box>
                            </Box>
                            <Box flex={1}>₹ {myplanPayment.discount_on_brik}</Box>
                          </Box>)}
                        {/* <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>
                            <Box>Discount :</Box>
                            <Box style={{ color: "#979797" }}>(On Booking Amount)</Box>
                          </Box>
                          <Box flex={1}>₹ {myplanPayment.discount_on_booking}</Box>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Box style={flfo} padding='3px 0'>
                            <Box>Discount :</Box>
                            <Box style={{ color: "#979797" }}>(On Brik Value)</Box>
                          </Box>
                          <Box flex={1}>₹ {myplanPayment.discount_on_brik}</Box>
                        </Box> */}
                      </Box>
                    </Box>

                    {/* <Box className={classes.paymentBox}>
                                    <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                                        <Box style={center} fontWeight='600'>Due Reminder</Box>
                                     
                                    </Box>

                                    <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                                        <Box>
                                            <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {myplanPayment.booking_amount - myplanPayment.booking_amount_paid}</Box>
                                            <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Total Due</Box>
                                        </Box>
                                        <Box textAlign='end'>
                                            <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ 20000</Box>
                                            <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Next Installment</Box>
                                            <Box style={{ color: "#979797", lineHeight: "2.0" }}>Due Date: 10 sep 2021</Box>
                                        </Box>
                                    </Box>
                                </Box> */}

                    <Box className={classes.paymentBox}>
                      <Box>
                        <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                          <Box style={center} fontWeight='600'>Disclaimer</Box>
                          {/* <Box>1</Box> */}
                        </Box>
                        <Box padding="10px" style={{ fontSize: "12px" }}>{myplanPayment.disclaimer}</Box>
                      </Box>
                    </Box>
                  </Box>


                </AccordionDetails>
              </Accordion>


              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ alignItems: "center" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box><img src={CalendarIcon} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Due Reminders</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  {/* <Box className={classes.paymentBox}>
                <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                  <Box style={center} fontWeight='600'>Property Due Reminder</Box>
                </Box>

                <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                  <Box>
                    <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Total Due</Box>
                    <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.property_due_remainder.total_due}</Box>
                  </Box>
                  <Box textAlign='end'>
                    <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Next Installment</Box>
                    <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.property_due_remainder.next_installment}</Box>
                    <Box style={{ color: "#979797", lineHeight: "2.0" }}>Due Date: {dueRemainders.property_due_remainder.due_date !== undefined ? moment(dueRemainders.property_due_remainder.due_date).format("DD MMM YYYY") : "-"}</Box>
                  </Box>
                </Box>
              </Box> */}

                  <Box className={classes.paymentBox}>
                    <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                      <Box style={center} fontWeight='600'>Property Due Reminder</Box>
                      {/* <Box>1</Box> */}
                    </Box>

                    <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                      <Box>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Next Installment</Box>
                        <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders?.property_due_remainder?.next_installment}</Box>
                      </Box>
                      <Box textAlign='end'>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Due Date:</Box>
                        {/* <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.property_due_remainder.next_installment}</Box> */}
                        <Box style={{ color: "#979797", lineHeight: "2.0" }}>{dueRemainders?.property_due_remainder?.due_date !== undefined ? moment(dueRemainders.property_due_remainder.due_date).format("DD MMM YYYY") : "-"}</Box>
                      </Box>
                    </Box>
                  </Box>


                  <Box className={classes.paymentBox}>
                    <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                      <Box style={center} fontWeight='600'>Maintanance Due Reminder</Box>
                    </Box>

                    <Box style={{ ...flex, fontSize: "12px", padding: "10px" }} >
                      <Box>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Total Due</Box>
                        <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ </Box>
                        {/* <Box style={{ fontSize: "14px", fontWeight: 600 }}>₹ {dueRemainders.maintenance_due_remainder.total_annual_due}</Box> */}
                      </Box>
                      <Box textAlign='end'>
                        <Box style={{ fontSize: "13px", color: "#B88952", fontWeight: 600 }}>Due Date</Box>
                        {/* <Box style={{ color: "#979797", lineHeight: "2.0" }}>{dueRemainders.maintenance_due_remainder.due_date !== undefined ? moment(dueRemainders.maintenance_due_remainder.due_date).format("DD MMM YYYY") : "-"}</Box> */}
                        <Box style={{ color: "#979797", lineHeight: "2.0" }}>-</Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ alignItems: "center" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box><img src={Payment} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Payment Plans</Typography>
                </AccordionSummary>

                {
                  installments.map((installment, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Box display="flex" flexDirection="column" padding="0 20px 15px 20px">


                          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box style={{ color: "#267DFF", fontWeight: "bold", paddingBottom: "5px", fontSize: "15px" }}>Installment {installment.installment}</Box>
                            <Box style={{ paddingRight: "10px" }}>
                              {installment.paid && (
                                <IconButton onClick={() => {
                                  if (installment.pdf !== null)
                                    window.open(installment.pdf, '_blank')
                                }} size="small">
                                  <img src={DownloadIcon} />
                                </IconButton>)}
                            </Box>
                          </Box>

                          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box>₹ {installment.instalment_amount}</Box>
                            {installment.paid ? (
                              <Box color="#267DFF" padding="1px 6px" border="1px solid white" style={{ backgroundColor: "#D0E3FF" }} borderRadius="7px">Paid</Box>
                              // <Box>{moment(installment.due_date).format("DD MMM YYYY")}</Box>
                            ) : (
                                <Box color="#E05151" padding="1px 6px" border="1px solid white" style={{ backgroundColor: "#FFD7D7" }} borderRadius="7px">Unpaid</Box>
                              )}
                          </Box>

                          <Box>
                            {installment.paid ?
                              installment.paid_date == null ? "-" : moment(installment.paid_date).format("DD MMM YYYY")
                              :
                              installment.due_date == null ? "-" : moment(installment.due_date).format("DD MMM YYYY")}</Box>
                        </Box>
                      </React.Fragment>
                    )
                  })
                }

              </Accordion>



              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >

                  <Box><img src={SettingsIcon} /></Box>

                  <Typography style={{ paddingLeft: "10px" }} className={classes.heading}>Maintanance</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  {maintenanceAnnual.map((maintenanceData) => {
                    return (
                      <Box className={classes.paymentBox}>
                        <Box>
                          <Box style={{ ...flex, borderBottom: '1px solid #E5E5E5', paddingLeft: "10px", paddingRight: "10px" }}>
                            <Box style={center} fontWeight='600'>Annual Maintanance Charge</Box>
                            <Box display="flex">
                              {/* <Box style={{ paddingRight: "10px" }}>1</Box> */}
                              <IconButton onClick={() => {
                                window.open(maintenanceData.payment_receipt, '_blank')
                              }} size="small">
                                <img src={DownloadIcon} />
                              </IconButton>
                            </Box>
                          </Box>
                          <Box padding="10px">
                            <Box style={flex}>
                              <Box>
                                <Box style={{ color: "#575757", fontSize: '13px', fontWeight: 700, padding: "2px 0px" }}>₹ {maintenanceData.cost_per_year} /Year</Box>
                                <Box fontSize='11px'>{moment(maintenanceData.payment_date).format("DD MMM YYYY")}</Box>
                              </Box>
                              <Box>
                                <Box style={{ color: "#575757", fontSize: '13px', fontWeight: 700, padding: "2px 0px" }}>Valid Till</Box>
                                <Box fontSize='11px'>{moment(maintenanceData.due_date).format("DD MMM YYYY")}</Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            </Grid>


            <Grid item xs={12} sm={12}>
              <Card style={{ marginTop: "30px", padding: "10px" }}>
                <Box display="flex" justifyContent="space-between" >
                  <Box>
                    <Box style={{ fontWeight: 700 }}>NA</Box>
                    <Box style={{ color: "#1FA24A" }}>Income</Box>
                  </Box>
                  <Box>
                    <Box style={{ fontWeight: 700 }}>₹ 10,000</Box>
                    <Box style={{ color: "#F14336" }}>Expense</Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: "10px", fontSize: "12px", padding: "1px", backgroundColor: "#EDDEC066" }}>
                  <ul>
                    <li>Expense is Fixed Annual maintanance charge</li>
                    <li>Booking vacation Coming Soon</li>
                  </ul>
                </Box>
              </Card>
              <Box paddingTop="20px">
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="secondary"
                  classes={{ indicator: classes.indicatorTabs }}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  aria-label="disabled tabs example"
                >
                  <Tab style={{ fontSize: "12px", fontWeight: "600" }} classes={{ selected: classes.selectedTab }} value="income" label="INCOME" />
                  <Tab style={{ fontSize: "12px", fontWeight: "600" }} classes={{ selected: classes.selectedTab }} value="expense" label="EXPENSE" />
                </Tabs>
                <TabPanel index="income" value={value}>
                  INCOME TAB
                        </TabPanel>
                <TabPanel index="expense" value={value}>
                  {maintenanceAnnual.map((maintenanceData) => {
                    return (
                      <Card style={{ marginTop: "10px", padding: "10px" }}>
                        <Box alignItems="center" display="flex">
                          <Box>
                            <img height="24px" width="42px" src={DbIcon} />
                          </Box>
                          <Box paddingLeft="10px">
                            <Box style={{ fontSize: "18px", fontWeight: 600, color: "#575757" }}>₹ {maintenanceData.cost_per_year}</Box>
                            <Box style={{ color: "#979797" }}>Annual Maintanance Charge</Box>
                          </Box>
                        </Box>
                        <Box marginTop="10px" justifyContent="space-between" display="flex">
                          <Box>
                            <Box style={{ color: "#3183FF" }}><img src={TickIcon} /> <Box component="span" paddingLeft="20">Paid</Box></Box>
                            <Box style={{ color: "#979797", lineHeight: 2, fontSize: "12px" }}>{moment(maintenanceData.payment_date).format("DD MMM YYYY")}</Box>
                          </Box>
                          <Box>
                            <Box style={{ color: "#3183FF", fontSize: "13px", fontWeight: 600 }}>Valid till</Box>
                            <Box style={{ color: "#979797", lineHeight: 2, fontSize: "12px" }}>{moment(maintenanceData.due_date).format("DD MMM YYYY")}</Box>
                          </Box>
                        </Box>
                      </Card>
                    )
                  })}
                </TabPanel>
              </Box>
            </Grid>
          </Grid>

        </Grid>
      </Box>
    </>
  )
}

export default Transaction
