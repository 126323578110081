import { Grid, Box, makeStyles, Card, Button, TextField, CircularProgress } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Carousel from '../component/Carousel'
import SliderImage from '../assets/images/Slider1.png'
import PropertyCard from './component/PropertyCard'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMyOwnerships } from '../store/actions/ownershipActions'
import HouseImg from '../assets/images/House.svg'
import { useGeneralProvider } from '../providers/GeneralProvider'
import { responsiveStyles } from '../../src/assets/css/generalStyling'
import { getDocumentById } from '../store/actions/apis'


const useStyles = makeStyles(theme => ({
  slider: { padding: "20px", width: "1000px" },
  [theme.breakpoints.down('sm')]: {
    slider: { width: "100%",padding: "0px" },
  }
}))

function MyOwnership() {
  const responsive = responsiveStyles()
  const classes = useStyles()
  const history = useHistory()
  const general = useGeneralProvider()
  const dispatch = useDispatch()
  const { myOwnerships, isComponentBusy } = useSelector((state) => {
    console.log("REDUX DATA: ", state.myOwnerShip.isComponentBusy)
    return {
      isComponentBusy: state.myOwnerShip.isComponentBusy,
      myOwnerships: state.myOwnerShip.myOwnerShips
    }
  });

  useEffect(() => {
    // getDocumentById('DID220113220325889KDMJCH9YBVBA1W').then(result=>{
    //   console.log("DUCMENT DETAILS DIGIDOC: ",result)
    // })
    myOwnerships.length == 0 && dispatch(fetchMyOwnerships())
    //  .setHeaderComponent(() => {
    //     return <TextField fullWidth variant="outlined" placeholder="search" />
    // });
    general.setHeaderComponent(() => {
      return ""
    });
  }, [])

  if (isComponentBusy) {
    return <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}><CircularProgress color="secondary" /></Box>
  }

  return (
    <Grid container >
      <Grid item className={responsive.web} xs={12}>
        <Box className={classes.slider}>
          <Box style={{ fontSize: "18px", paddingBottom: "12px", paddingLeft: "10px" }}>
            My Ownership
                    </Box>
          {myOwnerships.length==0?<div style={{ display: "flex", padding: "15px", alignItems: "center",justifyContent: "center"}}>No Briks Owned</div>:
          <Carousel responsive={{
            0: {
              items: 1,
            },
            450: {
              items: 2,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 3,
            },
          }} data={myOwnerships} dots={false} swipeable={true} customButtonGroup={false} items={3} loop={false}>
            {(data, index) => {
              return <React.Fragment key={index}>
                <PropertyCard data={data} onClick={() => {
                  history.push(`/detail/${data.id}`)
                }} />
              </React.Fragment>
            }}
          </Carousel>
          }
        </Box>
      </Grid>

      <Grid item className={responsive.app} xs={12} style={{padding:"10px"}}>
        <Box className={classes.slider}>
          <Box style={{ fontSize: "22px", padding: "10px", fontWeight:"600" }}>
            My Ownership
                    </Box>

          {myOwnerships.map((data, index) => {
            return <React.Fragment key={index}>
              <PropertyCard data={data} onClick={() => {
                history.push(`/detail/${data.id}`)
              }} />
            </React.Fragment>
          })}

        </Box>
      </Grid>

      <Grid item xs={12} style={{ padding: '20px 0' }} className={responsive.web}>
        <Box fontWeight='600' fontSize='18px' style={{ paddingBottom: "12px" }}>Wanna buy New Briks?</Box>
        <Card style={{ background: '#282827', color: 'white', fontSize: '10px', padding: '10px' }}>
          <Box display="flex" justifyContent="space-between">

            <Box padding='10px 0'>
              <Box paddingBottom='35px' style={{ fontSize: "12px" }}>Your Owned Properties are turned into boutique Stays which are Unique, inviting and just a little bit special. Why settle for bland and boring when you can have bright and beautiful. Find a exclusive and unforgettable place to stay and take home a memory you’ll treasure forever.              </Box>
              <Box>
                <Button onClick={() => {
                  window.location.href = "https://www.brikitt.com/projects"
                }} color='secondary' variant='contained'>BUY A BRIK</Button>
              </Box>
            </Box>
            <Box>
              <img src={HouseImg} />
            </Box>

          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} style={{ padding: '20px 0' }} className={responsive.app}>
        <Card style={{ background: '#282827', color: 'white', fontSize: '10px', padding: '10px' }}>
          <Box>

            <Box fontWeight='600' fontSize='18px' padding="5px 0">Wanna buy New Briks?</Box>

            <Box padding="5px 0">Your Owned Properties are turned into boutique Stays which are Unique, inviting and just a little bit special. Why settle for bland and boring when you can have bright and beautiful. Find a exclusive and unforgettable place to stay and take home a memory you’ll treasure forever.</Box>

            <Box display='flex' alignItems="center">
              <Box>
                <Button onClick={() => {
                  window.location.href = "https://www.brikitt.com/projects"
                }} variant='contained' color='secondary' style={{ width: "135px" }}>BUY A BRIK</Button>
              </Box>

              <Box >
                <img src={HouseImg} style={{ height: "70px", padding: "0 25px" }} />
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>

    </Grid>
  )
}

export default MyOwnership
